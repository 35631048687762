import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';

import { environment } from "../../environments/environment";
import {AuthService} from "../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (AuthService.isAuthorized())
      return true;

    let returnTo = btoa(
      'https://' + environment.host + state.url
        .replace('authorisation','scam')
    ).replace(/=/g,'');

    this.router.navigate(['/login'], { queryParams: { returnTo }}).then();
    return false;
  }
}
