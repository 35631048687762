import { Component, OnInit } from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {passwordStrength} from "check-password-strength";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import Swal from "sweetalert2";
import {Observable} from "rxjs";
import {AuthService} from "../../services/auth.service";
import {create, supported} from "@github/webauthn-json";
import {SimpleRequest} from "../../services/simple-request";

export type challengeProvider = 'PHONE' | 'OTP' | 'FIDO';

export interface ChallengeRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly challenge: Challenge;
  };
}
export interface ChallengesRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly challenges: Challenge[];
  };
}

export interface Challenge {
  readonly _id: string | undefined;
  readonly provider: challengeProvider;
  name: string | undefined;
  readonly setup: any | undefined;
  readonly flow: string;
  disabled: boolean | undefined;
  readonly last_used: string | undefined;
  readonly timestamp: string | undefined;
}


export type sessionLevel = 'USERNAME_PASSWORD' | 'LONG-LIFE_TOKEN' | 'MINECRAFT';

export interface SessionRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly session: Session;
  };
}
export interface SessionsRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly sessions: Session[];
  };
}

export interface Session {
  readonly _id: string;
  readonly party: any | undefined;
  readonly level: sessionLevel;
  readonly address: string;
  readonly details: SessionDetails;
  valid: boolean;
  readonly last_action: string;
  readonly timestamp: string;
}

export interface SessionDetails {
  readonly userAgent: SessionDetailsUserAgent;
  readonly scopes: string[];
}
export interface SessionDetailsUserAgent {
  readonly browser: SessionDetailsUserAgentDetails;
  readonly os: SessionDetailsUserAgentDetails;
  readonly platform: SessionDetailsUserAgentDetails;
  readonly engine: SessionDetailsUserAgentDetails;
}
export interface SessionDetailsUserAgentDetails {
  readonly type: string | undefined;
  readonly name: string | undefined;
  readonly version: string | undefined;
  readonly versionName: string | undefined;
}


@Component({
  selector: 'security',
  templateUrl: './security.component.html',
})
export class SecurityComponent implements OnInit {
  // @BlockUI('blockPasswordChangeUI') blockPasswordChangeUI: NgBlockUI | undefined;
  passwordGroup!: UntypedFormGroup;
  password_change = {
    password: '',
    new_password: '',
    new_password1: '',
    password_strength: 0,
    submitted: false,
    success: false,
    error: ''
  }

  challenges: Challenge[] = [];

  backup_codes: { code: string; used: boolean }[] = [];
  backup_codes_hidden: boolean = true;

  constructor(
    private httpClient: HttpClient,
  ) { }

  ngOnInit(): void {
    this.httpClient.get<ChallengesRequest>(environment.apiServer + 'users/@me/2fa').subscribe(value => this.challenges = value.data.challenges);
    this.httpClient.get<SimpleRequest<{ backup_codes: { [key: string]: boolean}}>>(environment.apiServer + 'users/@me/backup-codes').subscribe(value => this.updateCodes(value.data.backup_codes));
    this.httpClient.get<SessionsRequest>(environment.apiServer + 'users/@me/sessions').subscribe(value => this.sessions = value.data.sessions);

    this.passwordGroup = new UntypedFormGroup({
      password: new UntypedFormControl('', [
        Validators.required,
      ]),
      new_password: new UntypedFormControl('', [
        Validators.required,
        this.validatePasswordStrength(),
      ]),
      new_password1: new UntypedFormControl('', [
        Validators.required,
        this.validatePasswordMatch(),
      ]),
    });
  }

  updatePassword(): void {
    this.password_change.submitted = true;

    if (this.passwordGroup.status !== "VALID")
      return;

    this.passwordGroup.disable({ onlySelf: true });
    // this.blockPasswordChangeUI!.start('Loading...');

    this.password_change.error = '';


    Swal.fire({
      icon: "warning",

      title: $localize`Achtung!`,
      text: $localize`Achtung wenn du das Passwort jetzt änderst, werden alle anderen Sitzungen beendet!\nMöchtest du fortsetzten?`,

      showCancelButton: true,
      cancelButtonColor: '#dc3545',
      cancelButtonText: $localize`Abbrechen`,
    }).then(result => {
      if (!result.isConfirmed) {
        this.passwordGroup.enable({ onlySelf: true });
        return;
      }

      this.httpClient.post(environment.apiServer + 'users/@me/password', {
        password: this.password.value,
        new_password: this.new_password.value
      }, { params: { noDefError: 1 }}).subscribe({
        next: () => {
          // this.blockPasswordChangeUI!.stop();

          this.password_change.success = true;
          this.passwordGroup.enable({ onlySelf: true });
          this.password.setValue('');
          this.new_password.setValue('');
          this.new_password1.setValue('');
        },
        error: err => {
          const error = err.error;
          this.showError('message' in error ? '[' + error.status + '/' + error.error + '] ' + error.message : '[' + error.status + '/' + error.error + ']');
        }
      });
    });
  }

  get password() {
    if (!this.passwordGroup)
      return new UntypedFormControl();
    return this.passwordGroup.get('password')!;
  }
  get new_password() {
    if (!this.passwordGroup)
      return new UntypedFormControl();
    return this.passwordGroup.get('new_password')!;
  }
  get new_password1() {
    if (!this.passwordGroup)
      return new UntypedFormControl();
    return this.passwordGroup.get('new_password1')!;
  }

  private validatePasswordStrength(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value)
        return null;

      const test = passwordStrength(control.value);
      this.password_change.password_strength = test.id;
      if (test.id <= 1)
        return { too_weak: true };

      return null;
    }
  }
  private validatePasswordMatch(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = this.new_password.value;
      if (password && control.value !== password)
        return { password_missMatch: true };

      return null;
    }
  }

  private showError(error: string) {
    // this.blockPasswordChangeUI!.stop();
    this.passwordGroup.enable({ onlySelf: true });
    this.password_change.error = error;
    Swal.fire({
      icon: 'error',
      title: this.password_change.error,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    }).then();
  }


  get getChallenges(): Challenge[] {
    return this.challenges.sort((a,b) => new Date(a.timestamp!).valueOf() - new Date(b.timestamp!).valueOf());
  }

  newChallengeProvider(provider: challengeProvider): void {
    (this.httpClient.get(environment.apiServer + 'users/@me/2fa/' + provider) as Observable<ChallengeRequest>).subscribe(({ status, data: { challenge }}) => {
      let challenge_result: Challenge | undefined = undefined;
      switch (provider) {
        case "OTP":
          Swal.fire({
            title: 'OneTimePassword',
            imageUrl: challenge.setup.imageUrl,
            html: $localize`Scanne den QR Code mit deiner App um ihn hinzufügen zu können.<br><br><span class="text-muted">Du kannst auch diesen link benutzten wenn du mit dem Handy da bist.</span><br><a href="${challenge.setup.link}" class="text-muted">${challenge.setup.secret}</a>`,
            input: "text",
            allowOutsideClick: false,
            showLoaderOnConfirm: true,
            inputValidator: (value: string) => {
              value = value.trim();

              if (!value)
                return $localize`Code darf nicht leer sein!`;

              if (isNaN(parseFloat(value)))
                return $localize`Der Code darf nur aus Zahlen bestehen!`;

              if (value.length < 6)
                return $localize`Der Code ist zu kurz!`;

              if (value.length > 6)
                return $localize`Der Code ist zu lang!`;

              return null;
            },
            preConfirm: (value) => {
              return fetch(environment.apiServer + 'users/@me/2fa/' + provider, { headers: { 'Authorization': `Bearer ${AuthService.getToken()}`, "content-type": "application/json" }, method: 'POST', body: JSON.stringify({ flow: challenge.flow, response: value })})
                .then(response => {
                  if (!response.ok)
                    return response.json().then(data => {
                      switch (data.message) {
                        case 'code does not match':
                          Swal.showValidationMessage($localize`Der Code stimmt nicht!`);
                          break;
                        default:
                          Swal.showValidationMessage(`${data.message}`);
                      }
                    })
                  return response.json().then(result => result.data.challenge);
                })
                .catch(error => {
                  Swal.showValidationMessage(
                    `Request failed: ${error}`
                  )
                });
            },

            showCancelButton: true,
            cancelButtonText: $localize`Abbrechen`,
          }).then(result => {
            if (result.isConfirmed && result.value !== undefined) {
              this.challenges.push(result.value);
              this.renameChallengeProvider(result.value);
            }
          });
          break;

        case "FIDO":
          create({publicKey: challenge.setup}).then(r => {
            (this.httpClient.post(environment.apiServer + 'users/@me/2fa/' + provider, {
              flow: challenge.flow,
              response: r
            }) as Observable<ChallengeRequest>).subscribe(({status, data: {challenge}}) => {
              this.challenges.push(challenge);
              this.renameChallengeProvider(challenge);
            });
          });
          break;
      }
    });
  }

  lockChallengeProvider(challenge: Challenge): void {
    Swal.fire({
      icon: "question",

      title: $localize`Bist du dir sicher?`,
      text: $localize`Bist du dir sicher das du diesen Zweifaktor Provider deaktivieren möchtest?`,

      confirmButtonColor: '#dc3545',
      confirmButtonText: $localize`Deaktivieren`,
      showCancelButton: true,
      cancelButtonColor: '#198754',
      cancelButtonText: $localize`Abbrechen`,
    }).then(r => {
      if (!r.isConfirmed)
        return;

      (this.httpClient.patch(environment.apiServer + 'users/@me/2fa/' + challenge._id, { disabled: '1' }) as Observable<ChallengeRequest>).subscribe((data) => {
        challenge.disabled = true;
      });
    });
  }
  unlockChallengeProvider(challenge: Challenge): void {
    (this.httpClient.patch(environment.apiServer + 'users/@me/2fa/' + challenge._id, { disabled: '0' }) as Observable<ChallengeRequest>).subscribe((data) => {
      challenge.disabled = false;
    });
  }

  renameChallengeProvider(challenge: Challenge): void {
    Swal.fire({
      icon: "question",

      title: $localize`Möchtest du "${challenge.name || challenge.provider}" umbenennen?`,

      showCancelButton: true,
      cancelButtonText: $localize`Abbrechen`,

      input: "text",
      inputValue: challenge.name || challenge.provider,
    }).then(result => {
      if (!result.isConfirmed || !result.value)
        return;

      (this.httpClient.patch(environment.apiServer + 'users/@me/2fa/' + challenge._id, { name: result.value }) as Observable<ChallengeRequest>).subscribe((data) => {
        challenge.name = result.value;
      });
    });
  }
  deleteChallengeProvider(challenge: Challenge): void {
    Swal.fire({
      icon: "warning",

      title: challenge.name || challenge.provider,
      text: $localize`Bist du sicher das du ${challenge.name || challenge.provider} löschen möchtest?`,

      confirmButtonColor: '#dc3545',
      confirmButtonText: $localize`Löschen`,
      showCancelButton: true,
      cancelButtonColor: '#198754',
      cancelButtonText: $localize`Abbrechen`,
    }).then(result => {
      if (!result.isConfirmed)
        return;

      (this.httpClient.delete(environment.apiServer + 'users/@me/2fa/' + challenge._id) as Observable<ChallengeRequest>).subscribe((data) => {
        this.challenges = this.challenges.filter(a => a._id !== challenge._id);
      });
    })
  }

  get FIDOSupported() {
    return supported();
  }



  currentSessionId: string = AuthService.getTokenData().jti;
  sessions: Session[] = [];

  get getSessions() {
    return this.sessions.sort((a,b) => new Date(b.timestamp!).valueOf() - new Date(a.timestamp!).valueOf());
  }

  deleteSessions() {
    Swal.fire({
      icon: "warning",

      title: $localize`Achtung`,
      text: $localize`Bist du sicher das du alle Aktiven Sitzungen beenden möchtest?`,
      footer: $localize`<span class="text-muted">Deine jetzige Sitzung ist davon nicht betroffen.</span>`,

      confirmButtonColor: '#dc3545',
      confirmButtonText: $localize`Löschen`,

      showCancelButton: true,
      cancelButtonText: $localize`Abbrechen`,
    }).then(result => {
      if (result.isConfirmed)
        (this.httpClient.delete(environment.apiServer + 'users/@me/sessions') as Observable<SessionRequest>).subscribe((data) => {
          for (let session of this.sessions)
            if (session._id !== this.currentSessionId)
              session.valid = false;
        });
    });
  }

  deleteSession(session: Session) {
    if (!session.valid)
      return;

    if (AuthService.getTokenData().jti === session._id)
      return;

    Swal.fire({
      icon: "warning",

      title: $localize`Achtung`,
      text: $localize`Bist du sicher das du diese Sitzung beenden möchtest?`,

      confirmButtonColor: '#dc3545',
      confirmButtonText: $localize`Löschen`,

      showCancelButton: true,
      cancelButtonText: $localize`Abbrechen`,
    }).then(result => {
      if (result.isConfirmed)
        (this.httpClient.delete(environment.apiServer + 'users/@me/sessions/' + session._id) as Observable<SessionRequest>).subscribe((data) => {
          session.valid = false;
        });
    })
  }

  deleteCodes() {
    this.httpClient.delete<SimpleRequest<{ backup_codes: { [key: string]: boolean}}>>(environment.apiServer + 'users/@me/backup-codes').subscribe(value => this.updateCodes(value.data.backup_codes));
  }

  updateCodes(codes: { [key: string]: boolean}) {
    this.backup_codes = Object.entries(codes).map(value => ({ code: value[0], used: value[1] }));
  }

  copied2Clipboard(share = false) {
    Swal.fire({
      icon: 'success',
      title: $localize`Wiederherstellungs Code in den Zwischenspeicher kopiert`,

      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    }).then();
  }
}
