<div class="page-header">
  <div class="page-block">
    <div class="row">
      <div class="col-md-8">
        <div class="page-header-title">
          <h5 class="m-b-10" i18n>Impressum</h5>
        </div>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-end">
          <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
          <li class="breadcrumb-item" i18n>Legal</li>
          <li class="breadcrumb-item" i18n>Impressum</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="page-content">
  <div class="row">
    <div class="col-md-3">
      <div class="d-grid">
        <button class="btn btn-primary" [routerLinkActive]="['btn-info']" routerLink="/legal/imprint" i18n>Impressum</button>
        <button class="btn btn-primary" [routerLinkActive]="['btn-info']" routerLink="/legal/privacy-policy" i18n>Datenschutz</button>
        <button class="btn btn-primary" [routerLinkActive]="['btn-info']" routerLink="/legal/terms-of-service" i18n>Nutzungsbedingungen</button>
      </div>
    </div>
    <div class="col-md-9">
      <div class="card">
        <div class="card-body">
          <h2>Angaben gemäß § 5 TMG:</h2>
          <p><br>Fabio Scherrers</p>
          <h3>Postanschrift:</h3>
          <p>Sonderfingerstraße, 9<br>72760 Reutlingen</p>
          <h3>Kontakt:</h3>
          <p>E-Mail: contact@night.design</p>
          <br>
          <h2>Hinweise zur Website</h2>
          <h2>Information gemäß § 36 VSBG</h2>
          <p>Gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz über die alternative Streitbeilegung in Verbrauchersachen) erklärt der Betreiber dieser Website:<br>
            Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </div>
      </div>
    </div>
  </div>
</div>
