import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ModderViewerComponent} from "../../modder-viewer.component";
import {Party} from "../../../modder/modder.component";
import {User} from "../../../user/user.service";
import {environment} from "../../../../environments/environment";
import Swal from "sweetalert2";
import {
  concat,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  tap
} from "rxjs";
import {catchError} from "rxjs/operators";
import {UsersRequest} from "../../../services/auth.service";

export interface MemberRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly member: Member;
  }
}

export interface MembersRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly members: Member[];
  }
}

export type Role = 'OWNER' | 'ADMINISTRATOR' | 'DEVELOPER' | 'MODERATOR';

export interface Member {
  readonly _id: string;
  role: Role;
  readonly user: User;
  readonly timestamp: string;
}

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html'
})
export class MembersComponent implements OnInit {
  party!: Party;
  members: Member[] = [];

  userLoading = false;
  userInput = new Subject<string>();
  users: Observable<User[]> = new Observable<User[]>();
  user: User | undefined = undefined;
  selectedRole: Role = 'MODERATOR';

  constructor(
    private http: HttpClient,
    @Inject(ModderViewerComponent) private parent: ModderViewerComponent
  ) {}

  ngOnInit(): void {
    this.loadUsers();
    this.party = this.parent.party;
    this.http.get<MembersRequest>(environment.apiServer + 'mods/' + this.party._id + '/members').subscribe(response => {
      this.members = response.data.members;
    });
  }


  trackByFn(item: User) {
    return item._id;
  }

  loadUsers() {
    this.users = concat(
      of([]),
      this.userInput.pipe(
        distinctUntilChanged(),
        tap(() => this.userLoading = true),
        debounceTime(400),
        switchMap(term => term ? this.http.get<UsersRequest>(environment.apiServer + 'users', { params: { query: term }}).pipe(
          tap(() => this.userLoading = false),
          map<UsersRequest, User[]>(res => res.data.users),
          catchError(() => of([])),
        ) : EMPTY)
      )
    );
  }

  addMember() {
    const user = this.user;
    const role = this.selectedRole;

    this.user = undefined;
    this.selectedRole = this.validRoles.pop()!;

    if (user)
      this.http.post<MemberRequest>(environment.apiServer + 'mods/' + this.party._id + '/members', { user, role }).subscribe(response => this.members.push(response.data.member)
    );
  }

  validRoles: Role[] = ['ADMINISTRATOR','DEVELOPER','MODERATOR'];
  changeRoleMember(member: Member, role?: Role) {
    if (member.role === "OWNER")
      return;

    if (!role) {
      Swal.fire({
        title: $localize`Memberrolle ändern`,

        showConfirmButton: true,
        showCancelButton: true,

        input: "select",
        inputOptions: ['ADMINISTRATOR','DEVELOPER','MODERATOR'],
      }).then(result => {
        if (result.isConfirmed)
          this.changeRoleMember(member, this.validRoles[result.value]);
      });
      return;
    }

    this.http.patch<MembersRequest>(environment.apiServer + 'mods/' + this.party._id + '/members/' + member._id, { role }).subscribe(() => {
      member.role = role;
    });
  }

  deleteMember(member: Member) {
    if (member.role === "OWNER")
      return;

    Swal.fire({
      icon: "warning",

      title: $localize`Achtung!`,
      html: $localize`Bist du dir sicher das du <u>${member.user.username}</u> entfernen möchtest?`,

      showDenyButton: true
    }).then(result => {
      if (result.isConfirmed)
        this.http.delete<MembersRequest>(environment.apiServer + 'mods/' + this.party._id + '/members/' + member._id).subscribe(() => {
          this.members = this.members.filter(member_ => member !== member_);
        });
    })
  }
}
