import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ReCaptchaV3Service} from "ng-recaptcha";
import {HttpClient} from "@angular/common/http";
import {isPlatformBrowser} from "@angular/common";
import {SimpleRequest} from "../../services/simple-request";
import {environment} from "../../../environments/environment";
import {Server, ServerViewerComponent} from "../server-viewer.component";
import {concat, debounceTime, distinctUntilChanged, EMPTY, map, Observable, of, Subject, switchMap, tap} from "rxjs";
import {catchError} from "rxjs/operators";

@Component({
  selector: 'app-vote',
  templateUrl: './vote.component.html',
  styleUrls: ['./vote.component.css']
})
export class ServerVoteComponent implements OnInit {
  isBrowser: boolean;
  server!: Server;

  loadingVotes = true;
  players: PlayerVote[] = [];

  searchLoading = false;
  playerInput = new Subject<string>();
  playersSearch: Observable<Player[]> = new Observable<Player[]>();
  player: string | undefined = undefined;

  constructor(
    private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service,
    @Inject(ServerViewerComponent) private parent: ServerViewerComponent,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.server = this.parent.server!;

    if (this.isBrowser)
      this.loadPlayers();

    this.http.get<SimpleRequest<{ votes: PlayerVote[] }>>(environment.apiServer + 'servers/' + this.server._id + '/votes').subscribe(response => {
      this.players = response.data.votes;
      this.loadingVotes = false;
    });
  }

  get playerVotes() {
    return this.players.sort((a, b) => b.streak - a.streak);
  }

  postPlayerVote() {
    let player_ = this.player;
    this.player = undefined;

    if (!player_)
      return;

    this.http.get<SimpleRequest<{ player: Player }>>(environment.apiServer + 'players/' + player_).subscribe(({ data: { player }}) =>
      this.recaptchaV3Service.execute('social').subscribe(captcha =>
        this.http.post<SimpleRequest>(environment.apiServer + 'servers/' + this.server._id + '/votes', { player: player.uuid, captcha }).subscribe(response => {
          let a = this.players.find(b => player.uuid === b.player.uuid);
          if (a) {
            a.total++;
            a.streak++;
            return;
          }

          this.players.push({
            player: player,
            total: 1,
            streak: 1
          });
        })
      )
    );
  }


  loadPlayers() {
    this.playersSearch = concat(
      of([]),
      this.playerInput.pipe(
        distinctUntilChanged(),
        tap(() => this.searchLoading = true),
        debounceTime(400),
        switchMap(term => term ? this.http.get<SimpleRequest<{ players: Player[]}>>(environment.apiServer + 'players', { params: { query: term }}).pipe(
          tap(() => this.searchLoading = false),
          map<SimpleRequest<{ players: Player[]}>, Player[]>(res => res.data.players),
          catchError(() => of([])),
        ) : EMPTY)
      )
    );
  }

  trackByFn(item: Player) {
    return item;
  }
}

export interface PlayerVote {
  readonly player: Player;
  total: number;
  streak: number;
}

export interface Player {
  readonly uuid: string;
  readonly uuid_: string;
  readonly name: string;
}
