import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {catchError} from "rxjs/operators";
import * as Sentry from "@sentry/angular";
import {AuthService} from "../services/auth.service";
import Swal from "sweetalert2";
import {environment} from "../../environments/environment";

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  lastRequest: HttpRequest<unknown> | undefined;

  constructor(private auth: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.params.has('noDefError')) {
      request = request.clone({
        params: request.params.delete('noDefError')
      });
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse, caught): Observable<any> => {
        if (this.lastRequest === request)
          return caught;
        this.lastRequest = request;
        console.error(error);

        let errorMsg = '';
        if (error.error instanceof ErrorEvent)
          errorMsg = `Error: ${error.error.message}`;
        else
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;

        if ('message' in error.error) {
          const { status, message } = error.error;
          if (["Session got deleted!","User got deleted!","token got expired"].includes(message)) {
            this.auth.refreshTokens();
            return EMPTY;
          }

          errorMsg = `ERROR[${status}]: ${message}`;
        }

        if ('debug' in error.error) {
          if (AuthService.isAuthorized())
            this.auth.getUserData().subscribe(user => {
              Sentry.showReportDialog({
                dsn: environment.sentry,
                eventId: error.error.debug,
                user: {
                  name: user.data.user.username,
                  email: user.data.user.email
                }
              });
            });

          else
            Sentry.showReportDialog({ dsn: environment.sentry, eventId: error.error.debug });

          Swal.fire({
            icon: 'error',
            title: errorMsg,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          }).then();
        } else
          Swal.fire({
            title: $localize`Fehler`,
            text: errorMsg,
            icon: "error"
          }).then();

        console.error(errorMsg);
        return EMPTY;
      })
    );
  }
}
