
<div class="card">
  <div class="card-body">
    <ng-select class="form-select2" [items]="users | async"
               bindLabel="username"
               bindValue="_id"
               typeToSearchText="Bitte gebe mindestens 2 Buchstaben."
               i18n-typeToSearchText
               notFoundText="Es konnte kein Nutzer gefunden werden."
               i18n-notFoundText
               loadingText="Laden..."
               i18n-loadingText
               [trackByFn]="trackByFn"
               [loading]="userLoading"
               [minTermLength]="2"
               [typeahead]="userInput"
               [(ngModel)]="user">
      <ng-template ng-label-tmp let-item="item">
        <img class="me-2" height="25em" width="25em" [src]="item.avatar">
        <span [innerHTML]="item.display"></span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <img class="me-2" height="25em" width="25em" [src]="item.avatar">
        <span [innerHTML]="item.display"></span>
      </ng-template>
    </ng-select>

    <div class="text-center mt-2">
      <input autocomplete="off" type="radio" class="btn-check" name="role" id="role_administrator" [(ngModel)]="selectedRole" value="ADMINISTRATOR">
      <label class="btn btn-outline-warning" for="role_administrator" i18n>Administrator</label>
      <input autocomplete="off" type="radio" class="btn-check" name="role" id="role_developer" [(ngModel)]="selectedRole" value="DEVELOPER">
      <label class="btn btn-outline-primary" for="role_developer" i18n>Entwickler</label>
      <input autocomplete="off" type="radio" class="btn-check" name="role" id="role_moderation" [(ngModel)]="selectedRole" value="MODERATOR" checked>
      <label class="btn btn-outline-success" for="role_moderation" i18n>Moderator</label>

      <br><button class="btn btn-success mt-2" (click)="addMember()" [disabled]="!user" i18n>Benutzer hinzufügen</button>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-header">
    <h5 i18n>Benutzer-Profile</h5>
  </div>

  <div class="table-responsive">
    <table class="card-footer table m-0">
      <thead>
      <tr>
        <th scope="col" i18n>Rolle</th>
        <th scope="col" i18n>Nutzer</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let member of members">
        <td>{{member.role | capitalize}}</td>
        <th scope="row"><span class="ms-3" [innerHTML]="member.user.display"></span></th>
        <td class="text-end">
          <i class="fa-solid fa-edit me-1" [class.text-muted]="member.role === 'OWNER'" (click)="changeRoleMember(member)"></i>
          <i class="fa-solid fa-trash me-1" [class.text-muted]="member.role === 'OWNER'" (click)="deleteMember(member)"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <div class="btn btn-outline-danger" i18n>Owner</div>
          </div>
          <div class="col-md-9">
            Ein Owner darf folgende dinge:
            <ul>
              <li>Statistiken einsehen</li>
              <li>Moderation-arbeiten tätigen</li>
              <li>Mitglieder/Rechte verwalten</li>
              <li>Skripte bearbeiten</li>
              <li>Einstellungen bearbeiten</li>
              <li>Releases erstellen, bearbeiten und löschen</li>
              <li>Projekt überschreiben</li>
              <li>Projekt löschen</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <div class="btn btn-outline-warning" i18n>Administrator</div>
          </div>
          <div class="col-md-9">
            Ein Administrator darf folgende dinge:
            <ul>
              <li>Statistiken einsehen</li>
              <li>Moderation-arbeiten tätigen</li>
              <li>Mitglieder/Rechte verwalten</li>
              <li>Releases erstellen, bearbeiten und löschen</li>
              <li>Skripte bearbeiten</li>
              <li>Einstellungen bearbeiten</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <div class="btn btn-outline-primary" i18n>Entwickler</div>
          </div>
          <div class="col-md-9">
            Ein Entwickler darf folgende dinge:
            <ul>
              <li>Statistiken einsehen</li>
              <li>Releases erstellen und bearbeiten</li>
              <li>Skripte bearbeiten</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <div class="btn btn-outline-success" i18n>Moderator</div>
          </div>
          <div class="col-md-9">
            Ein Moderator darf folgende dinge:
            <ul>
              <li>Statistiken einsehen</li>
              <li>Moderation-arbeiten tätigen</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
