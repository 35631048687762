import {Component, OnInit} from '@angular/core';
import {Role, User} from "../../user/user.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {SimpleRequest} from "../../services/simple-request";
import {UserConnection} from "../../interfaces/user-connections";

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html'
})
export class TeamComponent implements OnInit {
  roles: Role[] = [];
  users: User[] = [];

  userConnections: {[key: string]: UserConnection[]} = {};

  heads = ['Founder','System Administrator'];

  constructor(
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.http.get<SimpleRequest<{ roles: Role[] }>>(environment.apiServer + 'roles?excludes=!project_helper').subscribe(response => {
      this.roles = response.data.roles;
    });
    this.http.get<SimpleRequest<{ users: User[] }>>(environment.apiServer + 'users?excludes=!project_helper').subscribe(response => {
      this.users = response.data.users;
      this.users.map(user => this.http.get<SimpleRequest<{ readonly connections: UserConnection[]; }>>(environment.apiServer + 'users/' + user._id + '/connected-accounts').subscribe(response1 => this.userConnections[user._id] = response1.data.connections.filter(connection => connection.profile.link)));
    });
  }


  get sortedHeadRoles(): Role[] {
    return this.roles.filter(role => this.heads.includes(role.name) && this.sortedUsers(role).length !== 0).sort(((a, b) => b.sort - a.sort));
  }

  get sortedTeamRoles(): Role[] {
    return this.roles.filter(role => !this.heads.includes(role.name) && this.sortedUsers(role).length !== 0).sort(((a, b) => b.sort - a.sort));
  }
  sortedUsers(role: Role): User[] {
    return this.users.filter(user => user.role._id === role._id).sort((a, b) => a.username.localeCompare(b.username));
  }

  getUserConnections(user: User): UserConnection[] {
    return (this.userConnections[user._id] ?? []).slice(0, 4).sort((a, b) => a.provider.localeCompare(b.provider));
  }

  getConnectionIcon(connection: UserConnection): string {
    switch (connection.provider) {
      case "discord":
        return "fa-brands fa-discord fa-2x";
      case "github":
        return "fa-brands fa-github fa-2x";
      case "google":
        return "fa-brands fa-google fa-2x";
      case "twitch":
        return "fa-brands fa-twitch fa-2x";
      case "minecraft":
      case "xbox":
        return "fa-solid fa-cube fa-2x";

      default:
        return 'fa-strong fa-question fa-2x';
    }
  }
}
