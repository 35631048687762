<div class="row" id="resource-search">
  <div class="col-md-3 d-none d-md-block" *ngIf="!forceMobileSettingsPage">
    <ng-container *ngTemplateOutlet="moreSettings"></ng-container>

    <ADPlacement [type]="'side-banner'"></ADPlacement>
  </div>

  <div class="col-12" [class.col-md-9]="!forceMobileSettingsPage">
    <div class="card">
      <div class="card-block">
        <auto-complete id="ResourceName" [addTag]="true" [multiple]="true" [(selectedInput)]="q_" (selectedInputChange)="update()"></auto-complete>
        <!--<input class="form-control" placeholder="Badlion, Optifine, Mojang..." [value]="q" (keyup)="onSearchInput($event)">-->
        <div class="row d-block d-md-none float-end" *ngIf="!forceMobileSettingsPage"><a class="a" (click)="showMoreSettings = !showMoreSettings" i18n>Erweiterter Filter</a></div>
        <div class="row float-end" *ngIf="forceMobileSettingsPage"><a class="a" (click)="showMoreSettings = !showMoreSettings" i18n>Erweiterter Filter</a></div>
      </div>
    </div>

    <ng-container *ngIf="showMoreSettings">
      <ng-container *ngTemplateOutlet="moreSettings"></ng-container>
    </ng-container>

    <h2>
      <span i18n>Suchergebnisse</span>
      <small class="float-end d-none d-md-block" *ngIf="!loadingResults" i18n>Insgesamt: {{statistics.total}}</small>
    </h2>
    <h5 class="d-block d-md-none" *ngIf="!loadingResults" i18n>Insgesamt: {{statistics.total}}</h5>
    <h5 class="d-block d-md-none" *ngIf="loadingResults">&nbsp;</h5>


    <div class="text-center text-danger" style="font-size: 6em;" *ngIf="statistics.total === 0 && !loadingResults">
      <i class="fa-solid fa-face-sad-tear"></i>
      <h1 i18n>Es konnten keine Designs gefunden werden!</h1>
    </div>

    <div class="text-center text-muted" style="font-size: 6em;" *ngIf="loadingResults">
      <i class="fa-duotone fa-loader fa-spin-pulse"></i>
      <h1 i18n>Lädt...</h1>
    </div>

    <div class="row resources" *ngIf="statistics.total !== 0 && !loadingResults">
      <ng-container *ngFor="let resource of resources; let i = index">
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="card">
            <img errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" [lazyLoad]="resource.files.preview.default.hasOwnProperty('image/webp') ? resource.files.preview.default['image/webp']! : resource.files.preview.default[resource.mime]!" class="card-img-top" (onStateChange)="getBackgroundColor($event, resource)" [id]="resource._id" [alt]="resource.name" routerLink="/resources/{{resource._id!}}">
            <div class="card-body">
              <h5 class="card-title" routerLink="/resources/{{resource._id}}">{{resource.name}}</h5>
              <p class="card-text">
                <span class="badge bg-primary" *ngFor="let tag of resource.tags" (click)="addTag(tag)">{{tag}}</span>
                <span class="badge bg-warning" (click)="selectOption(resource.type)">{{resource.type | titlecase}}</span>
              </p>
            </div>

            <ul class="list-group list-group-flush">
              <li class="list-group-item"><a class="text-muted" routerLink="/u/{{resource.user.username}}" [innerHTML]="resource.user.display"></a></li>
              <li class="list-group-item text-muted">
                <i class="fa-solid fa-download"></i>&nbsp;{{resource.downloads | numeral: '0.[0] a'}}
                <span class="float-end">{{resource.timestamp | timeago}}&nbsp;<i class="fa-solid fa-clock"></i></span>
              </li>
            </ul>
          </div>
        </div>

        <ADPlacement class="d-none d-lg-block col-12" [type]="'article-banner'" *ngIf="!forceMobileSettingsPage && page >= 1 && i !== 0 && (i + 1) % 16 === 0"></ADPlacement>
        <ADPlacement class="d-none d-md-block d-lg-none col-12" [type]="'article-banner'" *ngIf="!forceMobileSettingsPage && page >= 1 && i !== 0 && (i + 1) % 9 === 0"></ADPlacement>
        <ADPlacement class="d-block d-md-none col-12" [type]="'article-banner'" *ngIf="!forceMobileSettingsPage && page >= 1 && i !== 0 && (i + 1) % 6 === 0"></ADPlacement>
      </ng-container>
    </div>

    <ul class="pagination pagination-lg justify-content-center" *ngIf="statistics.pages > 1 && !loadingResults">
      <li [class]="page === 0 ? 'page-item disabled' : 'page-item'"><a class="page-link" (click)="prevPage()">Previous</a></li>
      <li [class]="page === page_ ? 'page-item active' : 'page-item'" *ngFor="let page_ of createPageRange()"><a class="page-link" (click)="changePage(page_)">{{+page_ + 1}}</a></li>
      <li [class]="+page+1 >= statistics.pages ? 'page-item disabled' : 'page-item'"><a class="page-link" (click)="nextPage()">Next</a></li>
    </ul>

    <ADPlacement [type]="'bottom-banner'" *ngIf="!forceMobileSettingsPage && statistics.total !== 0 && !loadingResults"></ADPlacement>
  </div>
</div>

<ng-template #moreSettings>
  <div class="row">
    <div class="col-12" [class.col-md-6]="forceMobileSettingsPage">
      <div class="card">
        <div class="card-block">
          <h5 class="sub-title" i18n>Sortierung</h5>
          <select name="sortBy" class="form-select" (change)="onChangeOrder($event)">
            <option value="name:asc" [selected]="order === 'name:asc'" i18n>Namen aufsteigend</option>
            <option value="name:desc" [selected]="order === 'name:desc'" i18n>Namen absteigend</option>
            <option value="downloads:asc" [selected]="order === 'downloads:desc'" i18n>Downloads aufsteigend</option>
            <option value="downloads:desc" [selected]="order === 'downloads:asc'" i18n>Downloads absteigend</option>
            <option value="timestamp:asc" [selected]="order === 'timestamp:asc'" i18n>Alter aufsteigend</option>
            <option value="timestamp:desc" [selected]="order === 'timestamp:desc'" i18n>Alter absteigend</option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-12" [class.col-md-6]="forceMobileSettingsPage">
      <div class="card">
        <div class="card-block">
          <h5 class="sub-title"><span i18n>Typen</span><a class="float-end" (click)="unselectAllOptions()" i18n>[Unselect All]</a></h5>
          <div *ngFor="let type of typeOptions; let last = last">
            <div class="pretty p-default p-thick">
              <input type="checkbox" [value]="type.value" [(ngModel)]="type.checked" (change)="updateOptions()">
              <div class="state p-success">
                <label>{{type.name}}</label>
              </div>
            </div><br *ngIf="!last">
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
