import {Component, Inject, OnInit} from '@angular/core';
import {User} from "../user.service";
import {UserComponent} from "../user.component";

@Component({
  selector: 'app-resources',
  templateUrl: './user-resources.component.html'
})
export class UserResourcesComponent implements OnInit {
  user!: User;

  constructor(
    @Inject(UserComponent) private parent: UserComponent
  ) { }

  ngOnInit(): void {
    this.user = this.parent.user;
  }
}
