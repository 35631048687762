import { Pipe, PipeTransform } from '@angular/core';
import * as num from "numeral";

@Pipe({
  name: 'numeral'
})
export class NumeralPipe implements PipeTransform {

  transform(input: number, format: string): string {
    return num(input).format(format);
  }
}
