<div class="page-content page-noHeader">
  <div class="row pt-5">
    <div class="col-md-12 d-flex justify-content-center">
      <div class="card" *blockUI="'loginCard'">
        <div class="card-block">
          <h3 class="text-center" i18n>Passwort vergessen?</h3>

          <!--[formGroup]="form"-->
          <form>
            <label for="email" class="form-label" i18n>Email Adresse</label>
            <input type="email" class="form-control" id="email" placeholder="player@example.com" formControlName="email" required>


            <div class="d-grid mt-3">
              <button class="btn btn-success" type="button" i18n>Passwort zurücksetzten</button>
            </div>

            <div class="row mt-3">
              <div class="col-md-6 d-grid">
                <button class="btn btn-success" type="button" routerLink="/login" i18n>Anmelden</button>
              </div>
              <div class="col-md-6 d-grid">
                <button class="btn btn-success" type="button" routerLink="/register" i18n>Registrieren</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
