import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Provider} from "../interfaces/user-connections";
import {environment} from "../../environments/environment";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  get pageId(): string {
    return this.route.firstChild!.routeConfig!.path!;
  }
  get pageName(): string {
    const pageId = this.pageId;
    switch (pageId) {
      case 'account':
        return $localize`Allgemeine Einstellungen`;
      case 'notifications':
        return $localize`Benachrichtigungen`;
      case 'connected-accounts':
        return $localize`Verbundene Accounts`;
      case 'account-security':
        return $localize`Account Sicherheit`;

      default:
        return pageId;
    }
  }

  authUrl(provider: Provider) {
    const returnTo = btoa(
      window.location.href
    ).replace(/=/g,'');

    location.href = environment.apiServer + 'auth/' + provider + '?returnTo=' + returnTo;
  }

  get isLoggedInViaThirdParty(): boolean {
    return 'iss' in AuthService.getTokenData();
  }
}
