import {AfterViewChecked, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GameServer, GameserverComponent} from "../gameserver.component";
import {environment} from "../../../environments/environment";
import {SimpleRequest} from "../../services/simple-request";
import {Socket} from "ngx-socket-io";
import {User} from "../../user/user.service";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.css']
})
export class ConsoleComponent implements OnInit, AfterViewChecked {
  authedUser!: User;
  parent!: GameserverComponent;
  server!: GameServer;
  serverStats: GameServerStats[] = [];

  @BlockUI("console")
  blockConsole!: NgBlockUI;
  @ViewChild("console")
  console!: ElementRef;
  lines: ConsoleEntry[] = [];

  doneRegex = new RegExp(/^(\[\s|.+\] )?\[.+INFO\]:? Done.+/);
  warningRegex = new RegExp(/^(\[\s|.+\] )?\[.+WARN\]:?.+/);
  errorRegex = new RegExp(/^(\[\s|.+\] )?\[.+ERROR\]:?.+/);

  addressRegex = new RegExp(/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/g);

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private socket: Socket,
    @Inject(GameserverComponent) private parent_: GameserverComponent,
  ) { }

  ngOnInit(): void {
    this.parent = this.parent_;
    this.server = this.parent_.server;

    this.auth.getUserData().subscribe(response => this.authedUser = response.data.user);

    this.socket.on("gameServer.stats", (serverId: string, stats: GameServerStats) => {
      if (serverId !== this.server._id)
        return;
      this.serverStats.push(stats);
    });
    this.socket.on("gameServer.console.reset", (serverId: string) => {
      if (serverId !== this.server._id)
        return;
      this.lines = [];
    });
    this.socket.on("gameServer.console", (serverId: string, data: ConsoleEntry) => {
      if (serverId !== this.server._id)
        return;
      this.lines.push(data);
      setTimeout(() => this.scrollToBottom(), 100);
    });
    this.http.get<SimpleRequest<{ console: ConsoleEntry[] }>>(environment.apiServer + "gameserver/" + this.server._id + "/console").subscribe(response => {
      this.lines = response.data.console;
    });
  }

  autoScroll = true;
  firstScroll = true;
  ngAfterViewChecked(): void {
    this.updateConsoleStatus();
    this.socket.on("gameServer.status", (serverId: string) => {
      if (this.server._id === serverId)
        this.updateConsoleStatus();
    });

    if (!this.firstScroll || this.lines.length === 0)
      return;
    this.firstScroll = false;
    this.scrollToBottom();
  }
  scrollToBottom() {
    if (this.autoScroll)
      this.console.nativeElement.scrollTop = this.console.nativeElement.scrollHeight;
  }

  @ViewChild("consoleInput")
  commandInput!: ElementRef;
  command = "";
  commandHistoryIndex = 0;
  sendCommandByKey($event: KeyboardEvent) {
    if (["ArrowUp","ArrowDown"].includes($event.key)) {
      const history = this.lines.filter(line => line.user && line.user._id === this.authedUser._id);
      if ($event.key === "ArrowUp" && this.commandHistoryIndex < history.length)
        this.commandHistoryIndex++;
      if ($event.key === "ArrowDown" && this.commandHistoryIndex > 0)
        this.commandHistoryIndex--;

      this.command = history[history.length - this.commandHistoryIndex]?.data ?? "";
      setTimeout(() => {
        const input = this.commandInput.nativeElement as HTMLInputElement;
        input.selectionStart = input.selectionEnd = input.value.length;
        input.focus();
      },0)
    }

    if ($event.key !== "Enter")
      return;

    const command = this.command;
    if (!command)
      return;

    this.commandHistoryIndex = 0;
    this.command = "";
    this.http.post<SimpleRequest<{ console: ConsoleEntry }>>(environment.apiServer + "gameserver/" + this.server._id + "/console", { command }).subscribe(response => {
      //  this.lines.push(response.data.console);
    });
  }

  getConsoleTimestamp(time: string) {
    const date = new Date(time);
    return {
      hours: date.getHours().toLocaleString(undefined, { minimumIntegerDigits: 2}),
      minutes: date.getMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2}),
      seconds: date.getSeconds().toLocaleString(undefined, { minimumIntegerDigits: 2})
    };
  }

  updateConsoleStatus() {
    if (!this.parent.canRunAction("execute") && !this.blockConsole.isActive)
      this.blockConsole.start();
    else if (this.parent.canRunAction("execute") && this.blockConsole.isActive)
      this.blockConsole.stop();
  }

  censorAddresses(data: string) {
    return data.replace(this.addressRegex, '0.0.0.0');
  }

  getLatestStats(): GameServerStats {
    return this.serverStats[this.serverStats.length - 1] ?? new DummyGameServerStats();
  }
  getBarColor(percent: number) {
    if (percent >= 90)
      return "danger";
    if (percent >= 65)
      return "warning";
    return "success";
  }
}

export interface ConsoleEntry {
  readonly _id: string;
  readonly user?: User;
  readonly data: string;
  readonly createdAt: string;
}


export class DummyGameServerStats implements GameServerStats {
  cpu = {
    usage: 0
  };
  disk = {};
  memory = {
    usage: 0,
    used: 0,
    limit: 0
  };
  network = {
    rx: {
      bytes: 0,
      dropped: 0,
      errors: 0,
      packets: 0
    },
    tx: {
      bytes: 0,
      dropped: 0,
      errors: 0,
      packets: 0
    }
  };
}
export interface GameServerStats {
  readonly memory: {
    readonly usage: number,
    readonly used: number
    readonly limit: number
  };
  readonly cpu: {
    readonly usage: number
  };
  readonly disk: {},
  readonly network: {
    readonly rx: {
      readonly bytes: number,
      readonly dropped: number,
      readonly errors: number,
      readonly packets: number
    },
    readonly tx: {
      readonly bytes: number,
      readonly dropped: number,
      readonly errors: number,
      readonly packets: number
    }
  }
}
