<div class="page-header">
  <div class="page-block">
    <div class="row">
      <div class="col-md-8">
        <div class="page-header-title">
          <h5 class="m-b-10" i18n>Publisher</h5>
        </div>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-end">
          <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
          <li class="breadcrumb-item"><a routerLink="/modder" i18n>Modderboard</a></li>
          <li class="breadcrumb-item"><a href="javascript:null" i18n>Publisher</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="page-content" [formGroup]="form" *blockUI="'form'">

  <div class="row justify-content-center">
    <div class="col-md-9">

      <div class="card">
        <div class="card-header"><h5>Metadaten</h5></div>
        <div class="card-body">
          <div class="form-group row">
            <div class="col-md-4 col-lg-2">
              <label for="name" class="block" i18n>Art *</label>
            </div>
            <div class="col-md-8 col-lg-10">
              <div class="row">
                <div class="col-md-6">
                  <div class="d-grid">
                    <input autocomplete="off" type="radio" class="btn-check" name="type" id="type_client" (click)="type = 'CLIENT'" [disabled]="form.disabled" checked>
                    <label class="btn btn-outline-success btn-small" for="type_client" i18n>Client</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-grid">
                    <input autocomplete="off" type="radio" class="btn-check" name="type" id="type_mod" (click)="type = 'MOD'" [disabled]="form.disabled">
                    <label class="btn btn-outline-success btn-small" for="type_mod" i18n>Mod</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row mt-2">
            <div class="col-md-4 col-lg-2">
              <label for="name" class="block" i18n>Namen *</label>
            </div>
            <div class="col-md-8 col-lg-10">
              <input autocomplete="off" id="name" type="text" class="required form-control" formControlName="player" (keyup)="updateSlug()">
<!--              <span class="text-muted" *ngIf="!customSlug && !hasInputError('player') && !hasInputError('slug') && getInputValue('slug')"><span i18n>Kurzlink: </span>https://night.design/mods/{{getInputValue('slug')}} <span (click)="customSlug = true;" i18n>[Berarbeiten]</span></span>-->

              <div class="invalid-feedback" style="display: block" *ngIf="hasInputError('name')"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{displayInputError('name')}}</div>
            </div>
          </div>

          <div class="form-group row mt-2">
            <div class="col-md-4 col-lg-2">
              <label for="slug" class="block" i18n>Kurzlink *</label>
            </div>
            <div class="col-md-8 col-lg-10">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="slug-addon">https://night.design/mods/</span>
                </div>
                <input autocomplete="off" type="text" class="form-control" id="slug" aria-describedby="slug-addon" formControlName="slug" (keyup)="customSlug = true">

                <div class="invalid-feedback" style="display: block" *ngIf="hasInputError('slug')"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{displayInputError('slug')}}</div>
              </div>
            </div>
          </div>

          <div class="form-group row mt-2">
            <div class="col-md-4 col-lg-2">
              <label for="summary" class="block" i18n>Kurzbeschreibung *</label>
            </div>
            <div class="col-md-8 col-lg-10">
              <input autocomplete="off" id="summary" type="text" class="required form-control" formControlName="summary">

              <div class="invalid-feedback" style="display: block" *ngIf="hasInputError('summary')"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{displayInputError('summary')}}</div>
            </div>
          </div>

          <div class="form-group row mt-2">
            <div class="col-md-4 col-lg-2" i18n>Kategorie *</div>
            <div class="col-md-8 col-lg-10">
              <ng-select class="form-select2" [items]="categories"
                         bindValue="id"
                         bindLabel="player"
                         (change)="categories_patch()"
                         formControlName="category">
              </ng-select>

              <div class="invalid-feedback" style="display: block" *ngIf="hasInputError('category')"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{displayInputError('category')}}</div>
            </div>
          </div>

          <div class="form-group row mt-2">
            <div class="col-md-4 col-lg-2" i18n>Zusätzliche Kategorien *</div>
            <div class="col-md-8 col-lg-10">
              <ng-select class="form-select2" [items]="second_categories_items"
                         bindValue="id"
                         bindLabel="player"
                         [multiple]="true"
                         [maxSelectedItems]="3"
                         formControlName="second_categories">
              </ng-select>

              <div class="invalid-feedback" style="display: block" *ngIf="hasInputError('second_categories')"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{displayInputError('second_categories')}}</div>
            </div>
          </div>

          <div class="form-group row mt-2">
            <div class="col-md-4 col-lg-2" i18n>Unterstütze Cosmetics *</div>
            <div class="col-md-8 col-lg-10">
              <ng-select class="form-select2" [items]="supportedCosmetics_items"
                         bindValue="id"
                         bindLabel="player"
                         [multiple]="true"
                         formControlName="supportedCosmetics">
              </ng-select>

              <div class="invalid-feedback" style="display: block" *ngIf="hasInputError('supportedCosmetics')"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{displayInputError('supportedCosmetics')}}</div>
            </div>
          </div>

        </div>
      </div>



      <div class="card">
        <div class="card-header"><h5 i18n>Beschreibung</h5></div>
        <div class="card-body">
          <div class="alert alert-danger" *ngIf="hasInputError('desc')">
            <i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{displayInputError('desc')}}
          </div>

          <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="descEditor" [toolbar]="descToolbar"></ngx-editor-menu>
            <ngx-editor
              class="editor"
              [editor]="descEditor"
              formControlName="desc"
              placeholder="Schreib hier..."
              i18n-placeholder
            ></ngx-editor>
          </div>
        </div>
      </div>



      <div class="card">
        <div class="card-header"><h5 i18n>Fragebogen</h5></div>

        <div class="card-body" *ngIf="triedSubmitting && questions.length !== questionsResultLength()">
          <div class="alert alert-danger">
            <i class="fa-solid fa-circle-exclamation"></i>&nbsp;<span i18n>Der Fragebogen darf nicht unbeantwortet sein!</span>
          </div>
        </div>

        <div [class]="triedSubmitting && questions.length !== questionsResultLength() ? 'table-responsive border border-3 border-danger' : 'table-responsive'">
          <table class="card-footer table m-0">
            <tbody>
            <tr *ngFor="let question of questions; let i = index">
              <th scope="row">{{i + 1}}.</th>
              <th>
                {{question.name}}
                <ng-container *ngIf="question.info"><i class="fa-solid fa-circle-question ms-1" (click)="help(question)"></i></ng-container>
              </th>
              <td>
                <div class="d-grid">
                  <input autocomplete="off" type="radio" class="btn-check" [name]="question.id" id="{{question.id}}_true" autocomplete="off" (click)="setQuestionResult(question, true)" [disabled]="form.disabled">
                  <label class="btn btn-outline-danger btn-small" for="{{question.id}}_true" i18n>Ja</label>
                </div>
              </td>
              <td>
                <div class="d-grid">
                  <input autocomplete="off" type="radio" class="btn-check" [name]="question.id" id="{{question.id}}_false" autocomplete="off" (click)="setQuestionResult(question, false)" [disabled]="form.disabled">
                  <label class="btn btn-outline-success btn-small" for="{{question.id}}_false" i18n>Nein</label>
                </div>
              </td>
              <td>
                <div class="d-grid">
                  <input autocomplete="off" type="radio" class="btn-check" [name]="question.id" id="{{question.id}}_null" autocomplete="off" (click)="setQuestionResult(question, null)" [disabled]="form.disabled">
                  <label class="btn btn-outline-warning btn-small" for="{{question.id}}_null" i18n>Unbekannt</label>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="card-footer text-muted" i18n>
          Es ist nicht schlimm wenn du bei einer oder mehrere Fragen mit Ja beantwortest.<br>
          Dieser Fragebogen hilft uns deinen Client/Mod besser überprüfen zu können.
        </div>
      </div>



      <div class="card">
        <div class="card-header"><h5>
          <span i18n>Dateien</span>
          <span class="float-end">
            <input autocomplete="off" type="radio" class="btn-check" name="release_resources" id="release_files" autocomplete="off" value="LOCAL" [(ngModel)]="filesLocation" [ngModelOptions]="{standalone: true}" checked>
            <label class="btn btn-outline-primary btn-small" for="release_files" i18n>Local Files</label>
<!--            <input autocomplete="off" type="radio" class="btn-check" player="release_resources" id="release_github" autocomplete="off" value="GITHUB" [(ngModel)]="filesLocation" [ngModelOptions]="{standalone: true}">-->
<!--            <label class="btn btn-outline-primary btn-small" for="release_github" i18n><i class="fa-brands fa-github"></i> GitHub</label>-->
        </span>
        </h5></div>
        <div class="card-body">
          <div class="form-group row">
            <div class="col-md-4 col-lg-2">
              <label for="release" class="block" i18n>Release Namen *</label>
            </div>
            <div class="col-md-8 col-lg-10">
              <input autocomplete="off" id="release" type="text" class="required form-control" formControlName="release">

              <div class="invalid-feedback" style="display: block" *ngIf="hasInputError('release')"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{displayInputError('release')}}</div>
            </div>
          </div>

          <div class="form-group row mt-2">
            <div class="col-md-4 col-lg-2" i18n>Unterstützte Versionen *</div>
            <div class="col-md-8 col-lg-10">
              <ng-select class="form-select2" [items]="versions"
                         bindValue="id"
                         bindLabel="player"
                         [multiple]="true"
                         formControlName="supportedVersions">
              </ng-select>

              <div class="invalid-feedback" style="display: block" *ngIf="hasInputError('supportedVersions')"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{displayInputError('supportedVersions')}}</div>
            </div>
          </div>

          <div class="form-group row mt-2">
            <div class="col-md-4 col-lg-2">
              <label for="release" class="block" i18n>Was hat sich geändert? *</label>
            </div>
            <div class="col-md-8 col-lg-10">
              <div class="alert alert-danger" *ngIf="hasInputError('releaseDesc')">
                <i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{displayInputError('releaseDesc')}}
              </div>

              <div class="NgxEditor__Wrapper">
                <ngx-editor-menu [editor]="releaseDescEditor" [toolbar]="descToolbar"></ngx-editor-menu>
                <ngx-editor
                  class="editor"
                  [editor]="releaseDescEditor"
                  formControlName="releaseDesc"

                  placeholder="Dies ist unsere allererste Version von unserem Client/Mod, ich hoffe sie gefällt euch!"
                  i18n-placeholder
                ></ngx-editor>
              </div>
            </div>
          </div>

          <hr>

          <ng-container *ngIf="filesLocation === 'LOCAL'">

            <div class="alert alert-danger" *ngIf="triedSubmitting && localFiles.length === 0">
              <i class="fa-solid fa-circle-exclamation"></i>&nbsp;<span i18n>Du musst mindestens eine Datei hochladen!</span>
            </div>

            <ngx-file-drop dropZoneLabel="Drop files here" [multiple]="true" accept=".jar,.txt,.md" (onFileDrop)="droppedFiles($event)">
              <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <span class="text-center"><i class="fa-solid fa-upload fa-5x mb-2"></i><br>
                  <span i18n>Lege hier deine Bilder ab die du hochladen möchtest.</span><br>
                  <button type="button" class="btn btn-outline-primary mt-3" (click)="openFileSelector()">Browse Files</button>
                </span>
              </ng-template>
            </ngx-file-drop>
          </ng-container>

          <ng-container *ngIf="filesLocation === 'GITHUB' && accountsCache.length === 0">
            <div class="h-100 d-flex justify-content-center align-items-center">
              <button class="btn btn-strong btn-github" routerLink="/settings/connected-accounts">Connect with <i class="fa-brands fa-github"></i> GitHub</button>
            </div>
          </ng-container>

          <ng-container *ngIf="filesLocation === 'GITHUB' && accountsCache.length !== 0">
            <div class="form-group row mt-2">
              <div class="col-md-4 col-lg-2" i18n>Account</div>
              <div class="col-md-8 col-lg-10">
                <ng-select class="form-select2" [items]="gitProfiles"
                           bindValue="id"
                           bindLabel="player"
                           (change)="switchGitProfile()"
                           [(ngModel)]="gitProfile">
                </ng-select>
              </div>
            </div>
            <div class="form-group row mt-2">
              <div class="col-md-4 col-lg-2" i18n>Repo</div>
              <div class="col-md-8 col-lg-10">
                <ng-select class="form-select2" [items]="gitRepos"
                           bindValue="id"
                           bindLabel="player"
                           [disabled]="gitRepos.length === 0"
                           (change)="switchGitRepo()"
                           [(ngModel)]="gitRepo">
                </ng-select>
              </div>
            </div>
            <div class="form-group row mt-2">
              <div class="col-md-4 col-lg-2" i18n>Branch</div>
              <div class="col-md-8 col-lg-10">
                <ng-select class="form-select2" [items]="gitRepoBranches"
                           bindValue="id"
                           bindLabel="player"
                           [disabled]="!gitRepo"
                           (change)="switchGitRepoBranch()"
                           [(ngModel)]="gitRepoBranch">
                </ng-select>
              </div>
            </div>



          </ng-container>
        </div>

        <div class="table-responsive">
          <table class="card-footer table m-0">
            <thead>
            <tr>
              <th scope="col" i18n>Datei</th>
              <th scope="col" i18n>Format</th>
              <th scope="col" i18n>Größe</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let file of localFiles">
              <th scope="row" style="width: 1%; white-space: nowrap;">{{file.name}}</th>
              <td style="width: 1%; white-space: nowrap;">{{file.file.type}}</td>
              <td style="width: 1%; white-space: nowrap;">{{file.file.size | numeral: '0.[00] b' }}</td>
              <td class="text-end">
                <i class="fa-regular fa-star text-warning me-2" [class.fa-solid]="file.primary" [class.text-muted]="uploadStatus.status !== 'WAITING'" (click)="primaryLocalFile(file)"></i>
                <i class="fa-regular fa-pencil text-primary me-2" [class.text-muted]="uploadStatus.status !== 'WAITING'" (click)="renameLocalFile(file)"></i>
                <i class="fa-regular fa-trash-alt text-danger" [class.text-muted]="uploadStatus.status !== 'WAITING'" (click)="removeLocalFile(file)"></i>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="card-footer text-center" *ngIf="filesLocation === 'LOCAL' && localFiles.length !== 0">
          <span class="text-warning" *ngIf="uploadStatus.status === 'WAITING'" i18n>Warte auf upload</span>
          <span class="text-warning" *ngIf="uploadStatus.status === 'UPLOADING'" i18n>Wird hochgeladen...</span>
          <span class="text-warning animate__animated animate__pulse animate__infinite" *ngIf="uploadStatus.status === 'PROCESSING'" i18n>Warte auf fertigstellung...</span>
          <span class="text-success" *ngIf="uploadStatus.status === 'DONE'" i18n>Hochgeladen und verarbeitet!</span>
          <span class="text-danger" *ngIf="uploadStatus.status === 'FAILED'">{{uploadStatus.error}}</span>
        </div>
      </div>


      <div class="d-grid">
        <button class="btn" style="font-size: 1.5em" (click)="publish()" [class.btn-outline-dark]="formInvalid || form.disabled || questions.length !== questionsResultLength() || (this.filesLocation === 'LOCAL' && this.localFiles.length === 0)" [class.btn-success]="!formInvalid && !form.disabled && questions.length === questionsResultLength() && ((this.filesLocation === 'LOCAL' && this.localFiles.length !== 0))" i18n>Client/Mod veröffentlichen</button>
      </div>
    </div>
  </div>
</div>
