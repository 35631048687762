import { Component, OnInit } from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['../../login/login.component.css']
})
export class NotFoundComponent {
  private history: string[] = []

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd)
        this.history.push(event.urlAfterRedirects)
    })
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0)
      return this.location.back();

    this.router.navigateByUrl('/').then();
  }
}
