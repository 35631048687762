<div class="page-header">
  <div class="page-block">
    <div class="row">
      <div class="col-md-8">
        <div class="page-header-title">
          <h5 class="m-b-10" i18n>Design: {{resource.name | capitalize: true}}</h5>
        </div>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-end">
          <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
          <li class="breadcrumb-item"><a routerLink="/resources" i18n>Designs</a></li>
          <li class="breadcrumb-item"><a routerLink="/resources" [queryParams]="{types: resource.type}" queryParamsHandling="merge" i18n>{{resource.type | titlecase}}</a></li>
          <li class="breadcrumb-item"><a routerLink="/resources/{{resource._id}}">{{resource.name | capitalize: true}}</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="page-content">
  <div class="alert alert-warning" *ngIf="resource.rendering" i18n><b>Achtung!</b> Diese Datei ist leider noch nicht durch unser Render System durch, das kann bedeuten das die Datei fehlerhaft sein kann und zu anzeigefehler führen kann.</div>

  <div class="row">
    <div class="col-md-5">
      <div class="card" #preview>
        <div class="card-block text-center">
          <img #preview [src]="previewImage" [alt]="resource.name | titlecase" class="img-fluid img-thumbnail" id="prev" (load)="getBackgroundColor(resource)" style="background-image: url('/assets/img/preview-background.webp'); background-repeat: repeat;">
        </div>

        <div class="card-footer text-center">
<!--          <button class="btn btn-success" (click)="toggleDownloadArea()" [disabled]="showDownload"><i class="fas fa-download"></i> Download</button>-->
          <a [href]="this.resource.files.download.default[this.resource.mime]" target="_blank"><button class="btn btn-success"><i class="fas fa-download"></i> Download</button></a>
          <button class="btn btn-warning" (click)="appliedCosmetic()" [hidden]="!authedUser" [disabled]="!cosmeticsValues.includes(resource.type) || resource.mime === 'image/gif'"><i class="fas fa-link"></i> Anwenden</button>
        </div>
      </div>

      <ADPlacement [type]="'side-banner'" [context]="resource" pageType="resource"></ADPlacement>
    </div>

    <div class="col-md-7">
      <div class="downloadArea" [hidden]="!showDownload">
        <div class="card">
          <div class="card-block">

            <div class="row">
              <div class="col-md-6 text-end">
                <a rel="nofollow" href="http://ad.night.design/serve/sitelinks/?id=478535&url={{resource._links.download}}" target="_blank"><button class="btn btn-success"><i class="fas fa-download"></i> Download</button></a>
              </div>

              <div class="col-md-6">
                <button [class]="downloadCooldown !== 0 ? 'btn btn-success disabled' : 'btn btn-success'" (click)="download()"><i class="fas fa-download"></i> Direct Download{{downloadCooldown !== 0 ? ' (' + downloadCooldown + ')' : ''}}</button>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <h5>
            <span i18n>Grund Informationen</span>
            <span class="float-end">
              <reportButton type="resource" [target]="resource" [smallBtn]="true" [disabled]="!authedUser || authedUser._id === resource.user._id"></reportButton>
              <span *ngIf="authedUser && authedUser._id === resource.user._id">
                &nbsp;<button class="btn btn-outline-danger btn-small" (click)="deleteResource()"><i class="fa-solid fa-trash-can"></i></button>
                &nbsp;<button class="btn btn-outline-primary btn-small" (click)="resetChanges(); editPopup.fire()"><i class="fa-solid fa-pencil"></i></button>
              </span>
            </span>
          </h5>
        </div>
        <div class="card-block">
          <p>
            Type: <code routerLink="/resources" [queryParams]="{types: resource.type}" queryParamsHandling="merge">{{resource.type}}</code>
            <br>Mimetype: <code>{{resource.mime}}</code>
            <br>Tags: <span class="badge bg-primary" *ngFor="let tag of resource.tags" routerLink="/resources" [queryParams]="{q: tag, types: resource.type}" queryParamsHandling="merge">{{tag}}</span>
          </p>

          <ng-container *ngIf="resource.desc">
            <div class="divider" i18n>Beschreibung</div>
            <p [innerHTML]="getParsedDesc"></p>
          </ng-container>

          <div class="divider" i18n>Teilen</div>
          <div class="text-center">
            <a rel="nofollow" href="https://www.facebook.com/sharer.php?u={{resource._links.share | encodeURI}}" target="_blank" class="btn btn-facebook"><i class="fa-brands fa-facebook-f"></i> Facebook</a>
            <a rel="nofollow" href="https://twitter.com/intent/tweet?text={{resource.name | encodeURI}}&url={{resource._links.share | encodeURI}}" target="_blank" class="btn btn-twitter"><i class="fa-brands fa-twitter"></i> Twitter</a>
            <a rel="nofollow" href="https://pinterest.com/pin/create/button/?url={{resource._links.share | encodeURI}}" target="_blank" class="btn btn-pinterest"><i class="fa-brands fa-pinterest-p"></i> Pinterest</a>
            <pre style="font-size: 1.2em; margin-top: 0.5em;" ngxClipboard [cbContent]="resource._links.share" (cbOnSuccess)="copied2Clipboard()"><i class="fal fa-link"></i> {{resource._links.share}}</pre>
          </div>
        </div>
      </div>

      <div class="card mini-profile" routerLink="/u/{{resource.user.username}}">
        <div class="card-block">
          <img [src]="resource.user.avatar" [alt]="resource.user.username">
          <div class="d-inline-block">
            <h6><span style="color: #fff" [innerHTML]="resource.user.display"></span></h6>
            <p class="text-muted" *ngIf="resource.user.role">{{resource.user.role.name}}</p>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="divider" i18n>Kommentare</div>
  <commentsArea pageType="resource" [pageId]="resource._id"></commentsArea>
</div>


<swal #editPopup [showCloseButton]="true" [allowOutsideClick]="false" width="60%">
  <ng-container *swalPortal="swalTargets.content">
    <div class="text-start" style="overflow: hidden!important;">
      <div class="divider" i18n>Metadaten</div>
      <div class="form-group row">
        <div class="col-md-4 col-lg-2">
          <label for="ResourceName" class="block" i18n>Cosmetic Namen *</label>
        </div>
        <div class="col-md-8 col-lg-10">
          <input id="ResourceName" type="text" class="required form-control" [(ngModel)]="changes!.name">
        </div>
      </div>
      <div class="form-group row mt-2">
        <div class="col-md-4 col-lg-2" i18n>Sichtbarkeit *</div>
        <div class="col-md-8 col-lg-10">
          <div class="btn" [class.btn-outline-primary]="!changes!.isPublic" [class.btn-success]="changes!.isPublic" (click)="changes!.isPublic = true" i18n>Öffentlich</div>
          <div class="btn" [class.btn-outline-primary]="changes!.isPublic" [class.btn-warning]="!changes!.isPublic" (click)="changes!.isPublic = false" i18n>Nicht gelistet</div>
        </div>
      </div>
      <div class="form-group row mt-2">
        <div class="col-md-4 col-lg-2" i18n>Tags *</div>
        <div class="col-md-8 col-lg-10">
          <!--<ng-select bindLabel=""
                     bindValue=""
                     [addTag]="true"
                     [multiple]="true"
                     [(ngModel)]="changes!.tags">
          </ng-select>-->

          <auto-complete [addTag]="true" [multiple]="true" [(selectedInput)]="changes!.tags"></auto-complete>
        </div>
      </div>


      <div class="divider" i18n>Beschreibung</div>
      <div class="NgxEditor__Wrapper" *ngIf="descEditor">
        <ngx-editor-menu [editor]="descEditor" [toolbar]="descToolbar"></ngx-editor-menu>
        <ngx-editor
          class="editor"
          [editor]="descEditor"
          [(ngModel)]="changes!.desc"
          [disabled]="false"
          placeholder="Schreib hier..."
          i18n-placeholder
        ></ngx-editor>
      </div>

      <ng-container *ngIf="['knight-shields','round-shields'].includes(resource.type)">
        <div class="divider" i18n>Schilder <sup>EXTRA</sup></div>

        <div class="form-group row">
          <div class="col-md-4 col-lg-2" i18n>Schild Farbe</div>
          <div class="col-md-8 col-lg-10">
            <input type="color" class="form-control form-control-color w-100" title="Choose your color" [(ngModel)]="changes!.variables['shield']">
            <span class="badge" style="background-color: #595959!important;" (click)="changes!.variables['shield'] = '#595959'" i18n>Standard</span>
            <span class="badge" [style.background-color]="color + '!important'" (click)="changes!.variables['shield'] = color" *ngFor="let color of suggestions!.colors">&nbsp;</span>
          </div>
        </div>

        <div class="form-group row mt-2">
          <div class="col-md-4 col-lg-2" i18n>Rand Farbe</div>
          <div class="col-md-8 col-lg-10">
            <input type="color" class="form-control form-control-color w-100" title="Choose your color" [(ngModel)]="changes!.variables['border']">
            <span class="badge" style="background-color: #595959!important;" (click)="changes!.variables['border'] = '#595959'" i18n>Standard</span>
            <span class="badge" [style.background-color]="color + '!important'" (click)="changes!.variables['border'] = color" *ngFor="let color of suggestions!.colors">&nbsp;</span>
          </div>
        </div>

        <div class="form-group row mt-2">
          <div class="col-md-4 col-lg-2" i18n>Handgriff Farbe</div>
          <div class="col-md-8 col-lg-10">
            <input type="color" class="form-control form-control-color w-100" title="Choose your color" [(ngModel)]="changes!.variables['handle']">
            <span class="badge" style="background-color: #595959!important;" (click)="changes!.variables['handle'] = '#595959'" i18n>Standard</span>
            <span class="badge" [style.background-color]="color + '!important'" (click)="changes!.variables['handle'] = color" *ngFor="let color of suggestions!.colors">&nbsp;</span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *swalPortal="swalTargets.actions">
    <button class="btn btn-success me-2" (click)="editPopup.close(); patchResource()" [disabled]="!changes!.name || changes!.tags.length < 2" i18n>Speichern</button>
    <button class="btn btn-outline-danger" (click)="editPopup.close();" i18n>Abbrechen</button>
  </ng-container>
</swal>
