<ng-container [formGroup]="metadata">
  <div class="card">
    <div class="card-header"><h5>
      <span i18n>Metadata</span>
      <span class="float-end"><button class="btn btn-small" [class.btn-outline-success]="!(metadata.touched && metadata.valid)" [class.btn-success]="metadata.touched && !formInvalid" [disabled]="formInvalid" (click)="patchMetadata()">Save Changes</button></span>
    </h5></div>
    <div class="card-body">
      <div class="form-group row">
        <div class="col-md-4 col-lg-2">
          <label for="name" class="block" i18n>Namen *</label>
        </div>
        <div class="col-md-8 col-lg-10">
          <input autocomplete="off" id="name" type="text" class="required form-control" formControlName="player">
          <div class="invalid-feedback" style="display: block" *ngIf="hasInputError('name')"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{displayInputError('name')}}</div>
        </div>
      </div>

      <div class="form-group row mt-2">
        <div class="col-md-4 col-lg-2">
          <label for="summary" class="block" i18n>Kurzbeschreibung *</label>
        </div>
        <div class="col-md-8 col-lg-10">
          <input autocomplete="off" id="summary" type="text" class="required form-control" formControlName="summary">

          <div class="invalid-feedback" style="display: block" *ngIf="hasInputError('summary')"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{displayInputError('summary')}}</div>
        </div>
      </div>

      <div class="form-group row mt-2">
        <div class="col-md-4 col-lg-2" i18n>Kategorie *</div>
        <div class="col-md-8 col-lg-10">
          <ng-select class="form-select2" [items]="categories"
                     bindValue="id"
                     bindLabel="player"
                     (change)="categories_patch()"
                     formControlName="category">
          </ng-select>

          <div class="invalid-feedback" style="display: block" *ngIf="hasInputError('category')"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{displayInputError('category')}}</div>
        </div>
      </div>

      <div class="form-group row mt-2">
        <div class="col-md-4 col-lg-2" i18n>Zusätzliche Kategorien *</div>
        <div class="col-md-8 col-lg-10">
          <ng-select class="form-select2" [items]="second_categories_items"
                     bindValue="id"
                     bindLabel="player"
                     [multiple]="true"
                     [maxSelectedItems]="3"
                     formControlName="second_categories">
          </ng-select>

          <div class="invalid-feedback" style="display: block" *ngIf="hasInputError('second_categories')"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{displayInputError('second_categories')}}</div>
        </div>
      </div>

      <div class="form-group row mt-2">
        <div class="col-md-4 col-lg-2" i18n>Unterstütze Cosmetics *</div>
        <div class="col-md-8 col-lg-10">
          <ng-select class="form-select2" [items]="supportedCosmetics_items"
                     bindValue="id"
                     bindLabel="player"
                     [multiple]="true"
                     formControlName="supportedCosmetics">
          </ng-select>

          <div class="invalid-feedback" style="display: block" *ngIf="hasInputError('supportedCosmetics')"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{displayInputError('supportedCosmetics')}}</div>
        </div>
      </div>

    </div>
  </div>

  <div class="card">
    <div class="card-header"><h5>
      <span i18n>Beschreibung</span>
      <span class="float-end"><button class="btn btn-small" [class.btn-outline-success]="!(metadata.touched && metadata.valid)" [class.btn-success]="metadata.touched && !formInvalid" [disabled]="formInvalid" (click)="patchMetadata()">Save Changes</button></span>
    </h5></div>
    <div class="card-body">
      <div class="alert alert-danger" *ngIf="hasInputError('desc')">
        <i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{displayInputError('desc')}}
      </div>

      <div class="NgxEditor__Wrapper">
        <ngx-editor-menu [editor]="descEditor" [toolbar]="descToolbar"></ngx-editor-menu>
        <ngx-editor
          class="editor"
          [editor]="descEditor"
          formControlName="desc"
          placeholder="Schreib hier..."
          i18n-placeholder
        ></ngx-editor>
      </div>
    </div>
  </div>
</ng-container>

<div class="row">
  <div class="col-md-6">
    <div class="card">
      <div class="card-header"><h5 i18n>Icon</h5></div>
      <div class="card-body text-center">
        <div class="alert alert-danger text-start" *ngIf="imageError.icon === 'TOO_BIG'" i18n>Die Dateigröße ist zu groß.</div>
        <div class="alert alert-danger text-start" *ngIf="imageError.icon === 'UNDER_SIZED'" i18n>Die Datei Dimension ist zu klein.</div>
        <div class="alert alert-danger text-start" *ngIf="imageError.icon === 'OVER_SIZED'" i18n>Die Datei Dimension ist zu groß.</div>
        <div class="alert alert-danger text-start" *ngIf="imageError.icon === 'WRONG_ASPECT_RATIO'" i18n>Die Aspect Ratio ist nicht richtig.</div>

        <img class="img-fluid rounded" style="max-height: 15em" [src]="party.metadata.icon || '/assets/img/logo-icon-grey.webp'" alt=""><br>

        <div class="mt-2">
          <button class="btn btn-success" (click)="imageType = 'icon'; uploadImageSelectDialog.click()" [disabled]="uploading" i18n>Hochladen</button>
          <button class="btn btn-outline-danger" (click)="deleteImage('icon')" [disabled]="!party.metadata.icon || uploading" i18n>Löschen</button>
        </div>
      </div>
      <div class="card-footer">
        Aspect Ratio: 1:1<br>
        <span i18n>Minimale Bildgröße</span>: 128×128px<br>
        <span i18n>Maximale Bildgröße</span>: 1024×1024px<br>
        <span i18n>Erlaubte Dateiformate</span>: png, gif<br>
        <span i18n>Maximale Dateigröße</span>: 2.5 MB
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header"><h5 i18n>Banner</h5></div>
      <div class="card-body text-center">
        <div class="alert alert-danger text-start" *ngIf="imageError.banner === 'TOO_BIG'" i18n>Die Dateigröße ist zu groß.</div>
        <div class="alert alert-danger text-start" *ngIf="imageError.banner === 'UNDER_SIZED'" i18n>Die Datei Dimension ist zu klein.</div>
        <div class="alert alert-danger text-start" *ngIf="imageError.banner === 'OVER_SIZED'" i18n>Die Datei Dimension ist zu groß.</div>
        <div class="alert alert-danger text-start" *ngIf="imageError.banner === 'WRONG_ASPECT_RATIO'" i18n>Die Aspect Ratio ist nicht richtig.</div>

        <img class="img-fluid" style="max-height: 15em" [src]="party.metadata.banner || '/assets/img/background_moon.webp'" alt=""><br>

        <div class="mt-2">
          <button class="btn btn-success" (click)="imageType = 'banner'; uploadImageSelectDialog.click()" [disabled]="uploading" i18n>Hochladen</button>
          <button class="btn btn-outline-danger" (click)="deleteImage('banner')" [disabled]="!party.metadata.banner || uploading" i18n>Löschen</button>
        </div>
      </div>
      <div class="card-footer">
        Aspect Ratio: 4:1<br>
        <span i18n>Minimale Bildgröße</span>: 1900×475px<br>
        <span i18n>Maximale Bildgröße</span>: 5700×1425px<br>
        <span i18n>Erlaubte Dateiformate</span>: png, gif<br>
        <span i18n>Maximale Dateigröße</span>: 6 MB
      </div>
    </div>
  </div>

  <input type="file" #uploadImageSelectDialog (change)="patchImage($event)" accept="image/png,image/gif" hidden>
</div>

<!--<div class="card" [formGroup]="moderation">
  <div class="card-header"><h5>
    <span i18n>Moderation</span>
  </h5></div>
  <div class="card-body">
    <div class="form-group row">
      <div class="col-md-4 col-lg-2">
        <label class="block" i18n>Überprüfungs Art</label>
      </div>
      <div class="col-md-8 col-lg-10">
        <div class="row">
          <div class="col-md-3">
            <div class="d-grid">
              <input autocomplete="off" type="radio" class="btn-check" player="type" id="moderation_type_client" [disabled]="moderation.disabled" checked>
              <label class="btn btn-outline-success btn-small" for="moderation_type_client" i18n>Automatisch</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="d-grid">
              <input autocomplete="off" type="radio" class="btn-check" player="type" id="moderation_type_mod" [disabled]="moderation.disabled">
              <label class="btn btn-outline-warning btn-small" for="moderation_type_mod" i18n>Manuel</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="d-grid">
              <input autocomplete="off" type="radio" class="btn-check" player="type" id="moderation_type_pre" [disabled]="moderation.disabled">
              <label class="btn btn-outline-warning btn-small" for="moderation_type_pre" i18n>Pre: Automatisch // Sub: Manuel</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="d-grid">
              <input autocomplete="off" type="radio" class="btn-check" player="type" id="moderation_type_disabled" [disabled]="moderation.disabled">
              <label class="btn btn-outline-danger btn-small" for="moderation_type_disabled" i18n>Keinen</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->
