<div class="card">
  <div class="card-header"><h5>
    <span i18n>Konsole</span>
    <span class="float-end">
      <i class="fa-solid fa-play" (click)="autoScroll = false" *ngIf="autoScroll"></i>
      <i class="fa-solid fa-pause" (click)="autoScroll = true" *ngIf="!autoScroll"></i>
    </span>
  </h5></div>
  <div class="card-block">
    <div class="console" *blockUI="'console'; template: consoleBlock">
      <div #console class="screen">
        <div
          class="line"
          [class.bg-primary]="line.user !== undefined"
          [class.bg-success]="doneRegex.test(line.data)"
          [class.bg-warning]="warningRegex.test(line.data)"
          [class.bg-danger]="errorRegex.test(line.data)"
          *ngFor="let line of lines">
          <span *ngIf="line.user">[{{getConsoleTimestamp(line.createdAt).hours}}:{{getConsoleTimestamp(line.createdAt).minutes}}:{{getConsoleTimestamp(line.createdAt).seconds}}] [CONSOLE/<span routerLink="/u/{{line.user.username}}">{{line.user.username}}</span>]:</span>
          {{censorAddresses(line.data)}}
        </div>
      </div>
      <div class="input">
        <i class="fa-solid fa-angle-right"></i>
        <input type="text" #consoleInput placeholder="Befehl eingabe" [disabled]="!parent.canRunAction('execute')" [(ngModel)]="command" (keydown)="sendCommandByKey($event)">
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <div class="card">
      <div class="card-header"><h5>Speicher auslastung</h5></div>
      <div class="card-body">
        <ngb-progressbar [type]="getBarColor(getLatestStats().memory.usage)" [animated]="getLatestStats().memory.usage >= 80" [striped]="getLatestStats().memory.usage >= 80" [value]="getLatestStats().memory.usage" [showValue]="true"></ngb-progressbar>
        <div>
          {{getLatestStats().memory.used | numeral: '0.0 b' }}
          <span class="float-end">{{getLatestStats().memory.limit | numeral: '0.0 b' }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header"><h5>Kern auslastung</h5></div>
      <div class="card-body">
        <ngb-progressbar [type]="getBarColor(getLatestStats().cpu.usage)" [animated]="getLatestStats().cpu.usage >= 80" [striped]="getLatestStats().cpu.usage >= 80" [value]="getLatestStats().cpu.usage" [showValue]="true"></ngb-progressbar>
        <div>
          0 %
          <span class="float-end">100 %</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header"><h5>Festplatte auslastung</h5></div>
      <div class="card-body">

      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header"><h5>Netzwerk auslastung</h5></div>
      <div class="card-body">

      </div>
    </div>
  </div>
</div>

<ng-template #consoleBlock>
  <div class="block-ui-template text-center">
    <i class="fa-solid fa-5x" [class.fa-ban]="!['STARTING','STOPPING'].includes(server.status)" [class.fa-circle-notch]="['STARTING','STOPPING'].includes(server.status)" [class.fa-spin]="['STARTING','STOPPING'].includes(server.status)" aria-hidden="true"></i>
    <h5 class="mt-4">Server is currently {{server.status | titlecase}}</h5>
  </div>
</ng-template>
