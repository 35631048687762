<div class="page-header">
  <div class="page-block">
    <div class="row">
      <div class="col-md-8">
        <div class="page-header-title">
          <h5 class="m-b-10" i18n>Unser Team</h5>
        </div>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-end">
          <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
          <li class="breadcrumb-item"><a routerLink="/users" i18n>Nutzer</a></li>
          <li class="breadcrumb-item" i18n>Unser Team</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="page-content">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <div class="card-body text-center">
          Wir danken unseren Lieben Team, denn ohne euch würde dieses Projekt nur halb so viel spass machen wie es uns jetzt macht!
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6" *ngFor="let role of sortedHeadRoles">
      <div class="row justify-content-center">
        <div class="divider">{{role.name}}</div>
        <div class="col-md-6" *ngFor="let user of sortedUsers(role)">
          <div class="card mb-3">
            <div class="card-body text-center">
              <img errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" class="rounded-circle" style="width: 50%; height: auto;" [lazyLoad]="user.avatar!" routerLink="/u/{{user.username}}">
            </div>
            <div class="card-footer text-center">
              <h3 [innerHTML]="user.display" routerLink="/u/{{user.username}}"></h3>
            </div>
            <div class="card-footer text-center" [innerHtml]="user.role.work_description" *ngIf="user.role.work_description"></div>
            <div class="card-footer text-center" *ngIf="getUserConnections(user).length !== 0">
              <a class="m-2" *ngFor="let connection of getUserConnections(user)" [href]="connection.profile.link" target="_blank"><i [className]="getConnectionIcon(connection)" [ngbTooltip]="connection.profile.name" placement="top"></i></a>
            </div>
            <div class="card-footer text-center" *ngIf="getUserConnections(user).length === 0">
              <i class="fa-solid fa-user-secret fa-2x text-muted"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center" *ngFor="let role of sortedTeamRoles">
    <div class="divider">{{role.name}} &#40;{{sortedUsers(role).length}}&#41;</div>
    <div class="col-md-3" *ngFor="let user of sortedUsers(role)">
      <div class="card mb-3">
        <div class="card-body text-center">
          <img errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" class="rounded-circle" style="width: 50%; height: auto;" [lazyLoad]="user.avatar!" routerLink="/u/{{user.username}}">
        </div>
        <div class="card-footer text-center">
          <h3 [innerHTML]="user.display" routerLink="/u/{{user.username}}"></h3>
        </div>
        <div class="card-footer text-center" [innerHtml]="user.role.work_description" *ngIf="user.role.work_description"></div>
        <div class="card-footer text-center" *ngIf="getUserConnections(user).length !== 0">
          <a class="m-2" *ngFor="let connection of getUserConnections(user)" [href]="connection.profile.link" target="_blank"><i [className]="getConnectionIcon(connection)" [ngbTooltip]="connection.profile.name" placement="top"></i></a>
        </div>
        <div class="card-footer text-center" *ngIf="getUserConnections(user).length === 0">
          <i class="fa-solid fa-user-secret fa-2x text-muted"></i>
        </div>
      </div>
    </div>
  </div>
</div>
