import { Component, OnInit } from '@angular/core';
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {AuthService, CommonRequest} from "../services/auth.service";
import * as Mailcheck from 'mailcheck';
import {environment} from "../../environments/environment";
import Swal from "sweetalert2";
import {passwordStrength} from 'check-password-strength'
import {gtag} from "../app.component";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['../login/login.component.css']
})
export class RegisterComponent implements OnInit {
  @BlockUI('loginCard') blockLoginUI: NgBlockUI | undefined;
  registerForm = { username: '', email: '', password: '', password_confirm: '' }
  form!: UntypedFormGroup;
  success = false;
  password_strength = 0;

  mailcheck: string | undefined;
  error: string | undefined;
  submitted: boolean = false;
  captchaSubscription: Subscription | undefined;
  authorisationSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      username: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(16),
        Validators.pattern('^[A-Za-z0-9_]{2,16}$'),
      ], [
        this.validateUsernameCheck(),
      ]),
      email: new UntypedFormControl('', [
        Validators.required,
        this.validateEmail(),
      ]),
      password: new UntypedFormControl('', [
        Validators.required,
        this.validatePasswordStrength(),
      ]),
      password_confirm: new UntypedFormControl('', [
        Validators.required,
        this.validatePasswordStrength(),
        this.validatePasswordMatch(),
      ]),
      agb: new UntypedFormControl('', [
        Validators.required,
      ]),
      privacy_police: new UntypedFormControl('', [
        Validators.required,
      ]),
      age: new UntypedFormControl('', [
        Validators.required,
      ]),
    });
  }

  register() {
    this.submitted = true;
    console.log(this.form);
    if (this.form.status !== "VALID")
      return;
    this.form.disable({ onlySelf: true });
    this.blockLoginUI!.start('Loading...');

    this.error = undefined;

    if (this.captchaSubscription)
      this.captchaSubscription.unsubscribe();

    this.captchaSubscription = this.recaptchaV3Service.execute('registration')
      .subscribe(token => {
        this.route.queryParams.subscribe(params => {
          const returnTo = 'returnTo' in params ? params['returnTo'] : undefined;
          const authorisation = this.authService.registration(this.username.value, this.email.value, this.password.value, token, returnTo);
          if (typeof authorisation === "string") {
            this.showError(authorisation);
            return;
          }


          if (this.authorisationSubscription)
            this.authorisationSubscription.unsubscribe();

          this.authorisationSubscription = authorisation.subscribe({
            next: (data: CommonRequest) => {
              this.blockLoginUI!.stop();
              this.success = true;

              gtag('event', 'sign_up', {});

              setTimeout(() => this.router.navigate(['/login'], { queryParams: { returnTo } }).then(), 8000);
            },
            error: err => {
              const error = err.error;
              this.showError('message' in error ? '[' + error.status + '/' + error.error + '] ' + error.message : '[' + error.status + '/' + error.error + ']');
            }
          });
        }).unsubscribe();
      });
  }

  get username() { return this.form.get('username')!; }
  get email() { return this.form.get('email')!; }
  get password() {
    if (!this.form)
      return new UntypedFormControl();
    return this.form.get('password')!;
  }
  get password_confirm() { return this.form.get('password_confirm')!; }

  get agb() { return this.form.get('agb')!; }
  get privacy_police() { return this.form.get('privacy_police')!; }
  get age() { return this.form.get('age')!; }


  private showError(error: string) {
    this.blockLoginUI!.stop();
    this.form.enable({ onlySelf: true });
    this.error = error;
    Swal.fire({
      icon: 'error',
      title: this.error,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    }).then();
  }

  private validateUsernameCheck(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      const username = control.value;

      return new Promise<ValidationErrors | null>((resolve, reject) => {
        fetch(environment.apiServer + 'auth/check-username?username=' + encodeURI(username)).then(async response => {
          console.log(response.status);
          switch (response.status) {
            case 400:
              switch ((await response.json()).message) {
                case 'username doesn\'t match the pattern':
                  resolve({ regex: true });
                  break;
                default:
                  resolve({ error: true });
              }
              break;
            case 410:
              resolve({ gone: true });
              break;
            default:
              resolve(null);
              break;
          }
        });
      });
    }
  }

  private validatePasswordStrength(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value)
        return null;

      const test = passwordStrength(control.value);
      this.password_strength = test.id;
      if (test.id <= 1)
        return { too_weak: true };

      return null;
    }
  }
  private validatePasswordMatch(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = this.password.value;
      if (password && control.value !== password)
        return { password_missMatch: true };

      return null;
    }
  }

  private validateEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const email = control.value.toLowerCase();

      const mailcheck = Mailcheck.run({
        email,
        domains: Mailcheck.defaultDomains,
        secondLevelDomains: Mailcheck.defaultSecondLevelDomains,
        topLevelDomains: Mailcheck.defaultTopLevelDomains
      });
      this.mailcheck = mailcheck && !mailcheck.full.includes(email) ? mailcheck.full : undefined;

      const regex = this.isEmail(email);
      if (regex)
        return {pattern: true};

      return null
    }
  }

  private isEmail(email: string): string | undefined {
    email = (email || '').trim();
    if (email.length === 0)
      return 'Email not provided';
    const split = email.split('@');
    if (split.length < 2)
      return 'Email does not contain "@".';

    else {
      const [domain] = split.slice(-1);
      if (domain.indexOf('.') === -1)
        return 'Must contain a "." after the "@".';
    }

    return undefined;
  }
}
