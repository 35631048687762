<div class="card" *ngIf="server.description">
  <div class="card-body" [innerHTML]="server.description"></div>
</div>

<!--<div class="divider" i18n>Neuigkeiten</div>-->



<div class="divider" i18n>Kommentare</div>
<commentsArea [pageId]="server._id" pageType="server"></commentsArea>
