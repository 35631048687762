<div class="page-header">
  <div class="page-block">
    <div class="row">
      <div class="col-md-8">
        <div class="page-header-title">
          <h5 class="m-b-10" i18n>Publisher</h5>
        </div>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-end">
          <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
          <li class="breadcrumb-item"><a routerLink="/creator" i18n>Creatorboard</a></li>
          <li class="breadcrumb-item"><a href="javascript:null" i18n>Publisher</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="page-content">
  <div class="row mb-3">
    <div class="col-md-4 d-grid"><button class="btn fw-bold" [class.btn-outline-success]="state !== 'WORKSPACE'" [class.btn-success]="selectedWorkspaceFile && state === 'WORKSPACE'" [class.btn-primary]="!selectedWorkspaceFile && state === 'WORKSPACE'" (click)="switchTo('WORKSPACE')" i18n>Arbeitsplatz</button></div>
    <div class="col-md-4 d-grid"><button class="btn fw-bold" [class.btn-outline-success]="selectedType && state !== 'TYPES'" [class.btn-outline-primary]="!selectedType && state !== 'TYPES'" [class.btn-primary]="!selectedType && state === 'TYPES'" [class.btn-success]="selectedType && state === 'TYPES'" (click)="switchTo('TYPES')" [disabled]="!selectedWorkspaceFile" i18n>Cosmetic Type</button></div>
    <div class="col-md-4 d-grid"><button class="btn fw-bold" [class.btn-outline-primary]="state !== 'DETAILS'" [class.btn-primary]="state === 'DETAILS'" (click)="switchTo('DETAILS')" [disabled]="!selectedType" i18n>Metadaten</button></div>
  </div>


  <div class="row" *ngIf="state === 'WORKSPACE'">
    <div class="col-md-12">
      <ngx-file-drop dropZoneLabel="Drop files here" [multiple]="true" [disabled]="uploadingFiles" accept="image/png,image/gif" (onFileDrop)="droppedFiles($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <span class="text-center"><i class="fa-solid fa-upload fa-5x mb-2"></i><br>
            <span i18n>Lege hier deine Bilder ab die du hochladen möchtest.</span><br>
            <button type="button" class="btn btn-outline-primary mt-3" (click)="openFileSelector()" [disabled]="uploadingFiles">Browse Files</button>
          </span>
<!--          <button type="button" (click)="openFileSelector()">Browse Files</button>-->
        </ng-template>
      </ngx-file-drop>

      <div class="card mt-3" *ngIf="files.length !== 0">
        <div class="card-header">
          <h5><span i18n>Dateien Hochladen</span><span class="float-end"><button class="btn btn-success" [disabled]="uploadingFiles" (click)="uploadFiles()" *ngIf="files.length !== 1" i18n>Alle Dateien hochladen</button></span></h5>
        </div>

        <div class="table-responsive">
          <table class="card-footer table m-0">
            <thead>
            <tr>
              <th scope="col" i18n>Datei</th>
              <th scope="col" i18n>Format</th>
              <th scope="col" i18n>Größe</th>
              <th scope="col" i18n>Status</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let file of files">
              <th scope="row" style="width: 1%; white-space: nowrap;">{{file.name}}</th>
              <td style="width: 1%; white-space: nowrap;">{{file.file.type}}</td>
              <td style="width: 1%; white-space: nowrap;">{{file.file.size | numeral: '0.[00] b' }}</td>
              <td style="width: 1%; white-space: nowrap;">
                <span class="text-danger" *ngIf="file.error">{{file.error}}</span>
                <span class="text-warning" *ngIf="file.progress === 0" i18n>Warte auf upload</span>
                <span class="text-warning animate__animated animate__pulse animate__infinite" *ngIf="!file.error && !file.done && file.progress !== 0" i18n>Warte auf fertigstellung</span>
                <span class="text-success" *ngIf="file.done" i18n>Hochgeladen</span>
              </td>
              <td>
                <div class="progress">
                  <div [class]="(file.progress !== 0 && !file.done ? 'progress-bar progress-bar-striped progress-bar-animated' : 'progress-bar')" [class.bg-warning]="!file.error && !file.done" [class.bg-success]="file.done" [class.bg-danger]="file.error" role="progressbar" [style.width]="(file.progress || 0) + '%'" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </td>
              <td class="text-end"><i class="fa-regular fa-trash-alt text-danger" (click)="removeFile(file)" *ngIf="!uploadingFiles && !isUploaded(file)"></i></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>


    <div class="row mt-3">
      <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let workspaceFile of getWorkspaceFiles">
        <div class="card" [style.border]="selectedWorkspaceFile === workspaceFile ? 'var(--bs-success) solid .2em' : 'none'">
          <img errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" [lazyLoad]="workspaceFile.file.image" class="card-img-top" (onStateChange)="getBackgroundColor($event, workspaceFile)" [id]="workspaceFile._id" [alt]="workspaceFile.name" (click)="selectedWorkspaceFile = workspaceFile; switchTo('TYPES')">
          <div class="card-body">
            <h5 class="card-title" (click)="selectedWorkspaceFile = workspaceFile; switchTo('TYPES')">{{workspaceFile.name}}</h5>
          </div>

          <ul class="list-group list-group-flush">
            <li class="list-group-item text-muted"><i class="fa-solid fa-file"></i> {{workspaceFile.file.size | numeral: '0.[00] b'}}</li>
            <li class="list-group-item text-muted">
              <i class="fa-solid fa-clock"></i> {{workspaceFile.timestamp | timeago}}
              <span class="float-end">
                <i class="fa-solid fa-pencil me-2" (click)="patchWorkspaceFile(workspaceFile)"></i>
                <i class="fa-solid fa-trash" (click)="deleteWorkspaceFile(workspaceFile)"></i>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>


    <div class="text-center mt-5" *ngIf="workspaceFiles.length === 0">
      <i class="fa-solid fa-arrow-up text-muted animate__animated animate__bounce animate__infinite" style="font-size: 12em"></i><br>
      <span class="text-muted" style="font-size: 2em" i18n>Dein Fotospeicher ist noch leer.</span>
    </div>
  </div>


  <ng-container *ngIf="state === 'TYPES'">
    <ng-container *ngIf="['HARD','FLAT'].includes(selectedWorkspaceFile!.file.suggestions.possible.mode)">
      <h3 class="text-center" i18n>{matchingTypes(true).length, plural, =1 {Vorgeschlagenes Cosmetic} other {Alle vorgeschlagene Cosmetics}}</h3>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let type of matchingTypes(true)">
          <div class="card" [style.border]="selectedType === type._id ? 'var(--bs-success) solid .2em' : 'none'" (click)="selectedType = type._id; switchTo('DETAILS')">
            <img errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" [lazyLoad]="type.image" class="card-img-top" [id]="type._id" [alt]="type.name">
            <div class="card-body">
              <h5 class="card-title">{{type.name}}</h5>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </ng-container>

    <h3 class="text-center" i18n>Alle verfügbare Cosmetics</h3>
    <div class="row justify-content-center">
      <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let type of types">
        <div class="card" [style.border]="selectedType === type._id ? 'var(--bs-success) solid .2em' : 'none'" (click)="warningMissMatchType(type._id)">
          <img errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" [lazyLoad]="type.image" class="card-img-top" [id]="type._id" [alt]="type.name">
          <div class="card-body">
            <h5 class="card-title">{{type.name}}</h5>
          </div>
        </div>
      </div>
    </div>
  </ng-container>


  <div class="row" *ngIf="state === 'DETAILS'">
    <div class="col-md-7">

      <div class="card">
        <div class="card-header"><h5>Metadaten</h5></div>
        <div class="card-body">


          <div class="form-group row">
            <div class="col-md-4 col-lg-2">
              <label for="ResourceName" class="block" i18n>Cosmetic Namen *</label>
            </div>
            <div class="col-md-8 col-lg-10">
              <auto-complete id="ResourceName" [addTag]="true" [(selectedInput)]="name"></auto-complete>
            </div>
          </div>

          <div class="form-group row mt-2">
            <div class="col-md-4 col-lg-2" i18n>Sichtbarkeit *</div>
            <div class="col-md-8 col-lg-10">
              <div class="btn" [class.btn-outline-primary]="!isPublic" [class.btn-success]="isPublic" (click)="isPublic = true" i18n>Öffentlich</div>
              <div class="btn" [class.btn-outline-primary]="isPublic" [class.btn-warning]="!isPublic" (click)="isPublic = false" i18n>Nicht gelistet</div>
            </div>
          </div>

          <div class="form-group row mt-2">
            <div class="col-md-4 col-lg-2" i18n>Tags *</div>
            <div class="col-md-8 col-lg-10">
              <auto-complete [addTag]="true" [multiple]="true" [(selectedInput)]="tags"></auto-complete>
            </div>
          </div>

        </div>
      </div>

      <div class="card">
        <div class="card-header"><h5 i18n>Beschreibung</h5></div>
        <div class="card-body">
          <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="descEditor" [toolbar]="descToolbar"></ngx-editor-menu>
            <ngx-editor
              class="editor"
              [editor]="descEditor"
              [(ngModel)]="desc"
              [disabled]="false"
              placeholder="Schreib hier..."
              i18n-placeholder
            ></ngx-editor>
          </div>
        </div>
      </div>

      <div class="card" *ngIf="['round-shields', 'knight-shields'].includes(selectedType!)">
        <div class="card-header"><h5 i18n>Schilder <sup>EXTRA</sup></h5></div>
        <div class="card-body">
          <div class="form-group row">
            <div class="col-md-4 col-lg-2" i18n>Schild Farbe</div>
            <div class="col-md-8 col-lg-10">
              <input type="color" class="form-control form-control-color w-100" title="Choose your color" [(ngModel)]="variables['shield']">
              <span class="badge" style="background-color: #595959!important;" (click)="variables['shield'] = '#595959'" i18n>Standard</span><span class="badge" [style.background-color]="color + '!important'" (click)="variables['shield'] = color" *ngFor="let color of selectedWorkspaceFile!.file.suggestions.colors">&nbsp;</span>
            </div>
          </div>

          <div class="form-group row mt-2">
            <div class="col-md-4 col-lg-2" i18n>Rand Farbe</div>
            <div class="col-md-8 col-lg-10">
              <input type="color" class="form-control form-control-color w-100" title="Choose your color" [(ngModel)]="variables['border']">
              <span class="badge" style="background-color: #595959!important;" (click)="variables['border'] = '#595959'" i18n>Standard</span><span class="badge" [style.background-color]="color + '!important'" (click)="variables['border'] = color" *ngFor="let color of selectedWorkspaceFile!.file.suggestions.colors">&nbsp;</span>
            </div>
          </div>

          <div class="form-group row mt-2">
            <div class="col-md-4 col-lg-2" i18n>Handgriff Farbe</div>
            <div class="col-md-8 col-lg-10">
              <input type="color" class="form-control form-control-color w-100" title="Choose your color" [(ngModel)]="variables['handle']">
              <span class="badge" style="background-color: #595959!important;" (click)="variables['handle'] = '#595959'" i18n>Standard</span><span class="badge" [style.background-color]="color + '!important'" (click)="variables['handle'] = color" *ngFor="let color of selectedWorkspaceFile!.file.suggestions.colors">&nbsp;</span>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="col-md-5">

      <div class="card">
        <div class="card-block">
          <img class="img-fluid" [src]="selectedWorkspaceFile!.file.image" [alt]="selectedWorkspaceFile!.name">
        </div>
      </div>

      <div class="d-grid"><button class="btn btn-success" [disabled]="cosmeticPublished" (click)="publishResource($event)" i18n>Veröffentlichen</button></div>
    </div>
  </div>

</div>
