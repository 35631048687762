import {Component, OnInit} from '@angular/core';
import {Party} from "../modder/modder.component";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import {PartyRequest} from "../modder-publisher/modder-publisher.component";
import {Cosmetics} from "../interfaces/player-cosmetics";
import {Member, MembersRequest, Role} from "./settings/members/members.component";
import {AuthService} from "../services/auth.service";

export interface StatisticsRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly dataset: DataSet[];
  }
}

export interface DataSet {
  readonly type: Cosmetics;
  readonly total: number;
  readonly date: string;
}

@Component({
  selector: 'app-modder-viewer',
  templateUrl: './modder-viewer.component.html'
})
export class ModderViewerComponent implements OnInit {
  party!: Party;
  memberView: Member | undefined = undefined;

  page: 'moderation' | 'settings' | undefined = undefined;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    // @ts-ignore
    this.route.firstChild!.url.subscribe(url => this.page = url[0].path);
    this.route.params.subscribe(params => {
      this.http.get<PartyRequest>(environment.apiServer + 'mods/' + params['id']).subscribe(request => {
        this.party = request.data.mod;

        this.auth.getUserData().subscribe(user => {
          this.http.get<MembersRequest>(environment.apiServer + 'mods/' + this.party._id + '/members').subscribe(response => {
            const member = response.data.members.find(member => member.user._id === user.data.user._id);
            if (member)
              this.memberView = member;
          });
        })
      });
    });
  }

  hasMemberRole(roles: Role | Role[]): boolean {
    if (!Array.isArray(roles))
      roles = [roles];

    if (!this.memberView)
      return true;

    return roles.some(role => this.memberView?.role === role);
  }
}

