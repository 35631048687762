import {AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild} from '@angular/core';
import {Resource, ResourceService, ResourceSuggestions} from "./resource.service";
import {ActivatedRoute, Router} from "@angular/router";
import Swal, {SweetAlertOptions} from 'sweetalert2'
import {environment} from "../../environments/environment";
import {UserConnectionsRequest} from "../interfaces/user-connections";
import {HttpClient} from "@angular/common/http";
import {
  Cosmetics,
  cosmeticsValues as cosmeticsValues_,
  localizeCosmetic,
  PlayerCosmeticRequest
} from "../interfaces/player-cosmetics";
import FastAverageColor from "fast-average-color";
import {WindowService} from "../window.service";
import { Title, Meta } from '@angular/platform-browser';
import {DOCUMENT, isPlatformBrowser} from "@angular/common";
import {AuthService} from "../services/auth.service";
import {User} from "../user/user.service";
import {SwalPortalTargets} from "@sweetalert2/ngx-sweetalert2";
import {Editor, Toolbar, toDoc, toHTML} from "ngx-editor";
import {SimpleRequest} from "../services/simple-request";

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styles: [
    '.badge { margin: 0.2em; }',
    '.card-img-top-inverted { background-image: url("../../assets/img/preview-background-inverted.webp")!important;}',
  ]
})
export class ResourceComponent implements OnInit, AfterViewInit {
  isBrowser: boolean;
  authedUser?: User;

  resource: Resource = this.resourceService.dummy();

  cosmeticsValues: Cosmetics[] = cosmeticsValues_;

  showDownload: boolean = false;

  @ViewChild('preview')
  private previewRef: ElementRef | undefined;
  previewImage: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private resourceService: ResourceService,
    private http: HttpClient,


    private window: WindowService,

    private auth: AuthService,
    public swalTargets: SwalPortalTargets,

    private titleService: Title,
    private metaTagService: Meta,
    private render: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser)
      this.descEditor = new Editor();
  }

  ngOnInit(): void {
    if (AuthService.isAuthorized())
      this.auth.getUserData().subscribe(user => this.authedUser = user.data.user);

    this.route.params.subscribe(params => {
      let id = params['id'];
      this.loadResource(id);
    });
  }

  loadResource(id: string) {
    // @ts-ignore
    this.resourceService.getResource(id)
      .subscribe({
        next: (data: SimpleRequest<{ resource: Resource, suggestions?: ResourceSuggestions }>) => {
          this.resource = data.data.resource;
          if (data.data.suggestions)
            this.suggestions = data.data.suggestions;

          this.previewImage = this.resource.files.preview.default['image/webp'] || this.resource.files.preview.default[this.resource.mime];

          this.titleService.setTitle($localize`Design: ${this.resource.name}` + ' | Night.design');
          this.metaTagService.addTag({ name: 'og:title', content: $localize`Design: ${this.resource.name}` + ' | Night.design' });
          this.metaTagService.addTag({ name: 'author', content: this.resource.user.username });

          if (!this.resource.rendering) {
            const desc = 'Creator: ' + this.resource.user.username + ' | Cosmetic Model: ' + localizeCosmetic(this.resource.type) + ' | Tags: ' + this.resource.tags.join(', ');
            this.metaTagService.addTag({ property: 'description', content: desc });
            this.metaTagService.addTag({ property: 'og:description', content: desc });

            this.metaTagService.addTag({ property: 'og:image', content: this.previewImage });
            this.metaTagService.addTag({ property: 'og:image:type', content: this.resource.mime });
            this.metaTagService.addTag({ property: 'og:image:alt', content: this.resource.name });
            this.metaTagService.addTag({ name: 'twitter:card', content: 'summary_large_image' });

            const element = this.render.createElement('script');
            element.type = 'application/ld+json';
            element.text = JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "ImageObject",
              "contentUrl": this.previewImage,
              "creditText": "Night.design",
              "creator": {
                "@type": "Person",
                "name": this.resource.user.username
              }
            });
            this.render.appendChild(this.document.head, element);
          }

          // gtag('event', 'select_content', { content_type: 'resource', item_id: id });
        },
        error: error => {
          if (error.error.status === 404)
            this.router.navigate(['/404']).then();
        }
      });
  }

  ngAfterViewInit(): void {
  }

  copied2Clipboard() {
    Swal.fire({
      icon: 'success',
      title: $localize`Text in den Zwischenspeicher kopiert`,

      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    }).then();
  }

  private downloadTimer: number | undefined;
  downloadCooldown = 5;
  toggleDownloadArea() {
    if (!this.showDownload) {
      // Show
      this.showDownload = true;
      animateCSS('.downloadArea', 'fadeInRight').then(() => {
        this.downloadCooldown = 5;
        this.downloadTimer = this.window.getWindow()!.setInterval(() => {
          this.downloadCooldown--;
          if (this.downloadCooldown <= 0)
            clearInterval(this.downloadTimer);
        }, 1000);
      });

    } else {
      if (this.downloadTimer)
        clearInterval(this.downloadTimer);
      // Hide
      animateCSS('.downloadArea', 'fadeOutRight').then(() => {
        this.showDownload = false;
      });
    }
  }

  download() {
    if (this.downloadCooldown !== 0)
      return;

    this.window.getWindow()!.open(this.resource.files.download.default[this.resource.mime], '_blank')!.focus();
  }

  appliedCosmetic() {
    if (!localStorage.getItem('access_token'))
      return;

    const options: SweetAlertOptions = {
      title: $localize`Cosmetic anwenden`,
      text: $localize`Bist du dir sicher das du das Cosmetic auf deinem Account anwenden möchtest?`,
      imageUrl: this.resource.files.preview.default[this.resource.mime],
      confirmButtonText: $localize`Anwenden`,
      showDenyButton: true,
      denyButtonText: $localize`Abbrechen`,
    };

    this.http.get<UserConnectionsRequest>(environment.apiServer + 'users/@me/connected-accounts').subscribe(value => {
      const accountsCache = value.data.connections.filter(a => ['minecraft', 'xbox'].includes(a.provider));
      const hasAccounts = accountsCache.length != 0;

      if (accountsCache.length > 1) {
        options.input = "select";
        options.inputOptions = {};
        for (let userConnection of accountsCache)
          // @ts-ignore
          options.inputOptions[userConnection.profile.id] = userConnection.profile.name;
      }

      if (!hasAccounts)
        options.confirmButtonText = $localize`Account hinzufügen`

      Swal.fire(options).then(result => {
        if (!result.isConfirmed)
          return;

        if (!hasAccounts)
          return location.href = '/settings/connected-accounts';

        let selectedAccount = accountsCache[0].profile.id;
        if (accountsCache.length > 1)
          selectedAccount = result.value;

        return this.http.patch<PlayerCosmeticRequest>(environment.apiServer + 'players/' + selectedAccount + '/cosmetics', { type: this.resource.type, file: this.resource.files.download.default[this.resource.mime].split('files/')[1].split('.')[0]}).subscribe(
          value1 => {
            if (value1.status === 200)
              Swal.fire({
                icon: "success",
                text: $localize`Dein Cosmetic wurde erfolgreich geändert.`,
                timer: 2500,
                timerProgressBar: true,
              }).then();
          }
        );
      });

      if (!hasAccounts)
        Swal.showValidationMessage($localize`Du hast noch keinen Minecraft Account verknüpft`);
    });
  }


  descEditor?: Editor;
  descToolbar: Toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    ['link', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    ['text_color', 'background_color'],
    ['format_clear']
  ];


  suggestions?: ResourceSuggestions;
  changes?: {
    name: string;
    desc: string;
    isPublic: boolean;
    tags: string[];
    variables: { [key: string]: string; };
  };

  resetChanges() {
    const variables: { [key: string]: string; } = {};

    switch (this.resource.type) {
      case "knight-shields":
      case "round-shields":
        variables['shield'] = this.resource.variables['shield'] ?? '#595959';
        variables['border'] = this.resource.variables['border'] ?? '#595959';
        variables['handle'] = this.resource.variables['handle'] ?? '#595959';
    }

    this.changes = {
      name: this.resource.name,
      desc: this.resource.desc ? (typeof this.resource.desc === "string" ? this.resource.desc : toHTML(this.resource.desc)) : "",
      isPublic: this.resource.public,
      tags: this.resource.tags,
      variables
    }
  }
  patchResource() {
    const { name, desc, isPublic, tags, variables } = this.changes!;

    this.http.patch<SimpleRequest>(environment.apiServer + `resources/${this.resource._id}`, {
      title: name,
      description: JSON.stringify(toDoc(desc)),
      tags: tags,
      unlisted: !isPublic,
      variables,
    } ).subscribe(() => window.location.reload())
  }

  deleteResource() {
    Swal.fire({
      icon: "warning",
      title: $localize`Bist du dir sicher das du deine Resource löschen möchtest?`,
      confirmButtonText: $localize`Löschen`,
      showCancelButton: true,
      cancelButtonText: $localize`Abbrechen`
    }).then(result => {
      if (result.isConfirmed)
        this.http.delete<SimpleRequest>(environment.apiServer + `resources/${this.resource._id}`).subscribe(() => window.location.reload());
    })
  }

  get getParsedDesc(): string {
    if (typeof this.resource.desc === "string")
      return this.resource.desc;

    return toHTML(this.resource.desc!);
  }

  private fac = new FastAverageColor();
  getBackgroundColor(resource: Resource) {
    if (!document)
      return;

    const element = document.getElementById('prev');
    if (!element)
      return;

    try {
      if (this.fac.getColor(element as HTMLImageElement, { crossOrigin: 'night.design' }).isLight)
        element.classList.add('card-img-top-inverted');
    } catch (e) {
      this.fac.getColorAsync(resource.files.preview.default[resource.mime], { crossOrigin: 'night.design' }).then((result) => {
        if (result.isLight)
          element.classList.add('card-img-top-inverted');
      });
    }
  }
}


const animateCSS = (element: string, animation: String, prefix = 'animate__') =>
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);
    if (!node)
      return;

    node.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event: { stopPropagation: () => void; }) {
      if (node)
        node.classList.remove(`${prefix}animated`, animationName);
      event.stopPropagation();
      resolve('Animation ended');
    }

    node.addEventListener('animationend', handleAnimationEnd, {once: true});
  });
