<div class="page-content page-noHeader">
  <div class="row pt-5 justify-content-center">
    <div class="col-md-4 d-flex justify-content-center">
      <div class="card bg-warning" *ngIf="error">
        <div class="text-center text-danger" style="font-size: 6em;">
          <i class="fa-solid fa-triangle-exclamation"></i>
          <h1>{{error}}</h1>
        </div>
      </div>

      <div class="card" *ngIf="!client && !error">
        <div class="card-block">
          <loadingArea></loadingArea>
        </div>
      </div>
      <div class="card" *ngIf="client && !error">
        <div class="card-block">
          <h3 class="text-center" i18n>Verbindung autorisieren</h3>





          <div class="row mt-3">
            <div class="col-md-6 d-grid">
              <button class="btn btn-success btn-block" type="submit" (click)="authorize()" i18n>Autorisieren</button>
            </div>
            <div class="col-md-6 d-grid">
              <button class="btn btn-outline-danger" type="button" (click)="redirectWithError('unauthorized_client')" i18n>Abbrechen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
