<div class="row">
  <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let element of queueElements">
    <div class="card border-2" [class.border]="element.status" [class.border-dark]="element.status === 'SELF'" [class.border-success]="element.status === 'ACCEPTED'" [class.border-danger]="element.status === 'DENIED'">
      <img errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" [lazyLoad]="element.file!.image" class="card-img-top" [alt]="element.file!.name">
      <div class="card-body">
        <h5 class="card-title">
          {{element.file!.name}}
          <span class="float-end small text-muted">{{ localizeCosmetic(element.cosmetic) }}</span>
        </h5>
        <div class="row">
          <div class="col-md-6 d-grid"><button class="btn btn-outline-success" (click)="moderateQueueElement(element, true)" [disabled]="element.status" i18n>Freischalten</button></div>
          <div class="col-md-6 d-grid"><button class="btn btn-outline-danger" (click)="moderateQueueElement(element, false)" [disabled]="element.status" i18n>Entfernen</button></div>
        </div>
      </div>

      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <a class="text-muted" routerLink="/u/{{element.user.username}}" [innerHTML]="element.user.display"></a>

          <span class="float-end text-muted" [title]="element.file!.timestamp">{{element.file!.timestamp | timeago }} <i class="fa-solid fa-clock"></i></span>
        </li>
        <li class="list-group-item text-muted">
          <i class="fa-solid fa-file"></i> {{element.file!.mime}}
          <span class="float-end">{{element.file!.size | numeral: '0.[0] b'}} <i class="fa-solid fa-disc-drive"></i></span>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="text-center" *ngIf="queue.length === 0">
  <i class="fa-solid fa-thumbs-up text-muted" style="font-size: 12em"></i><br>
  <span class="text-muted" style="font-size: 2em" i18n>Super ihr habt alle Elemente abgearbeitet!</span>
</div>
