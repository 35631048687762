<div class="row">
  <div class="col-md-6">
    <div class="card">
      <div class="card-header"><h5 i18n>Avatar</h5></div>
      <div class="card-body text-center">
        <div class="alert alert-danger text-start" *ngIf="imageError.avatar === 'TOO_BIG'" i18n>Die Dateigröße ist zu groß.</div>
        <div class="alert alert-danger text-start" *ngIf="imageError.avatar === 'UNDER_SIZED'" i18n>Die Datei Dimension ist zu klein.</div>
        <div class="alert alert-danger text-start" *ngIf="imageError.avatar === 'OVER_SIZED'" i18n>Die Datei Dimension ist zu groß.</div>
        <div class="alert alert-danger text-start" *ngIf="imageError.avatar === 'WRONG_ASPECT_RATIO'" i18n>Die Aspect Ratio ist nicht richtig.</div>

        <img class="img-fluid rounded-circle" style="max-height: 15em" [src]="userData.avatar || '/assets/img/logo-icon-grey.webp'" alt=""><br>

        <div class="mt-2">
          <button class="btn btn-success" (click)="imageType = 'avatar'; uploadImageSelectDialog.click()" [disabled]="uploading" i18n>Hochladen</button>
          <button class="btn btn-outline-danger" (click)="deleteImage('avatar')" [disabled]="!userData.avatar || uploading" i18n>Löschen</button>
        </div>
      </div>
      <div class="card-footer">
        Aspect Ratio: 1:1<br>
        <span i18n>Minimale Bildgröße</span>: 128×128px<br>
        <span i18n>Maximale Bildgröße</span>: 1024×1024px<br>
        <span i18n>Erlaubte Dateiformate</span>: png, gif<br>
        <span i18n>Maximale Dateigröße</span>: 2.5 MB
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header"><h5 i18n>Banner</h5></div>
      <div class="card-body text-center">
        <div class="alert alert-danger text-start" *ngIf="imageError.banner === 'TOO_BIG'" i18n>Die Dateigröße ist zu groß.</div>
        <div class="alert alert-danger text-start" *ngIf="imageError.banner === 'UNDER_SIZED'" i18n>Die Datei Dimension ist zu klein.</div>
        <div class="alert alert-danger text-start" *ngIf="imageError.banner === 'OVER_SIZED'" i18n>Die Datei Dimension ist zu groß.</div>
        <div class="alert alert-danger text-start" *ngIf="imageError.banner === 'WRONG_ASPECT_RATIO'" i18n>Die Aspect Ratio ist nicht richtig.</div>

        <img class="img-fluid" style="max-height: 15em" [src]="userData.banner || '/assets/img/background_profile.webp'" alt=""><br>

        <div class="mt-2">
          <button class="btn btn-success" (click)="imageType = 'banner'; uploadImageSelectDialog.click()" [disabled]="uploading" i18n>Hochladen</button>
          <button class="btn btn-outline-danger" (click)="deleteImage('banner')" [disabled]="!userData.banner || uploading" i18n>Löschen</button>
        </div>
      </div>
      <div class="card-footer">
        Aspect Ratio: 4:1<br>
        <span i18n>Minimale Bildgröße</span>: 1600×400px<br>
        <span i18n>Maximale Bildgröße</span>: 4800×1200px<br>
        <span i18n>Erlaubte Dateiformate</span>: png, gif<br>
        <span i18n>Maximale Dateigröße</span>: 6 MB
      </div>
    </div>
  </div>

  <input type="file" #uploadImageSelectDialog (change)="patchImage($event)" accept="image/png,image/gif" hidden>
</div>

<div class="card">
  <div class="card-header">
    <h5 i18n>Username ändern</h5>
  </div>
  <div class="card-body">
    <form [formGroup]="usernameGroup">
      <div class="alert alert-danger" *ngIf="usernameErrorCooldown" i18n>Du kannst alle 31 Tage deinen Namen ändern.</div>
      <label for="username" class="form-label mt-2" i18n>Benutzername</label>
      <input type="text" class="form-control" autocomplete="off" [class.is-invalid]="username.invalid && (usernameSubmitted || username.dirty || username.touched)" id="username" [placeholder]="userData.username" formControlName="username" required>
      <div *ngIf="username.status === 'INVALID' && (usernameSubmitted || username.dirty || username.touched)"
           class="invalid-feedback" style="display: block">
        <ng-container *ngIf="username.errors?.['required']" i18n>
          Benutzername ist ein pflicht Feld.
        </ng-container>
        <ng-container *ngIf="username.errors?.['pattern']" i18n>
          Benutzername entspricht nicht dem pattern.
        </ng-container>
        <ng-container *ngIf="username.errors?.['gone']" i18n>
          Benutzername ist nicht mehr frei.
        </ng-container>
      </div>

      <div class="mt-3 text-center">
        <button class="btn btn-success" (click)="patchUsername()" i18n>Namen ändern</button>
      </div>
    </form>
  </div>
</div>

<div class="card">
  <div class="card-header">
    <h5><span i18n>Username darstellung</span> <span class="float-end text-danger"><b>BETA</b></span><!--<span class="float-end text-rainbow-shadow" i18n>PREMIUM</span>--></h5>
  </div>
  <!--        <div class="card-body text-center border-bottom border-dark"></div>-->

  <div class="card-body text-center border-bottom border-dark" style="font-size: 2.5em!important;" [innerHTML]="userData.display"></div>
  <div class="card-body text-center border-bottom border-dark">
    <button class="btn btn-outline-primary btn-preview" (click)="patchUsernameDisplay('color','white-purple')"><span class="text-preview text-white-purple">{{userData.username}}</span></button>
    <button class="btn btn-outline-primary btn-preview" (click)="patchUsernameDisplay('color','white-blue')"><span class="text-preview text-white-blue">{{userData.username}}</span></button>
    <button class="btn btn-outline-primary btn-preview" (click)="patchUsernameDisplay('color','red-yellow')"><span class="text-preview text-red-yellow">{{userData.username}}</span></button>
<!--              <button class="btn btn-outline-primary btn-preview"><span class="text-preview text-glitch">{{userData.username}}</span></button>&lt;!&ndash;NOT READY&ndash;&gt;-->
    <br />
    <button class="btn btn-outline-primary btn-preview" (click)="patchUsernameDisplay('color','gradient')"><span class="text-preview text-gradient">{{userData.username}}</span></button>
    <button class="btn btn-outline-primary btn-preview" (click)="patchUsernameDisplay('color','rainbow')"><span class="text-preview text-rainbow">{{userData.username}}</span></button>
<!--              <button class="btn btn-outline-primary btn-preview"><span class="text-preview text-rainbow-shadow">{{userData.username}}</span></button>&lt;!&ndash;NOT READY&ndash;&gt;-->
<!--    <button class="btn btn-outline-primary btn-preview"><span class="text-preview text-terminator" style="&#45;&#45;test: #029ef3">{{userData.username}}</span></button>-->
<!--    <button class="btn btn-outline-primary btn-preview"><span class="text-preview text-terminator" style="&#45;&#45;test: #e50202">{{userData.username}}</span></button>-->
    <button class="btn btn-outline-danger btn-preview" (click)="patchUsernameDisplay('color','reset')"><i class="fa-solid fa-trash-can"></i></button>
  </div>
  <div class="card-footer text-center">
    <button class="btn btn-outline-primary btn-preview font-Achieve_Regular" (click)="patchUsernameDisplay('font','Achieve_Regular')">Achieve Regular</button>
    <button class="btn btn-outline-primary btn-preview font-BatuphatScript" (click)="patchUsernameDisplay('font','BatuphatScript')">BatuphatScript</button>
    <button class="btn btn-outline-primary btn-preview font-BonaNova_Regular" (click)="patchUsernameDisplay('font','BonaNova_Regular')">BonaNova Regular</button>
    <button class="btn btn-outline-primary btn-preview font-BonheurRoyale_Regular" (click)="patchUsernameDisplay('font','BonheurRoyale_Regular')">BonheurRoyale Regular</button>
    <button class="btn btn-outline-primary btn-preview font-Brygada1918_VariableFont" (click)="patchUsernameDisplay('font','Brygada1918_VariableFont')">Brygada1918 VariableFont</button>
    <button class="btn btn-outline-primary btn-preview font-Foxbot" (click)="patchUsernameDisplay('font','Foxbot')">Foxbot</button>
    <button class="btn btn-outline-primary btn-preview font-Important" (click)="patchUsernameDisplay('font','Important')">Important</button>
    <button class="btn btn-outline-primary btn-preview font-InterTight_VariableFont" (click)="patchUsernameDisplay('font','InterTight_VariableFont')">InterTight VariableFont</button>
    <button class="btn btn-outline-primary btn-preview font-LakehurstGothic" (click)="patchUsernameDisplay('font','LakehurstGothic')">LakehurstGothic</button>
    <button class="btn btn-outline-primary btn-preview font-Mansalva_Regular" (click)="patchUsernameDisplay('font','Mansalva_Regular')">Mansalva Regular</button>
    <button class="btn btn-outline-primary btn-preview font-Paragon_Regular" (click)="patchUsernameDisplay('font','Paragon_Regular')">Paragon Regular</button>
    <button class="btn btn-outline-primary btn-preview font-RubikDirt_Regular" (click)="patchUsernameDisplay('font','RubikDirt_Regular')">RubikDirt Regular</button>
    <button class="btn btn-outline-primary btn-preview font-SairaStencilOne_Regular" (click)="patchUsernameDisplay('font','SairaStencilOne_Regular')">SairaStencilOne Regular</button>
    <button class="btn btn-outline-primary btn-preview font-Simpel_Medium" (click)="patchUsernameDisplay('font','Simpel_Medium')">Simpel Medium</button>
    <button class="btn btn-outline-primary btn-preview font-Streetwear" (click)="patchUsernameDisplay('font','Streetwear')">Streetwear</button>
    <button class="btn btn-outline-primary btn-preview font-SugarSnow" (click)="patchUsernameDisplay('font','SugarSnow')">SugarSnow</button>
    <button class="btn btn-outline-primary btn-preview font-Veselka4F" (click)="patchUsernameDisplay('font','Veselka4F')">Veselka4F</button>
    <button class="btn btn-outline-primary btn-preview font-WindSong_Regular" (click)="patchUsernameDisplay('font','WindSong_Regular')">WindSong Regular</button>
    <button class="btn btn-outline-danger btn-preview" (click)="patchUsernameDisplay('font','reset')"><i class="fa-solid fa-trash-can"></i></button>
  </div>
</div>

<div class="card">
  <div class="card-header">
    <h5 i18n>Account löschen</h5>
  </div>
  <div class="card-body">
    <input class="form-check-input" [class.is-invalid]="false" type="checkbox" id="account_delete" formControlName="account_delete" [checked]="deleteUserConfirm" (change)="deleteUserConfirm = !deleteUserConfirm" required>
    &nbsp;<label class="form-check-label text-muted" [class.text-danger]="false" for="account_delete" i18n>Bist du sicher das du deinen Account löschen möchtest? Diese aktion ist nach der "Cooldown Phase" nicht rückgängig.</label>
    <div class="float-end"><button class="btn" [class.btn-outline-danger]="!deleteUserConfirm" [class.btn-danger]="deleteUserConfirm" (click)="deleteUser()" i18n>Account löschen</button></div>
  </div>
</div>
