<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <highcharts-chart
          *ngIf="isBrowser"
          [Highcharts]="Highcharts"
          [options]="userCustomUploads"
          [oneToOne]="true"
          [(update)]="updateFlag"
          style="width: 100%; height: 400px; display: block;"></highcharts-chart>
      </div>
    </div>
  </div>
</div>
