<ng-container *ngIf="!loadingComments">
  <div class="card" *ngIf="authedUser && (pageType !== 'comment' || (pageType === 'comment' && openedSubComment && openedSubComment._id === pageId))">
    <div class="card-body" *blockUI="'commentArea'">
      <img class="rounded-circle" style="width: 3em; height: auto; margin-top: -2.5em; margin-right: 1em;" [src]="authedUser.avatar" [alt]="authedUser.username">
      <div class="d-inline-block">
        <h6>
          <span style="color: #fff" [innerHTML]="authedUser.display"></span>
          <span class="ps-1" i18n>wird eine Nachricht schreiben...</span>
        </h6>
        <p class="text-muted ">{{authedUser.role.name}}</p>
      </div>

      <textarea class="form-control" rows="3" [(ngModel)]="commentInput" (keydown)="postCommentByKey($event)"></textarea>
      <div class="mt-2">
        <!--      <button class="btn btn-outline-dark text-muted"><i class="fa-solid fa-circle-plus"></i>&nbsp;<span>Bild hinzufügen</span></button>-->
        <div class="float-end"><button class="btn btn-success" [disabled]="!commentInput" (click)="postComment()" i18n>Absenden</button></div>
      </div>
    </div>
  </div>


  <ng-container  *ngFor="let comment of comments; let i1 = index">
    <div class="card" [id]="comments.length - i1">
      <div class="card-body pb-0">
        <span class="float-end text-muted" (click)="changeHash(comments.length - i1)">#{{ comments.length - i1 }}</span>
        <img class="rounded-circle" style="width: 3em; height: auto; margin-top: -2.5em; margin-right: 1em;" [src]="comment.user.avatar" [alt]="comment.user.username">
        <div class="d-inline-block">
          <h6><span style="color: #fff" routerLink="/u/{{comment.user.username}}" [innerHTML]="comment.user.display"></span></h6>
          <p class="text-muted">{{comment.user.role.name || '&nbsp;'}}</p>
        </div><br>
      </div>

      <div class="card-body pt-0 ps-5">
        {{comment.message}}
      </div>

      <div class="card-footer">
        <span class="text-muted"><i class="fa-regular fa-clock"></i>&nbsp;{{comment.timestamp | timeago}}</span>
        <div class="float-end">

          <reportButton type="comment" [target]="comment" [smallBtn]="true" class="ps-2" *ngIf="authedUser && comment.user._id !== authedUser?._id"></reportButton>
          <button class="btn btn-outline-danger btn-small" *ngIf="authedUser && comment.user._id === authedUser._id" (click)="deleteComment(comment)" i18n>Löschen</button>
          <button class="btn btn-outline-primary btn-small" *ngIf="authedUser" (click)="openedSubComment = comment" i18n>Antworten</button>
        </div>
      </div>
    </div>

    <div class="ms-4">
      <commentsArea [pageId]="comment._id" pageType="comment" [showCommentInput]="true" [openedSubComment]="openedSubComment" [requireRequest]="comment.subComments !== 0"></commentsArea>
    </div>
  </ng-container>

  <div class="text-center mt-5" *ngIf="comments.length === 0 && pageType !== 'comment'">
    <i class="fa-solid fa-question text-muted" style="font-size: 12em"></i><br>
    <span class="text-muted" style="font-size: 2em" i18n>Leider noch nichts passiert...</span>
  </div>
</ng-container>


<div class="text-center text-muted" style="font-size: 6em;" *ngIf="loadingComments">
  <i class="fa-duotone fa-loader fa-spin-pulse"></i>
  <h1 i18n>Lade Kommentare...</h1>
</div>
