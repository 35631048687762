import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {User} from "../user/user.service";
import {File} from "../interfaces/file";
import {map, Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {SimpleRequest} from "./simple-request";

export interface CommentsRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly comments: Comment[];
  }
}
export interface CommentRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly comment: Comment;
  }
}
export interface Comment {
  readonly _id: string;
  readonly user: User;
  message: string;
  readonly files?: File[];
  subComments: number;
  readonly timestamp: string;
}
export type pageType = 'server' | 'resource' | 'user' | 'comment';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(
    private http: HttpClient,
  ) { }

  getComments(type: pageType, id: string): Observable<Comment[]> {
    return this.http.get<CommentsRequest>(environment.apiServer + ['comments',type, id].join('/')).pipe(map(response => response.data.comments));
  }

  postComment(type: pageType, id: string, message: string): Observable<Comment> {
    return this.http.post<CommentRequest>(environment.apiServer + ['comments',type, id].join('/'), { message }).pipe(map(response => response.data.comment));
  }

  patchComment(comment: Comment, message: string): Observable<SimpleRequest> {
    return this.http.patch<SimpleRequest>(environment.apiServer + ['comments', comment._id].join('/'), { message });
  }

  deleteComment(comment: Comment): Observable<SimpleRequest> {
    return this.http.delete<SimpleRequest>(environment.apiServer + ['comments', comment._id].join('/'));
  }
}
