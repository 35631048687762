import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentry,
  integrations: [
    new BrowserTracing({
      tracingOrigins: ["https://api.night.design","https://api.dev.night.design"],
      routingInstrumentation: Sentry.routingInstrumentation
    }),
  ],
  tracesSampleRate: 1.0,
});

function bootstrap() {
     platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
   };


 if (document.readyState === 'complete') {
   bootstrap();
 } else {
   document.addEventListener('DOMContentLoaded', bootstrap);
 }

