import {Inject, Injectable, NgModule, PLATFORM_ID} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ResourcesComponent } from './resources/resources.component';
import { ResourceComponent } from './resource/resource.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  NgBytesPipeModule,
  NgCapitalizePipeModule, NgCountPipeModule,
  NgDecodeURIPipeModule, NgEncodeURIComponentPipeModule,
  NgEncodeURIPipeModule, NgJoinPipeModule,
  NgKeysPipeModule, NgToArrayPipeModule, NgTrimPipeModule
} from "angular-pipes";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {ClipboardModule} from "ngx-clipboard";
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {AuthGuard} from "./guard/auth.guard";
import {AuthInterceptor} from "./interceptors/auth.interceptor";
import {BlockUIModule} from "ng-block-ui";
import { RegisterComponent } from './register/register.component';
import { PasswordForgottenComponent } from './password-forgotten/password-forgotten.component';
import {ErrorCatchingInterceptor} from "./interceptors/error-catching.interceptor";
import { ConnectionsComponent } from './settings/connections/connections.component';
import { NotFoundComponent } from './error/not-found/not-found.component';
import {Socket, SocketIoModule} from 'ngx-socket-io';
import {environment} from "../environments/environment";
import {NgcCookieConsentModule} from "ngx-cookieconsent";
import {LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks} from "ng-lazyload-image";
import {NumeralPipe} from "./numeral.pipe";
import { SecurityComponent } from './settings/security/security.component';
import {IL10nsStrings, TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule} from "ngx-timeago";
import { AccountComponent } from './settings/account/account.component';
import { NotificationsComponent } from './settings/notifications/notifications.component';
import {LoadingBarHttpClientModule} from "@ngx-loading-bar/http-client";
import { CreatorComponent } from './creator/creator.component';
import { CreatorPublisherComponent } from './creator-publisher/creator-publisher.component';
import {NgxEditorModule} from "ngx-editor";
import {NgxFileDropModule} from "ngx-file-drop";
import {NgSelectModule} from "@ng-select/ng-select";
import { ModderComponent } from './modder/modder.component';
import { ModderPublisherComponent } from './modder-publisher/modder-publisher.component';
import { ModderViewerComponent } from './modder-viewer/modder-viewer.component';
import { HighchartsChartModule } from "highcharts-angular";
import { StatisticsComponent } from './modder-viewer/statistics/statistics.component';
import { MembersComponent } from './modder-viewer/settings/members/members.component';
import { PartyComponent } from './modder-viewer/settings/party/party.component';
import { ScriptsComponent } from './modder-viewer/settings/scripts/scripts.component';
import { SecurityComponent as PartySecurityComponent } from './modder-viewer/settings/security/security.component';
import { ModerationQueueComponent } from './modder-viewer/moderation/queue/moderation-queue.component';
import {ModerationResourcesComponent} from "./modder-viewer/moderation/resources/moderation-resources.component";
import { ImprintComponent } from './legal/imprint/imprint.component';
import { TermsOfServiceComponent } from './legal/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './legal/privacy-policy/privacy-policy.component';
import { SettingsComponent } from './settings/settings.component';
import {CookieService} from "ngx-cookie-service";
import { UserComponent } from './user/user.component';
import { HoverClassDirective } from './hover-class.directive';
import { NotHoveredClassDirective } from './not-hovered-class.directive';
import { UserActivitiesComponent } from './user/user-activities/user-activities.component';
import {UserResourcesComponent} from "./user/user-resources/user-resources.component";
import { ResourcesSearchComponent } from './utils/resources-search/resources-search.component';
import { UserConnectedAccountsComponent } from './user/user-connected-accounts/user-connected-accounts.component';
import {isPlatformBrowser} from "@angular/common";
import {ServerListComponent} from "./server-list/server-list.component";
import {ServerViewerComponent} from "./server-viewer/server-viewer.component";
import {ServerStatisticsComponent} from "./server-viewer/statistics/statistics.component";
import {UrlPipe} from "./url.pipe";
import {BypassSecurityTrustUrlPipe} from "./bypass-security-trust-url.pipe";
import {CommentsComponent} from "./utils/comments/comments.component";
import {ServerDetailsComponent} from "./server-viewer/details/details.component";
import {ServerVoteComponent} from "./server-viewer/vote/vote.component";
import {ServerPeakComponent} from "./server-viewer/statistics/peak/peak.component";
import {ReportComponent} from "./utils/report/report.component";
import {TeamComponent} from "./users/team/team.component";
import {UsersComponent} from "./users/users.component";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {AuthorizeComponent} from "./authorize/authorize.component";
import {LoadingComponent} from "./utils/loading/loading.component";
import {AdsComponent} from "./utils/ads/ads.component";
import {AdsenseModule} from "ng2-adsense";
import {EditorComponent} from "./editor/editor.component";
import {GameserverComponent} from "./gameserver/gameserver.component";
import {ConsoleComponent} from "./gameserver/console/console.component";
import {FileManagerComponent} from "./gameserver/file-manager/file-manager.component";
import {SetupComponent} from "./gameserver/setup/setup.component";
import {AutoCompleteComponent} from "./utils/auto-complete/auto-complete.component";

@Injectable()
export class TimeagoLocalize extends TimeagoIntl {
  override strings: IL10nsStrings = {
    prefixAgo: $localize`:Text before the time@@prefixAgo:vor` !== '#NOT_USED#' ? $localize`:Text before the time@@prefixAgo:vor` : undefined,
    prefixFromNow: $localize`:Text before the time@@prefixFromNow:in` !== '#NOT_USED#' ? $localize`:Text before the time@@prefixFromNow:in` : undefined,

    suffixAgo: $localize`:Text after the time@@suffixAgo:` !== '#NOT_USED#' ? $localize`:Text after the time@@suffixAgo:` : undefined,
    suffixFromNow: $localize`:Text after the time@@suffixFromNow:` !== '#NOT_USED#' ? $localize`:Text after the time@@suffixFromNow:` : undefined,

    second: $localize`:@@second:wenigen Sekunden`,
    seconds: $localize`:@@seconds:%d Sekunden`,
    minute: $localize`:@@minute:etwa einer Minute`,
    minutes: $localize`:@@minutes:%d Minuten`,
    hour: $localize`:@@hour:etwa einer Stunde`,
    hours: $localize`:@@hours:%d Stunden`,
    day: $localize`:@@day:etwa einem Tag`,
    days: $localize`:@@days:%d Tagen`,
    week: $localize`:@@week:etwa einer Woche`,
    weeks: $localize`:@@weeks:%d Wochen`,
    month: $localize`:@@month:etwa einem Monat`,
    months: $localize`:@@months:%d Monaten`,
    year: $localize`:@@year:etwa einem Jahr`,
    years: $localize`:@@years:%d Jahren`
  };
}


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ResourcesComponent,
    ResourceComponent,
    LoginComponent,
    DashboardComponent,
    RegisterComponent,
    PasswordForgottenComponent,
    ConnectionsComponent,
    NotFoundComponent,
    NumeralPipe,
    SecurityComponent,
    AccountComponent,
    NotificationsComponent,
    CreatorComponent,
    CreatorPublisherComponent,
    ModderComponent,
    ModderPublisherComponent,
    ModderViewerComponent,
    StatisticsComponent,
    MembersComponent,
    PartyComponent,
    ScriptsComponent,
    ModerationQueueComponent,
    ModerationResourcesComponent,
    PartySecurityComponent,
    ImprintComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    SettingsComponent,
    UserComponent,
    HoverClassDirective,
    NotHoveredClassDirective,
    UserActivitiesComponent,
    UserResourcesComponent,
    ResourcesSearchComponent,
    UserConnectedAccountsComponent,
    ServerListComponent,
    ServerViewerComponent,
    ServerStatisticsComponent,
    UrlPipe,
    BypassSecurityTrustUrlPipe,
    CommentsComponent,
    ServerDetailsComponent,
    ServerVoteComponent,
    ServerPeakComponent,
    ReportComponent,
    TeamComponent,
    UsersComponent,
    AuthorizeComponent,
    LoadingComponent,
    AdsComponent,
    EditorComponent,
    GameserverComponent,
    ConsoleComponent,
    FileManagerComponent,
    SetupComponent,
    AutoCompleteComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    NgEncodeURIPipeModule,
    NgCapitalizePipeModule,
    NgBytesPipeModule,
    SweetAlert2Module.forRoot(),
    ClipboardModule,
    RecaptchaV3Module,
    ReactiveFormsModule,
    NgSelectModule,
    NgxEditorModule,
    NgxFileDropModule,
    BlockUIModule.forRoot(),
    SocketIoModule.forRoot({
      url: 'https://' + new URL(environment.apiServer).host,
      options: {
        extraHeaders: {
          'authorisation': localStorage.getItem('access_token') !== undefined ? 'Barrier ' + localStorage.getItem('access_token')! : ''
        },
        reconnection: true,
        reconnectionDelay: 500,
        reconnectionAttempts: 10
      }
    }),
    NgcCookieConsentModule.forRoot({
      "cookie": {
        "domain": environment.host.split(':')[0]
      },
      "position": "bottom-right",
      "theme": "edgeless",
      "palette": {
        "popup": {
          "background": "#000000",
          "text": "#ffffff",
          "link": "#ffffff"
        },
        "button": {
          "background": "#f1d600",
          "text": "#000000",
          "border": "transparent"
        }
      },
      "type": "opt-in",
      "content": {
        "message": $localize`Diese Webseite benötigt für die funktion der Webseite cookies.`,
        "dismiss": $localize`Alles klar!`,
        "link": $localize`Erfahre mehr`,
        "href": "https://" + environment.host + "/legal/privacy-policy",
        "policy": "Cookie Policy",
        allow: $localize`Cookies erlauben`,
        deny: $localize`Cookies ablehnen`,
      }
    }),
    LazyLoadImageModule,
    TimeagoModule.forRoot({
      intl: {provide: TimeagoIntl, useClass: TimeagoLocalize},
      formatter: {provide: TimeagoFormatter, useClass: TimeagoCustomFormatter},
    }),
    LoadingBarHttpClientModule,
    HighchartsChartModule,
    NgKeysPipeModule,
    NgDecodeURIPipeModule,
    NgEncodeURIComponentPipeModule,
    NgCountPipeModule,
    NgbModule,
    AdsenseModule.forRoot({
      adClient: environment.adsence
    }),
    NgToArrayPipeModule,
    NgJoinPipeModule,
    NgTrimPipeModule,
  ],
  providers: [
    CookieService,
    AuthGuard,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LdsW-0fAAAAADFvkSmrezl3jvW7sqbFMJZw1q0X" },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorCatchingInterceptor, multi: true },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    { provide: TimeagoIntl, useClass: TimeagoLocalize }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(
    private socket: Socket,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    if (isPlatformBrowser(platformId))
      socket.connect();
  }
}
