<ng-container *ngIf="!loadingVotes">
  <div class="alert alert-info" *ngIf="!server.vote_rewards">
    <span class="float-end">
      <span class="fa-stack fa-2x">
        <i class="fa-solid fa-gift fa-stack-1x"></i>
        <i class="fa-solid fa-slash fa-inverse fa-stack-1x" style="bottom: -3px;"></i>
        <i class="fa-solid fa-slash fa-stack-1x"></i>
      </span>
    </span>

    <span i18n>
      Dieser Server bietet hier leider noch keine Vote Rewards an.<br>
      Frag doch einfach mal, ganz lieb, das Server Team ob sie auch hier Vote Rewards freischalten wollen.<br>
      <b>Du kannst dennoch Voten und dem Server im ranking unterstützen!</b>
    </span>
  </div>

  <div class="card">
    <div class="card-body">
      <ng-select class="form-select2" [items]="playersSearch | async"
                 bindLabel="name"
                 bindValue="uuid"
                 typeToSearchText="Bitte gebe mindestens 2 Buchstaben."
                 i18n-typeToSearchText
                 notFoundText="Es konnte kein Spieler gefunden werden."
                 i18n-notFoundText
                 loadingText="Laden..."
                 i18n-loadingText
                 [trackByFn]="trackByFn"
                 [loading]="searchLoading"
                 [minTermLength]="2"
                 [typeahead]="playerInput"
                 [(ngModel)]="player">
        <ng-template ng-label-tmp let-item="item">
          <img class="me-2" height="25em" width="25em" [src]="'https://crafatar.com/avatars/' + item.uuid">
          <span>{{item.name}}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <img class="me-2" height="25em" width="25em" [src]="'https://crafatar.com/avatars/' + item.uuid">
          <span>{{item.name}}</span>
        </ng-template>
      </ng-select>

      <div class="text-center mt-2">
        <button class="btn btn-success" (click)="postPlayerVote()" [disabled]="!player" i18n>Voten</button>
      </div>
    </div>
  </div>

  <ADPlacement [type]="'top-banner'" [context]="server" pageType="server"></ADPlacement>

  <ng-container *ngIf="players.length !== 0">
    <div class="divider" i18n>Top Voter</div>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card first-place">
          <div style="position: absolute; left: -1em; top: -1em;"><i class="fa-solid fa-trophy-star fa-3x fa-rotate-by text-red-yellow" style="--fa-rotate-angle: -15deg;"></i></div>
          <div class="card-block">
            <div class="row">
              <div class="col-lg-4 text-center text-lg-start" routerLink="/players/{{playerVotes[0].player.name}}"><img src="https://crafatar.com/avatars/{{playerVotes[0].player.uuid}}" style="max-height: 6em"></div>
              <div class="col-lg-4 text-center text-lg-start pt-4"><h3 routerLink="/players/{{playerVotes[0].player.name}}">{{playerVotes[0].player.name}}</h3></div>
              <div class="col-lg-4 text-center pt-3">
                <div class="row">
                  <div class="col-6"><h3>{{playerVotes[0].streak}}</h3>Streak</div>
                  <div class="col-6"><h3>{{playerVotes[0].total}}</h3>Total</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="players.length === 2">
        <div class="card second-place">
          <div class="card-block">
            <div class="row">
              <div class="col-lg-4 text-center text-lg-start" routerLink="/players/{{playerVotes[1].player.name}}"><img src="https://crafatar.com/avatars/{{playerVotes[1].player.uuid}}" style="max-height: 6em"></div>
              <div class="col-lg-4 text-center text-lg-start pt-4"><h3 routerLink="/players/{{playerVotes[1].player.name}}">{{playerVotes[1].player.name}}</h3></div>
              <div class="col-lg-4 text-center pt-3">
                <div class="row">
                  <div class="col-6"><h3>{{playerVotes[1].streak}}</h3>Streak</div>
                  <div class="col-6"><h3>{{playerVotes[1].total}}</h3>Total</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" *ngIf="players.length > 2">
      <div class="col-md-6">
        <div class="card second-place">
          <div class="card-block">
            <div class="row">
              <div class="col-lg-4 text-center text-lg-start" routerLink="/players/{{playerVotes[1].player.name}}"><img src="https://crafatar.com/avatars/{{playerVotes[1].player.uuid}}" style="max-height: 6em"></div>
              <div class="col-lg-4 text-center text-lg-start pt-4"><h3 routerLink="/players/{{playerVotes[1].player.name}}">{{playerVotes[1].player.name}}</h3></div>
              <div class="col-lg-4 text-center pt-3">
                <div class="row">
                  <div class="col-6"><h3>{{playerVotes[1].streak}}</h3>Streak</div>
                  <div class="col-6"><h3>{{playerVotes[1].total}}</h3>Total</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card third-place">
          <div class="card-block">
            <div class="row">
              <div class="col-lg-4 text-center text-lg-start" routerLink="/players/{{playerVotes[2].player.name}}"><img src="https://crafatar.com/avatars/{{playerVotes[2].player.uuid}}" style="max-height: 6em"></div>
              <div class="col-lg-4 text-center text-lg-start pt-4"><h3 routerLink="/players/{{playerVotes[2].player.name}}">{{playerVotes[2].player.name}}</h3></div>
              <div class="col-lg-4 text-center pt-3">
                <div class="row">
                  <div class="col-6"><h3>{{playerVotes[2].streak}}</h3>Streak</div>
                  <div class="col-6"><h3>{{playerVotes[2].total}}</h3>Total</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="divider" i18n>Voter</div>
    <div class="card">
      <div class="table-responsive">
        <table class="card-footer table m-0">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Player</th>
            <th scope="col">Total Votes</th>
            <th scope="col">Streak</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let playerVote of playerVotes; let i = index">
            <th scope="row">{{i + 1}}.</th>
            <td routerLink="/players/{{playerVote.player.name}}"><img src="https://crafatar.com/avatars/{{playerVote.player.uuid}}" class="pe-2" style="height: 1.5em" *ngIf="i < 3">{{playerVote.player.name}}</td>
            <td>{{playerVote.total}}</td>
            <td>{{playerVote.streak}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</ng-container>

<div class="text-center text-muted pt-3" style="font-size: 6em;" *ngIf="loadingVotes">
  <i class="fa-duotone fa-loader fa-spin-pulse"></i>
  <h1 i18n>Lädt...</h1>
</div>
