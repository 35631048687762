import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import Highcharts, {Options} from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import heatmap from "highcharts/modules/heatmap";
import exporting from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/export-data";
import {isPlatformBrowser} from "@angular/common";
import {Server} from "../../server-viewer.component";
import {SimpleRequest} from "../../../services/simple-request";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-peak',
  templateUrl: './peak.component.html',
  styleUrls: ['./peak.component.css']
})
export class ServerPeakComponent implements OnInit {
  isBrowser: boolean;

  @Input()
  server!: Server;

  Highcharts: typeof Highcharts = Highcharts;
  updateFlag = false;

  peakTime: Options ={
    chart: {
      type: 'heatmap',
      marginTop: 40,
      marginBottom: 80,
      plotBorderWidth: 1
    },

    title: {
      text: $localize`Durchschnittliche Spieler Zahl`
    },

    tooltip: {
      pointFormat: '{point.y}, {point.x}: <b>{point.value}</b>',
      nullFormat: '<b>' + $localize`Keine Datensätze vorhanden` + '</b>'
    },

    xAxis: {
      categories: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00']
    },

    yAxis: {
      categories: [$localize`Montag`,$localize`Dienstag`,$localize`Mittwoch`,$localize`Donnerstag`,$localize`Freitag`,$localize`Samstag`,$localize`Sonntag`],
      title: {
        text: null
      },
      reversed: true
    },

    colorAxis: {
      minColor: '#FFFFFF',
      maxColor: '#7cb5ec'
    },

    legend: {
      align: 'right',
      layout: 'vertical',
      margin: 0,
      verticalAlign: 'top',
      y: 25,
      symbolHeight: 280
    },

    credits: {
      enabled: true,
      text: 'Night.design',
      href: 'https://night.design'
    },

    series: []
  };


  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) platformId: Object
    ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      HighchartsMore(Highcharts);
      exporting(Highcharts);
      offlineExporting(Highcharts);
      // TODO: Fix Heatmap
      heatmap(Highcharts);
    }
  }

  ngOnInit(): void {
    this.http.get<SimpleRequest<{ peak: [[number]] }>>(environment.apiServer + 'servers/' + this.server._id + '/statistics/peak').subscribe(response => {
      const data = response.data.peak;
      this.peakTime.series?.push({
        type: "heatmap",
        name: $localize`Durchschnittliche Spielerzahl`,
        borderWidth: 1,
        data,
        dataLabels: {
          enabled: true,
          color: '#000000'
        }
      });
      this.updateFlag = true;
    });
  }
}
