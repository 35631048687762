<div class="page-header">
  <div class="page-block">
    <div class="row">
      <div class="col-md-8">
        <div class="page-header-title">
          <h5 class="m-b-10" i18n>Home</h5>
        </div>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-end">
          <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="page-content">
  <div class="row">
    <div class="col-md-8">
      <div class="mb-3">
        <div class="btn btn-primary">File</div>
        <div class="btn btn-primary">Edit</div>
        <div class="btn btn-primary">View</div>
        <div class="btn btn-primary">Image</div>
        <div class="btn btn-primary">Layers</div>
        <div class="btn btn-primary">Adjustments</div>
        <div class="btn btn-primary">Effects</div>
      </div>


      <div class="card">
        <div class="card-body">
          <div class="editor">
            <canvas class="layer" [style.z-index]="i" [id]="layer.id" [hidden]="layer.hidden" *ngFor="let layer of layers; let i = index;"></canvas>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card">
        <div class="card-header">
          <h5>
            <span i18n>Tools</span>
          </h5>
        </div>

        <div class="card-body">
          <span class="divider">Selection</span>
          <button class="btn btn-outline-primary btn-small"><i class="fa-solid fa-hand"></i></button>
          <button class="btn btn-outline-primary btn-small"><i class="fa-solid fa-square-dashed"></i></button>
          <button class="btn btn-outline-primary btn-small"><i class="fa-solid fa-lasso"></i></button>
          <button class="btn btn-outline-primary btn-small"><i class="fa-solid fa-wand-magic-sparkles"></i></button>

          <span class="divider">Manipulation</span>
          <button class="btn btn-outline-primary btn-small"><i class="fa-solid fa-paintbrush"></i></button>
          <button class="btn btn-outline-primary btn-small"><i class="fa-solid fa-eraser"></i></button>
          <button class="btn btn-outline-primary btn-small"><i class="fa-solid fa-eye-dropper"></i></button>
          <button class="btn btn-outline-primary btn-small"><i class="fa-solid fa-fill-drip"></i></button>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <h5>
            <span i18n>Ebenen</span>
            <span class="float-end"><i class="fa-solid fa-plus" (click)="addLayer()"></i></span>
          </h5>
        </div>

        <div class="card-body">
          <div *ngFor="let layer of layers">
            {{layer.name}}
            <span class="float-end">
              <i class="fa-solid fa-eye" *ngIf="!layer.hidden" (click)="layer.hidden = true"></i>
              <i class="fa-solid fa-eye-slash" *ngIf="layer.hidden" (click)="layer.hidden = false"></i>
              <i class="fa-solid fa-pencil ms-2"></i>
              <i class="fa-solid fa-trash ms-2" (click)="deleteLayer(layer)"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
