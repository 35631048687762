import {Component, ElementRef, forwardRef, Host, Inject, OnInit, Optional} from '@angular/core';
import {User} from "../user.service";
import {UserComponent} from "../user.component";
import {AuthService} from "../../services/auth.service";
import {HttpClient} from "@angular/common/http";
import {Resource} from "../../resource/resource.service";
import {environment} from "../../../environments/environment";
import {Comment, CommentsService, pageType} from "../../services/comments.service";
import {localizeCosmetic} from "../../interfaces/player-cosmetics";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import Swal from "sweetalert2";
import {SwalPortalTargets} from "@sweetalert2/ngx-sweetalert2";
import {Location, ViewportScroller} from "@angular/common";
import {ActivatedRoute} from "@angular/router";

export interface ActivitiesRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly activities: Activity[];
  }
}
export interface Activity {
  readonly _id: string;
  readonly user: User;
  readonly comment?: Comment;
  subComments?: Comment[];
  readonly resource?: Resource;
  readonly createdAt: string;
}

@Component({
  selector: 'app-activities',
  templateUrl: './user-activities.component.html',
  styleUrls: ['./user-activities.component.css'],
})
export class UserActivitiesComponent implements OnInit {
  user!: User;
  authedUser?: User;


  postSubComment!: ElementRef;

  @BlockUI('commentArea')
  commentBlock!: NgBlockUI
  commentInput: string = '';

  activities_: Activity[] = [];

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private comment: CommentsService,
    private route: ActivatedRoute,
    private location: Location,
    private viewportScroller: ViewportScroller,
    public swalTargets: SwalPortalTargets,
    @Inject(UserComponent) private parent: UserComponent
  ) { }

  ngOnInit(): void {
    this.user = this.parent.user;

    if (AuthService.isAuthorized())
      this.auth.getUserData().subscribe(response => this.authedUser = response.data.user);

    this.loadActivities();
  }

  loadActivities(page: number = 0) {
    this.http.get<ActivitiesRequest>(environment.apiServer + 'users/' + this.user._id + '/activities', { params: { page }}).subscribe(response => {
      this.activities_ = response.data.activities.map(activity => {
        if ('comment' in activity) {
          activity.subComments = [];

          if (activity.comment!.subComments !== 0)
            this.comment.getComments("comment", activity.comment!._id).subscribe(comments => activity.subComments = comments);
        }

        return activity;
      });

      setTimeout(() => {
        this.route.fragment.subscribe(fragment => {
          if (fragment && !fragment.includes('.'))
            this.viewportScroller.scrollToAnchor(fragment);
        });
      }, 700);
    });
  }

  get parentUser(): User {
    return this.parent.user;
  }

  get activities(): Activity[] {
    return this.activities_.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }

  localizeCosmetic(resource: Resource): string {
    return localizeCosmetic(resource.type);
  }


  sortSubComments(comments: Comment[]): Comment[] {
    return comments.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
  }

  popupPostComment(activity: Activity) {
    Swal.fire({
      title: $localize`Kommentar von ${activity.user.username} kommentieren`,
      input: "textarea",
      confirmButtonText: $localize`Absenden`,
      showCancelButton: true
    }).then(result => {
      console.log(!result.isConfirmed, !result.value, !result.isConfirmed || !result.value)
      if (!result.isConfirmed || !result.value)
        return;

      this.postComment("comment", activity.comment!._id, result.value);
    });
  }

  postCommentByKey($event: KeyboardEvent) {
    if ($event.key === 'Enter')
      $event.preventDefault();

    if (!$event.ctrlKey || $event.key !== 'Enter')
      return;

    this.postComment();
  }
  postComment(type: pageType = "user", id?: string, value?: string) {
    if (!value && !this.commentInput)
      return;

    this.commentBlock.start();
    this.comment.postComment(type, id || this.user._id, value || this.commentInput).subscribe(response => {
      this.commentBlock.stop();
      this.commentInput = '';

      if (!value)
        this.activities_.push(new class implements Activity {
          readonly _id: string = response._id;
          readonly comment: Comment = response;
          subComments: Comment[] = [];
          readonly createdAt: string = response.timestamp;
          readonly user: User = response.user;
        });

      else {
        const activity = this.activities_.find(activity => 'comment' in activity && activity.comment!._id === id);
        if (!activity)
          return;
        activity.comment!.subComments++;
        activity.subComments!.push(response);
        activity.subComments = this.sortSubComments(activity.subComments!);
      }
    });
  }
  deleteComment(comment: Comment) {
    this.comment.deleteComment(comment).subscribe(response => {
      if (response.status === 200)
        this.activities_ = this.activities_.filter(activity => activity.comment !== comment).map(activity => {
          if (!activity.subComments)
            return activity;

          activity.comment!.subComments--;
          activity.subComments = activity.subComments.filter(comment_ => comment_ !== comment);
          return activity;
        });
    });
  }

  changeHash(hash?: string | number) {
    this.location.replaceState(this.location.path(false) + '#' + hash);
  }
}
