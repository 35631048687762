<ng-select
  class="ng-night"
  [items]="items | async"
  bindLabel="name"
  [bindValue]="returnId ? '_id' : 'name'"
  [addTag]="addTag"
  [multiple]="multiple"
  [hideSelected]="true"
  [trackByFn]="trackByFn"
  [minTermLength]="3"
  [loading]="loading"
  typeToSearchText="Please enter 3 or more characters"
  [placeholder]="placeholder"
  [typeahead]="input"
  (change)="update()"
  [(ngModel)]="selectedInput">
  <ng-template ng-label-tmp let-item="item">
    <img class="me-2" height="25em" width="25em" [src]="item.avatar" *ngIf="item.avatar">
    <span [innerHTML]="item.name"></span>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    <img class="me-2" height="25em" width="25em" [src]="item.avatar" *ngIf="item.avatar">
    <span [innerHTML]="item.name"></span>
  </ng-template>
</ng-select>
