import { File } from './file';

export type Cosmetics = 'cloaks' | 'bandanas' | 'hd-skins' | 'round-shields' | 'knight-shields';
export const cosmeticsValues: Cosmetics[] = ['cloaks', 'bandanas', 'hd-skins', 'round-shields', 'knight-shields']
export const cosmeticsTemplates: Record<Cosmetics, String> = {
  cloaks: "https://cdn.night.design/files/635452200706534bafcfc320.png",
  bandanas: "",
  "hd-skins": "",
  "round-shields": "https://cdn.night.design/files/635451c80706534bafcfc002.png",
  "knight-shields": "https://cdn.night.design/files/635451c90706534bafcfc00c.png"
};

export interface PlayerCosmeticsRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly cosmetics: PlayerCosmetic[];
  };
}

export interface PlayerCosmeticRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly cosmetic: PlayerCosmetic;
  };
}

export interface PlayerCosmetic {
  readonly type: Cosmetics;
  file: File | undefined;
}

export function localizeCosmetic(cosmetics: Cosmetics) {
  switch (cosmetics) {
    case "cloaks":
      return $localize`Umhang`;
    case "bandanas":
      return $localize`Bandana`;
    case "hd-skins":
      return $localize`HD-Skin`;
    case "round-shields":
      return $localize`Rundschild`;
    case "knight-shields":
      return $localize`Ritterschild`;
  }

  return cosmetics;
}
