import {Component, OnDestroy, OnInit} from '@angular/core';
import {Editor, toDoc, Toolbar, Validators as EditorValidators } from "ngx-editor";
import Swal, {SweetAlertIcon} from "sweetalert2";
import {UserConnection, UserConnectionsRequest} from "../interfaces/user-connections";
import {environment} from "../../environments/environment";
import {HttpClient, HttpResponse} from "@angular/common/http";
import { LoadingBarService } from '@ngx-loading-bar/core';
import {Router} from "@angular/router";
import {NgxFileDropEntry} from "ngx-file-drop";
import {
  AbstractControl,
  FormGroup,
  FormControl,
  Validators, AsyncValidatorFn, ValidationErrors
} from "@angular/forms";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {Party} from "../modder/modder.component";
import {cosmeticsValues, localizeCosmetic} from "../interfaces/player-cosmetics";
import {WindowService} from "../window.service";


export interface PartyRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly mod: Party;
  }
}


export interface Question {
  readonly id: string,
  readonly name: string,
  readonly info: {
    readonly html: string,
    readonly footer: string | undefined,
    readonly link: string | undefined
  } | undefined
}

export interface InternalFile {
  name: string;
  relativePath: string;
  file: File;
  primary: boolean;
}

export interface UploadStatus {
  status: 'WAITING' | 'UPLOADING' | 'PROCESSING' | 'DONE' | 'FAILED';
  error: string | undefined;
}

@Component({
  selector: 'app-modder-publisher',
  templateUrl: './modder-publisher.component.html',
  styleUrls: ['./modder-publisher.component.css']
})
export class ModderPublisherComponent implements OnInit, OnDestroy {
  @BlockUI('form') blockUI!: NgBlockUI;
  loader = this.loadingBar.useRef();
  accountsCache: UserConnection[] = [];
  form!: FormGroup;

  type: 'CLIENT' | 'MOD' | 'BOTH' = 'CLIENT';

  customSlug: boolean = false;

  categories: { id: string, name: string, disabled: boolean }[] = [
    {
      id: 'vanilla',
      name: 'Vanilla Mod',
      disabled: false
    },
    {
      id: 'forge',
      name: 'Forge Mod',
      disabled: false
    },
    {
      id: 'fabric',
      name: 'Fabric Mod',
      disabled: false
    },
    {
      id: 'optifine',
      name: 'Optifine',
      disabled: false
    },
    {
      id: 'labymod_addon',
      name: 'LabyMod Addon',
      disabled: false
    },
    {
      id: 'injection',
      name: 'Injection',
      disabled: false
    },
    {
      id: 'hosts',
      name: 'Host File rewrite',
      disabled: false
    },
  ];
  second_categories_items: { id: string, name: string, disabled: boolean }[] = [];

  supportedCosmetics_items: { id: string, name: string, disabled: boolean }[] = cosmeticsValues.map(cosmetic => { return { id: cosmetic, name: localizeCosmetic(cosmetic), disabled: false }})


  descEditor: Editor = new Editor();
  descToolbar: Toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    ['link', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    ['text_color', 'background_color'],
    ['format_clear']
  ];


  questions: Question[] = [
    {
      id: 'autoUpdater',
      name: $localize`Besitzt euer Client/Mod einen AutoUpdater?`,
      info: {
        html: $localize`AutoUpdater sind cool und sehr praktisch, jedoch wenn das Update von einem anderen Server als von unseren kommt, sehen wir diese Versionen des Clients/Mods als unsicher denn wir können es leider nicht überprüfen.<br>Keine sorge, du kannst dennoch einen AutoUpdate einbauen, jedoch dazu musst du den Dienst von uns nutzen damit wir die sicherheit der Nutzer aufrecht halten können.<br>Hierzu müsstet ihr unserem System zugriff auf eure Github-Repo geben.`,
        footer: $localize`Wusstest du, dass das Spigot Forum auch externe Update Server verbietet?`,
        link: undefined
      }
    },
    {
      id: 'own_store',
      name: $localize`Habt ihr einen eigenen Cosmetics Store?`,
      info: undefined
    },
    {
      id: 'rewrite_hosts',
      name: $localize`Modifiziert euer Client/Mod die "hosts"-Datei?`,
      info: {
        html: $localize`Modifizierung der "hosts"-Datei bedeutet, dass ihr eintragungen in die "hosts"-Datei des jeweiligen Betriebssystemes' vornehmt um einem andern Programm vorzugaukeln das ihr dieser Dienst seid.`,
        footer: $localize`Betroffene Dateien:<br><ul><li>Windows: %windir%\\system32\\drivers\\etc\\hosts</li><li>Linux: /etc/hosts</li></ul>`,
        link: undefined
      }
    },
    {
      id: 'cheats',
      name: $localize`Ermöglicht dein Client/Mod unfaire Vorteile gegenüber anderen Spieler?`,
      info: {
        html: $localize`Mit unfaire Vorteile meinen wir, dass ihr dem Nutzer nutzung von Module wie KillAura/AimBot/Fly/Speed ermöglicht.`,
        footer: undefined,
        link: undefined
      }
    },
    {
      id: 'disabled_antiVirus',
      name: $localize`Um deinen Client/Mod installieren zu können, muss man sein Virenschutzprogramm deaktivieren?`,
      info: undefined
    },
    {
      id: 'notMyCode',
      name: $localize`Beinhaltet der Client/Mod Code von dritte?`,
      info: {
        html: $localize`Code von dritte bedeutet, dass ihr den Code von anderen Clients/Mods nutzt ohne dessen erlaubnis dazu erhalten zu haben.<br>Ja, diese Regel gilt auch aus OpenSource Projekte!<br>Hierbei auch die Lizenzen der andern Projekte beachten!`,
        footer: undefined,
        link: undefined
      }
    },
    {
      id: 'mcp',
      name: $localize`Nutzt ihr das MinecraftCoderPack?`,
      info: {
        html: $localize`Das MinecraftCoderPack ist an sich eine feine sache, nur meistens verstößt es leider gegen die Minecraft Eula da der Source Code von MC oft "Roh" weitergeben wird.`,
        footer: 'License and terms of use.<br>=========================<br><br>No warranties. If MCP does not work for you, or causes any damage, it\'s your problem. Use it at own risk.<br><br>You are allowed to:<br>- Use MCP to decompile the Minecraft client and server jar files.<br>- Use the decompiled source code to create mods for Minecraft.<br>- Recompile modified versions of Minecraft.<br>- Reobfuscate the classes of your mod for Minecraft.<br><br>You are NOT allowed to:<br>- Use MCP to do anything that violated Mojangs terms of use for Minecraft.<br>- Release Minecraft versions or modifications that allow you to play without having bought Minecraft from Mojang.<br>- Release modified or unmodified versions of MCP anywhere.<br>- Use any of MCPs scripts, tools or data files without explicit written permission.<br>- Make money with anything based on MCP (excluding Minecraft mods created by using MCP).<br>- Use MCP to create clients that are used for griefing or exploiting server bugs.<br>- Release the decompiled source code of Minecraft in any way.',
        link: undefined
      }
    },
    {
      id: 'obfuscated',
      name: $localize`Verschleiert(Obfuscated) ihr euren Code?`,
      info: {
        html: $localize`Mit verschleiern(obfuscated) wird gemeint, dass ihr euer Projekt gegen "Reverse Engineering" schützen wollt.<br>Leider erschwert ihr damit auch uns den Client/Mod zu überprüfen, falls ihr dennoch sowas nutzten wollt müsstet ihr unserem System zugriff auf eure Github-Repo geben.`,
        footer: undefined,
        link: undefined
      }
    },
  ];
  questionsResult: { [key: string]: boolean | null } = {};
  questionsResultLength = (): number => Object.keys(this.questionsResult).length;



  versions: { id: string, name: string, disabled: boolean }[] = [
    { id: 'v1_7', name: '1.7.X', disabled: false },
    { id: 'v1_8', name: '1.8.X', disabled: false },
    { id: 'v1_9', name: '1.9.X', disabled: false },
    { id: 'v1_10', name: '1.10.X', disabled: false },
    { id: 'v1_11', name: '1.11.X', disabled: false },
    { id: 'v1_12', name: '1.12.X', disabled: false },
    { id: 'v1_13', name: '1.13.X', disabled: false },
    { id: 'v1_14', name: '1.14.X', disabled: false },
    { id: 'v1_15', name: '1.15.X', disabled: false },
    { id: 'v1_16', name: '1.16.X', disabled: false },
    { id: 'v1_17', name: '1.17.X', disabled: false },
    { id: 'v1_18', name: '1.18.X', disabled: false },
    { id: 'v1_19', name: '1.19.X', disabled: false },
  ];
  releaseDescEditor: Editor = new Editor();


  filesLocation: 'LOCAL' | 'GITHUB' = 'LOCAL';
  localFiles: InternalFile[] = [];
  uploadStatus: UploadStatus = {
    status: "WAITING",
    error: undefined
  };

  gitProfiles: { id: string, name: string }[] = [];
  gitProfile: String = '';
  gitRepos: { id: string, name: string }[] = [];
  gitRepo: String = '';
  gitRepoBranches: { id: string, name: string }[] = [];
  gitRepoBranch: String = '';


  triedSubmitting: boolean = false;


  constructor(
    private router: Router,
    private http: HttpClient,
    private loadingBar: LoadingBarService,
    private window: WindowService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(32),
      ]),
      slug: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(16),
        Validators.pattern('[A-Za-z0-9_-]*'),
      ], [
        this.checkSlug(),
      ]),
      summary: new FormControl('', [
        Validators.required,
        Validators.minLength(16),
        Validators.maxLength(128),
      ]),
      category: new FormControl('', [
        Validators.required,
      ]),
      second_categories: new FormControl({value: '', disabled: true}, [
        Validators.required,
      ]),

      supportedCosmetics: new FormControl('', [
        Validators.required,
      ]),

      desc: new FormControl('', [
        EditorValidators.required(),
        EditorValidators.minLength(128),
        EditorValidators.maxLength(2048),
      ]),


      release: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(32),
      ]),
      supportedVersions: new FormControl('', [
        Validators.required,
      ]),
      releaseDesc: new FormControl('', [
        EditorValidators.required(),
        EditorValidators.minLength(16),
        EditorValidators.maxLength(512),
      ])
    });

    this.http.get<UserConnectionsRequest>(environment.apiServer + 'users/@me/connected-accounts').subscribe(value => {
      this.accountsCache = value.data.connections.filter(a => ['github'].includes(a.provider));

      this.gitProfiles = this.accountsCache.map((account, index) => { return { id: account._id, name: account.profile.name}});

      if (this.accountsCache.length !== 0) {
        this.gitProfile = this.accountsCache[0]._id;
        this.switchGitProfile();
      }
    });
  }

  ngOnDestroy() {
    this.descEditor.destroy();
    this.releaseDescEditor.destroy();
  }

  getInput(key: string): AbstractControl {
    return this.form.get(key)!;
  }
  getInputValue(key: string): string {
    return this.getInput(key).value;
  }
  hasInputError(key: string, error?: string): boolean {
    const control = this.getInput(key);
    if (!control || control.status === "PENDING")
      return false;

    if (!control.touched)
      return false;

    if (!error)
      return control.invalid;

    return control.hasError(error);
  }
  displayInputError(key: string, offset?: number): string | undefined {
    if (!offset)
      offset = 0;

    const control = this.getInput(key);
    if (!control)
      return undefined;

    if (!control.touched)
      return undefined;

    if (!control.errors)
      return undefined;

    const error = Object.keys(control.errors)[offset];
    const errorData = control.errors[error];
    switch (error) {
      case 'required':
        return $localize`Dieses Feld darf nicht leer sein!`;

      case 'pattern':
        console.log(errorData)
        return $localize`Dieses Feld entspricht nicht dem erlaubten Format!`;

      case 'minlength':
        const lengthMin = errorData['requiredLength'] - errorData['actualLength'];
        return $localize`Dieses Feld ist um ${lengthMin} Zeichen zu kurz!`;

      case 'maxlength':
        const lengthMax = errorData['actualLength'] - errorData['requiredLength'];
        return $localize`Dieses Feld ist um ${lengthMax} Zeichen zu lang!`;

      case 'gone':
        const suggestion = control.value + String(errorData['offset']);
        return $localize`Diese eingabe ist bereits belegt! Versuche es mal mit ${suggestion}`;

      default:
        return undefined;
    }
  }
  updateInput(key: string, value?: string | KeyboardEvent) {
    const input = this.getInput(key);

    if (value)
      input.setValue(typeof value === 'string' ? value : (value.target as HTMLInputElement).value);

    input.markAsTouched();
    input.updateValueAndValidity();
  }
  markFormAsTouched() {
    Object.keys(this.form.controls).map(key => this.form.controls[key].markAsTouched());
  }
  get formInvalid(): boolean {
    return Object.keys(this.form.controls).some(key => this.form.controls[key].invalid);
  }


  updateSlug() {
    if (this.customSlug)
      return;

    const name = this.getInput('name').value.trim();
    if (!name)
      return;

    this.updateInput('slug', name
      .substring(0, 16)
      .replace(/\s+/g, '-')
      .replace(/[^A-Za-z0-9_-]/g, '')
      .toLowerCase());
  }
  timeoutSlug: number = 0;
  checkSlug(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise<ValidationErrors | null>(resolve => {
        if (this.timeoutSlug !== 0)
          clearTimeout(this.timeoutSlug);

        this.timeoutSlug = this.window.getWindow()!.setTimeout(() => {
          this.http.get<HttpResponse<any>>(environment.apiServer + 'mods/check-slug', { params: { noDefError: true, slug: control.value }}).subscribe(response => {
            if (response.ok)
              return resolve(null);

            if (response.status === 410)
              return resolve({ gone: { offset: response.body.data.offset } });
          }, error => {
            if (error.ok)
              return resolve(null);

            if (error.status === 410)
              return resolve({ gone: { offset: error.error.data.offset } });
          });
        }, 800);
      });
    };
  }


  categories_patch() {
    const val = this.getInput('category').value;
    const input = this.getInput('second_categories');
    input.setValue('');

    if (!val)
      input.disable();
    else
      input.enable();

    this.second_categories_items = this.categories.map(a => { return { ...a, disabled: a.id === val }});
  }


  help(question: Question) {
    Swal.fire({
      width: '50%',
      icon: "question",

      title: question.name,
      html: question.info?.html,
      footer: question.info?.footer,

      showConfirmButton: false,
      showCloseButton: true
    })
  }

  setQuestionResult(question: Question, result: boolean | null) {
    this.questionsResult[question.id] = result;
  }



  droppedFiles(files: NgxFileDropEntry[]) {
    if ((files.length + this.localFiles.length) > 6) {
      this.showAlert($localize`Du kannst leider nur 6 Dateien gleichzeitig veröffentlichen!`);
      return;
    }

    if (files.some(a => a.fileEntry.isDirectory)) {
      this.showAlert($localize`Du kannst leider keine Ordner hochladen!`);
      return;
    }

    files.filter(a => a.fileEntry.isFile).map((a, i) => {
      const { name, file } = a.fileEntry as FileSystemFileEntry;
      file(b => {
        if (!['image/png','image/gif'].includes(b.type) && !b.name.toLowerCase().endsWith('.jar'))
          return;

        this.localFiles.push({
          name,
          relativePath: a.relativePath,
          file: b,
          primary: this.localFiles.length === 0 && i === 0,
        });
      });
    });
  }
  primaryLocalFile(file: InternalFile) {
    if (this.uploadStatus.status === 'WAITING')
      this.localFiles.map(localFile => localFile.primary = localFile === file);
  }
  renameLocalFile(file: InternalFile) {
    if (this.uploadStatus.status === 'WAITING')
      Swal.fire({
        icon: "question",
        title: $localize`Datei umbenennen`,
        footer: $localize`Bitte denke daran die richtige Dateiendung zu verwenden! Ansonsten kann es passieren das die Datei nicht richtig funktioniert!`,

        input: "text",
        inputValue: file.name,

        showCancelButton: true,
      }).then(result => {
        if (!result.isConfirmed)
          return;

        file.name = result.value;
      });
  }
  removeLocalFile(file: InternalFile) {
    if (this.uploadStatus.status !== "WAITING")
      return;

    if (file.primary && this.localFiles.length !== 1)
      if (this.localFiles.indexOf(file) === 0)
        this.localFiles[1].primary = true;
      else
        this.localFiles[0].primary = true;

    this.localFiles = this.localFiles.filter(a => a !== file);
  }



  installApp() {
    const a = this.window.getWindow()!.open('https://github.com/apps/nightupdater/installations/new/permissions?target_id=85646221', '_blank', 'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=600em, height=1500em, left=500em')

    this.window.getWindow()!.setTimeout(() => console.log(a!.location.href), 1500)
  }

  switchGitProfile() {

  }
  switchGitRepo() {

  }
  switchGitRepoBranch() {

  }





  publish() {
    this.triedSubmitting = true;
    this.markFormAsTouched();
    if (this.formInvalid) {
      this.showAlert($localize`Das Formular wurde nicht komplett ausgefüllt!`);
      return;
    }

    if (this.questions.length !== this.questionsResultLength()) {
      this.showAlert($localize`Der Fragebogen darf nicht unbeantwortet sein!`);
      return;
    }

    if (this.filesLocation === "LOCAL" && this.localFiles.length === 0) {
      this.showAlert($localize`Du musst mindestens eine Datei hochladen!`);
      return;
    }

    if (environment.production) {
      this.form.disable();
      this.blockUI.start($localize`Daten werden verarbeitet...`);
    } else
      console.info("Disabled block page and form in dev mode.")

    const body = {
      type: this.type,
      name: this.getInputValue('name'),
      slug: this.getInputValue('slug'),

      summary: this.getInputValue('summary'),
      desc: toDoc(this.getInputValue('desc')),

      category: this.getInputValue('category'),
      second_categories: this.getInputValue('second_categories'),

      cosmetics: this.getInputValue('supportedCosmetics'),

      questions: this.questionsResult
    };

    const releaseData = new FormData();
    releaseData.append("name", this.getInputValue('release'));
    releaseData.append("versions", this.getInputValue('supportedVersions'));
    releaseData.append("desc", JSON.stringify(toDoc(this.getInputValue('releaseDesc'))));
    releaseData.append("primaryFile", String(this.localFiles.findIndex(localFile => localFile.primary)));
    for (let localFile of this.localFiles)
      releaseData.append("file[]", localFile.file, localFile.name);

    this.uploadStatus.status = 'WAITING';
    this.uploadStatus.error = undefined;
    this.http.post<PartyRequest>(environment.apiServer + 'mods', body).subscribe(response => {

      this.uploadStatus.status = 'UPLOADING';
      this.http.post<PartyRequest>(environment.apiServer + 'mods/' + response.data.mod._id + '/releases', releaseData).subscribe(releaseResponse => {
        this.uploadStatus.status = 'DONE';
        this.showAlert($localize`Client/Mod wurde eingereicht und wird nun überprüft.`, "success");
        this.window.getWindow()!.setTimeout(() => this.router.navigate(['/modder/' + response.data.mod._id]).then(), 800);
      }, error => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent)
          errorMsg = `Error: ${error.error.message}`;
        else
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;

        if ('message' in error.error) {
          const { status, message } = error.error;
          errorMsg = `ERROR[${status}]: ${message}`;
        }

        this.uploadStatus.status = 'FAILED';
        this.uploadStatus.error = errorMsg;
        console.log(errorMsg);
      });
    });
  }






  showAlert(title: string, icon: SweetAlertIcon= 'error') {
    Swal.fire({
      icon,
      title,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    }).then();
  }
}
