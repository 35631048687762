<div class="row">
  <div class="col-md-6" *ngFor="let connection of getConnections('xbox')">
    <div class="card btn-xbox social-card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3 text-center">
            <img class="rounded-circle social-avatar" [src]="connection.profile.avatar" alt="Connection Avatar">
          </div>
          <div class="col-md-9">
            <img class="rounded img-fluid float-end me-3" src="/assets/img/minecraft.webp" style="width: 5em; height: auto">
            <!--                      <i class="fab fa-xbox float-end me-3" style="font-size: 5em"></i>-->
            <h3>{{connection.profile.name}}</h3>
            <button class="btn btn-primary" (click)="showProfile(connection)" *ngIf="connection.profile.link" i18n>Profil anzeigen</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6" *ngFor="let connection of getConnections('google')">
    <div class="card btn-google social-card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3 text-center">
            <img class="rounded-circle social-avatar" [src]="connection.profile.avatar" alt="Connection Avatar">
          </div>
          <div class="col-md-9">
            <i class="fab fa-google float-end me-3" style="font-size: 5em"></i>
            <h3>{{connection.profile.name}}</h3>
            <button class="btn btn-primary" (click)="showProfile(connection)" *ngIf="connection.profile.link" i18n>Profil anzeigen</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6" *ngFor="let connection of getConnections('discord')">
    <div class="card btn-discord social-card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3 text-center">
            <img class="rounded-circle social-avatar" [src]="connection.profile.avatar" alt="Connection Avatar">
          </div>
          <div class="col-md-9">
            <i class="fab fa-discord float-end" style="font-size: 5em"></i>
            <h3>{{connection.profile.name}}</h3>
            <button class="btn btn-primary" (click)="showProfile(connection)" *ngIf="connection.profile.link" i18n>Profil anzeigen</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6" *ngFor="let connection of getConnections('twitch')">
    <div class="card btn-twitch social-card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3 text-center">
            <img class="rounded-circle social-avatar" style="border: 0.2rem #adadb8 solid; background-color: #9147ff" [src]="connection.profile.avatar" alt="Connection Avatar">
          </div>
          <div class="col-md-9">
            <i class="fab fa-twitch float-end me-1" style="font-size: 5em"></i>
            <h3>{{connection.profile.name}}</h3>
            <button class="btn btn-primary" (click)="showProfile(connection)" *ngIf="connection.profile.link" i18n>Profil anzeigen</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6" *ngFor="let connection of getConnections('github')">
    <div class="card btn-github social-card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3 text-center">
            <img class="rounded-circle social-avatar" [src]="connection.profile.avatar" alt="Connection Avatar">
          </div>
          <div class="col-md-9">
            <i class="fab fa-github float-end me-1" style="font-size: 5em"></i>
            <h3>{{connection.profile.name}}</h3>
            <button class="btn btn-primary" (click)="showProfile(connection)" *ngIf="connection.profile.link" i18n>Profil anzeigen</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="text-center mt-5" *ngIf="connectionsCache.length === 0">
  <i class="fa-solid fa-question text-muted" style="font-size: 12em"></i><br>
  <span class="text-muted" style="font-size: 2em" i18n>Hier gibts noch nichts zu sehen...</span>
</div>
