import {Component, OnInit} from '@angular/core';
import {User} from "./user.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService, UserRequest} from "../services/auth.service";
import {environment} from "../../environments/environment";
import {SimpleRequest} from "../services/simple-request";
import {Title} from "@angular/platform-browser";

export interface Statistics {
  readonly resources: number;
  readonly downloads: number;
  readonly comments: number;
  readonly createdAt: string;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  user!: User;
  authedUser?: User;

  statistics?: Statistics;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private auth: AuthService,
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let id = params['id'];
      this.http.get<UserRequest>(environment.apiServer + 'users/' + id, { params: { noDefError: 1 }}).subscribe(response => {
        this.user = response.data.user;

        this.titleService.setTitle($localize`User: ${this.user.username} - ${this.pageName}` + ' | Night.design');

        this.http.get<SimpleRequest<{ statistics: Statistics }>>(environment.apiServer + 'users/' + this.user._id + '/statistics').subscribe(response => {
          this.statistics = response.data.statistics;
        });
      }, error => {
        if (error.error.status === 404)
          this.router.navigate(['/404']).then();
      });
    });

    if (AuthService.isAuthorized())
      this.auth.getUserData().subscribe(response => this.authedUser = response.data.user);
  }

  get pageId(): string {
    return this.route.firstChild!.routeConfig!.path!;
  }
  get pageName(): string {
    const pageId = this.pageId;
    switch (pageId) {
      case 'activities':
        return $localize`Aktivitäten`;
      case 'resources':
        return $localize`Designs`;
      case 'connected-accounts':
        return $localize`Verbundene Accounts`;

      default:
        return pageId;
    }
  }

}
