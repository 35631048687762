import { Component, OnInit } from '@angular/core';
import Swal from "sweetalert2";

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit {

  models = [];
  selectedModel = {};

  layers: Layer[] = [
    new Layer("Layer 1"),
  ];

  // https://github.com/MauritsWilke/mcpfp/blob/main/src/lib/rendering/generateProfile.ts
  constructor() { }

  ngOnInit(): void {
  }

  addLayer() {
    Swal.fire({
      title: "Namen der neuen Ebene",

      input: "text",
      inputValue: "Layer " + (this.layers.length + 1),
    }).then(result => {
      this.layers.push(new Layer(result.value));
    });
  }

  deleteLayer(layer: Layer) {
    this.layers = this.layers.filter(a => a !== layer)
  }
}

export class Layer {
  id = String(Date.now());
  name!: string;
  hidden: boolean = false;
  element!: HTMLCanvasElement;

  constructor(name: string) {
    this.name = name;
    setTimeout(() => {
      this.element = document.getElementById(this.id) as HTMLCanvasElement;
      const ctx = this.element.getContext("2d")!;
      ctx.fillRect(0,0, 5,5);

      //const img = new Image();
      //img.addEventListener("load", () => ctx.drawImage(img,0,0),  false)
      //img.src = "https://cdn.night.design/files/635452200706534bafcfc320.png";
    }, 500);
  }
}
