import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GameServer, GameserverComponent} from "../gameserver.component";
import {environment} from "../../../environments/environment";
import {SimpleRequest} from "../../services/simple-request";
import {SwalComponent, SwalPortalTargets} from "@sweetalert2/ngx-sweetalert2";

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {
  parent!: GameserverComponent;
  server!: GameServer;
  listType?: ServerType;

  supportedSoftware: ServerSoftware[] = [];
  selectedSoftware!: ServerSoftware;

  @ViewChild('setupPopup')
  setupPopup!: SwalComponent;

  costMapping: { [key: string]: string } = {
    "RUNTIME": "Laufzeit (pro Minute)",
    "SETUP": "Einrichtung (Einmalig)"
  };

  constructor(
    public swalTargets: SwalPortalTargets,
    private http: HttpClient,
    @Inject(GameserverComponent) private parent_: GameserverComponent,
  ) { }

  ngOnInit(): void {
    this.parent = this.parent_;
    this.server = this.parent_.server;

    this.http.get<SimpleRequest<{ versions: ServerSoftware[] }>>(`${environment.apiServer}gameserver/versions`).subscribe(response => {
      this.supportedSoftware = response.data.versions.sort((a,b) => (a.name ?? a.software).localeCompare(b.name ?? b.software));
      this.selectedSoftware = new class implements ServerSoftware {
        additionalCost = new class implements ServerSoftwareFee {
          amount = 0;
          type = "RUNTIME" as FeeType;

        };
        description = "";
        extraInput = [];
        featured = false;
        image = "";
        name = "";
        slug = "";
        software = "";
         tags = [];
        value: any;
        version = [];
      };
    });
  }

  getFeaturedList(): ServerSoftware[] {
    if (!this.listType || !["MODDED"].includes(this.listType!))
      return this.supportedSoftware.filter(software => software.featured);
    return [];
  }
  getSoftwareList(): ServerSoftware[] {
    if (!this.listType)
      return this.supportedSoftware.filter(software => !software.featured).filter(software => !["MODPACK", "SETUP"].some(value => software.tags?.includes(value)));

    if (this.listType === "SETUP")
      return this.supportedSoftware.filter(software => software.tags !== undefined && software.tags!.includes(this.listType!));

    return this.supportedSoftware.filter(software => !software.featured).filter(software => !["MODPACK", "SETUP"].some(value => software.tags?.includes(value))).filter(software => software.tags !== undefined && software.tags!.includes(this.listType!));
  }
  getSetupList(): ServerSoftware[] {
    if (!this.listType || this.listType === "VANILLA" || this.listType === "SETUP")
      return [];
    return this.supportedSoftware.filter(software => !software.featured).filter(software => software.tags !== undefined && software.tags!.includes(this.listType!) && ["MODPACK", "SETUP"].some(value => software.tags?.includes(value)));
  }

  selectSoftware(software: ServerSoftware) {
    if (software.version.length === 1)
      software.value = software.version[0];
    this.selectedSoftware = software;
    setTimeout(() =>
      this.setupPopup.fire().then(),
      1
    );
  }
}

export type ServerType =  "VANILLA" | "PLUGINS" | "MODDED" | "SETUP" | string;

export interface ServerSoftware {
  readonly software: string;
  readonly name?: string;
  readonly slug?: string;
  readonly description?: string;
  readonly image?: string;
  readonly tags?: ServerType[];
  readonly version: ServerSoftwareVersion[];
  readonly extraInput?: ServerSoftwareExtraInput[];
  readonly featured: boolean;
  readonly additionalCost: ServerSoftwareFee;



  value?: ServerSoftwareVersion;
}
export interface ServerSoftwareVersion {
  readonly name: versionName;
  readonly mcVersion: mcVersion[];
  readonly stability: "STABLE" | "SNAPSHOT" | string;
}
export interface ServerSoftwareExtraInput {
  readonly version?: versionName[];
  readonly key: string;
  readonly name: string;
  readonly type: "text" | "select";
  readonly checks?: {
    readonly min?: number,
    readonly max?: number,
    readonly pattern?: string,
    readonly enum?: any[],
    readonly required?: boolean,
  },
  readonly prefix?: string;
  readonly suffix?: string;
  readonly placeholder?: string;
  readonly help?: string;
  value: any;
}

export interface ServerSoftwareFee {
  readonly type: FeeType,
  readonly amount: number
}



export type versionName = string;
export type mcVersion =
  "1.8.8" | "1.8.9"
  | "1.9.4"
  | "1.10.2"
  | "1.11.2"
  | "1.12" | "1.12.1" | "1.12.2"
  | "1.13" | "1.13.1" | "1.13.2"
  | "1.14" | "1.14.1" | "1.14.2" | "1.14.3" | "1.14.4"
  | "1.15" | "1.15.1" | "1.15.2"
  | "1.16.1" | "1.16.2" | "1.16.3" | "1.16.4" | "1.16.5"
  | "1.17" | "1.17.1"
  | "1.18" | "1.18.1" | "1.18.2"
  | "1.19" | "1.19.1" | "1.19.2" | "1.19.3" | "1.19.4"
  | string;
export type FeeType = "RUNTIME" | "SETUP";
