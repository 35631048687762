import {Component, Inject, OnInit} from '@angular/core';
import {Party} from "../../../modder/modder.component";
import {HttpClient} from "@angular/common/http";
import {ModderViewerComponent} from "../../modder-viewer.component";
import {environment} from "../../../../environments/environment";
import Swal from "sweetalert2";
import {WindowService} from "../../../window.service";

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
})
export class SecurityComponent implements OnInit {
  party!: Party;

  hideTimer: number = 0;
  newSecret: string | undefined;

  validScopes: string[] = ['GET_USER_EMAIL','GET_WORKSPACE','GET_WORKSPACE_FILE'];
  oauth2: { url: (string | undefined)[], scopes: string[] } | undefined = undefined;

  constructor(
    private http: HttpClient,
    private window: WindowService,
    @Inject(ModderViewerComponent) private parent: ModderViewerComponent
  ) {}

  ngOnInit(): void {
    this.party = this.parent.party;
  }

  createNewSecret(accepted?: boolean) {
    if (accepted === undefined) {
      Swal.fire({
        title: $localize`Achtung!`,
        text: $localize`Du bist dabei einen neuen Secret für die Party ${this.party.name} zu erstellen.`,
        footer: '<span class="text-danger">' + $localize`Diese Aktion wird sofort ausgeführt und invalidiert den alten Secret! Diese Aktion ist unwiderruflich!` + '</span>',
        showConfirmButton: true
      }).then(result => this.createNewSecret(result.isConfirmed));
      return;
    }
    if (!accepted)
      return;

    this.http.post<any>(environment.apiServer + 'mods/' + this.party._id + '/renew-secret', {}).subscribe(response => {
      this.newSecret = response.data.secret;

      if (this.hideTimer !== 0)
        clearTimeout(this.hideTimer);
      this.hideTimer = this.window.getWindow()!.setTimeout(() => this.newSecret = undefined, 5000);
    });
  }

  updateOAuth2URL($event: Event, index: number) {
    if (!this.oauth2)
      return;

    const input = $event.target as HTMLInputElement;
    const value = input.value.trim().toLowerCase();

    if (!value) {
      if (this.oauth2.url.length !== 1)
        this.oauth2.url = this.oauth2.url.filter((value, i) => i !== index);
      return;
    }

    if (!this.oauth2.url[index]?.trim())
      this.oauth2.url.push(Array(this.oauth2.url.length).join(' '));

    this.oauth2.url[index] = value;
  }

  toggleOAuth2Scope(scope: string) {
    if (!this.oauth2)
      return;

    if (!this.oauth2.scopes.some(scope_ => scope_ === scope)) {
      this.oauth2.scopes.push(scope);
      return;
    }

    this.oauth2.scopes = this.oauth2.scopes.filter(scope_ => scope_ !== scope);
  }
  get notUsedScopes(): string[] {
    if (!this.oauth2)
      return [];

    // @ts-ignore
    return this.validScopes.filter(scope => !this.oauth2.scopes.includes(scope));
  }

  copied2Clipboard() {
    Swal.fire({
      icon: 'success',
      title: $localize`Text in den Zwischenspeicher kopiert`,

      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    }).then();
  }
}
