import {Component, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../environments/environment";

import {AuthorisationRequest, AuthService} from "../services/auth.service"
import Swal from 'sweetalert2'
import {map, Observable, Subscription} from "rxjs";
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";

import * as Mailcheck from 'mailcheck';
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {Provider} from "../interfaces/user-connections";
import {Challenge} from "../settings/security/security.component";
import {User} from "../user/user.service";
import {gtag} from "../app.component";
import {isPlatformBrowser} from "@angular/common";
import {SwalComponent, SwalPortalTargets} from "@sweetalert2/ngx-sweetalert2";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  isBrowser = false;


  @BlockUI('loginCard') blockLoginUI: NgBlockUI | undefined;
  loginForm = { email: '', password: '' };
  form!: UntypedFormGroup;

  mailcheck: string | undefined;
  error: string | undefined;
  errorCode: string | undefined;
  submitted: boolean = false;
  captchaSubscription: Subscription | undefined;
  authorisationSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    private authService: AuthService,
    public swalTargets: SwalPortalTargets,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (!this.isBrowser)
      return;


    this.form = new UntypedFormGroup({
      email: new UntypedFormControl(this.loginForm.email, [
        Validators.required,
        this.validateEmail(),
      ]),
      password: new UntypedFormControl(this.loginForm.password, Validators.required),
    });


    this.route.queryParams.subscribe(params => {
      if (AuthService.isAuthorized() && 'returnTo' in params) {
        const returnTo = new URL('https://' + atob(params['returnTo']));
        if (returnTo.host !== environment.host) {
          this.router.navigate(['/dashboard']).then();
          return;
        }

        const search: any = {};
        returnTo.searchParams.forEach((value: string, key: string) => search[key] = value);

        this.router.navigate([returnTo.pathname], { queryParams: search }).then();
        return;
      }

      if ('authorisation' in params) {
        this.error = undefined;
        this.form.disable({onlySelf: true});
        this.blockLoginUI!.start('Loading...');

        const token = params['authorisation'];
        const tokenData = JSON.parse(atob(token.split('.')[1]));

        const authorisation = this.authService.loginViaThirdParty(token);
        if (typeof authorisation === "string") {
          this.showError(authorisation, authorisation);
          return;
        }

        if (this.authorisationSubscription)
          this.authorisationSubscription.unsubscribe();

        this.authorisationSubscription = authorisation.subscribe({
          next: (data: AuthorisationRequest) => {
            this.blockLoginUI!.stop();
            localStorage.setItem('access_token', data.data.authorization.access_token);
            localStorage.setItem('refresh_token', data.data.authorization.refresh_token);


            if (tokenData && Object.keys(tokenData).includes('returnTo')) {
              const returnTo = new URL('https:' + tokenData.returnTo);
              if (returnTo.host !== environment.host) {
                this.router.navigate(['/dashboard']).then();
                return;
              }

              const search: any = {};
              returnTo.searchParams.forEach((value: string, key: string) => search[key] = value);

              this.router.navigate([returnTo.pathname], {queryParams: search}).then();
              return;
            }

            this.router.navigate(['/dashboard']).then();
          },
          error: err => {
            const error = err.error;
            this.check2FARequired(error);
            this.showError('message' in error ? '[' + error.status + '/' + error.error + '] ' + error.message : '[' + error.status + '/' + error.error + ']', error.message);
          }
        });
      }

      if ('activation' in params) {
        this.error = undefined;
        this.form.disable({onlySelf: true});
        this.blockLoginUI!.start('Loading...');

        const token = params['activation'];
        const tokenData = JSON.parse(atob(token.split('.')[1]));

        const authorisation = this.authService.loginActivation(token);
        if (typeof authorisation === "string") {
          this.showError(authorisation, authorisation);
          return;
        }

        if (this.authorisationSubscription)
          this.authorisationSubscription.unsubscribe();

        this.authorisationSubscription = authorisation.subscribe({
          next: (data: AuthorisationRequest) => {
            this.blockLoginUI!.stop();
            localStorage.setItem('access_token', data.data.authorization.access_token);
            localStorage.setItem('refresh_token', data.data.authorization.refresh_token);
            gtag('event', 'login');

            if (tokenData && Object.keys(tokenData).includes('returnTo')) {
              const returnTo = new URL('https:' + tokenData.returnTo);
              if (returnTo.host !== environment.host) {
                this.router.navigate(['/dashboard']).then();
                return;
              }

              const search: any = {};
              returnTo.searchParams.forEach((value: string, key: string) => search[key] = value);

              this.router.navigate([returnTo.pathname], {queryParams: search}).then();
              return;
            }

            this.router.navigate(['/dashboard']).then();
          },
          error: err => {
            const error = err.error;
            this.check2FARequired(error);
            this.showError('message' in error ? '[' + error.status + '/' + error.error + '] ' + error.message : '[' + error.status + '/' + error.error + ']', error.message);
          }
        });
      }
    }).unsubscribe();
  }

  login(): void {
    this.submitted = true;
    if (this.form.status !== "VALID")
      return;
    this.form.disable({ onlySelf: true });
    this.blockLoginUI!.start('Loading...');

    this.error = undefined;

    if (this.captchaSubscription)
      this.captchaSubscription.unsubscribe();

    this.captchaSubscription = this.recaptchaV3Service.execute('login')
      .subscribe(token => {
        const authorisation = this.authService.login(this.email.value, this.password.value, token);
        if (typeof authorisation === "string") {
          this.showError(authorisation, authorisation);
          return;
        }


        if (this.authorisationSubscription)
          this.authorisationSubscription.unsubscribe();

        this.authorisationSubscription = authorisation.subscribe({
          next: (data: AuthorisationRequest) => {
            this.blockLoginUI!.stop();
            localStorage.setItem('access_token', data.data.authorization.access_token);
            localStorage.setItem('refresh_token', data.data.authorization.refresh_token);

            this.route.queryParams.subscribe(params => {
              if ('returnTo' in params) {
                const returnTo = new URL('https://' + atob(params['returnTo']));
                if (returnTo.host !== environment.host) {
                  this.router.navigate(['/dashboard']).then();
                  return;
                }

                const search: any = {};
                returnTo.searchParams.forEach((value: string, key: string) => search[key] = value);

                this.router.navigate([returnTo.pathname], { queryParams: search }).then();
                return;
              }

              this.router.navigate(['/dashboard']).then();
            }).unsubscribe();
          },
          error: err => {
            const error = err.error;
            this.check2FARequired(error);
            this.showError('message' in error ? '[' + error.status + '/' + error.error + '] ' + error.message : '[' + error.status + '/' + error.error + ']', error.message);
          }
        });
      });
  }

  get email() { return this.form.get('email')!; }
  get password() { return this.form.get('password')!; }

  private showError(error: string, errorCode: string) {
    this.blockLoginUI!.stop();
    this.form.enable({ onlySelf: true });

    if (errorCode === '2FA required')
      return;

    this.error = error;
    this.errorCode = errorCode;
    Swal.fire({
      icon: 'error',
      title: this.error,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    }).then();
  }

  private validateEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const email = control.value.toLowerCase();

      const mailcheck = Mailcheck.run({
        email,
        domains: Mailcheck.defaultDomains,
        secondLevelDomains: Mailcheck.defaultSecondLevelDomains,
        topLevelDomains: Mailcheck.defaultTopLevelDomains
      });
        this.mailcheck = mailcheck && !mailcheck.full.includes(email) ? mailcheck.full : undefined;

      const regex = this.isEmail(email);
      if (regex)
        return { pattern: true };

      return null
    }
  }

  private isEmail(email: string): string | undefined {
    email = (email || '').trim();
    if (email.length === 0)
      return 'Email not provided';
    const split = email.split('@');
    if (split.length < 2)
      return 'Email does not contain "@".';

    else {
      const [domain] = split.slice(-1);
      if (domain.indexOf('.') === -1)
        return 'Must contain a "." after the "@".';
    }

    return undefined;
  }

  authUrl(provider: Provider): Observable<string> {
    const url = new URL(environment.apiServer + 'auth/' + provider);
    const params = url.searchParams;

    return this.route.queryParams.pipe(
      map(source => {
        const returnTo = new URL('https://' + environment.host + '/login');
        if ('returnTo' in source)
          returnTo.searchParams.set('returnTo', source['returnTo'])
        params.set('returnTo', btoa(returnTo.href));

        return url.href;
      })
    );
  }


  uuid?: string;
  code?: string;
  @ViewChild('minecraftLogin')
  minecraftPopup!: SwalComponent
  loginViaMinecraft() {
    if (!this.uuid || !this.code)
      return;

    this.blockLoginUI!.start();
    const authorization = this.authService.loginViaMinecraft(this.uuid, this.code);
    delete this.uuid;
    delete this.code;

    if (typeof authorization === "string") {
      this.minecraftPopup.close();
      this.showError(authorization, authorization);
      return;
    }

    authorization.subscribe({
      next: response => {
        this.minecraftPopup.close();
        this.blockLoginUI!.stop();
        localStorage.setItem('access_token', response.data.authorization.access_token);
        localStorage.setItem('refresh_token', response.data.authorization.refresh_token);

        this.route.queryParams.subscribe(params => {
          if ('returnTo' in params) {
            const returnTo = new URL('https://' + atob(params['returnTo']));
            if (returnTo.host !== environment.host) {
              this.router.navigate(['/dashboard']).then();
              return;
            }

            const search: any = {};
            returnTo.searchParams.forEach((value: string, key: string) => search[key] = value);

            this.router.navigate([returnTo.pathname], {queryParams: search}).then();
            return;
          }

          this.router.navigate(['/dashboard']).then();
        });
      },
      error: err => {
      const error = err.error;
      this.minecraftPopup.close();
      this.check2FARequired(error);
      this.showError('message' in error ? '[' + error.status + '/' + error.error + '] ' + error.message : '[' + error.status + '/' + error.error + ']', error.message);
    }
    });
  }

  user: User | undefined = undefined;
  challenges: Challenge[] = [];
  challengeToken: string = '';
  check2FARequired(errorData: { [key: string]: any}) {
    if (![200, 900].includes(errorData['status']))
      return;

    this.user = errorData['data']['user'];
    this.challenges = errorData['data']['challenges'];
    this.challengeToken = errorData['data']['token'];
  }

  runChallenge(challenge: Challenge) {
    switch (challenge.provider) {
      case "OTP":
        Swal.fire({
          title: challenge.name,
          text: $localize`Bitte gebe deinen OTP Token in das Feld ein.`,

          showCancelButton: true,

          input: "text",
          inputPlaceholder: '000000',
          inputAutoTrim: true,
          preConfirm: (value: string): Promise<boolean> => {
            const body = new FormData();
            body.set('response', value);
            body.set('token', this.challengeToken);
            return fetch(environment.apiServer + 'auth/challenges/' + challenge._id, { body, method: 'POST'}).then(async response => {
              const json = await response.json();
              if (!response.ok) {
                switch (json.status) {
                  case 403:
                    Swal.showValidationMessage($localize`Dein Token ist ungültig.`);
                    break;

                  default:
                    Swal.showValidationMessage(json.message);
                }
                return false;
              }

              localStorage.setItem('access_token', json.data.access_token);
              localStorage.setItem('refresh_token', json.data.refresh_token);
              setTimeout(() => window.location.reload(), 700);

              return true;
            });
          }
        }).then();
    }
  }



  logout() {
    this.authService.logout();
    setTimeout(() => window.location.reload(), 700);
  }
  get isAuthorized(): boolean {
    return AuthService.isAuthorized();
  }

  backupChallenge() {
    Swal.fire({
      title: $localize`Wiederherstellungs Code`,
      text: $localize`Bitte gebe deinen Wiederherstellungs Code in das Feld ein.`,

      showCancelButton: true,

      input: "text",
      inputPlaceholder: 'xxxxxxxx',
      inputAutoTrim: true,
      preConfirm: (value: string): Promise<boolean> => {
        const body = new FormData();
        body.set('response', value);
        body.set('token', this.challengeToken);
        return fetch(environment.apiServer + 'auth/challenges/backup-code', { body, method: 'POST'}).then(async response => {
          const json = await response.json();
          if (!response.ok) {
            switch (json.status) {
              default:
                Swal.showValidationMessage(json.message);
            }
            return false;
          }

          localStorage.setItem('access_token', json.data.access_token);
          localStorage.setItem('refresh_token', json.data.refresh_token);
          setTimeout(() => window.location.reload(), 700);

          return true;
        });
      }
    }).then();
  }

    protected readonly undefined = undefined;
}
