import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {Comment, CommentsService, pageType} from "../../services/comments.service";
import {User} from "../../user/user.service";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {Location} from "@angular/common";

@Component({
  selector: 'commentsArea',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit, OnChanges {

  @Input()
  pageType!: pageType;
  @Input()
  pageId!: string;

  @Input()
  showCommentInput: boolean = true;
  @Input()
  requireRequest: boolean = true;
  @Input()
  openedSubComment?: Comment;

  @Input()
  subCommentId?: number;

  @BlockUI('commentArea')
  commentBlock!: NgBlockUI
  commentInput: string = '';

  authedUser?: User;
  loadingComments = true;
  comments: Comment[] = [];


  constructor(
    private auth: AuthService,
    private comment: CommentsService,
    private location: Location,
  ) { }

  ngOnInit(): void {
    if (AuthService.isAuthorized())
      this.auth.getUserData().subscribe(response => this.authedUser = response.data.user);

    if (this.requireRequest)
      this.updateComments();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!('pageId' in changes))
      return;

    this.updateComments();
  }

  updateComments() {
    if (this.pageId.length === 0)
      return;

    this.comment.getComments(this.pageType, this.pageId).subscribe(comments => {
      this.comments = comments;
      this.loadingComments = false;
    });
  }


  postCommentByKey($event: KeyboardEvent) {
    if ($event.key === 'Enter')
      $event.preventDefault();

    if (!$event.ctrlKey || $event.key !== 'Enter')
      return;

    this.postComment();
  }
  postComment(type: pageType = this.pageType, id: string = this.pageId, value?: string) {
    if (!value && !this.commentInput)
      return;

    this.commentBlock.start();
    this.comment.postComment(type, id, value || this.commentInput).subscribe(response => {
      this.commentBlock.stop();
      this.commentInput = '';
      this.openedSubComment = undefined;

      this.comments.push(response);
    });
  }
  deleteComment(comment: Comment) {
    this.comment.deleteComment(comment).subscribe(response => this.comments = this.comments.filter(comment_ => comment_ !== comment));
  }



  changeHash(hash?: string | number) {
    this.location.replaceState(this.location.path(false) + '#' + hash);
  }
}
