import {Component, Inject, OnInit} from '@angular/core';
import {Server, ServerViewerComponent} from "../server-viewer.component";

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html'
})
export class ServerDetailsComponent implements OnInit {
  server!: Server;

  constructor(
    @Inject(ServerViewerComponent) private parent: ServerViewerComponent,
  ) { }

  ngOnInit(): void {
    this.server = this.parent.server!;
  }
}
