<div class="page-header" [style.background-image]="party && party.metadata.banner ? 'url(' + party.metadata.banner + ')!important' : null">
  <div class="page-block">
    <div class="row">
      <div class="col-md-8">
        <div class="page-header-title">
          <h5 class="m-b-10">{{party.name}}</h5>
        </div>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-end">
          <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
          <li class="breadcrumb-item"><a routerLink="/modder" i18n>Modderboard</a></li>
          <li class="breadcrumb-item"><a routerLink="/modder/{{party._id}}">{{party.name}}</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="page-content">
  <div class="row">
    <div class="col-md-3">
      <div class="d-grid">
        <button class="btn btn-primary" [routerLinkActive]="['btn-info']" routerLink="/modder/{{party._id}}/statistics"><i class="fa-solid fa-chart-line"></i>&nbsp;<span i18n>Statistiken</span></button>
        <button class="btn btn-primary" [routerLinkActive]="['btn-info']" routerLink="/modder/{{party._id}}/moderation" *ngIf="hasMemberRole(['OWNER','ADMINISTRATOR','MODERATOR'])"><i class="fa-solid fa-user-pilot"></i>&nbsp;<span i18n>Moderation</span></button>
        <button class="btn btn-primary" [routerLinkActive]="['btn-info']" routerLink="/modder/{{party._id}}/settings" *ngIf="hasMemberRole(['OWNER','ADMINISTRATOR'])"><i class="fa-solid fa-gears"></i>&nbsp;<span i18n>Einstellungen</span></button>

        <ng-container *ngIf="page === 'moderation' && hasMemberRole(['OWNER','ADMINISTRATOR','MODERATOR'])">
          <hr>
          <button class="btn btn-primary" [routerLinkActive]="['btn-info']" routerLink="/modder/{{party._id}}/moderation/queue"><i class="fa-solid fa-list-check"></i>  &nbsp;<span i18n>Custom Uploads Warteschlange</span></button>
<!--          <button class="btn btn-primary" [routerLinkActive]="['btn-info']" routerLink="/modder/{{client_id._id}}/moderation/resources"><i class="fa-solid fa-list"></i>&nbsp;<span i18n>Öffentliche Designs</span></button>-->
        </ng-container>

        <ng-container *ngIf="page === 'settings' && hasMemberRole(['OWNER','ADMINISTRATOR'])">
          <hr>
          <button class="btn btn-primary" [routerLinkActive]="['btn-info']" routerLink="/modder/{{party._id}}/settings/basic"><i class="fa-solid fa-wrench"></i>&nbsp;<span i18n>Allgemeine einstellungen</span></button>
          <button class="btn btn-primary" [routerLinkActive]="['btn-info']" routerLink="/modder/{{party._id}}/settings/members"><i class="fa-solid fa-users"></i>&nbsp;<span i18n>Mitglieder</span></button>
<!--          <button class="btn btn-primary" [routerLinkActive]="['btn-info']" routerLink="/modder/{{client_id._id}}/settings/scripts"><i class="fa-solid fa-code"></i>&nbsp;<span i18n>Scripts</span></button>-->
          <button class="btn btn-primary" [routerLinkActive]="['btn-info']" routerLink="/modder/{{party._id}}/settings/security"><i class="fa-solid fa-key"></i>&nbsp;<span i18n>Sicherheit</span></button>
        </ng-container>
      </div>
    </div>

    <div class="col-md-9">

      <div class="alert alert-primary">
        <span class="float-end"><button routerLink="/discord" class="btn btn-discord">Discord Support</button></span>
        Willkommen zu deinem neuem Dashboard als Mod/Client.<br>
        Dieses Dashboard ist brand neu und benötigt noch deine hilfe!<br>
        Sag uns gerne was dir fehlt, was nicht funktioniert!
      </div>

      <router-outlet></router-outlet>

    </div>
  </div>
</div>
