import { Component, OnInit } from '@angular/core';
import {Resource} from "../resource/resource.service";
import FastAverageColor from "fast-average-color";
import {StateChange} from "ng-lazyload-image";
import {HttpClient} from "@angular/common/http";
import {ResourcesRequest} from "../resources/resources.service";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-creator',
  templateUrl: './creator.component.html',
  styleUrls: ['../utils/resources-search/resources-search.component.css']
})
export class CreatorComponent implements OnInit {
  resources: Resource[] = [];

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.http.get<ResourcesRequest>(environment.apiServer + 'resources', { params: { 'excludes': '!mine' }}).subscribe(response => {
      this.resources = response.data.resources;
    });
  }

  private fac = new FastAverageColor();
  getBackgroundColor($event: StateChange, resource: Resource) {
    if ($event.reason !== "loading-succeeded")
      return;

    const element = document.getElementById(resource._id);
    if (!element)
      return;

    this.fac.getColorAsync(resource.files.preview.default[resource.mime], { crossOrigin: 'night.design' }).then((result) => {
      if (result.isLight)
        element.classList.add('card-img-top-inverted');
    });
  }
}
