<div class="row mb-2">
  <div class="col-md-2 d-grid">
    <button [classList]="!listType ? 'btn btn-primary' : 'btn btn-outline-primary'" (click)="listType = undefined">Alle</button>
  </div>
  <div class="col-md-2 d-grid">
    <button [classList]="listType === 'VANILLA' ? 'btn btn-primary' : 'btn btn-outline-primary'" (click)="listType = 'VANILLA'">Vanilla</button>
  </div>
  <div class="col-md-2 d-grid">
    <button [classList]="listType === 'PLUGINS' ? 'btn btn-primary' : 'btn btn-outline-primary'" (click)="listType = 'PLUGINS'">Plugins</button>
  </div>
  <div class="col-md-2 d-grid">
    <button [classList]="listType === 'MODDED' ? 'btn btn-primary' : 'btn btn-outline-primary'" (click)="listType = 'MODDED'">Modded</button>
  </div>
  <div class="col-md-2 d-grid">
    <button [classList]="listType === 'PROXY' ? 'btn btn-primary' : 'btn btn-outline-primary'" (click)="listType = 'PROXY'">Proxy</button>
  </div>
  <div class="col-md-2 d-grid">
    <button [classList]="listType === 'SETUP' ? 'btn btn-warning' : 'btn btn-outline-warning'" (click)="listType = 'SETUP'">Setup</button>
  </div>
</div>

<div class="row">
  <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let software of getFeaturedList()">
    <div class="card" [class.border-3]="selectedSoftware && selectedSoftware.software === software.software" [class.border-success]="selectedSoftware && selectedSoftware.software === software.software" (click)="selectSoftware(software)">
      <img errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" [lazyLoad]="software.image ?? '/assets/img/preview-notFound.gif'" class="card-img-top" [alt]="software.name ?? software.software | titlecase">
      <div class="card-header"><h5>{{software.name ?? (software.software | titlecase)}}&nbsp;<sup><span class="badge bg-primary me-1" *ngFor="let tag of software.tags">Featured</span></sup></h5></div>
      <div class="card-body"  *ngIf="software.slug">
        {{software.slug}}
      </div>
      <div class="card-footer">
        <div class="badge bg-primary me-1" *ngFor="let tag of software.tags" (click)="listType = tag">{{tag | titlecase}}</div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let software of getSoftwareList()">
    <div class="card" [class.border-3]="selectedSoftware && selectedSoftware.software === software.software" [class.border-success]="selectedSoftware && selectedSoftware.software === software.software" (click)="selectSoftware(software)">
      <img errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" [lazyLoad]="software.image ?? '/assets/img/preview-notFound.gif'" class="card-img-top" [alt]="software.name ?? software.software | titlecase">
      <div class="card-header"><h5>{{software.name ?? (software.software | titlecase)}}</h5></div>
      <div class="card-body"  *ngIf="software.slug">
        {{software.slug}}
      </div>
      <div class="card-footer">
        <div class="badge bg-primary me-1" *ngFor="let tag of software.tags" (click)="listType = tag">{{tag | titlecase}}</div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="getSetupList().length !== 0">
  <div class="divider" i18n>Vorgeschlagene Projekte</div>
  <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let software of getSetupList()">
    <div class="card" [class.border-3]="selectedSoftware && selectedSoftware.software === software.software" [class.border-success]="selectedSoftware && selectedSoftware.software === software.software" (click)="selectSoftware(software)">
      <img errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" [lazyLoad]="software.image ?? '/assets/img/preview-notFound.gif'" class="card-img-top" [alt]="software.name ?? software.software | titlecase">
      <div class="card-header"><h5>{{software.name ?? (software.software | titlecase)}}</h5></div>
      <div class="card-body"  *ngIf="software.slug">
        {{software.slug}}
      </div>
      <div class="card-footer">
        <div class="badge bg-primary me-1" *ngFor="let tag of software.tags" (click)="listType = tag">{{tag | titlecase}}</div>
      </div>
    </div>
  </div>
</div>

<swal #setupPopup [showCloseButton]="true" [allowOutsideClick]="false" width="60%" [title]="selectedSoftware.name ?? (selectedSoftware.software | titlecase)">
  <ng-container *swalPortal="swalTargets.content">
    <div class="text-start">
      <ng-container *ngIf="selectedSoftware.description">
        <div class="divider" i18n>Beschreibung</div>
        <div [innerHtml]="selectedSoftware.description"></div>
      </ng-container>

      <ng-container *ngIf="selectedSoftware.version?.length !== 0">
        <div class="divider" i18n>Software Version</div>
        <ng-select
          [items]="selectedSoftware.version"
          bindLabel="name"
          [disabled]="selectedSoftware.version.length === 1"
          [(ngModel)]="selectedSoftware.value">
        </ng-select>
      </ng-container>

      <ng-container *ngIf="selectedSoftware.extraInput?.length !== 0">
        <div class="divider" i18n>Zusätzliche Einstellungen</div>
        <div class="mb-3" *ngFor="let field of selectedSoftware.extraInput!">
          <label [for]="field.key" class="form-label">{{field.name ?? (field.key | titlecase)}}:</label>
          <div [class.input-group]="field.prefix || field.suffix">
            <span class="input-group-text" *ngIf="field.prefix">{{field.prefix}}</span>
            <input [type]="field.type" [placeholder]="field.placeholder" class="form-control" [id]="field.key" [(ngModel)]="field.value">
            <span class="input-group-text" *ngIf="field.suffix">{{field.suffix}}</span>
          </div>
          <div class="form-text" *ngIf="field.help"><i class="fa-regular fa-circle-info"></i>&nbsp;{{field.help}}</div>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedSoftware.additionalCost && selectedSoftware.additionalCost.amount !== 0">
        <div class="divider" i18n>Zusätzliche Kosten</div>
        <div i18n>Zahlungs Art: {{selectedSoftware.additionalCost.type | i18nSelect: costMapping}}</div>
        <div i18n>Preis: {{selectedSoftware.additionalCost.amount / 100000}} €</div>
      </ng-container>

      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>


    </div>
  </ng-container>
  <ng-container *swalPortal="swalTargets.actions">
    <!--<button class="btn btn-danger me-2" (click)="setupPopup.close()" i18n>Melden</button>
    <button class="btn btn-outline-success" (click)="setupPopup.close()" i18n>Abbrechen</button>-->
  </ng-container>
</swal>

<!--
<ng-select
  [items]="software.version"
  bindLabel="name"
  bindValue="name"
  [(ngModel)]="software.value">
</ng-select>
-->
