<div class="page-header">
  <div class="page-block">
    <div class="row">
      <div class="col-md-8">
        <div class="page-header-title">
          <h5 class="m-b-10">{{user.username}}</h5>
        </div>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-end">
          <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
          <li class="breadcrumb-item"><a routerLink="/users" i18n>Nutzer</a></li>
          <li class="breadcrumb-item"><a routerLink="/u/{{user.username}}">{{user.username}}</a></li>
          <li class="breadcrumb-item">{{pageName}}</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="page-content">
  <div class="row">
    <div class="col-md-9">
      <div class="card user mb-3">
        <div class="banner" [style.background-image]="user.banner ? 'url(' + user.banner + ')' : 'var(--profile-banner)'">
          <button class="btn btn-dark float-end m-2" routerLink="/settings/account" *ngIf="authedUser && user._id === authedUser._id"><i class="fa-solid fa-pencil"></i></button>
        </div>
        <div class="details">
          <img errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" [lazyLoad]="user.avatar!" class="rounded-circle" [alt]="user.username">
          <h2>{{user.username}}</h2>
        </div>
        <div class="card-body">
          <div><span i18n>Mitglied seit</span> {{ statistics ? (statistics?.createdAt | timeago) : 'NaN' }}, {{user.role.name}}</div>
          <div><a routerLink="/u/{{user.username}}/resources" i18n>Designs</a>: {{ statistics?.resources || 0 | numeral : '0.[0]a' }}, <span i18n>Downloads</span>: {{ statistics?.downloads || 0 | numeral : '0.[0]a' }}, <span i18n>Kommentare</span>: {{ statistics?.comments || 0 | numeral : '0.[0]a' }}<!--, <span i18n>Profil-Aufrufe</span>: 105--></div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-4 d-grid"><button class="btn btn-primary" routerLinkActive="btn-info" routerLink="/u/{{user.username}}/activities" i18n>Aktivitäten</button></div>
        <div class="col-md-4 d-grid"><button class="btn btn-primary" routerLinkActive="btn-info" routerLink="/u/{{user.username}}/resources" i18n>Designs</button></div>
        <div class="col-md-4 d-grid"><button class="btn btn-primary" routerLinkActive="btn-info" routerLink="/u/{{user.username}}/connected-accounts" i18n>Verbundene Accounts</button></div>
      </div>

      <ng-container *ngIf="user">
        <router-outlet></router-outlet>
      </ng-container>
    </div>
    <div class="col-md-3">
      <div class="card mb-3">
        <div class="card-body text-center">
          <img errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" class="rounded-circle" style="width: 50%; height: auto;" [lazyLoad]="user.avatar!">
        </div>
        <div class="card-footer text-center">
          <h3 [innerHTML]="user.display"></h3>
        </div>
        <div class="card-footer text-center">
          {{user.role.name}}
        </div>
        <div class="card-footer">
          <a routerLink="/u/{{user.username}}/resources" i18n>Designs</a>:
          <span class="float-end">{{ statistics?.resources || 0 | numeral : '0.[0]a' }}</span>
        </div>
        <div class="card-footer">
          <span i18n>Downloads</span>:
          <span class="float-end">{{ statistics?.downloads || 0 | numeral : '0.[0]a' }}</span>
        </div>
        <div class="card-footer">
          <span i18n>Kommentare</span>:
          <span class="float-end">{{ statistics?.comments || 0 | numeral : '0.[0]a' }}</span>
        </div>
        <!--<div class="card-footer">
          <span i18n>Profil-Aufrufe</span>:
          <span class="float-end">0</span>
        </div>-->
      </div>

      <reportButton type="user" [target]="user" class="d-grid"></reportButton>

<!--      <div class="d-grid mb-3"><button class="btn btn-outline-primary" i18n>Folgen</button></div>-->
<!--      <div class="d-grid mb-3"><button class="btn btn-outline-primary" i18n>Freundschaftsanfrage versenden</button></div>-->
<!--      <div class="d-grid mb-3"><button class="btn btn-outline-primary" i18n>Direktnachrichten</button></div>-->

<!--      <div class="card">-->
<!--        <div class="card-header"><h3>-->
<!--          <span i18n>Folgt</span>&nbsp;<span style="font-size: .6em;">[23]</span>-->
<!--          <span class="float-end"><button class="btn btn-outline-dark btn-small" routerLink="/u/{{user.username}}/followed">Zeigen</button></span>-->
<!--        </h3></div>-->
<!--        <div class="card-body text-center">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="card">-->
<!--        <div class="card-header"><h3>-->
<!--          <span i18n>Gefolgt von</span>&nbsp;<span style="font-size: .6em;">[9]</span>-->
<!--          <span class="float-end"><button class="btn btn-outline-dark btn-small" routerLink="/u/{{user.username}}/follower">Zeigen</button></span>-->
<!--        </h3></div>-->
<!--        <div class="card-body text-center">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--          <img class="rounded-circle" style="margin: .1em; width: 4em; height: auto;" src="favicon.ico">-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</div>
