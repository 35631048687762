<div class="page-header">
  <div class="page-block">
    <div class="row">
      <div class="col-md-8">
        <div class="page-header-title">
          <h5 class="m-b-10" i18n>Creatorboard</h5>
        </div>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
          <li class="breadcrumb-item"><a href="javascript:null" i18n>Creatorboard</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="page-content">

  <div class="d-grid">
    <button class="btn btn-success" routerLink="/creator/publisher" style="font-size: 1.5em" i18n>Neue Cosmetics hochladen</button>
  </div>

<!--  <div class="card mt-3" *ngIf="resources.length !== 0">-->
<!--    <div class="card-block">-->
<!--      <input class="form-control" placeholder="Badlion, Optifine, Mojang..."> &lt;!&ndash;(keyup)="onSearchInput($event)"&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->


  <div class="row resources" *ngIf="resources.length !== 0">
    <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let resource of resources">
      <div class="card">
        <img errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" [lazyLoad]="resource.files.preview.default.hasOwnProperty('image/webp') ? resource.files.preview.default['image/webp']! : resource.files.preview.default[resource.mime]!" class="card-img-top" (onStateChange)="getBackgroundColor($event, resource)" [id]="resource._id" [alt]="resource.name" routerLink="/resources/{{resource._id!}}">
        <div class="card-body">
          <h5 class="card-title" routerLink="/resources/{{resource._id}}">{{resource.name}}</h5>
          <p class="card-text">
            <span class="badge bg-primary" *ngFor="let tag of resource.tags">{{tag}}</span>
            <span class="badge bg-warning">{{resource.type | titlecase}}</span>
          </p>
        </div>

        <ul class="list-group list-group-flush">
          <li class="list-group-item"><a class="text-muted" routerLink="/u/{{resource.user.username}}" [innerHTML]="resource.user.display"></a></li>
          <li class="list-group-item text-muted"><i class="fa-solid fa-download"></i> {{resource.downloads | numeral: '0.[0] a'}}</li>
        </ul>
      </div>
    </div>
  </div>


  <div class="text-center mt-5" *ngIf="resources.length === 0">
    <i class="fa-solid fa-arrow-up text-muted animate__animated animate__bounce animate__infinite" style="font-size: 12em"></i><br>
    <span class="text-muted" style="font-size: 2em" i18n>Hier werden bald bestimmt sehr coole und tolle Cosmetics von dir sein!</span>
  </div>
</div>
