<div class="page-content page-noHeader">
  <div class="row pt-5">
    <div class="col-md-12 d-flex justify-content-center">
      <div class="card" [hidden]="!success">
        <div class="card-block">
          <div class="alert alert-success" i18n>
            Bitte schaue nun in deinem Postfach nach und bestätige deine E-Mail Adresse! Bitte überprüfe auch deinen Spam Ordner! Falls du innerhalb von 15min keine E-Mail von uns bekommen hast, dann schreibe uns eine Email: support@night.design
          </div>
        </div>
      </div>
      <div class="card" *blockUI="'loginCard'" [hidden]="success">
        <div class="card-block">
          <h3 class="text-center" i18n>Registrieren</h3>

          <form [formGroup]="form">

            <div class="alert alert-danger" *ngIf="error">
              {{error}}
            </div>

            <label for="username" class="form-label mt-2" i18n>Benutzername</label>
            <input type="text" class="form-control" [class.is-invalid]="username.invalid && (submitted || username.dirty || username.touched)" id="username" placeholder="BCCS-Team" formControlName="username" required>
            <div *ngIf="username.status === 'INVALID' && (submitted || username.dirty || username.touched)"
                 class="invalid-feedback" style="display: block">
              <ng-container *ngIf="username.errors?.['required']" i18n>
                Benutzername ist ein pflicht Feld.
              </ng-container>
              <ng-container *ngIf="username.errors?.['pattern']" i18n>
                Benutzername entspricht nicht dem pattern.
              </ng-container>
              <ng-container *ngIf="username.errors?.['gone']" i18n>
                Benutzername ist nicht mehr frei.
              </ng-container>
            </div>

            <label for="email" class="form-label mt-2" i18n>Email Adresse</label>
            <input type="email" class="form-control" [class.is-invalid]="email.invalid && (submitted || email.dirty || email.touched)" id="email" placeholder="player@example.com" formControlName="email" required>
            <div *ngIf="mailcheck || (email.status === 'INVALID' && (submitted || email.dirty || email.touched))"
                 class="invalid-feedback" style="display: block">
              <ng-container *ngIf="email.errors?.['required']" i18n>
                Email ist ein pflicht Feld.
              </ng-container>
              <ng-container *ngIf="!email.errors?.['required'] && email.errors?.['pattern']" i18n>
                Die Email address ist ungültig.
              </ng-container>
              <ng-container *ngIf="email.status === 'VALID' && mailcheck" i18n>
                Meintest du etwa <a class="link-warning" (click)="email.setValue(mailcheck)">{{mailcheck}}</a>?
              </ng-container>
            </div>

            <div class="row mt-2">
              <div class="col-md-6">
                <label for="password" class="form-label mt-2" i18n>Passwort</label>
                <input type="password" class="form-control" [class.is-invalid]="password.invalid && (submitted || password.dirty || password.touched)" id="password" placeholder="password" formControlName="password" required>
                <div *ngIf="password.status === 'INVALID' && (submitted || password.dirty || password.touched)"
                     class="invalid-feedback" style="display: block">
                  <ng-container *ngIf="password.errors?.['required']" i18n>
                    Passwort ist ein pflicht Feld.
                  </ng-container>
                  <ng-container *ngIf="password.errors?.['too_weak'] || password.errors?.['weak']" i18n>
                    Das Passwort ist zu leicht.
                  </ng-container>
                </div>
              </div>

              <div class="col-md-6">
                <label for="password_confirm" class="form-label mt-2" i18n>Passwort wiederholen</label>
                <input type="password" class="form-control" [class.is-invalid]="password_confirm.invalid && (submitted || password_confirm.dirty || password_confirm.touched)" id="password_confirm" placeholder="password" formControlName="password_confirm" required>
                <div *ngIf="password_confirm.status === 'INVALID' && (submitted || password_confirm.dirty || password_confirm.touched)"
                     class="invalid-feedback" style="display: block">
                  <ng-container *ngIf="password_confirm.errors?.['required']" i18n>
                    Passwort wiederholen ist ein pflicht Feld.
                  </ng-container>
                  <ng-container *ngIf="password_confirm.errors?.['password_missMatch']" i18n>
                    Die Passwörter stimmen nicht überein.
                  </ng-container>
                </div>
              </div>

              <div class="col-12 mt-1">
                <div class="progress">
                  <ng-container *ngIf="submitted || password.dirty || password.touched">
                    <div class="progress-bar bg-danger" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" *ngIf="password_strength >= 0"></div>
                    <div class="progress-bar bg-warning" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" *ngIf="password_strength >= 1"></div>
                    <div class="progress-bar bg-primary" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" *ngIf="password_strength >= 2"></div>
                    <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" *ngIf="password_strength >= 3"></div>
                  </ng-container>
                </div>
              </div>
            </div>


            <div class="form-check mt-3">
              <label class="form-check-label" [class.text-danger]="agb.invalid && (submitted || agb.dirty || agb.touched)" for="agb" i18n>
                Ich habe die <a routerLink="/legal/terms-of-service">allgemeinen Geschäftsbedingungen</a> gelesen und bin damit einverstanden.
              </label>
              <input class="form-check-input" [class.is-invalid]="agb.invalid && (submitted || agb.dirty || agb.touched)" type="checkbox" id="agb" formControlName="agb" required>
              <div *ngIf="agb.status === 'INVALID' && (submitted || agb.dirty || agb.touched)"
                   class="invalid-feedback" style="display: block" i18n>
                Du musst hiermit einverstanden sein, damit du fortsetzen kannst.
              </div>
            </div>

            <div class="form-check mt-2">
              <label class="form-check-label" [class.text-danger]="privacy_police.invalid && (submitted || privacy_police.dirty || privacy_police.touched)" for="privacy_police" i18n>
                Ich habe die <a routerLink="/legal/privacy-policy">Datenschutzerklärung</a> gelesen und verstanden.
              </label>
              <input class="form-check-input" [class.is-invalid]="privacy_police.invalid && (submitted || privacy_police.dirty || privacy_police.touched)" type="checkbox" id="privacy_police" formControlName="privacy_police" required>
              <div *ngIf="privacy_police.status === 'INVALID' && (submitted || privacy_police.dirty || privacy_police.touched)"
                   class="invalid-feedback" style="display: block" i18n>
                Du musst hiermit einverstanden sein, damit du fortsetzen kannst.
              </div>
            </div>

            <div class="form-check mt-2">
              <label class="form-check-label" [class.text-danger]="age.invalid && (submitted || age.dirty || age.touched)" for="age" i18n>
                Ich bestätige, dass ich mindestens 16 Jahre alt bin oder meine Erziehungsberechtigten mit der Registrierung einverstanden sind.
              </label>
              <input class="form-check-input" [class.is-invalid]="age.invalid && (submitted || age.dirty || age.touched)" type="checkbox" id="age" formControlName="age" required>
              <div *ngIf="age.status === 'INVALID' && (submitted || age.dirty || age.touched)"
                   class="invalid-feedback" style="display: block" i18n>
                Du musst hiermit einverstanden sein, damit du fortsetzen kannst.
              </div>
            </div>


            <div class="row mt-3">
              <div class="col-md-6 d-grid">
                <button class="btn btn-primary btn-block" type="submit" (click)="register()" [disabled]="form.disabled" i18n>Registrieren</button>
              </div>
              <div class="col-md-6 d-grid">
                <button class="btn btn-success" type="button" routerLink="/login" i18n>Einloggen</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
