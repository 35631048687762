<div class="page-header">
  <div class="page-block">
    <div class="row">
      <div class="col-md-8">
        <div class="page-header-title">
          <h5 class="m-b-10">{{pageName}}</h5>
        </div>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-end">
          <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
          <li class="breadcrumb-item"><a routerLink="/settings" i18n>Einstellungen</a></li>
          <li class="breadcrumb-item">{{pageName}}</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="page-content">
  <div class="row">
    <div class="col-md-3">
      <div class="d-grid">
        <button class="btn btn-primary" [routerLinkActive]="['btn-info']" routerLink="/settings/account"><i class="fa-solid fa-user"></i>&nbsp;<span i18n>Allgemein</span></button>
        <button class="btn btn-primary" [routerLinkActive]="['btn-info']" routerLink="/settings/notifications"><i class="fa-solid fa-bell"></i>&nbsp;<span i18n>Benachrichtigungen</span></button>
        <button class="btn btn-primary" [routerLinkActive]="['btn-info']" routerLink="/settings/connected-accounts"><i class="fa-solid fa-users"></i>&nbsp;<span i18n>Verbundene Accounts</span></button>
        <button class="btn btn-primary" [routerLinkActive]="['btn-info']" routerLink="/settings/account-security"><i class="fa-solid fa-key"></i>&nbsp;<span i18n>Account Sicherheit</span></button>
      </div>
      <ng-container *ngIf="pageId ==='connected-accounts'">
        <hr><div class="d-grid">
          <button class="btn btn-xbox" (click)="authUrl('xbox')"><i class="fab fa-xbox"></i> Xbox (Microsoft / Minecraft)</button>
          <button class="btn btn-google" (click)="authUrl('google')"><i class="fab fa-google"></i> Google</button>
          <button class="btn btn-discord" (click)="authUrl('discord')"><i class="fab fa-discord"></i> Discord</button>
          <button class="btn btn-twitch" (click)="authUrl('twitch')"><i class="fab fa-twitch"></i> Twitch</button>
          <button class="btn btn-github" (click)="authUrl('github')"><i class="fab fa-github"></i> GitHub</button>
        </div>
      </ng-container>
    </div>

    <div class="col-md-9">
      <div class="alert alert-danger" *ngIf="isLoggedInViaThirdParty" i18n>
        <b>Achtung!</b> Du bist derzeitig in einer Drittanbieter-Sitzung drin, das bedeutet jede Änderung die du an deinem Account machst werden vom system blockiert.<br>
        Um das zu ändern musst du dich neuanmelden.
      </div>

      <router-outlet></router-outlet>

    </div>
  </div>
</div>
