<div class="text-center" *ngIf="connectionsCache.length === 0">
  <i class="fa-solid fa-warning text-muted" style="font-size: 12em"></i><br>
  <div class="alert alert-warning text-start mt-3"><i class="fas fa-warning"></i>&nbsp;<span i18n>Du hast derzeit noch keine Accounts verknüpft.</span></div>
</div>

<div class="card btn-xbox" *ngFor="let connection of getConnections('xbox')">
  <div class="card-body">
    <div class="row">
      <div class="col-md-3 text-center">
        <img class="rounded img-fluid" [src]="connection.profile.avatar" alt="Connection Avatar">
      </div>
      <div class="col-md-9">
        <img class="rounded img-fluid float-end me-3" src="/assets/img/minecraft.webp" style="width: 5em; height: auto">
        <!--              <i class="fab fa-xbox float-end me-3" style="font-size: 5em"></i>-->
        <h3>{{connection.profile.name}}</h3>
        <button class="btn btn-primary" (click)="showProfile(connection)" *ngIf="connection.profile.link" i18n>Profil anzeigen</button>
        <button class="btn btn-outline-danger" (click)="unlink(connection)" i18n>Verbindung auflösen</button>

        <button class="btn btn-success" (click)="toggleVisibility(connection)" *ngIf="connection.public"><i class="fa-solid fa-eye"></i> <span i18n>Öffentlich</span></button>
        <button class="btn btn-danger" (click)="toggleVisibility(connection)" *ngIf="!connection.public"><i class="fa-solid fa-eye-slash"></i> <span i18n>Nicht gelistet</span></button>
      </div>
    </div>
  </div>
</div>

<div class="card btn-google" *ngFor="let connection of getConnections('google')">
  <div class="card-body">
    <div class="row">
      <div class="col-md-3 text-center">
        <img class="rounded-circle img-fluid" [src]="connection.profile.avatar" alt="Connection Avatar">
      </div>
      <div class="col-md-9">
        <i class="fab fa-google float-end me-3" style="font-size: 5em"></i>
        <h3>{{connection.profile.name}}</h3>
        <button class="btn btn-primary" (click)="showProfile(connection)" *ngIf="connection.profile.link" i18n>Profil anzeigen</button>
        <button class="btn btn-outline-danger" (click)="unlink(connection)" i18n>Verbindung auflösen</button>

        <button class="btn btn-success" (click)="toggleVisibility(connection)" *ngIf="connection.public"><i class="fa-solid fa-eye"></i> <span i18n>Öffentlich</span></button>
        <button class="btn btn-danger" (click)="toggleVisibility(connection)" *ngIf="!connection.public"><i class="fa-solid fa-eye-slash"></i> <span i18n>Nicht gelistet</span></button>

        <br /><button class="btn btn-small btn-success" (click)="refresh(connection)" [disabled]="hasRefreshCooldown(connection)" i18n><i class="fa-solid fa-refresh me-1"></i><span [date]="connection.timestamp" timeago></span></button>
      </div>
    </div>
  </div>
</div>

<div class="card btn-discord" *ngFor="let connection of getConnections('discord')">
  <div class="card-body">
    <div class="row">
      <div class="col-md-3 text-center">
        <img class="rounded-circle img-fluid" [src]="connection.profile.avatar" alt="Connection Avatar">
      </div>
      <div class="col-md-9">
        <i class="fab fa-discord float-end" style="font-size: 5em"></i>
        <h3>{{connection.profile.name}}</h3>
        <button class="btn btn-primary" (click)="showProfile(connection)" *ngIf="connection.profile.link" i18n>Profil anzeigen</button>
        <button class="btn btn-outline-danger" (click)="unlink(connection)" i18n>Verbindung auflösen</button>

        <button class="btn btn-success" (click)="toggleVisibility(connection)" *ngIf="connection.public"><i class="fa-solid fa-eye"></i> <span i18n>Öffentlich</span></button>
        <button class="btn btn-danger" (click)="toggleVisibility(connection)" *ngIf="!connection.public"><i class="fa-solid fa-eye-slash"></i> <span i18n>Nicht gelistet</span></button>

<!--        <br /><button class="btn btn-small btn-success" (click)="refresh(connection)" [disabled]="hasRefreshCooldown(connection)" i18n><i class="fa-solid fa-refresh me-1"></i><span [date]="connection.timestamp" timeago></span></button>-->
      </div>
    </div>
  </div>
</div>

<div class="card btn-twitch" *ngFor="let connection of getConnections('twitch')">
  <div class="card-body">
    <div class="row">
      <div class="col-md-3 text-center">
        <img class="rounded-circle img-fluid" style="border: 0.2rem #adadb8 solid; background-color: #9147ff" [src]="connection.profile.avatar" alt="Connection Avatar">
      </div>
      <div class="col-md-9">
        <i class="fab fa-twitch float-end me-1" style="font-size: 5em"></i>
        <h3>{{connection.profile.name}}</h3>
        <button class="btn btn-primary" (click)="showProfile(connection)" *ngIf="connection.profile.link" i18n>Profil anzeigen</button>
        <button class="btn btn-outline-danger" (click)="unlink(connection)" i18n>Verbindung auflösen</button>

        <button class="btn btn-success" (click)="toggleVisibility(connection)" *ngIf="connection.public"><i class="fa-solid fa-eye"></i> <span i18n>Öffentlich</span></button>
        <button class="btn btn-danger" (click)="toggleVisibility(connection)" *ngIf="!connection.public"><i class="fa-solid fa-eye-slash"></i> <span i18n>Nicht gelistet</span></button>

        <br /><button class="btn btn-small btn-success" (click)="refresh(connection)" [disabled]="hasRefreshCooldown(connection)" i18n><i class="fa-solid fa-refresh me-1"></i><span [date]="connection.timestamp" timeago></span></button>
      </div>
    </div>
  </div>
</div>

<div class="card btn-github" *ngFor="let connection of getConnections('github')">
  <div class="card-body">
    <div class="row">
      <div class="col-md-3 text-center">
        <img class="rounded-circle img-fluid" style="border: 0.2rem #adadb8 solid; background-color: #f5f5f5" [src]="connection.profile.avatar" alt="Connection Avatar">
      </div>
      <div class="col-md-9">
        <i class="fab fa-github float-end me-1" style="font-size: 5em"></i>
        <h3>{{connection.profile.name}}</h3>
        <button class="btn btn-primary" (click)="showProfile(connection)" *ngIf="connection.profile.link" i18n>Profil anzeigen</button>
        <button class="btn btn-outline-danger" (click)="unlink(connection)" i18n>Verbindung auflösen</button>

        <button class="btn btn-success" (click)="toggleVisibility(connection)" *ngIf="connection.public"><i class="fa-solid fa-eye"></i> <span i18n>Öffentlich</span></button>
        <button class="btn btn-danger" (click)="toggleVisibility(connection)" *ngIf="!connection.public"><i class="fa-solid fa-eye-slash"></i> <span i18n>Nicht gelistet</span></button>

        <br /><button class="btn btn-small btn-success" (click)="refresh(connection)" [disabled]="hasRefreshCooldown(connection)" i18n><i class="fa-solid fa-refresh me-1"></i><span [date]="connection.timestamp" timeago></span></button>
      </div>
    </div>
  </div>
</div>
