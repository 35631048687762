<div class="card">
  <div class="card-header"><h5 i18n>Autorisierung</h5></div>
  <div class="card-body">
    <div class="form-group row">
      <div class="col-md-4 col-lg-2">
        <label class="block">ClientId</label>
      </div>
      <div class="col-md-8 col-lg-10">
        <input autocomplete="off" type="text" class="required form-control" [value]="party._id" readonly ngxClipboard [cbContent]="party._id" (cbOnSuccess)="copied2Clipboard()">
      </div>
    </div>
    <div class="form-group row mt-2">
      <div class="col-md-4 col-lg-2">
        <label class="block">ClientSecret</label>
      </div>
      <div class="col-md-8 col-lg-10">
        <input autocomplete="off" type="text" class="required form-control" [value]="newSecret || ''" placeholder="*******************************" [disabled]="!newSecret" readonly ngxClipboard [cbContent]="newSecret" (cbOnSuccess)="copied2Clipboard()">
        <a href="javascript:null" (click)="createNewSecret()">ClientSecret neugenerieren</a>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-header"><h5>
    <span i18n>OAuth2</span>
    <span class="float-end">
      <button class="btn btn-outline-success btn-small" *ngIf="!oauth2" (click)="oauth2 = {url:[undefined], scopes:[]}" disabled i18n>Aktivieren</button>
      <button class="btn btn-outline-danger btn-small" *ngIf="oauth2" (click)="oauth2 = undefined" i18n>Deaktivieren</button>
    </span>
  </h5></div>
  <div class="card-body" *ngIf="oauth2">
    <div class="form-group row">
      <div class="col-md-4 col-lg-2">
        <label class="block" i18n>Umleitungsadresse/n</label>
      </div>
      <div class="col-md-8 col-lg-10">
        <input autocomplete="off" type="text" class="required form-control" *ngFor="let url of oauth2.url; let i = index;" [value]="url ? url.trim() : ''" placeholder="https://..." (change)="updateOAuth2URL($event, i)">
      </div>
    </div>
    <div class="form-group row mt-2">
      <div class="col-md-4 col-lg-2">
        <label class="block" i18n>Scopes</label>
      </div>
      <div class="col-md-8 col-lg-10">
        <span class="text-muted" *ngIf="oauth2.scopes.length === 0" i18n>Keine Scopes zugewiesen.</span>
        <span class="badge bg-primary me-1" *ngFor="let scope of oauth2.scopes" (click)="toggleOAuth2Scope(scope)">{{scope}}</span>

        <ng-container *ngIf="notUsedScopes.length !== 0">
          <hr>
          <span class="badge bg-black me-1" *ngFor="let scope of notUsedScopes" (click)="toggleOAuth2Scope(scope)">{{scope}}</span>
        </ng-container>
      </div>
    </div>

    <div class="float-end"><button class="btn btn-success" i18n>Änderung anwenden</button></div>
  </div>
  <div class="card-footer text-muted" *ngIf="oauth2" i18n>
    Achtung! Bereits alle autorisierte verbindungen müssten, damit die neuen Scopes angewendet werden, erneut verknüpft werden.<br>
    Alle bereits vorhandene verknüpfungen bleiben weiterhin mit den alten Scopes bestehen.
  </div>
</div>
