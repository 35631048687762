<div class="card">
  <div class="card-body">
    <highcharts-chart
      *ngIf="isBrowser"
      [Highcharts]="Highcharts"
      [options]="players"
      [oneToOne]="true"
      [(update)]="updateFlag"
      style="width: 100%; height: 400px; display: block;"></highcharts-chart>
  </div>
</div>

<ADPlacement [type]="'article-banner'" [context]="server" pageType="server"></ADPlacement>

<div class="card">
  <div class="card-body">
    <app-peak [server]="server"></app-peak>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <highcharts-chart
          *ngIf="isBrowser"
          [Highcharts]="Highcharts"
          [options]="onlineOffline"
          [oneToOne]="true"
          [(update)]="updateFlag_onlineOffline"
          style="width: 100%; height: 400px; display: block;"></highcharts-chart>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <ADPlacement [type]="'article-banner'" [context]="server" pageType="server"></ADPlacement>
  </div>
</div>
