import { NgModule } from '@angular/core';
import {Router, RouterModule, Routes} from '@angular/router';

import { HomeComponent} from "./home/home.component";

import { ResourceComponent } from "./resource/resource.component";
import { ResourcesComponent } from "./resources/resources.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {AuthGuard} from "./guard/auth.guard";
import {LoginComponent} from "./login/login.component";
import {RegisterComponent} from "./register/register.component";
import {PasswordForgottenComponent} from "./password-forgotten/password-forgotten.component";
import {ConnectionsComponent} from "./settings/connections/connections.component";
import {NotFoundComponent} from "./error/not-found/not-found.component";
import {SecurityComponent} from "./settings/security/security.component";
import {NotificationsComponent} from "./settings/notifications/notifications.component";
import {AccountComponent} from "./settings/account/account.component";
import {CreatorComponent} from "./creator/creator.component";
import {CreatorPublisherComponent} from "./creator-publisher/creator-publisher.component";
import {ModderComponent} from "./modder/modder.component";
import {ModderPublisherComponent} from "./modder-publisher/modder-publisher.component";
import {ModderViewerComponent} from "./modder-viewer/modder-viewer.component";
import {StatisticsComponent} from "./modder-viewer/statistics/statistics.component";
import {MembersComponent} from "./modder-viewer/settings/members/members.component";
import {PartyComponent} from "./modder-viewer/settings/party/party.component";
import {ModerationQueueComponent} from "./modder-viewer/moderation/queue/moderation-queue.component";
import {ModerationResourcesComponent} from "./modder-viewer/moderation/resources/moderation-resources.component";
import { SecurityComponent as PartySecurityComponent } from './modder-viewer/settings/security/security.component';
import {ImprintComponent} from "./legal/imprint/imprint.component";
import {PrivacyPolicyComponent} from "./legal/privacy-policy/privacy-policy.component";
import {TermsOfServiceComponent} from "./legal/terms-of-service/terms-of-service.component";
import {SettingsComponent} from "./settings/settings.component";
import * as Sentry from "@sentry/angular";
import {UserComponent} from "./user/user.component";
import {UserActivitiesComponent} from "./user/user-activities/user-activities.component";
import {UserResourcesComponent} from "./user/user-resources/user-resources.component";
import {UserConnectedAccountsComponent} from "./user/user-connected-accounts/user-connected-accounts.component";
import {ServerViewerComponent} from "./server-viewer/server-viewer.component";
import {ServerStatisticsComponent} from "./server-viewer/statistics/statistics.component";
import {ServerDetailsComponent} from "./server-viewer/details/details.component";
import {ServerVoteComponent} from "./server-viewer/vote/vote.component";
import {TeamComponent} from "./users/team/team.component";
import {AuthorizeComponent} from "./authorize/authorize.component";
import {EditorComponent} from "./editor/editor.component";
import {ConsoleComponent} from "./gameserver/console/console.component";
import {GameserverComponent} from "./gameserver/gameserver.component";
import {FileManagerComponent} from "./gameserver/file-manager/file-manager.component";
import {SetupComponent} from "./gameserver/setup/setup.component";

const routes: Routes = [
  { path: "", redirectTo: 'home', pathMatch: 'full' },
  { path: "home", component: HomeComponent, title: $localize`Startseite` + ' | Night.design' },


  { path: "resources",  component: ResourcesComponent, title: $localize`Design suchen` + ' | Night.design' },
  { path: "resources/:id",  component: ResourceComponent },


  // { path: "users",  component: TeamComponent, title: $localize`Nutzer suche` + ' | Night.design' },
  { path: "team",  component: TeamComponent, title: $localize`Unser Team` + ' | Night.design' },
  {
    path: "u/:id",
    component: UserComponent,
    children: [
      { path: "activities", component: UserActivitiesComponent },
      { path: "resources", component: UserResourcesComponent },
      { path: "connected-accounts", component: UserConnectedAccountsComponent },
      { path: '', redirectTo: 'activities', pathMatch: "full" }
    ]
  },


  {
    path: "gameserver/:id",
    component: GameserverComponent,
    children: [
      { path: "console", component: ConsoleComponent },
      { path: "files", component: FileManagerComponent },
      { path: "setup", component: SetupComponent },
      { path: '', redirectTo: 'console', pathMatch: "full" }
    ]
  },

  { path: "editor", component: EditorComponent, title: $localize`Editor` + ' | Night.design' },


  { path: "login", component: LoginComponent, title: $localize`Anmelden` + ' | Night.design' },
  { path: "register", component: RegisterComponent, title: $localize`Registrieren` + ' | Night.design' },
  { path: "authorize", canActivate: [AuthGuard], component: AuthorizeComponent },
  { path: "password-forgotten", component: PasswordForgottenComponent, title: $localize`Passwort vergessen` + ' | Night.design'  },

  { path: "dashboard", canActivate: [AuthGuard], component: DashboardComponent, title: $localize`Dashboard` + ' | Night.design' },

  { path: "creator", canActivate: [AuthGuard], component: CreatorComponent },
  { path: "creator/publisher", canActivate: [AuthGuard], component: CreatorPublisherComponent },

  { path: "modder", canActivate: [AuthGuard], component: ModderComponent },
  { path: "modder/publisher", canActivate: [AuthGuard], component: ModderPublisherComponent },
  // { path: "modder/:id", canActivate: [AuthGuard], component: ModderViewerComponent },

  {
    path: "server/:id",
    component: ServerViewerComponent,
    children: [
      {
        path: 'details',
        component: ServerDetailsComponent
      },
      {
        path: 'statistics',
        component: ServerStatisticsComponent
      },
      {
        path: 'vote',
        component: ServerVoteComponent
      },
      {
        path: '',
        redirectTo: 'details',
        pathMatch: "full"
      }
    ]
  },

  {
    path: "modder/:id",
    canActivate: [AuthGuard],
    component: ModderViewerComponent,
    children: [
      {
        path: 'statistics',
        component: StatisticsComponent
      },
      {
        path: 'moderation',
        children: [
          {
            path: 'queue',
            component: ModerationQueueComponent
          },
          {
            path: 'resources',
            component: ModerationResourcesComponent
          },
          {
            path: '',
            redirectTo: 'queue',
            pathMatch: "full"
          }
        ]
      },
      {
        path: 'settings',
        children: [
          {
            path: 'basic',
            component: PartyComponent
          },
          {
            path: 'members',
            component: MembersComponent
          },
          {
            path: 'security',
            component: PartySecurityComponent
          },
          {
            path: '',
            redirectTo: 'basic',
            pathMatch: "full"
          }
        ]
      },
      {
        path: '',
        redirectTo: 'statistics',
        pathMatch: "full"
      }
    ]
  },

  {
    path: 'settings',
    canActivate: [AuthGuard],
    component: SettingsComponent,
    title: $localize`Einstellungen` + ' | Night.design',
    children: [
      { path: "account", canActivate: [AuthGuard], component: AccountComponent },
      { path: "notifications", canActivate: [AuthGuard], component: NotificationsComponent },
      { path: "connected-accounts", canActivate: [AuthGuard], component: ConnectionsComponent },
      { path: "account-security", canActivate: [AuthGuard], component: SecurityComponent },
      { path: '', redirectTo: 'account', pathMatch: "full" }
    ]
  },

  {
    path: 'legal',
    children: [
      {
        path: 'imprint',
        component: ImprintComponent,
        title: $localize`Impressum` + ' | Night.design'
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        title: $localize`Datenschutz` + ' | Night.design'
      },
      {
        path: 'terms-of-service',
        component: TermsOfServiceComponent,
        title: $localize`Nutzungsbedingungen` + ' | Night.design'
      },
      {
        path: '',
        redirectTo: 'imprint',
        pathMatch: "full"
      }
    ]
  },


  { path: "404", component: NotFoundComponent, title: '404 | Night.design' },
  { path: "**",  redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled", initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
  providers: [
    { provide: Sentry.TraceService, deps: [Router] }
  ]
})
export class AppRoutingModule { }
