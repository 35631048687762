import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import Swal from "sweetalert2";
import {HttpClient, HttpEventType} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {finalize, Subscription} from "rxjs";
import {
  Cosmetics,
  cosmeticsValues,
  cosmeticsTemplates,
  PlayerCosmetic,
  PlayerCosmeticRequest,
  PlayerCosmeticsRequest
} from "../interfaces/player-cosmetics";
import {UserConnection, UserConnectionsRequest} from "../interfaces/user-connections";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {ActivatedRoute, Router} from "@angular/router";
import {Party} from "../modder/modder.component";
import {PartyRequest} from "../modder-publisher/modder-publisher.component";
import {AuthService} from "../services/auth.service";
import {CookieService} from "ngx-cookie-service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  isBrowser = false;

  @BlockUI('cosmeticsList') blockCosmeticsList: NgBlockUI | undefined;
  accountsCache: UserConnection[] = [];
  account: UserConnection | undefined;
  cosmetics: PlayerCosmetic[] = [];

  specialParams: { client_id: string } | undefined = undefined;
  party: Party | undefined = undefined;

  cosmeticsTemplates = cosmeticsTemplates;

  selectedCosmetic: Cosmetics | undefined;
  @ViewChild('fileInput')
  private fileInput: ElementRef | undefined;

  uploadFileName: String | undefined;
  uploadProgress : number | undefined;
  uploadSub: Subscription | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private cookie: CookieService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (!this.isBrowser)
      return;

    this.blockCosmeticsList?.start('Loading...');

    this.route.queryParams.subscribe(data => {
      console.log(data);
      if ('client_id' in data) {
        console.log(data['client_id']);
        this.http.get<PartyRequest>(environment.apiServer + 'mods/' + data['client_id']).subscribe(value => {
          this.party = value.data.mod;
          this.specialParams = { client_id: this.party._id };
        });
      }

      this.http.get<UserConnectionsRequest>(environment.apiServer + 'users/@me/connected-accounts').subscribe(value => {
        this.accountsCache = value.data.connections.filter(a => ['minecraft','xbox'].includes(a.provider));

        switch (this.accountsCache.length) {
          case 0:
            if (!this.cookie.check('hideAccountAlert'))
              Swal.fire({
                title: $localize`Fehler`,
                text: $localize`Du hast keine/n Minecraft Account/s mit deinem Account verbunden!`,
                icon: "error",
                confirmButtonColor: '#198754',
                confirmButtonText: $localize`Account verbinden`,
                showCancelButton: true,
                cancelButtonText: $localize`Ohne Account fortfahren`
              }).then(result => {
                if (result.isConfirmed) {
                  this.router.navigate(['/settings/connected-accounts']).then();
                  return;
                }

                this.cookie.set('hideAccountAlert','1', 259200);
                this.blockCosmeticsList?.stop();
              });

            break;
          default:
            this.blockCosmeticsList?.stop();
            this.switchAccount(this.accountsCache[0]);
        }
      });
    });
  }

  switchAccount(account: UserConnection) {
    this.blockCosmeticsList?.start('Loading...');
    this.http.get<PlayerCosmeticsRequest>(environment.apiServer + 'players/' + account.profile.id + '/cosmetics', { params: this.specialParams }).subscribe(value => {
      this.account = account;
      this.cosmetics = value.data.cosmetics;
      this.blockCosmeticsList?.stop();
    });
  }
  switchAccountInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const account = this.accountsCache?.find(a => a._id === input.value);

    if (account)
      this.switchAccount(account);
  }

  upload(cosmetic: Cosmetics) {
    Swal.fire({
      title: $localize`Upload Regeln`,
      html: $localize`Mit dem Hochladen deiner Textur akzeptierst du automatisch die untenstehenden Regeln und dass du diese befolgst. Ein Design darf folgendes nicht beinhalten:<br><br>
  <b>Pornografie</b><br>
  Texturen, welche ein klares Ziel haben, den Betrachter sexuell zu erregen, d. h. Pornografie, Hentai, Models, etc.<br><br>
  <b>Radikaler Extremismus</b><br>
  Texturen, welche diese Ideologien repräsentieren und verbreiten, z.B. Hakenkreuze, Bilder von Hitler, etc.<br><br>
  <b>Inakzeptabler Text</b><br>
  Text, welcher beabsichtigt, eine Person oder Gruppe zu erniedrigen oder anzugreifen, d.h. Handeln, Beleidigungen, entwürdigendes Verhalten usw.<br><br>
  <b>Nicht lustig</b><br>
  Texturen, die "schwarzen Humor" enthalten, d. h. eine Verletzung der Menschenrechte, Körperverletzung usw. zeigen.<br><br>
  <b>Bilder von anderen Menschen</b><br>
  Bilder von anderen Menschen dessen Eingeständnis du nicht bekommen hast.<br><br>
  <b>Urhebergeschütze Bilder</b><br>
  Bilder von anderen Urwerke an denen du keine Rechte besitzt.<br><br>
  <b>Lunar Cosmetics</b><br>
  Lunar verbietet strickt das ihre Cosmetics in anderen Clients geteilt werden.`,
      footer: '<span class="text-muted">' + $localize`Das verletzten von einer diesen Regeln führt zu einer Verwarnung oder zu einer Kontoschließung!<br>Das Night.design-Team behält sich vor, ohne angegebene gründe Cosmetics zu entfernen.` + '</span>',

      showCancelButton: true,
      confirmButtonColor: '#198754',
      confirmButtonText: $localize`Akzeptieren`,
      cancelButtonColor: '#dc3545',
      cancelButtonText: $localize`Ablehnen`,

      width: '50em',
    }).then(value => {
      if (!value.isConfirmed)
        return;
      this.selectedCosmetic = cosmetic;
      this.fileInput?.nativeElement.click();
    });
  }
  uploadFile(event: Event) {
    const input = event.target as HTMLInputElement;

    if (input.files?.length === 0)
      return;

    if (!this.selectedCosmetic)
      return;
    const selectedCosmetic = <Cosmetics>String(this.selectedCosmetic)

    const file: File = input.files![0];
    if (!file)
      return;

    const formData = new FormData();
    formData.append('type', selectedCosmetic);
    formData.append("file", file, file.name);
    const upload$ = this.http.patch(environment.apiServer + 'players/' + this.account?.profile.id + '/cosmetics', formData, {
      params: this.specialParams,
      reportProgress: true,
      observe: 'events',
    })
      .pipe(
        finalize(() => this.reset()),
      );

    this.uploadSub = upload$.subscribe(event => {
      if (event.type == HttpEventType.UploadProgress)
        this.uploadProgress = Math.round(100 * (event.loaded / event.total!));

      if (event.type != HttpEventType.Response)
        return;

      //@ts-ignore
      const body: PlayerCosmeticRequest = event.body!;
      this.cosmetics = this.cosmetics.filter(a => a.type !== selectedCosmetic);
      this.cosmetics.push(body.data.cosmetic);
    });
  }

  delete(cosmetic: Cosmetics) {
    Swal.fire({
      icon: "warning",
      title: $localize`Bist du sicher?`,
      text: $localize`Bist du dir sicher das du dein ${cosmetic}:cosmeticName: entfernen möchtest?`,
      showCancelButton: true,
      customClass: {
        confirmButton: 'bg-danger_'
      },
      confirmButtonColor: '#dc3545',
      confirmButtonText: $localize`Löschen`,
      cancelButtonColor: '#198754',
      cancelButtonText: $localize`Abbrechen`,
    }).then(value => {
      if (!value.isConfirmed)
        return;

      this.http.delete<PlayerCosmeticsRequest>(environment.apiServer + 'players/' + this.account?.profile.id + '/cosmetics', { body: { type: cosmetic }, params: this.specialParams }).subscribe(value => {
        this.cosmetics.find(a => a.type === cosmetic)!.file = undefined;
      });
    });
  }

  reset() {
    this.selectedCosmetic = undefined;
    this.uploadFileName = undefined;
    this.uploadProgress = undefined;

    if (this.uploadSub)
      this.uploadSub.unsubscribe();
    this.uploadSub = undefined;

    this.fileInput!.nativeElement.value = '';
  }

  getCosmetic(cosmetic: Cosmetics): PlayerCosmetic | undefined {
    return this.cosmetics?.some(a => a.type === cosmetic) ? this.cosmetics?.find(a => a.type === cosmetic) as PlayerCosmetic : undefined;
  }

  getCosmetics(): PlayerCosmetic[] {
    return this.cosmetics.sort((a, b) => cosmeticsValues.indexOf(a.type) - cosmeticsValues.indexOf(b.type));
  }

  get isLoggedInViaThirdParty(): boolean {
    return 'iss' in AuthService.getTokenData();
  }
}
