<ngx-loading-bar></ngx-loading-bar>

<div class="header">

  <span class="logo" routerLink="/home">
    <img class="icon" src="/assets/img/logo-icon-simple.webp" alt="NightDesign Logo Icon">
    <img class="text" src="/assets/img/logo-text.webp" alt="NightDesign Logo Text">
  </span>
<!--  <img class="logo" src="assets/img/logo.webp" alt="NightDesign Logo" routerLink="/home">-->



  <div class="search" *ngIf="false">
    <input type="text" class="form-control" id="search" placeholder="Search for...">
  </div>
  <div [class]="'language fi fi-' + selectedLanguage.flag" (click)="languagesSwal.fire()" *ngIf="selectedLanguage"></div>
  <nav>
    <div class="navigator">
      <div class="links">
        <a [routerLinkActive]="['active']" routerLink="/home" i18n>Startseite</a> <!--TODO: Future GONE-->
        <a [routerLinkActive]="['active']" routerLink="/resources" i18n>Designs</a>
<!--        <a [routerLinkActive]="['active']" routerLink="/models" i18n>Modelle</a>--> <!--TODO: Sub Menu-->
<!--        <a [routerLinkActive]="['active']" routerLink="/servers" i18n>Server</a>--> <!--TODO: Main Menu-->
        <a href="/discord">Discord</a>
<!--        <a [routerLinkActive]="['active']" routerLink="/members" i18n>Members</a>--> <!--TODO: Main Menu-->
        <a [routerLinkActive]="['active']" routerLink="/team" class="text-primary" i18n>Team</a>
      </div>
    </div>

    <div class="account">
      <div class="links" *ngIf="!authedUser">
        <a [routerLinkActive]="['active']" routerLink="/login" [queryParams]="{returnUrl}" i18n>Anmelden</a>
        <a [routerLinkActive]="['active']" routerLink="/register" [queryParams]="{returnUrl}" i18n>Registrieren</a>
      </div>

      <div class="links" *ngIf="authedUser">
        <a [routerLink]="'/u/' + authedUser.username" style='background-size: cover;' [style.background-image]="'url(' + authedUser.avatar + ')'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
        <a [routerLink]="'/u/' + authedUser.username" [innerHTML]="authedUser.display" i18n></a>
<!--        <a routerLink="/notifications">-->
<!--          <i class="fa-regular fa-bell"></i>-->
<!--        </a>-->
        <a href="javascript:void(0);" (click)="toggleSubUserMenu()"><i class="fa-solid fa-angle-down"></i></a>
      </div>

      <div class="sub-links" [hidden]="!authedUser || !showSubUserMenu" (mousemove)="resetSubUserMenuTimeout()">
        <ul>
          <li><a [routerLinkActive]="['active']" routerLink="/dashboard" (click)="toggleSubUserMenu()" i18n>Dashboard</a></li>
          <li><a [routerLinkActive]="['active']" routerLink="/settings"(click)="toggleSubUserMenu()" i18n>Einstellungen</a></li>
          <li><a href="javascript:void(0);" (click)="logoutUser()" i18n>Abmelden</a></li>
        </ul>
      </div>
    </div>
  </nav>
</div>

<div class="router-content">
  <router-outlet></router-outlet>

  <div class="page-footer">
    <div class="row justify-content-center">
      <div class="col-md-3 mb-3">
        <h5 class="sub-title" i18n>Hilfe</h5>
        <a href="https://status.night.design" target="_blank" i18n>Status</a><br>
        <a href="https://docs.night.design" target="_blank" i18n>Wiki</a><br>
        <a href="https://api.night.design" target="_blank" i18n>API Dokumentation</a><br>
        <a href="https://crowdin.com/project/nightdesign" target="_blank" i18n>Übersetzten</a><br>
        <a routerLink="/discord" i18n>Discord Support</a><br><br>
<!--        <a href="javascript:void(0);" (click)="showLatestChangeLog()" i18n>Zeige letzter Changelog</a><br><br>-->
      </div>
      <div class="col-md-3 mb-3">
        <h5 class="sub-title" i18n>Rechtliches</h5>
        <a routerLink="/legal/imprint" i18n>Impressum</a><br>
        <a routerLink="/legal/privacy-policy" i18n>Datenschutz</a><br>
        <a routerLink="/legal/terms-of-service" i18n>Nutzungsbedingungen</a>
      </div>
      <div class="col-md-3 mb-3">
        <h5 class="sub-title" i18n>Partner</h5>
        <a rel="nofollow" href="https://manager.halfhosting.de/?tag=mvuco5kmr1m" target="_blank">Halfhosting.de</a><br>
        <a rel="nofollow" href="https://morecosmetics.org/?ref=nightDesign" target="_blank">MoreCosmetics Mod</a>

        <h5 class="sub-title mt-3" i18n>Unterstützen</h5>
        <a href="https://www.patreon.com/bePatron?u=8910714" target="_blank"><button class="btn btn-pateron"><i class="fa-brands fa-patreon me-2"></i><span i18n>Patreon werden</span></button></a>
<!--        <a href="https://paypal.me/BCCSteam" target="_blank"><button class="btn btn-paypal"><i class="fa-brands fa-paypal me-2"></i><span i18n>PayPal donation</span></button></a>-->
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <i class="fa-regular fa-copyright"></i> 2021 - {{ today | date:'YYYY' }} Night.design
        <div>With <i class="fa-solid fa-heart text-danger" (click)="loveCounter()"></i> designed by <a href="http://bccs.me" target="_blank">BCCS-Team</a> & <a href="https://www.instagram.com/_basskatze_/" target="_blank">BassKatze</a></div>

        <div class="row text-muted">
          <div class="col-md-6">Not affiliated with Mojang Studios.</div>
          <div class="col-md-6">
            This work is licensed under
            <a href="http://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer" style="display:inline-block;">
              CC BY-NC-SA 4.0
              <i class="fa-brands fa-creative-commons ms-1"></i>
              <i class="fa-brands fa-creative-commons-by ms-1"></i>
              <i class="fa-brands fa-creative-commons-nc-eu ms-1"></i>
              <i class="fa-brands fa-creative-commons-sa ms-1"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<swal #languagesSwal [showConfirmButton]="false" [showCloseButton]="true" [allowOutsideClick]="false" title="Sprache ändern" i18n-title>
  <ng-container *swalPortal="swalTargets.content">
    <button class="btn" [class.btn-outline-primary]="!language.selected" [class.btn-primary]="language.selected" *ngFor="let language of languages" (click)="changeLanguage(language)"><i [class]="'fi fi-' + language.flag"></i>&nbsp;{{language.name}}</button>
  </ng-container>
  <ng-container *swalPortal="swalTargets.footer">
    <a href="https://crowdin.com/project/nightdesign" target="_blank" style="text-decoration-line: none">Language not found?</a>
  </ng-container>
</swal>

<ng-template #verify_email>
  <div class="card border-1 border-warning">
    <div class="card-block">
      <div class="text-center"><h1 class="text-rainbow">Es wartet mehr auf dich!</h1></div>
      <div class="row justify-content-center" style="font-weight: bold;">
        <div class="col-md-5 text-center mt-md-5" style="font-size: 1.3em;">
          Hey, du hast noch keine Email Adresse hinterlegt!<br>
          Vielleicht möchtest du das noch tun damit du keine Features verpasst!
        </div>
        <div class="col-md-5">
          <ul>
            <li>Höhere Upload Limits!</li>
            <li>Neuigkeiten über updates!</li>
            <li>Benachrichtigung per Email!</li>
            <li>Custom Profil Bild und Banner!</li>
            <li>Schreibe Nachrichten und Bewertungen!</li>
            <li>Bekomme auf dich angepasste Server vorschläge!</li>
            <li>Verwalte und Bewerbe deinen eigenen Mod/Client/Server!</li>
            <li>Speichere dir Texturen, Modelle und Server in deine Favoriten ab!</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-md-8">
          <input class="form-control" autocomplete="email" placeholder="this-is-my@mail.com">
        </div>
        <div class="col-md-4 pt-2 pt-md-0">
          <div class="d-grid">
            <button class="btn btn-success btn-small">Speichern</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
