<div class="page-content page-noHeader">
  <div class="row pt-5 d-flex justify-content-center">
    <div class="col-md-12 text-center pt-md-5 pb-md-5">
      <h2 class="text-danger" style="font-size: 8em;">404</h2>
      <h2 class="text-danger"><b i18n>Die gesuchte Seite konnte nicht gefunden werden!</b></h2>
      <div class="row ">
        <div class="col-md-12 d-flex justify-content-center">
          <div class="card">
            <div class="card-body">
              <p i18n>Möglicherweise existiert die gesuchte Seite nicht mehr oder du hast dich vielleicht vertippt.</p>

              <button class="btn btn-success" (click)="back()" i18n>Zurück</button>
              <button class="btn" style="background-color: #5865F2; color: #fff; -webkit-box-shadow: none; box-shadow: none;" routerLink="/discord"><i class="fa-brands fa-discord me-1"></i><span i18n>Discord Support</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
