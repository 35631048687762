import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resources',
  templateUrl: './moderation-resources.component.html',
})
export class ModerationResourcesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
