import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

export interface NotificationsRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly notifications: Notification[];
  };
}

export type NotificationName =
  'NEW_DIRECT_MESSAGE' | 'ANSWERER_DIRECT_MESSAGE'
  | 'FOLLOW' | 'COMMENT_PIN' | 'COMMENT_ANSWERER' | 'COMMENT_PIN_ANSWERER' | 'COMMENT_REACTION' | 'TROPHY_RECEIVED'
  | 'FRIENDS_REQUEST' | 'FRIENDS_REQUEST_ACCEPTED' | 'FRIENDS_REQUEST_DENIED' | 'FRIENDS_BIRTHDAY';
export type NotificationType = 'DIRECT_MESSAGES' | 'PROFILES' | 'FRIENDS';

export interface Notification {
  readonly name: NotificationName | string;
  readonly type: NotificationType | undefined;
  enabled: boolean;
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit {

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.http.get<NotificationsRequest>(environment.apiServer + 'users/@me/notifications').subscribe(request => this.notifications = request.data.notifications);
  }

  private notifications: Notification[] = [];
  getNotifications(type: NotificationType | undefined): Notification[] {
    return this.notifications.filter(a => a.type === type);
  }

  patchNotifications(notification: Notification) {
    const data: { [key: string]: boolean; } = {};
    data[notification.name] = !notification.enabled;
    this.http.patch<NotificationsRequest>(environment.apiServer + 'users/@me/notifications', data).subscribe(() => notification.enabled = !notification.enabled);
  }

  localizedName(notification: Notification): string {
    switch (notification.name) {
      case 'NEW_DIRECT_MESSAGE':
        return $localize`Neue Direktnachrichten`;
      case 'ANSWERER_DIRECT_MESSAGE':
        return $localize`Neue Antwort auf eine Direktnachricht`;

      case 'FOLLOW':
        return $localize`Jemand folgt dir`;
      case 'COMMENT_PIN':
        return $localize`Neuen Kommentar an deiner Pinnwand`;
      case 'COMMENT_ANSWERER':
        return $localize`Neue Antwort auf einen Kommentar von dir`;
      case 'COMMENT_PIN_ANSWERER':
        return $localize`Neue Antwort auf einen Kommentar an deiner Pinnwand`;
      case 'COMMENT_REACTION':
        return $localize`Jemand hat auf deinen Kommentar reagiert`;
      case 'TROPHY_RECEIVED':
        return $localize`Trophäe erhalten`;

      case 'FRIENDS_REQUEST':
        return $localize`Freundschaftsanfrage erhalten`;
      case 'FRIENDS_REQUEST_ACCEPTED':
        return $localize`Freundschaftsanfrage wurde akzeptiert`;
      case 'FRIENDS_REQUEST_DENIED':
        return $localize`Freundschaftsanfrage wurde abgelehnt`;
      case 'FRIENDS_BIRTHDAY':
        return $localize`Freund hat Geburtstag`;

      default:
        return notification.name;
    }
  }
}
