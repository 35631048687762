import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {cosmeticsValues, localizeCosmetic} from "../../../interfaces/player-cosmetics";
import {ModderViewerComponent} from "../../modder-viewer.component";
import {Party} from "../../../modder/modder.component";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {PartyRequest} from "../../../modder-publisher/modder-publisher.component";
import {Editor, Toolbar, Validators as EditorValidators} from "ngx-editor";

@Component({
  selector: 'app-party',
  templateUrl: './party.component.html',
})
export class PartyComponent implements OnInit {
  party!: Party;
  metadata!: FormGroup;

  categories: { id: string, name: string, disabled: boolean }[] = [
    {
      id: 'vanilla',
      name: 'Vanilla Mod',
      disabled: false
    },
    {
      id: 'forge',
      name: 'Forge Mod',
      disabled: false
    },
    {
      id: 'fabric',
      name: 'Fabric Mod',
      disabled: false
    },
    {
      id: 'optifine',
      name: 'Optifine',
      disabled: false
    },
    {
      id: 'labymod_addon',
      name: 'LabyMod Addon',
      disabled: false
    },
    {
      id: 'injection',
      name: 'Injection',
      disabled: false
    },
    {
      id: 'hosts',
      name: 'Host File rewrite',
      disabled: false
    },
  ];
  second_categories_items: { id: string, name: string, disabled: boolean }[] = [];

  supportedCosmetics_items: { id: string, name: string, disabled: boolean }[] = cosmeticsValues.map(cosmetic => { return { id: cosmetic, name: localizeCosmetic(cosmetic), disabled: false }})

  descEditor: Editor = new Editor();
  descToolbar: Toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    ['link', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    ['text_color', 'background_color'],
    ['format_clear']
  ];



  moderation!: FormGroup;

  constructor(
    private http: HttpClient,
    @Inject(ModderViewerComponent) private parent: ModderViewerComponent,
  ) { }

  ngOnInit(): void {
    this.party = this.parent.party;
    if (this.party.metadata.category)
      this.second_categories_items = this.categories.map(a => { return { ...a, disabled: a.id === this.party.metadata.category }});

    this.metadata = new FormGroup({
      name: new FormControl(this.party.name, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(32),
      ]),
      summary: new FormControl(this.party.metadata.summary, [
        Validators.required,
        Validators.minLength(16),
        Validators.maxLength(128),
      ]),
      category: new FormControl(this.party.metadata.category, [
        Validators.required,
      ]),
      second_categories: new FormControl({value: this.party.metadata.second_categories, disabled: this.party.metadata.second_categories.length === 0 }, [
        Validators.required,
      ]),

      supportedCosmetics: new FormControl(this.party.metadata.cosmetics, [
        Validators.required,
      ]),

      desc: new FormControl(this.party.metadata.description, [
        EditorValidators.required(),
        EditorValidators.minLength(128),
        EditorValidators.maxLength(2048),
      ]),
    });


    this.moderation = new FormGroup({});
  }

  getInput(key: string): AbstractControl {
    return this.metadata.get(key)!;
  }
  getInputValue(key: string): string {
    return this.getInput(key).value;
  }
  hasInputError(key: string, error?: string): boolean {
    const control = this.getInput(key);
    if (!control || control.status === "PENDING")
      return false;

    if (!control.touched)
      return false;

    if (!error)
      return control.invalid;

    return control.hasError(error);
  }
  displayInputError(key: string, offset?: number): string | undefined {
    if (!offset)
      offset = 0;

    const control = this.getInput(key);
    if (!control)
      return undefined;

    if (!control.touched)
      return undefined;

    if (!control.errors)
      return undefined;

    const error = Object.keys(control.errors)[offset];
    const errorData = control.errors[error];
    switch (error) {
      case 'required':
        return $localize`Dieses Feld darf nicht leer sein!`;

      case 'pattern':
        console.log(errorData)
        return $localize`Dieses Feld entspricht nicht dem erlaubten Format!`;

      case 'minlength':
        const lengthMin = errorData['requiredLength'] - errorData['actualLength'];
        return $localize`Dieses Feld ist um ${lengthMin} Zeichen zu kurz!`;

      case 'maxlength':
        const lengthMax = errorData['actualLength'] - errorData['requiredLength'];
        return $localize`Dieses Feld ist um ${lengthMax} Zeichen zu lang!`;

      case 'gone':
        const suggestion = control.value + String(errorData['offset']);
        return $localize`Diese eingabe ist bereits belegt! Versuche es mal mit ${suggestion}`;

      default:
        return undefined;
    }
  }
  updateInput(key: string, value?: string | KeyboardEvent) {
    const input = this.getInput(key);

    if (value)
      input.setValue(typeof value === 'string' ? value : (value.target as HTMLInputElement).value);

    input.markAsTouched();
    input.updateValueAndValidity();
  }
  markFormAsTouched() {
    Object.keys(this.metadata.controls).map(key => this.metadata.controls[key].markAsTouched());
  }
  get formInvalid(): boolean {
    if (this.metadata.untouched)
      return true;

    return Object.keys(this.metadata.controls).some(key => this.metadata.controls[key].invalid);
  }


  categories_patch() {
    const val = this.getInput('category').value;
    const input = this.getInput('second_categories');
    input.setValue('');

    if (!val)
      input.disable();
    else
      input.enable();

    this.second_categories_items = this.categories.map(a => { return { ...a, disabled: a.id === val }});
  }

  patchMetadata() {
    const patches: { [key: string]: any } = {};

    for (let key of Object.keys(this.metadata.controls)) {
      const control = this.metadata.controls[key];
      if (!control.touched)
        continue;

      patches[key] = control.value;
    }
    this.metadata.markAsUntouched();

    if (Object.keys(patches).length === 0)
      return;

    this.http.patch(environment.apiServer + 'mods/' + this.party._id, patches).subscribe(() => this.refreshPartyData());
  }


  imageError: { icon: string, banner: string } = { icon: '', banner: '' };
  imageType: Image = "icon";
  uploading = false;
  patchImage($event: Event) {
    const element = $event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (!fileList)
      return;

    this.uploading = true;
    this.imageError[this.imageType] = '';

    const file = fileList[0];
    const data = new FormData();
    data.set('file', file, file.name);
    this.http.patch(environment.apiServer + 'mods/' + this.party._id + '/' + this.imageType, data, { params: { noDefError: 1 }}).subscribe(() => {
      this.refreshPartyData();
      element.value = '';
      this.uploading = false;
    }, error => {
      console.log(error);
      this.imageError[this.imageType] = error.error.data.check;
      this.uploading = false;
    });
  }
  deleteImage(image: Image) {
    this.http.delete(environment.apiServer + 'mods/' + this.party._id + '/' + image).subscribe(() => {
      switch (image) {
        case "icon":
          this.parent.party.metadata.icon = undefined;
          break;
        case "banner":
          this.parent.party.metadata.banner = undefined;
          break;
      }
    });
  }



  refreshPartyData() {
    this.http.get<PartyRequest>(environment.apiServer + 'mods/' + this.party._id).subscribe(response => {
      this.party = response.data.mod;
      this.parent.party = response.data.mod;
    });
  }
}


export type Image = 'icon' | 'banner';
