<div class="page-header">
  <div class="page-block">
    <div class="row">
      <div class="col-md-8">
        <div class="page-header-title">
          <h5 class="m-b-10" i18n>Modderboard</h5>
        </div>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-end">
          <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
          <li class="breadcrumb-item"><a href="javascript:null" i18n>Modderboard</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="page-content">

  <div class="d-grid">
    <button class="btn btn-success" routerLink="/modder/publisher" style="font-size: 1.5em" i18n>Neuer Mod/Client hinzufügen</button>
  </div>

  <div class="card mt-3" *ngIf="parties.length === -5">
    <div class="card-block">
      <input class="form-control" placeholder="Badlion, Optifine, Mojang..."> <!--(keyup)="onSearchInput($event)"-->
    </div>
  </div>


  <div class="row mt-3" *ngIf="parties.length !== 0">
    <div class="col-md-6" *ngFor="let party of parties" routerLink="/modder/{{party._id}}">
      <div class="card">
        <div class="card-block">
          <div class="row">
            <div class="col-2 text-center">
              <img class="img-fluid rounded" [src]="party.metadata.icon || '/assets/img/icon-icon-grey.webp'" alt="">
            </div>
            <div class="col-10">
              <h3>
                {{party.name}}
                <span class="float-end">
                  <i class="fa-solid fa-face-thinking text-warning" *ngIf="!party.verifiedAt"></i>
                  <i class="fa-solid fa-face-grin text-success" *ngIf="party.verifiedAt"></i>
                </span>
              </h3>
              <div class="text-muted">
                {{party.metadata.summary}}
              </div>
              <span class="badge bg-info me-1">{{party.metadata.category | titlecase}}</span>
              <span class="badge bg-primary me-1" *ngFor="let category of party.metadata.second_categories">{{category | titlecase}}</span>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <span class="badge border border-primary text-primary me-1" *ngFor="let cosmetic of party.metadata.cosmetics">{{localizeCosmetic(cosmetic)}}</span>
          <div class="float-end">{{party.downloads | numeral: '0.[00] a'}}&nbsp;<i class="fa-solid fa-download"></i></div>
        </div>
      </div>
    </div>

    <div class="col-md-12 text-end" *ngIf="parties.length >= 32">
      <button class="btn" [class.btn-success]="page < maxPages" [class.btn-outline-dark]="page >= maxPages" (click)="loadMore()" i18n>Mehr laden</button>
    </div>
  </div>


  <div class="text-center mt-5" *ngIf="parties.length === 0">
    <i class="fa-solid fa-arrow-up text-muted animate__animated animate__bounce animate__infinite" style="font-size: 12em"></i><br>
    <span class="text-muted" style="font-size: 2em" i18n>Hier werden bald bestimmt sehr coole und tolle Cosmetics von dir sein!</span>
  </div>
</div>
