<div class="page-content page-noHeader">
  <div class="row pt-5">
    <div class="col-md-12 d-flex justify-content-center">
      <div class="card" *blockUI="'loginCard'">

        <div class="card-block" *ngIf="user">
          <h3 class="text-center" i18n>Willkommen zurück, {{user.username}}</h3>
          <div class="alert alert-primary" i18n>
            Du musst dich mit einer deiner 2FA Geräte bestätigen.
          </div>


          <div class="d-grid">
            <button class="btn btn-primary" *ngFor="let challenge of challenges;" (click)="runChallenge(challenge)">
              <i class="fa-solid fa-mobile-screen" *ngIf="challenge.provider === 'PHONE'"></i>
              <i class="fa-solid fa-lock" *ngIf="challenge.provider === 'OTP'"></i>
              <i class="fa-solid fa-key-skeleton" *ngIf="challenge.provider === 'FIDO'"></i>
              {{challenge.name}}
            </button>
            <button class="btn btn-outline-danger" (click)="backupChallenge()"><i class="fa-solid fa-key"></i>&nbsp;<span i18n>Wiederherstellungs Code</span></button>
          </div>
        </div>

        <div class="card-block" *ngIf="!user">
          <h3 class="text-center" i18n>Einloggen</h3>

          <form [formGroup]="form">
            <div class="alert alert-danger" *ngIf="error">
              {{errorCode}}
              <ng-container *ngIf="errorCode === 'account_unverified'" i18n>
                Du hast deinen Account noch nicht bestätigt. Bitte schaue nochmals in deinen Emails nach.<br>
                Falls dieser fehler bestehen bleibt oder du keine Email bekommen hast, melde dich bitte mit deiner registrierten Email Adresse an support@night.design
              </ng-container>

              <ng-container *ngIf="!errorCode">
                {{error}}
              </ng-container>
            </div>

            <div class="text-center" *ngIf="isAuthorized">
              <button class="btn btn-primary" (click)="logout()" i18n>Abmelden</button>
            </div>

            <ng-container *ngIf="!isAuthorized">
              <label for="email" class="form-label" i18n>Email Adresse</label>
              <input type="email" class="form-control" [class.is-invalid]="email.invalid && (submitted || email.dirty || email.touched)" id="email" placeholder="player@example.com" formControlName="email" required>
              <div *ngIf="mailcheck || (email.status === 'INVALID' && (submitted || email.dirty || email.touched))"
                   class="invalid-feedback" style="display: block">
                <ng-container *ngIf="email.errors?.['required']" i18n>
                  Email ist ein pflicht Feld.
                </ng-container>

                <ng-container *ngIf="!email.errors?.['required'] && email.errors?.['pattern']" i18n>
                  Die Email address ist ungültig.
                </ng-container>

                <ng-container *ngIf="email.status === 'VALID' && mailcheck" i18n>
                  Meintest du etwa <a class="link-warning" (click)="email.setValue(mailcheck)">{{mailcheck}}</a>?
                </ng-container>
              </div>

              <label for="password" class="form-label mt-2" i18n>Passwort</label>
              <input type="password" class="form-control" [class.is-invalid]="password.invalid && (submitted || password.dirty || password.touched)" id="password" placeholder="password" formControlName="password" required>
              <div *ngIf="password.status === 'INVALID' && (submitted || password.dirty || password.touched)"
                   class="invalid-feedback" style="display: block">
                <ng-container *ngIf="password.errors?.['required']" i18n>
                  Passwort ist ein pflicht Feld.
                </ng-container>
              </div>

              <div class="row mt-3">
                <div class="col-md-6 d-grid">
                  <button class="btn btn-primary btn-block" type="submit" (click)="login()" [disabled]="form.disabled" i18n>Einloggen</button>
                </div>
                <div class="col-md-6 d-grid">
                  <button class="btn btn-success" type="button" routerLink="/register" i18n>Registrieren</button>
                </div>
                <div class="col-md-12 text-center">
                  <a routerLink="/password-forgotten" i18n>Passwort vergessen?</a>
                </div>
              </div>
            </ng-container>
          </form>
        </div>

        <div class="card-footer text-center" *ngIf="user">
          <a class="me-1" routerLink="/login"><button class="btn btn-outline-danger" i18n>Abbrechen</button></a>
        </div>

        <div class="card-footer text-center d-grid" *ngIf="!user">
          <button class="btn btn-block btn-xbox" (click)="minecraftLogin.fire()">Anmelden mit Minecraft</button>
        </div>

        <div class="card-footer text-center" *ngIf="!user">
          <a class="me-1" [href]="authUrl('xbox') | async"><button class="btn btn-block btn-microsoft"><i class="fab fa-microsoft"></i></button></a>
          <a class="me-1" [href]="authUrl('google') | async"><button class="btn btn-block btn-google"><i class="fab fa-google"></i></button></a>
          <a class="me-1" [href]="authUrl('discord') | async"><button class="btn btn-block btn-discord"><i class="fab fa-discord"></i></button></a>
          <a [href]="authUrl('twitch') | async"><button class="btn btn-block btn-twitch"><i class="fab fa-twitch"></i></button></a>
        </div>
      </div>
    </div>
  </div>
</div>

<swal #minecraftLogin [showConfirmButton]="false" [showCloseButton]="true" [allowOutsideClick]="false" title="Anmelden mit Minecraft" width="75%" i18n-title>
  <ng-container *swalPortal="swalTargets.content">
    <div class="row">
      <div class="col-md-6" style="border-right: 1px solid #eee">
        <h4 i18n>Melde dich mit deiner Minecraft Sitzung an!</h4>
        <div class="mb-3">
          <ol class="text-start">
            <li i18n>Starte das Spiel und gehe auf Mehrspieler</li>
            <li i18n>Joine auf dem Server <code ngxClipboard cbContent="auth.night.design">auth.night.design <i class="fa-solid fa-copy"></i></code></li>
            <li i18n>Trage hier den dir angezeigten Code ein</li>
          </ol>
        </div>

        <div class="mb-2 text-start">
          <auto-complete type="PLAYERS" [returnId]="true" [(selectedInput)]="uuid" placeholder="Minecraft Name" i18n-placeholder></auto-complete>
        </div>
        <input type="text" [minLength]="6" [maxLength]="6" class="form-control mb-2" [(ngModel)]="code" placeholder="Login Code">
        <button class="btn btn-xbox" type="button" (click)="loginViaMinecraft()" [disabled]="!uuid || !code || code.toString().length != 6" i18n>Anmelden</button>

      </div>
      <div class="col-md-6 d-flex align-items-center justify-content-center">
        <div>
          <h4 i18n>Melde dich mit Microsoft direkt an!</h4>
          <a class="me-1" [href]="authUrl('xbox') | async"><button class="btn btn-block btn-microsoft"><i class="fab fa-microsoft"></i> Microsoft</button></a>
        </div>
      </div>
    </div>
  </ng-container>
</swal>
