<ng-container *ngIf="server">
  <div class="page-header">
    <div class="page-block">
      <div class="row">
        <div class="col-md-8">
          <div class="page-header-title">
            <h5 class="m-b-10">{{server.name}}, {{pageName}}</h5>
          </div>
        </div>
        <div class="col-md-4">
          <ul class="breadcrumb justify-content-end">
            <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
            <li class="breadcrumb-item"><a routerLink="/servers" i18n>Server</a></li>
            <li class="breadcrumb-item">{{server.name}}</li>
            <li class="breadcrumb-item">{{pageName}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="page-content">
    <div class="row">
      <div class="col-md-3">
        <div class="text-center">
          <button class="btn btn-success" [disabled]="!canRunAction('start')" (click)="runAction('start')"><i class="fa-solid fa-play"></i></button>
          <button class="btn btn-danger" [disabled]="!canRunAction('stop')" (click)="runAction('stop')"><i class="fa-solid fa-stop"></i></button>
          <button class="btn btn-warning" [disabled]="!canRunAction('restart')" (click)="runAction('restart')"><i class="fa-solid fa-rotate"></i></button>
        </div>

        <div class="d-grid mt-3"><button class="btn btn-primary" routerLinkActive="btn-info" routerLink="/gameserver/{{server._id}}/console" i18n>Konsole</button></div>
        <div class="d-grid"><button class="btn btn-primary" routerLinkActive="btn-info" routerLink="/gameserver/{{server._id}}/plugins" i18n>Plugins Verwaltung</button></div>
        <div class="d-grid"><button class="btn btn-primary" routerLinkActive="btn-info" routerLink="/gameserver/{{server._id}}/files" i18n>Datei Verwaltung</button></div>
        <div class="d-grid"><button class="btn btn-primary" routerLinkActive="btn-info" routerLink="/gameserver/{{server._id}}/settings" i18n>Einstellungen</button></div>

        <div class="card mt-3">
          <div class="card-body">
            <div><span i18n>Status:</span><span class="float-end">{{server.status | titlecase}}</span></div>
            <div><span i18n>Address:</span><span class="float-end">{{server.connection.address}}:{{server.connection.port}} <i ngxClipboard cbContent="{{server.connection.address}}:{{server.connection.port}}" *ngxClipboardIfSupported class="fa-solid fa-copy"></i></span></div>
            <div><span i18n>Version:</span><span class="float-end">{{server.software.type}}/{{server.software.version}}</span></div>
            <div><span i18n>Java Version:</span><span class="float-end">java{{server.java.version}}</span></div>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!server">
  <div class="page-header">
    <div class="page-block">
      <div class="row">
        <div class="col-md-8">
          <div class="page-header-title">
            <h5 class="m-b-10"><i class="fa-duotone fa-loader fa-spin-pulse"></i>, {{pageName}}</h5>
          </div>
        </div>
        <div class="col-md-4">
          <ul class="breadcrumb justify-content-end">
            <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
            <li class="breadcrumb-item"><a routerLink="/servers" i18n>Server</a></li>
            <li class="breadcrumb-item"><i class="fa-duotone fa-loader fa-spin-pulse"></i></li>
            <li class="breadcrumb-item">{{pageName}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="page-content">
    <div class="text-center text-muted pt-5" style="font-size: 6em;">
      <i class="fa-duotone fa-loader fa-spin-pulse"></i>
      <h1 i18n>Lädt...</h1>
    </div>
  </div>
</ng-container>
