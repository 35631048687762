<div class="card">
  <div class="card-header">
    <h5 i18n>Konversationen</h5>
  </div>

  <div class="table-responsive">
    <table class="card-footer table m-0">
      <tbody>
      <tr *ngFor="let notification of getNotifications('DIRECT_MESSAGES')" (click)="patchNotifications(notification)">
        <th scope="row"><span class="ms-3">{{ localizedName(notification) }}</span></th>
        <td>
          <i class="float-end fa-solid fa-bell text-success me-3" *ngIf="notification.enabled"></i>
          <i class="float-end fa-solid fa-bell-slash text-danger me-3" *ngIf="!notification.enabled"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


<div class="card">
  <div class="card-header">
    <h5 i18n>Benutzer-Profile</h5>
  </div>

  <div class="table-responsive">
    <table class="card-footer table m-0">
      <tbody>
      <tr *ngFor="let notification of getNotifications('PROFILES')" (click)="patchNotifications(notification)">
        <th scope="row"><span class="ms-3">{{ localizedName(notification) }}</span></th>
        <td>
          <i class="float-end fa-solid fa-bell text-success me-3" *ngIf="notification.enabled"></i>
          <i class="float-end fa-solid fa-bell-slash text-danger me-3" *ngIf="!notification.enabled"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


<div class="card">
  <div class="card-header">
    <h5 i18n>Freunde</h5>
  </div>

  <div class="table-responsive">
    <table class="card-footer table m-0">
      <tbody>
      <tr *ngFor="let notification of getNotifications('FRIENDS')" (click)="patchNotifications(notification)">
        <th scope="row"><span class="ms-3">{{ localizedName(notification) }}</span></th>
        <td>
          <i class="float-end fa-solid fa-bell text-success me-3" *ngIf="notification.enabled"></i>
          <i class="float-end fa-solid fa-bell-slash text-danger me-3" *ngIf="!notification.enabled"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


<div class="card" *ngIf="getNotifications(undefined).length !== 0">
  <div class="card-header">
    <h5 i18n>Sonstiges</h5>
  </div>

  <div class="table-responsive">
    <table class="card-footer table m-0">
      <tbody>
      <tr *ngFor="let notification of getNotifications(undefined)" (click)="patchNotifications(notification)">
        <th scope="row"><span class="ms-3">{{ localizedName(notification) }}</span></th>
        <td>
          <i class="float-end fa-solid fa-bell text-success me-3" *ngIf="notification.enabled"></i>
          <i class="float-end fa-solid fa-bell-slash text-danger me-3" *ngIf="!notification.enabled"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
