import {Component, Inject, Injector, LOCALE_ID, OnInit, PLATFORM_ID} from '@angular/core';
import Swal from "sweetalert2";
import packageJson from '../../package.json';
import {environment} from "../environments/environment";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {AuthService} from "./services/auth.service";
import {User} from "./user/user.service";
import {SwalPortalTargets} from "@sweetalert2/ngx-sweetalert2";
import {NgcCookieConsentService, NgcStatusChangeEvent} from "ngx-cookieconsent";
import {filter} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import {isPlatformBrowser} from "@angular/common";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  isBrowser = false;

  title = 'NightDesignPanel';
  today: number = Date.now();
  protected readonly lastSeenVersion: string | undefined = localStorage.getItem('version') || undefined;

  returnUrl: string = "";
  authedUser: User | undefined = undefined;
  showSubUserMenu = false;
  showSubUserMenuTimeout = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) platformId: Object,
    private readonly injector: Injector,
    public swalTargets: SwalPortalTargets,
    private cookieService: CookieService,
    private metaTagService: Meta,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.metaTagService.addTag({ property: 'og:site_name', content: 'NightDesign' });
    this.metaTagService.addTag({ property: 'og:locale', content: this.locale });
    for (let supportedLanguage of this.supportedLanguages)
      this.metaTagService.addTag({ property: 'og:locale:alternate', content: supportedLanguage.id });


    if (!this.isBrowser)
      return;

    const ccService = this.injector.get(NgcCookieConsentService);
    ccService.initialize$.subscribe(value => {
      if (value.status !== "allow") {
        this.deleteCookies();
        return;
      }

      this.addAnalytics();
    });

    ccService.statusChange$.subscribe(value => {
      if (value.status === "deny") {
        window.location.reload();
        return;
      }

      if (value.status !== "allow")
        return;

      this.addAnalytics();
    });



    this.router.events.pipe<any>(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
      })
    });

    this.returnUrl = btoa(
      'https://' + environment.host + '/' + this.route.pathFromRoot
        .filter(v => v.routeConfig)
        .map(v => v.routeConfig!.path)
        .join('/')
        .replace('authorisation','scam')
    ).replace(/=/g,'');

    window.setInterval(() => {
      if (!this.authedUser && AuthService.isAuthorized())
        this.authService.getUserData().subscribe(request => this.authedUser = request.data.user);
      if (this.authedUser && !AuthService.isAuthorized())
        this.authedUser = undefined;
    }, 1000);

    if (localStorage.getItem('love'))
      document.querySelector('.fa-heart')!.classList.add('animate__animated','animate__heartBeat','animate__infinite');

    // if (!(this.lastSeenVersion && semver.compare(this.lastSeenVersion, packageJson.version) === 0))
    //    this.showLatestChangeLog();
  };

  toggleSubUserMenu() {
    switch (this.showSubUserMenu) {
      case true:
        animateCSS('.sub-links','fadeOut').then(() => this.showSubUserMenu = false);
        break;
      case false:
        this.showSubUserMenu = true;
        animateCSS('.sub-links','fadeIn').then(() => this.resetSubUserMenuTimeout());
        break;
    }
  }
  resetSubUserMenuTimeout() {
    if (this.showSubUserMenuTimeout !== 0)
      clearTimeout(this.showSubUserMenuTimeout);

    this.showSubUserMenuTimeout = window.setTimeout(() => {
      if (!this.showSubUserMenu)
        return;

      this.toggleSubUserMenu();
      this.showSubUserMenuTimeout = 0;
    }, 1500);
  }

  logoutUser() {
    this.authService.logout();
    window.location.reload();
  }

  showLatestChangeLog() {
    Swal.fire({
      title: 'Update v' + packageJson.version,
      width: '75%',
      imageUrl: 'https://c.tenor.com/JIS_KDKKsgYAAAAM/guaton-computadora.gif',
      customClass: {
        htmlContainer: 'text-start'
      },
      html: `
Hi, Willkommen auf unserer neuen Webseite!<br>
Nach langer langer Zeit haben wir uns entschieden ein eigenes Design aufzusetzten und modernere Techniken verwenden.<br>
Das neue System sollte nun schneller und besseres Caching verwenden!<br>
Unter anderem werden wir bald Mods Entwickler mehr möglichkeiten geben uns Integrieren und Verwalten zu können.
<hr>
+ Liebe <i class="fa-solid fa-face-smile-hearts text-danger"></i> hinzugefügt<br>
+ Cosmetic anwenden Knopf für Partner Mods hinzugefügt<br>
+ <s>Bugs</s><span style="background-color: yellow; color: black;">Features</span> hinzugefügt<br>
* Alle Accounts und Resourcen wurden bereits übertragen und angepasst<br>
`
    }).then(() => localStorage.setItem('version', packageJson.version));
  }

  private love: number = 0;
  loveCounter() {
    if (++this.love !== 5) {
      animateCSS('.fa-heart','heartBeat');
      return;
    }

    localStorage.setItem('love','1');
    document.querySelector('.fa-heart')!.classList.add('animate__animated','animate__heartBeat','animate__infinite');

    window.open('https://www.youtube.com/watch?v=NNC0kIzM1Fo?t=5', '_blank')!.focus();
  }

  supportedLanguages: Language[] = [
    { id: 'en', flag: 'gb', name: 'English', selected: false },
    { id: 'de', flag: 'de', name: 'German', selected: false },
    // { id: 'ar', flag: 'ar', player: 'Arabic', selected: false },
    { id: 'cs', flag: 'cz', name: 'Czech', selected: false },
    { id: 'nl', flag: 'nl', name: 'Dutch', selected: false },
    { id: 'fr', flag: 'fr', name: 'French', selected: false },
    { id: 'it', flag: 'it', name: 'Italian', selected: false },
    { id: 'pl', flag: 'pl', name: 'Polish', selected: false },
    // { id: 'pt-br', flag: 'br', player: 'Portuguese (Brazilian)', selected: false },
    { id: 'ru', flag: 'ru', name: 'Russian', selected: false },
    { id: 'es', flag: 'es', name: 'Spanish', selected: false },
  ];
  selectedLanguage: Language = this.supportedLanguages.find(language => language.id === this.locale)! || { id: 'en', flag: 'gb', name: 'English', selected: true };
  get languages() {
    return this.supportedLanguages.map(language => {
      language.selected = language === this.selectedLanguage
      return language;
    });
  }
  changeLanguage(lang: Language) {
    const newLoc = new URL(window.location.href);
    newLoc.pathname = newLoc.pathname.replace('/' + this.locale, '/' + lang.id);

    window.location.href = newLoc.href;
  }




  deleteCookies() {
    Object.keys(this.cookieService.getAll()).filter(a => a !== 'cookieconsent_status').forEach(key => this.cookieService.delete(key));
  }

  addAnalytics() {
    const scriptId = 'ga-gtag';
    if (document.getElementById(scriptId)) return;

    const trackingId = environment.GA_TRACKING_ID;
    const {head} = document;
    const script = document.createElement('script');
    script.id = scriptId;
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    head.insertBefore(script, head.firstChild);

    // @ts-ignore
    window.dataLayer = window.dataLayer || [];

    gtag('js', new Date());
    gtag('config', trackingId, { 'anonymize_ip': true });
  }
}

export function gtag(action: string, keyValue: any, object: Object = {}) {
  // @ts-ignore
  if (window.dataLayer)
    // @ts-ignore
    window.dataLayer.push(arguments);
}

const animateCSS = (element: string, animation: String, prefix = 'animate__') => new Promise((resolve, reject) => {
  const animationName = `${prefix}${animation}`;
  const node = document.querySelector(element);
  if (!node)
    return;

  node.classList.add(`${prefix}animated`, animationName);

  // When the animation ends, we clean the classes and resolve the Promise
  function handleAnimationEnd(event: { stopPropagation: () => void; }) {
    if (node)
      node.classList.remove(`${prefix}animated`, animationName);
    event.stopPropagation();
    resolve('Animation ended');
  }

  node.addEventListener('animationend', handleAnimationEnd, {once: true});
});


export interface Language {
  readonly id: string;
  readonly flag: string;
  readonly name: string;
  selected: boolean;
}
