import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {SwalPortalTargets} from "@sweetalert2/ngx-sweetalert2";
import {Server} from "../../server-viewer/server-viewer.component";
import {User} from "../../user/user.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {SimpleRequest} from "../../services/simple-request";
import Swal from "sweetalert2";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'reportButton',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  isAuthed = AuthService.isAuthorized();

  @Input()
  target!: BasicTarget;
  @Input()
  type!: targetType;

  @Input()
  smallBtn: boolean = false;
  @Input()
  disabled: boolean = false;

  @ViewChild('reportPopup')
  popup!: ElementRef;

  fields: Field[] = [
    {
      id: 'user_avatar',
      name: $localize`User Avatar`,
      value: "image",
      targets: ["user","resource","server","comment"]
    },
    {
      id: 'user_banner',
      name: $localize`User Banner`,
      value: "image",
      targets: ["user"]
    },
    {
      id: 'user_username',
      name: $localize`Nutzernamen`,
      value: "text",
      targets: ["user","resource","server","comment"]
    },

    {
      id: 'comment_message',
      name: $localize`Nachricht`,
      value: "text",
      targets: ["comment"]
    },
    {
      id: 'comment_append',
      name: $localize`Bildanhang`,
      value: "image",
      targets: ["comment"]
    },

    {
      id: 'resource_image',
      name: $localize`Design Bild`,
      value: "image",
      targets: ["resource"]
    },
    {
      id: 'resource_description',
      name: $localize`Design Beschreibung`,
      value: "text",
      targets: ["resource"]
    },

    {
      id: 'server_icon',
      name: $localize`Server Icon`,
      value: "image",
      targets: ["server"]
    },
    {
      id: 'resource_address',
      name: $localize`Server Adresse`,
      value: "text",
      targets: ["server"]
    },
    {
      id: 'resource_description',
      name: $localize`Server Beschreibung`,
      value: "text",
      targets: ["server"]
    },
  ];
  selectedField?: Field;

  reasons: Reason[] = [
    {
      name: $localize`Geklauter Inhalt`,
      value: "stolen_content",
      available: {
        targets: ["user","resource","server","comment"],
        fields: ["text","image","avatar","banner"]
      }
    },
    {
      name: $localize`Pornographischer Inhalt`,
      value: "porn",
      available: {
        targets: ["user","resource","server","comment"],
        fields: ["text","image","avatar","banner"]
      }
    },
    {
      name: $localize`Rechtsextremer Inhalt`,
      value: "right-wing_extremists",
      available: {
        targets: ["user","resource","server","comment"],
        fields: ["text","image","avatar","banner"]
      }
    },
    {
      name: $localize`Hassrede`,
      value: "hate_speech",
      available: {
        targets: ["user","resource","server","comment"],
        fields: ["text","avatar","banner"]
      }
    },
    {
      name: $localize`Gewalttätiger oder abstoßender Inhalt`,
      value: "violent_content",
      available: {
        targets: ["user","resource","server","comment"],
        fields: ["text"]
      }
    },
    {
      name: $localize`Gefährlicher Inhalt`,
      value: "dangerous_content",
      available: {
        targets: ["user","resource","server","comment"],
        fields: ["text","image","avatar","banner"]
      }
    },
    {
      name: $localize`Beleidigender Inhalt`,
      value: "offensive_content",
      available: {
        targets: ["user","resource","server","comment"],
        fields: ["text","image","avatar","banner"]
      }
    },
  ];
  selectedReason?: Reason;

  message: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    public swalTargets: SwalPortalTargets,
  ) { }

  ngOnInit(): void {
    if (this.type === "user")
      this.target.user = this.target as User;

    const fields = this.targetFields;
    if (fields.length === 1)
      this.selectedField = fields[0];

    const reasons = this.targetReasons;
    if (reasons.length === 1)
      this.selectedReason = reasons[0];

    this.route.queryParams.subscribe(query => {
      const target = query['report'];

      if (target !== this.target._id)
        return;

      if (!this.isAuthed)
        return this.loginRedirect();

      this.popup.nativeElement.click();
    });
  }


  loginRedirect() {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    params.set('report', this.target._id);

    const returnTo = btoa(url.href).replace(/=/g,'');
    this.router.navigate(['/login'], { queryParams: { returnTo }}).then();
  }


  post() {
    this.http.post<SimpleRequest>(environment.apiServer + 'reports', { target: this.target._id, type: this.type, element: this.selectedField?.id, reason: this.selectedReason?.value, message: this.message }).subscribe(response => {
      this.reset();
      Swal.fire({
        icon: 'success',
        title: $localize`${this.localizedType} report wurde erstellt!`,
        text: $localize`Du kannst nun dieses Fenster schließen.`,
        showConfirmButton: true,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
      }).then();
    });
  }

  reset() {
    this.selectedField = undefined;
    this.selectedReason = undefined;
    this.message = '';
  }


  get targetFields(): Field[] {
    return this.fields.filter(field => (
      !field.targets
      || field.targets.includes(this.type)
    ) && (
      !field.targets?.includes("user")
      || (
        field.targets?.includes("user")
        && (
          this.target.user
          || this.type === "user"
        )
      )
    ));
  }
  get targetReasons(): Reason[] {
    return this.reasons.filter(reason =>
      (
        !reason.available.targets
        || reason.available.targets.includes(this.type)
      ) && (
        this.selectedField
        && (
          !reason.available.fields
          || reason.available.fields.includes(this.selectedField.value)
        )
      )
    );
  }
  get targetName(): string {
    const { domain, name, username, title, message } = this.target;
    return domain ?? name ?? username ?? title ?? message ?? 'Unknown';
  }
  get localizedType(): string {
    switch (this.type) {
      case "user":
        return $localize`Nutzer`;
      case "resource":
        return $localize`Design`;
      case "server":
        return $localize`Server`;
      case "comment":
        return $localize`Kommentar`;

      default:
        return this.type;
    }
  }
}

export interface BasicTarget {
  readonly _id: string;
  readonly domain?: string;
  readonly title?: string;
  readonly name?: string;
  readonly username?: string;
  message?: string;
  user?: User;
}

export type targetType = 'user' | 'resource' | 'server' | 'comment';
export type specificField = 'text' | 'file' | 'image' | 'avatar' | 'banner';

export interface Field {
  readonly id: string;
  readonly name: string;
  readonly value: specificField;
  readonly targets?: targetType[];
}

export interface Reason {
  readonly name: string;
  readonly value: string;
  readonly available: {
    readonly targets?: targetType[];
    readonly fields?: specificField[];
  };
}
