<ng-container *ngTemplateOutlet="comment"></ng-container>


<ng-container  *ngFor="let activity of activities; let i1 = index">
  <div class="card" [id]="activities.length - i1">
    <div class="card-body pb-0">
      <span class="float-end text-muted" (click)="changeHash(activities.length - i1)">#{{ activities.length - i1 }}</span>
      <img class="rounded-circle" style="width: 3em; height: auto; margin-top: -2.5em; margin-right: 1em;" [src]="activity.user.avatar" [alt]="activity.user.username">
      <div class="d-inline-block">
        <h6>
          <span style="color: #fff" routerLink="/u/{{activity.user.username}}" [innerHTML]="activity.user.display"></span>
          <span class="ps-1" *ngIf="!activity.comment" i18n>hat eine neues Design veröffentlicht.</span>
          <span class="ps-1" *ngIf="activity.comment && activity.resource" i18n>kommentierte ein Design von <span routerLink="/u/{{activity.resource!.user.username}}" [innerHTML]="activity.resource!.user.display"></span>, "<a routerLink="/resources/{{activity.resource._id}}">{{activity.resource.name}}</a>".</span>
          <span class="ps-1" *ngIf="activity.comment && !activity.resource && activity.user._id !== user._id" i18n>hat eine Nachricht bei <span routerLink="/u/{{user.username}}" [innerHTML]="user.display"></span> hinterlassen.</span>
          <span class="ps-1" *ngIf="activity.comment && !activity.resource && activity.user._id === user._id" i18n>hat eine Nachricht geschrieben.</span>
        </h6>
        <p class="text-muted">{{activity.user.role?.name || '&nbsp;'}}</p>
      </div><br>
    </div>


    <div class="card-body pt-0" *ngIf="activity.resource">
      <div class="row">
        <div class="col-md-4 text-center">
          <img routerLink="/resources/{{activity.resource._id}}" errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" [lazyLoad]="activity.resource.files.preview.default['image/webp'] ? activity.resource.files.preview.default['image/webp'] : activity.resource.files.preview.default[activity.resource.mime]" class="resources-preview" [alt]="activity.resource.name">
        </div>
        <div class="col-md-auto">
          <h2><a routerLink="/resources/{{activity.resource._id}}">{{activity.resource.name}}</a></h2>
          <span class="badge bg-primary" *ngFor="let tag of activity.resource.tags" routerLink="/resources" [queryParams]="{ q: tag }">{{tag}}</span>
          <span class="badge bg-warning" routerLink="/resources" [queryParams]="{ types: activity.resource.type }">{{ localizeCosmetic(activity.resource) }}</span>

          <div class="mt-4">
            <img class="rounded-circle" style="width: 3em; height: auto; margin-top: -2.5em; margin-right: 1em;" [src]="activity.resource!.user.avatar" [alt]="activity.resource!.user.username" routerLink="/u/{{activity.resource!.user.username}}">
            <div class="d-inline-block">
              <h6><span style="color: #fff" [innerHTML]="activity.resource!.user.display" routerLink="/u/{{activity.resource!.user.username}}"></span></h6>
              <p class="text-muted">{{activity.resource!.user.role?.name || '&nbsp;'}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body pt-0 ps-5" *ngIf="activity.comment">
      {{activity.comment!.message}}
    </div>

    <div class="card-footer">
      <span class="text-muted"><i class="fa-regular fa-clock"></i>&nbsp;{{activity.createdAt | timeago}}</span>
      <div class="float-end">
<!--        <button class="btn btn-small ps-2" hoverClass="btn-danger" notHoveredClass="btn-outline-dark" *ngIf="activity.user._id !== authedUser?._id && (!activity.resource || activity.comment)">Melden</button>-->
        <button class="btn btn-outline-danger btn-small" *ngIf="activity.comment && activity.user._id === authedUser?._id" (click)="deleteComment(activity.comment!)" i18n>Löschen</button>
        <button class="btn btn-outline-primary btn-small" *ngIf="authedUser && activity.comment" (click)="popupPostComment(activity)" i18n>Antworten</button>
      </div>
    </div>
  </div>

<!--  <div class="ms-4">-->
<!--    <ng-container *ngTemplateOutlet="comment"></ng-container>-->
<!--  </div>-->


  <div class="card ms-4" *ngFor="let subComment of activity.subComments; let i2 = index" [id]="(activities.length - i1) + '.' + (activity.subComments!.length - i2)">
    <div class="card-body pb-0">
      <span class="float-end text-muted" (click)="changeHash((activities.length - i1) + '.' + (activity.subComments!.length - i2))">#{{ activities.length - i1 }}.{{ activity.subComments!.length - i2 }}</span>
      <img class="rounded-circle avatar" [src]="subComment.user.avatar" [alt]="subComment.user.username">
      <div class="d-inline-block">
        <h6>
          <span style="color: #fff" routerLink="/u/{{subComment.user.username}}" [innerHTML]="subComment.user.display"></span>
        </h6>
        <p class="text-muted">{{subComment.user.role?.name || '&nbsp;'}}</p>
      </div><br>
    </div>

    <div class="card-body pt-0 ps-5">
      {{subComment.message}}
    </div>

    <div class="card-footer">
      <span class="text-muted"><i class="fa-regular fa-clock"></i>&nbsp;{{subComment.timestamp | timeago}}</span>
      <div class="float-end">
<!--        <button class="btn btn-small ps-2" hoverClass="btn-danger" notHoveredClass="btn-outline-dark" *ngIf="subComment.user._id !== authedUser?._id">Melden</button>-->
        <button class="btn btn-outline-danger btn-small" *ngIf="subComment.user._id === authedUser?._id" (click)="deleteComment(subComment!)" i18n>Löschen</button>
      </div>
    </div>
  </div>
</ng-container>

<div class="text-center mt-5" *ngIf="activities.length === 0">
  <i class="fa-solid fa-question text-muted" style="font-size: 12em"></i><br>
  <span class="text-muted" style="font-size: 2em" i18n>Leider noch nichts passiert...</span>
</div>


<ng-template #comment>
  <div class="card" *ngIf="authedUser">
    <div class="card-body" *blockUI="'commentArea'">
      <img class="rounded-circle" style="width: 3em; height: auto; margin-top: -2.5em; margin-right: 1em;" [src]="authedUser.avatar" [alt]="authedUser.username">
      <div class="d-inline-block">
        <h6>
          <span style="color: #fff" [innerHTML]="authedUser?.display"></span>
          <span class="ps-1" i18n>wird eine Nachricht schreiben...</span>
        </h6>
        <p class="text-muted ">{{authedUser.role?.name || '&nbsp;'}}</p>
      </div>

      <textarea class="form-control" rows="3" [(ngModel)]="commentInput" (keydown)="postCommentByKey($event)"></textarea>
      <div class="mt-2">
        <!--      <button class="btn btn-outline-dark text-muted"><i class="fa-solid fa-circle-plus"></i>&nbsp;<span>Bild hinzufügen</span></button>-->
        <div class="float-end"><button class="btn btn-success" [disabled]="!commentInput" (click)="postComment()" i18n>Absenden</button></div>
      </div>
    </div>
  </div>
</ng-template>
