<div class="card">
  <div class="card-header">
    <h5 i18n>Passwort ändern</h5>
  </div>
  <div class="card-body">
    <form [formGroup]="passwordGroup">
      <div class="row">
        <div class="col-12">
          <div class="alert alert-success" *ngIf="password_change.success" i18n>
            Du hast das passwort erfolgreich geändert.
          </div>
          <div class="alert alert-danger" *ngIf="password_change.error">
            {{password_change.error}}
          </div>
        </div>

        <div class="col-12">
          <label for="password" class="form-label mt-2" i18n>Aktuelles Passwort</label>
          <input type="password" autocomplete="current-password" [class.is-invalid]="password.invalid && (password_change.submitted || password.dirty || password.touched)" class="form-control" id="password" placeholder="password" formControlName="password" required>
          <div *ngIf="password.status === 'INVALID' && (password_change.submitted || password.dirty || password.touched)"
               class="invalid-feedback" style="display: block">
            <ng-container *ngIf="password.errors?.['required']" i18n>
              Passwort ist ein pflicht Feld.
            </ng-container>
          </div>
        </div>

        <div class="col-md-6">
          <label for="new_password" class="form-label mt-2" i18n>Neues Passwort</label>
          <input type="password" autocomplete="new-password" [class.is-invalid]="new_password.invalid && (password_change.submitted || new_password.dirty || new_password.touched)" class="form-control" id="new_password" placeholder="password" formControlName="new_password" required>
          <div *ngIf="new_password.status === 'INVALID' && (password_change.submitted || new_password.dirty || new_password.touched)"
               class="invalid-feedback" style="display: block">
            <ng-container *ngIf="new_password.errors?.['required']" i18n>
              Passwort ist ein pflicht Feld.
            </ng-container>
            <ng-container *ngIf="new_password.errors?.['too_weak'] || new_password.errors?.['weak']" i18n>
              Das Passwort ist zu leicht.
            </ng-container>
          </div>
        </div>
        <div class="col-md-6">
          <label for="new_password1" class="form-label mt-2" i18n>Neues Passwort wiederholen</label>
          <input type="password" autocomplete="new-password" [class.is-invalid]="new_password1.invalid && (password_change.submitted || new_password1.dirty || new_password1.touched)" class="form-control" id="new_password1" placeholder="password" formControlName="new_password1" required>
          <div *ngIf="new_password1.status === 'INVALID' && (password_change.submitted || new_password1.dirty || new_password1.touched)"
               class="invalid-feedback" style="display: block">
            <ng-container *ngIf="new_password1.errors?.['required']" i18n>
              Passwort ist ein pflicht Feld.
            </ng-container>
            <ng-container *ngIf="new_password1.errors?.['password_missMatch']" i18n>
              Die Passwörter stimmen nicht überein.
            </ng-container>
          </div>
        </div>

        <div class="col-12 mt-1">
          <div class="progress">
            <ng-container *ngIf="password_change.submitted || new_password.dirty || new_password.touched">
              <div class="progress-bar bg-danger" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" *ngIf="password_change.password_strength >= 0"></div>
              <div class="progress-bar bg-warning" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" *ngIf="password_change.password_strength >= 1"></div>
              <div class="progress-bar bg-primary" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" *ngIf="password_change.password_strength >= 2"></div>
              <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" *ngIf="password_change.password_strength >= 3"></div>
            </ng-container>
          </div>
        </div>

        <div class="col-12 text-center mt-2"><button class="btn btn-success" type="submit" (click)="updatePassword()" [disabled]="passwordGroup.disabled" i18n>Password ändern</button></div>
      </div>
    </form>
  </div>
</div>

<div class="card">
  <div class="card-header">
    <h5 i18n>Zweifaktor Authentifizierung</h5>
  </div>
  <div class="card-body text-center">
    <button class="btn btn-outline-primary me-1 disabled" (click)="newChallengeProvider('PHONE')" [hidden]="true"><i class="fa-solid fa-mobile-screen"></i>&nbsp;<span i18n>Handy hinzufügen</span></button>
    <button class="btn btn-primary me-1" (click)="newChallengeProvider('OTP')"><i class="fa-solid fa-lock"></i>&nbsp;<span i18n>OTP hinzufügen</span></button>
    <button class="btn btn-primary" (click)="newChallengeProvider('FIDO')" *ngIf="FIDOSupported"><i class="fa-solid fa-key-skeleton"></i>&nbsp;<span i18n>FIDO hinzufügen</span></button>
    <button class="btn btn-secondary disabled" *ngIf="!FIDOSupported"><i class="fa-solid fa-key-skeleton"></i>&nbsp;<span i18n>U2F hinzufügen</span></button>
  </div>
  <div class="table-responsive">
    <table class="card-footer table m-0">
      <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col" i18n>Name</th>
        <th scope="col" i18n>Hinzugefügt</th>
        <th scope="col" i18n>Zuletzt benutzt</th>
        <th class="text-center" scope="col" i18n>Eingeschaltet</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>

<!--                    <tr>-->
<!--                      <th class="text-center" scope="row">-->
<!--                        <i class="fa-solid fa-envelope"></i>-->
<!--                      </th>-->
<!--                      <td>EMAIL</td>-->
<!--                      <td></td>-->
<!--                      &lt;!&ndash;                <td [title]="challenge.last_used"><i class="fa-regular fa-clock me-2"></i>{{challenge.last_used | timeago}}</td>&ndash;&gt;-->
<!--                      <td class="text-center">-->
<!--                        <i class="fa-regular fa-check text-success"></i>-->
<!--                      </td>-->
<!--                      <td></td>-->
<!--                    </tr>-->

      <tr *ngFor="let challenge of getChallenges">
        <th class="text-center" scope="row">
          <i class="fa-solid fa-mobile-screen" *ngIf="challenge.provider === 'PHONE'"></i>
          <i class="fa-solid fa-lock" *ngIf="challenge.provider === 'OTP'"></i>
          <i class="fa-solid fa-key-skeleton" *ngIf="challenge.provider === 'FIDO'"></i>
        </th>
        <td>{{challenge.name || challenge.provider}}</td>
        <td [title]="challenge.timestamp"><i class="fa-regular fa-clock me-2"></i>{{challenge.timestamp | timeago}}</td>

        <td [title]="challenge.last_used" *ngIf="challenge.last_used"><i class="fa-regular fa-clock me-2"></i>{{challenge.last_used | timeago}}</td>
        <td *ngIf="!challenge.last_used" i18n>Niemals benutzt</td>

        <td class="text-center">
          <i class="fa-solid fa-check-square text-success" (click)="lockChallengeProvider(challenge)" *ngIf="!challenge.disabled"></i>
          <i class="fa-solid fa-times-square text-danger" (click)="unlockChallengeProvider(challenge)" *ngIf="challenge.disabled"></i>
        </td>
        <td class="text-center">
          <i class="fa-regular fa-pencil" (click)="renameChallengeProvider(challenge)"></i>
          &nbsp;<i class="fa-regular fa-trash-alt text-danger" (click)="deleteChallengeProvider(challenge)"></i>
        </td>
      </tr>

      <tr *ngIf="challenges.length === 0"><td colspan="4" class="text-center text-danger" i18n>Du hast noch keine Zweifaktor Authentifizierung hinzugefügt.</td></tr>
      </tbody>
    </table>
  </div>
</div>


<div class="card">
  <div class="card-header">
    <h5><span i18n>Wiederherstellungs Codes</span><span class="float-end"><button class="btn btn-outline-danger btn-small" (click)="deleteCodes()" i18n>Codes zurücksetzten</button></span></h5>
  </div>

  <div class="card-body row" *ngIf="!backup_codes_hidden">
    <h3 class="col-6" [class.text-muted]="entry.used" [class.text-end]="(i + 1)  % 2 !== 0" [ngStyle]="entry.used ? {'text-decoration': 'line-through'} : null" ngxClipboard [cbContent]="entry.code" (cbOnSuccess)="copied2Clipboard()" *ngFor="let entry of backup_codes; let i = index">{{entry.code}}</h3>

    <div class="mt-2 text-center"><button class="btn btn-success" (click)="backup_codes_hidden = true;" i18n>Verstecken</button></div>
  </div>

  <div class="card-body text-center" *ngIf="backup_codes_hidden">
    <i class="fa-solid fa-eye fa-5x"></i>
    <div i18n>Wiederherstellungs Codes sind zum schutz versteckt.</div>
    <div class="mt-2"><button class="btn btn-outline-warning" (click)="backup_codes_hidden = false;" i18n>Zeigen</button></div>
  </div>
</div>

<div class="card">
  <div class="card-header">
    <h5><span i18n>Aktive Sitzungen</span><span class="float-end"><button class="btn btn-outline-danger btn-small" (click)="deleteSessions()" i18n>Alle Sitzungen beenden</button></span></h5>
  </div>

  <div class="table-responsive">
    <table class="card-footer table m-0">
      <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col" i18n>Adresse</th>
        <th scope="col" i18n>Browser</th>
        <th scope="col" i18n>OS</th>
        <!--              <th scope="col" i18n>Letzte Aktion</th>-->
        <th scope="col" i18n>Angemeldet am</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let session of getSessions">
        <th class="text-center" scope="row">
          <i class="fa-solid fa-check text-success" *ngIf="session.valid"></i>
          <i class="fa-solid fa-times text-danger" *ngIf="!session.valid"></i>&nbsp;

          <i class="fa-solid fa-key"  i18n-title title="Username & Passwort" *ngIf="session.level === 'USERNAME_PASSWORD'"></i>
          <i class="fa-solid fa-cookie"  i18n-title title="LongLife-Token" *ngIf="session.level === 'LONG-LIFE_TOKEN'"></i>
          <i class="fa-solid fa-cube"  i18n-title title="Minecraft" *ngIf="session.level === 'MINECRAFT'"></i>
        </th>
        <td>{{session.address}}</td>
        <td [title]="session.details.userAgent.browser.version">
          <i class="fa-brands fa-chrome" *ngIf="session.details.userAgent.browser.name === 'Chrome'"></i>
          <i class="fa-brands fa-safari" *ngIf="session.details.userAgent.browser.name === 'Safari'"></i>
          &nbsp;{{session.details.userAgent.browser.name}}
        </td>
        <td [title]="session.details.userAgent.os.version">
          <i class="fa-brands fa-windows" *ngIf="session.details.userAgent.os.name === 'Windows'"></i>
          <i class="fa-brands fa-apple" *ngIf="session.details.userAgent.os.name && ['macOS','iPhone'].includes(session.details.userAgent.os.name)"></i>
          &nbsp;{{session.details.userAgent.os.name}} {{session.details.userAgent.os.versionName}}
        </td>
        <!--              <td [title]="session.last_action">{{session.last_action | timeago}}</td>-->
        <td [title]="session.timestamp">{{session.timestamp | timeago}}</td>
        <td class="text-center"><i class="fa-regular fa-trash-alt text-danger" [class.text-muted]="currentSessionId === session._id" (click)="deleteSession(session)" *ngIf="session.valid"></i></td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
