import {Component, OnDestroy, OnInit} from '@angular/core';
import {Cosmetics, cosmeticsTemplates, cosmeticsValues, localizeCosmetic} from "../interfaces/player-cosmetics";
import {Editor, toDoc, Toolbar} from "ngx-editor";
import {NgxFileDropEntry} from "ngx-file-drop";
import {environment} from "../../environments/environment";
import {HttpClient, HttpEventType} from "@angular/common/http";
import FastAverageColor from "fast-average-color";
import {StateChange} from "ng-lazyload-image";
import {ResourceRequest} from "../resource/resource.service";
import Swal, {SweetAlertIcon} from "sweetalert2";
import {ViewportScroller} from "@angular/common";
import {Router} from "@angular/router";

export type State = 'WORKSPACE' | 'TYPES' | 'DETAILS' | 'FINAL';

export interface WorkspaceFileRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly file: WorkspaceFile
  };
}
export interface WorkspaceFilesRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly files: WorkspaceFile[]
  };
}
export interface WorkspaceFile {
  readonly _id: string;
  name: string;
  readonly timestamp: string;
  readonly file: WFile;
}

export interface WFile {
  readonly _id: string;
  readonly name: string;
  readonly mime: string;
  readonly size: number;
  readonly width: number;
  readonly height: number;
  readonly hash: string;
  readonly suggestions: {
    readonly colors: string[];
    readonly possible: {
      readonly mode: 'HARD' | 'FLAT' | 'SOFT';
      readonly types: Cosmetics[];
    };
    readonly tags: {
      name: string;
      match: number;
    }[];
  };

  readonly image: string;
}

export interface InternalFile {
  name: string;
  relativePath: string;
  file: File;
  progress: number;
  primary: boolean;
  done: boolean;
  error: string | undefined;
}

@Component({
  selector: 'app-creator-publisher',
  templateUrl: './creator-publisher.component.html',
  styleUrls: ['./creator-publisher.component.css']
})
export class CreatorPublisherComponent implements OnInit, OnDestroy {
  state: State = "WORKSPACE";

  workspaceFiles: WorkspaceFile[] = []
  selectedWorkspaceFile: WorkspaceFile | undefined = undefined;


  // TODO: API Requested Details
  types: { _id: Cosmetics, name: string, image: string }[] = cosmeticsValues.map(cosmetic => { return { _id: cosmetic, name: localizeCosmetic(cosmetic), image: cosmeticsTemplates[cosmetic]} as { _id: Cosmetics, name: string, image: string }});
  selectedType: Cosmetics | undefined = undefined;


  name?: string;
  isPublic: boolean = true;

  tags: string[] = [];
  tagsSuggestions: any[] = [];

  descEditor: Editor = new Editor();
  descToolbar: Toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    ['link', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    ['text_color', 'background_color'],
    ['format_clear']
  ];
  desc: string = '';

  variables: { [key: string]: string } = {};


  cosmeticPublished: boolean = false;

  constructor(
    private http: HttpClient,
    private viewport: ViewportScroller,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.http.get<WorkspaceFilesRequest>(environment.apiServer + 'users/@me/workspace').subscribe(data => this.workspaceFiles = data.data.files);
  }

  ngOnDestroy() {
    this.descEditor.destroy();
  }

  switchTo(state: State) {
    console.log(this.selectedWorkspaceFile)
    switch (state) {
      case "TYPES":
        if (!this.selectedWorkspaceFile)
          return;
        break;
      case "DETAILS":
        if (!this.selectedType)
          return;

        this.variables = {};
        switch (this.selectedType) {
          case "knight-shields":
          case "round-shields":
            this.variables['shield'] = '#595959';
            this.variables['border'] = '#595959';
            this.variables['handle'] = '#595959';
        }

        this.tagsSuggestions = this.selectedWorkspaceFile!.file.suggestions.tags.map(a => { return { id: a.name, name: a.name + ' (' + a.match + ' %)'}});
        break;
    }

    // window.scroll(0,0);
    this.viewport.scrollToPosition([0,0]);
    this.state = state;
  }

  get getWorkspaceFiles(): WorkspaceFile[] {
    return this.workspaceFiles.sort((a,b) => new Date(b.timestamp!).valueOf() - new Date(a.timestamp!).valueOf());
  }

  files: InternalFile[] = [];
  uploadingFiles: boolean = false;
  droppedFiles(files: NgxFileDropEntry[]) {
    if (this.uploadingFiles)
      return;

    if (files.length >= 15) {
      this.showAlert($localize`Du kannst leider nur 15 Bilder gleichzeitig hochladen!`);
      return;
    }

    if (files.some(a => a.fileEntry.isDirectory)) {
      this.showAlert($localize`Du kannst leider keine Ordner hochladen!`);
      return;
    }

    this.resetFileUploader();
    files.filter(a => a.fileEntry.isFile).map(a => {
      const { name, file } = a.fileEntry as FileSystemFileEntry;
      file(b => {
        if (!['image/png','image/gif'].includes(b.type))
          return;

        this.files.push({
          name,
          relativePath: a.relativePath,
          file: b,
          progress: 0,
          primary: false,
          done: false,
          error: undefined
        });
      });
    });

    if (this.files.length === 1)
      this.uploadFiles();
  }

  uploadFiles() {
    if (this.uploadingFiles)
      return;

    Swal.fire({
      title: $localize`Upload Regeln`,
      html: $localize`Mit dem Hochladen deiner Textur akzeptierst du automatisch die untenstehenden Regeln und dass du diese befolgst. Ein Design darf folgendes nicht beinhalten:<br><br>
  <b>Pornografie</b><br>
  Texturen, welche ein klares Ziel haben, den Betrachter sexuell zu erregen, d. h. Pornografie, Hentai, Models, etc.<br><br>
  <b>Radikaler Extremismus</b><br>
  Texturen, welche diese Ideologien repräsentieren und verbreiten, z.B. Hakenkreuze, Bilder von Hitler, etc.<br><br>
  <b>Inakzeptabler Text</b><br>
  Text, welcher beabsichtigt, eine Person oder Gruppe zu erniedrigen oder anzugreifen, d.h. Handeln, Beleidigungen, entwürdigendes Verhalten usw.<br><br>
  <b>Nicht lustig</b><br>
  Texturen, die "schwarzen Humor" enthalten, d. h. eine Verletzung der Menschenrechte, Körperverletzung usw. zeigen.<br><br>
  <b>Bilder von anderen Menschen</b><br>
  Bilder von anderen Menschen dessen Eingeständnis du nicht bekommen hast.<br><br>
  <b>Urhebergeschütze Bilder</b><br>
  Bilder von anderen Urwerke an denen du keine Rechte besitzt.<br><br>
  <b>Lunar & Feather Client Cosmetics</b><br>
  Lunar & Feather Client verbietet strickt das ihre Designs in keinen anderen Mods/Clients oder Webseiten zu finden sind, daher sind diese bei uns leider nicht erlaubt.`,
      footer: '<span class="text-muted">' + $localize`Das verletzten von einer diesen Regeln führt zu einer Verwarnung oder zu einer Kontoschließung!<br>Das Night.design-Team behält sich vor, ohne angegebene gründe Cosmetics zu entfernen.` + '</span>',

      showCancelButton: true,
      confirmButtonColor: '#198754',
      confirmButtonText: $localize`Akzeptieren`,
      cancelButtonColor: '#dc3545',
      cancelButtonText: $localize`Ablehnen`,

      width: '50em',
    }).then(value => {
      if (!value.isConfirmed) {
        this.files = [];
        return;
      }

      this.uploadingFiles = true;

      for (let file of this.files) {
        if (file.done)
          continue;

        const formData = new FormData();
        formData.append("file", file.file, file.name);

        this.http.post(environment.apiServer + 'users/@me/workspace', formData, {
          reportProgress: true,
          observe: 'events',
          params: {
            noDefError: 1
          }
        }).subscribe(event => {
          if (event.type == HttpEventType.UploadProgress)
            file.progress = Math.round(100 * (event.loaded / event.total!));

          if (event.type != HttpEventType.Response)
            return;

          file.done = true;

          if (!this.files.some(a => !a.done))
            this.uploadingFiles = false;

          const body = event.body as WorkspaceFileRequest;
          this.workspaceFiles.push(body.data.file);
        }, error => {

          let errorMsg = '';
          if (error.error instanceof ErrorEvent)
            errorMsg = `Error: ${error.error.message}`;
          else
            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;

          if ('message' in error.error) {
            const { status, message } = error.error;
            errorMsg = `ERROR[${status}]: ${message}`;
          }

          file.progress = -1;
          file.error = errorMsg;
          console.log(errorMsg);
        });
      }
    });
  }

  removeFile(file: InternalFile) {
    this.files = this.files.filter(a => a !== file);
  }

  resetFileUploader() {
    this.files = [];
    this.uploadingFiles = false;
  }

  isUploaded(file: InternalFile) {
    return file.progress >= 95;
  }

  patchWorkspaceFile(file: WorkspaceFile) {
    Swal.fire({
      icon: "question",
      title: $localize`Datei umbenennen`,

      input: "text",
      inputValue: file.name,

      showCancelButton: true,
    }).then(result => {
      if (!result.isConfirmed)
        return;

      this.http.patch<WorkspaceFilesRequest>(environment.apiServer + 'users/@me/workspace/' + file._id, { name: result.value }).subscribe(() => file.name = result.value);
    });
  }

  deleteWorkspaceFile(file: WorkspaceFile) {
    Swal.fire({
      icon: "warning",

      title: $localize`Achtung!`,
      text: $localize`Du bist dabei ${file.name} zu löschen!`,
      footer: "<span class=\"text-danger\">" + $localize`Achtung, diese Aktion ist unwiderruflich!` + "</span>",

      showDenyButton: true,
    }).then(result => {
      if (!result.isConfirmed)
        return;

      this.http.delete<WorkspaceFilesRequest>(environment.apiServer + 'users/@me/workspace/' + file._id).subscribe(() => this.workspaceFiles = this.workspaceFiles.filter(a => a !== file));
    })
  }

  private fac = new FastAverageColor();
  getBackgroundColor($event: StateChange, file: WorkspaceFile) {
    if ($event.reason !== "loading-succeeded")
      return;

    const element = document.getElementById(file._id);
    if (!element)
      return;

    this.fac.getColorAsync(file.file.image, { crossOrigin: 'night.design' }).then((result) => {
      if (result.isLight)
        element.classList.add('card-img-top-inverted');
    });
  }

  matchingTypes(match: boolean) {
    return this.types.filter(a => this.selectedWorkspaceFile?.file.suggestions.possible.types.includes(a._id) === match);
  }

  warningMissMatchType(type: Cosmetics) {
    if (this.selectedWorkspaceFile?.file.suggestions.possible.mode === 'SOFT') {
      this.selectedType = type;
      this.switchTo('DETAILS');
    }

    Swal.fire({
      icon: "warning",
      title: $localize`Achtung!`,
      text: $localize`Bist du dir sicher das es ein ${type} ist?`,
      showCancelButton: true,
    }).then(result => {
      if (!result.isConfirmed)
        return;

      this.selectedType = type;
      this.switchTo('DETAILS');
    })
  }

  showAlert(title: string, icon: SweetAlertIcon= 'error') {
    Swal.fire({
      icon,
      title,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    }).then();
  }

  addTag(text: string) {
    return { id: text, name: text };
  }
  pushTag(id: string) {
    if (!this.tags.some(a => a.toLowerCase() === id.toLowerCase()))
      this.tags.push(id);
  }

  publishResource($event: MouseEvent) {
    if (!this.name)
      return this.showAlert($localize`Der Name darf nicht leer sein!`);

    if (this.tags.length < 2)
      return this.showAlert($localize`Bitte füge mindestens 2 Tags hinzu damit deine Cosmetic besser findbar ist!`);

    if (this.cosmeticPublished)
      return;

    if (this.selectedWorkspaceFile?.file.suggestions.possible.mode !== "SOFT" && !this.selectedWorkspaceFile?.file.suggestions.possible.types.includes(this.selectedType!)) {
      Swal.fire({
        icon: "question",

        html: $localize`Unser System hat erkannt das dein Cosmetic nicht die alle Anforderungen von einem ${this.selectedType} entspricht.<br>Soll unser System versuchen dein Cosmetic zu korrigieren?`,

        showDenyButton: true,
        showCancelButton: true,
      }).then(result => {
        if (result.isDismissed)
          return;

        this.publishResource_($event.ctrlKey, result.isConfirmed);
      });
      return;
    }

    this.publishResource_($event.ctrlKey);
  }

  publishResource_(redirect: boolean, autoPatch: boolean = false) {
    this.cosmeticPublished = true;
    const file = this.selectedWorkspaceFile!;

    this.http.post<ResourceRequest>(environment.apiServer + 'users/@me/workspace/' + file._id,{
      type: this.selectedType,
      name: this.name,
      description: JSON.stringify(toDoc(this.desc)),
      tags: this.tags,
      unlisted: !this.isPublic,
      variables: this.variables,
      autoPatch
    }).subscribe(request => {
      this.workspaceFiles = this.workspaceFiles.filter(a => a !== file);

      this.name = '';
      this.isPublic = true;
      this.tags = [];
      this.tagsSuggestions = [];
      this.desc = '';
      this.cosmeticPublished = false;
      this.selectedType = undefined;
      this.selectedWorkspaceFile = undefined;
      this.variables = {};
      this.switchTo('WORKSPACE');
      this.resetFileUploader();

      if (redirect) {
        this.router.navigate(['/resources/' + request.data.resource._id]).then();
        return;
      }

      Swal.fire({
        icon: "success",

        text: $localize`Deinen hochgeladenen Cosmetic ist ab nun verfügbar, weitere versionen werden bereits von unserem System generiert.`,

        confirmButtonText: $localize`Anzeigen`,
        showCancelButton: true,
      }).then(result => {
        if (!result.isConfirmed)
          return;

        this.router.navigate(['/resources/' + request.data.resource._id]).then();
      });
    });
  }
}
