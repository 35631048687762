import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {isPlatformBrowser} from "@angular/common";
import Highcharts, {Options, YAxisOptions} from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/export-data";
import {Server, ServerViewerComponent} from "../server-viewer.component";
import {SimpleRequest} from "../../services/simple-request";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {DateTime} from "luxon";

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class ServerStatisticsComponent implements OnInit {
  isBrowser: boolean;
  server!: Server;

  Highcharts: typeof Highcharts = Highcharts;
  updateFlag = false;

  players: Options ={
    title: {
      text: $localize`Server Statistiken`
    },

    xAxis: {
      type: "datetime"
    },

    yAxis: {
      title: {
        text: null
      },
      plotLines: []
    },

    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom'
    },

    credits: {
      enabled: true,
      text: 'Night.design',
      href: 'https://night.design'
    },

    series: []
  };

  updateFlag_onlineOffline = false;
  onlineOffline: Options ={
    title: {
      text: $localize`Server Online/Offline`
    },

    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}%</b>'
    },

    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },

    credits: {
      enabled: true,
      text: 'Night.design',
      href: 'https://night.design'
    },

    series: []
  };


  constructor(
    private router: Router,
    private http: HttpClient,
    @Inject(ServerViewerComponent) private parent: ServerViewerComponent,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      HighchartsMore(Highcharts);
      exporting(Highcharts);
      offlineExporting(Highcharts);
    }
  }

  ngOnInit(): void {
    this.server = this.parent.server!;

    this.http.get<SimpleRequest<{ players: PlayerStatistic[] }>>(environment.apiServer + 'servers/' + this.server._id + '/statistics/players').subscribe(response => {
      const stats = response.data.players.sort((a,b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

      const last = stats[stats.length - 1];
      if (last && last.max !== 0) {
        (this.players.yAxis as YAxisOptions).plotLines?.push({
          value: last.max,
          color: 'red',
          width: 1,
          label: {
            text: $localize`Maximale Spieler Anzahl`,
            align: 'center',
            style: {
              color: 'gray'
            }
          }
        });
      }

      this.players.series?.push({
        type: "line",
        name: $localize`Spieler Online`,
        pointInterval: 1000 * 60 * 5,
        color: '#00ff00',
        data: stats.map(data => { return { x: new Date(data.timestamp).getTime(), y: data.online }})
      });

      this.players.series?.push({
        type: "line",
        name: $localize`Server Ping`,
        pointInterval: 1000 * 60 * 5,
        color: '#CC5500',
        data: stats.map(data => { return { x: new Date(data.timestamp).getTime(), y: data.ping }})
      });
      this.updateFlag = true;
    });

    this.http.get<SimpleRequest<{ uptime: {  up: number, down: number } }>>(environment.apiServer + 'servers/' + this.server._id + '/statistics/uptime').subscribe(response => {
      const { up, down } = response.data.uptime;
      this.onlineOffline.series?.push({
        type: "pie",
        data: [
          {
            name: $localize`Online`,
            y: up,
            color: '#00ff00'
          },
          {
            name: $localize`Offline`,
            y: down,
            color: '#880808'
          },
        ]
      })
      this.updateFlag_onlineOffline = true;
    });
  }

  fixDateRange(data: {x: number, y: number}[]): {x: number, y: number}[] {
    const date = DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    for (let i = 0; i < 30; i++) {
      const timestamp = date.minus({ day: i });
      const millis = timestamp.toMillis();

      if (DateTime.fromISO(this.server!.timestamp).toMillis() > millis)
        break;

      if (data.some(value => value.x === millis))
        continue;

      data.push({ x: timestamp.toLocal().toMillis(), y: 0 });
    }

    return data.sort((a, b) => a.x - b.x);
  }
}


export interface PlayerStatistic {
  readonly _id: string;
  readonly ping: number;
  readonly online: number;
  readonly max: number;
  readonly timestamp: string;
}
