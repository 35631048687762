import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Party} from "../../modder/modder.component";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import Highcharts, {Options} from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/export-data";
import {DateTime} from "luxon";
import {cosmeticsValues, localizeCosmetic} from "../../interfaces/player-cosmetics";
import {ModderViewerComponent, StatisticsRequest} from "../modder-viewer.component";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html'
})
export class StatisticsComponent implements OnInit {
  isBrowser = false;
  party!: Party;

  Highcharts: typeof Highcharts = Highcharts;
  updateFlag = false;

  userCustomUploads: Options ={
    title: {
      text: $localize`Personalisierte Cosmetics`
    },

    xAxis: {
      type: "datetime"
    },

    yAxis: {
      title: {
        text: $localize`Uploads`
      }
    },

    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom'
    },

    credits: {
      enabled: true,
      text: 'Night.design',
      href: 'https://night.design'
    },

    series: []
  };


  constructor(
    private http: HttpClient,
    @Inject(ModderViewerComponent) private parent: ModderViewerComponent,
  @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      HighchartsMore(Highcharts);
      exporting(Highcharts);
      offlineExporting(Highcharts);
    }
  }

  ngOnInit(): void {
    this.party = this.parent.party;

    this.http.get<StatisticsRequest>(environment.apiServer + 'mods/' + this.party._id + '/statistics/custom-uploads').subscribe(request => {
      for (let cosmetic of cosmeticsValues) {
        const data = request.data.dataset.filter(data => data.type === cosmetic);
        if (data.length === 0)
          continue;

        this.userCustomUploads.series?.push({
          type: "line",
          name: localizeCosmetic(cosmetic),
          data: this.fixDateRange(data.map(data => { return { x: new Date(data.date).getTime(), y: data.total }}))
        });
      }

      this.updateFlag = true;
    });
  }


  fixDateRange(data: {x: number, y: number}[]): {x: number, y: number}[] {
    const date = DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    for (let i = 0; i < 30; i++) {
      const timestamp = date.minus({ day: i });
      const millis = timestamp.toMillis();

      if (DateTime.fromISO(this.party!.timestamp).toMillis() > millis)
        break;

      if (data.some(value => value.x === millis))
        continue;

      data.push({ x: millis, y: 0 });
    }

    return data.sort((a, b) => a.x - b.x);
  }
}
