<ng-container *ngIf="server">
  <div class="page-header">
    <div class="page-block">
      <div class="row">
        <div class="col-md-8">
          <div class="page-header-title">
            <h5 class="m-b-10">{{server.domain | titlecase}}, {{pageName}}</h5>
          </div>
        </div>
        <div class="col-md-4">
          <ul class="breadcrumb justify-content-end">
            <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
            <li class="breadcrumb-item"><a routerLink="/servers" i18n>Server</a></li>
            <li class="breadcrumb-item">{{server.domain}}</li>
            <li class="breadcrumb-item">{{pageName}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="page-content">
    <div class="row">
      <div class="col-md-9">
        <div class="alert alert-primary" *ngIf="!server.user">
          <b>Hey, ist das hier dein Server?</b><br>
          Dein Server kann hier viel mehr Potential zeigen!
        </div>


        <div class="row mb-3">
          <div class="col-md-4 d-grid"><button class="btn btn-primary" routerLinkActive="btn-info" routerLink="/server/{{server.domain}}/details" i18n>Beschreibung</button></div>
          <div class="col-md-4 d-grid"><button class="btn btn-primary" routerLinkActive="btn-info" routerLink="/server/{{server.domain}}/statistics" i18n>Statistiken</button></div>
          <div class="col-md-4 d-grid"><button class="btn btn-primary" routerLinkActive="btn-info" routerLink="/server/{{server.domain}}/vote" i18n>Voten</button></div>
        </div>

        <ng-container *ngIf="server">
          <router-outlet></router-outlet>
        </ng-container>
      </div>

      <div class="col-md-3">
        <div class="card">
          <div class="card-body text-center">
            <img errorImage="/assets/img/logo-icon-grey.webp" defaultImage="/assets/img/loading-preview.gif" class="rounded bg-body" style="width: 50%; height: auto;" [lazyLoad]="server.statistic && server.statistic.favicon ? server.statistic!.favicon.image : '/assets/img/logo-icon-grey.webp'">
          </div>
          <ng-container *ngIf="server.statistic">
            <div class="card-body">
              <div>
                <span i18n>Status:</span>
                <span class="text-success float-end" *ngIf="server.statistic && !server.statistic.offline" i18n>Online</span>
                <span class="text-danger float-end" *ngIf="!server.statistic || server.statistic.offline" i18n>Offline</span>
              </div>
              <div>
                <span i18n>Spieler:</span>
                <span class="float-end" *ngIf="server.statistic && !server.statistic.offline"><b>{{server.statistic.players.online}}</b><span class="text-muted">/{{server.statistic.players.max}}</span></span>
                <span class="text-danger float-end" *ngIf="!server.statistic || server.statistic.offline" i18n>Server is currently Offline</span>
              </div>
              <div>
                <span i18n>Version:</span>
                <span class="float-end" *ngIf="server.statistic && !server.statistic.offline">{{server.statistic.version.name}} ({{server.statistic.version.protocol}})</span>
                <span class="text-danger float-end" *ngIf="!server.statistic || server.statistic.offline" i18n>Server is currently Offline</span>
              </div>
            </div>
            <div class="card-footer" *ngIf="server.socials.website">
              <i class="fa-solid fa-globe"></i>&nbsp;<span i18n>Webseite:</span>
              <a class="float-end" [href]="server.socials.website" target="_blank">{{server.socials.website | host}}</a>
            </div>
            <div class="card-footer" *ngIf="server.socials.store">
              <i class="fa-solid fa-shop"></i>&nbsp;<span i18n>Shop:</span>
              <a class="float-end" [href]="server.socials.store" target="_blank">{{server.socials.store | host}}</a>
            </div>
            <div class="card-footer" *ngIf="server.socials.teamspeak">
              <i class="fa-brands fa-teamspeak"></i>&nbsp;<span i18n>TeamSpeak:</span>
              <a class="float-end" [href]="'ts3server://' + server.socials.teamspeak | bypassSecurityTrustUrl">ts3server://{{server.socials.teamspeak}}</a>
            </div>
            <div class="card-footer" *ngIf="server.socials.discord">
              <i class="fa-brands fa-discord"></i>&nbsp;<span i18n>Discord:</span>
              <a class="float-end" [href]="server.socials.discord" target="_blank">{{server.socials.discord | host}}</a>
            </div>
          </ng-container>
        </div>

        <div class="server-address mb-4" ngxClipboard [cbContent]="server.domain" (cbOnSuccess)="copied2Clipboard()" i18n>Server Adresse: <b>{{server.domain | titlecase}}</b></div>

        <div class="d-grid mb-3">
          <button class="btn btn-success" routerLink="/server/{{server.domain}}/vote">Voten</button>
        </div>

        <reportButton type="server" [target]="server" class="d-grid"></reportButton>
      </div>
    </div>

    <div class="divider" i18n>Server Banner</div>
    <div class="row">
      <div class="col-md-4" *ngFor="let banner of serverBanner">
        <div class="card">
          <div class="card-body">
            <img errorImage="/assets/img/error-server.webp" defaultImage="/assets/img/loading-server.gif" class="img-fluid rounded bg-body mb-3" [lazyLoad]="banner.url" ngxClipboard [cbContent]="banner.url" (cbOnSuccess)="copied2Clipboard(true)">
            <pre class="server-address m-0 mb-2" ngxClipboard [cbContent]="getShareCode(banner).bbcode" (cbOnSuccess)="copied2Clipboard(true)" [textContent]="getShareCode(banner).bbcode"></pre>
            <pre class="server-address m-0" ngxClipboard [cbContent]="getShareCode(banner).html" (cbOnSuccess)="copied2Clipboard(true)" [textContent]="getShareCode(banner).html"></pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!server">
  <div class="page-header">
    <div class="page-block">
      <div class="row">
        <div class="col-md-8">
          <div class="page-header-title">
            <h5 class="m-b-10"><i class="fa-duotone fa-loader fa-spin-pulse"></i>, {{pageName}}</h5>
          </div>
        </div>
        <div class="col-md-4">
          <ul class="breadcrumb justify-content-end">
            <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
            <li class="breadcrumb-item"><a routerLink="/servers" i18n>Server</a></li>
            <li class="breadcrumb-item"><i class="fa-duotone fa-loader fa-spin-pulse"></i></li>
            <li class="breadcrumb-item">{{pageName}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="page-content">
    <div class="text-center text-muted pt-5" style="font-size: 6em;">
      <i class="fa-duotone fa-loader fa-spin-pulse"></i>
      <h1 i18n>Lädt...</h1>
    </div>
  </div>
</ng-container>
