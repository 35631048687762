import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {pageType} from "../../services/comments.service";
import {isPlatformBrowser} from "@angular/common";
import {environment} from "../../../environments/environment";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'ADPlacement',
  templateUrl: './ads.component.html',
  styles: [
    '.adsbygoogle { flex: 1 }'
  ]
})
export class AdsComponent implements OnInit {
  isBrowser = false;
  isAuthorized = AuthService.isAuthorized();
  developmentMode = !environment.production;

  @Input()
  type!: "top-banner" | "bottom-banner" | "article-banner" | "comment-banner" | "side-banner";

  @Input()
  managedByGoogle = false;

  @Input()
  context?: any;
  @Input()
  pageType?: pageType;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
  }
}
