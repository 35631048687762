import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output} from '@angular/core';
import {User} from "../../user/user.service";
import {Resource} from "../../resource/resource.service";
import FastAverageColor from "fast-average-color";
import {StateChange} from "ng-lazyload-image";
import {ActivatedRoute, Router} from "@angular/router";
import {ResourcesRequest, ResourcesService, SearchData, Statistics} from "../../resources/resources.service";
import {Location, ViewportScroller} from "@angular/common";
import {cosmeticsValues, localizeCosmetic} from "../../interfaces/player-cosmetics";
import {gtag} from "../../app.component";
import {WindowService} from "../../window.service";

@Component({
  selector: 'resources-search',
  templateUrl: './resources-search.component.html',
  styleUrls: ['./resources-search.component.css']
})
export class ResourcesSearchComponent implements OnInit {
  @Input()
  user?: User;
  @Input()
  forceMobileSettingsPage?: boolean = false;

  @Output()
  onSearchChange = new EventEmitter<Resource[]>()

  private timeout: number = 0;
  showMoreSettings: boolean = false;

  q: string = '';
  types: string[] = [];
  order: string = 'name:asc';
  page: number = 0;

  typeOptions = cosmeticsValues.filter(cosmetic => cosmetic !== 'hd-skins').map(cosmetic => { return { name: localizeCosmetic(cosmetic), value: cosmetic, checked: false}});
  statistics: Statistics = { total: 0, pages: 0 };
  resources: Resource[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private location: Location,
    private viewportScroller: ViewportScroller,
    @Inject(LOCALE_ID) public locale: string,
    private window: WindowService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params =>  {
      this.cache = params;
      let data = {
        q: '',
        types: [],
        sort: 'name:asc',
        page: 1
      };

      if ('q' in params)
        data.q = params['q'];

      if ('types' in params)
        data.types = params['types'].split(',');

      if ('order' in params)
        data.sort = params['order'];

      if ('page' in params)
        data.page = params['page'];

      this.q = data.q;
      this.types = data.types;
      this.order = data.sort;
      this.page = --data.page;

      for (let typeOption of this.typeOptions) {
        if (this.types.includes(typeOption.value))
          typeOption.checked = true;
      }

      if (this.resources.length === 0)
        this.search(data, this.page);
    }).unsubscribe();
  }

  loadingResults = false;
  search(data: SearchData, page: number | string | undefined) {
    if (data.q)
      this.q_ = (typeof data.q === "string" ? data.q.split(",") : data.q).map(a=>a.trim());

    if (this.user) {
      if (Array.isArray(data.q))
        data.q.push('@' + this.user.username);
      else {
        if (!data.q)
          data.q = '';
        data.q += (data.q ? ',' : '') + '@' + this.user.username;
      }
    }

    gtag('event', 'search', { search_term: data.q });

    this.loadingResults = true;
    this.resourcesService.getResources(data, page)
      .subscribe({
        next: (data: ResourcesRequest) => {
          this.statistics = data.data.statistics || data.data.stats;
          this.resources = data.data.resources;
        }, // success path
        error: err => {
          this.statistics = { total: 0, pages: 0 };
          this.resources = [];
        },
        complete: () => this.loadingResults = false
      });
  }

  onSearchInput($event: any) {
    if (this.timeout !== 0)
      clearTimeout(this.timeout);
    this.timeout = this.window.getWindow()!.setTimeout(() => {
      this.timeout = 0;

      let q = $event.target.value.trim();
      if (q.toLowerCase() === this.q.toLowerCase())
        return;

      this.q = q;
      this.page = 0;

      this.replaceState({ q: q ? q : null, page: null });
      this.search({ q }, 0);
    }, 600);
  }

  onChangeOrder($event: any) {
    let sort = $event.target.value;
    this.order = sort;

    this.replaceState({ order: sort ? sort : null, page: null });
    this.search({ sort }, undefined);
  }
  selectOption(option: string) {
    if (this.types.includes(option + 's'))
      return;

    this.unselectAllOptions();
    for (let typeOption of this.typeOptions) {
      if (typeOption.value !== option + 's')
        continue;
      typeOption.checked = true;
    }
    this.updateOptions();
  }
  updateOptions() {
    let types = this.typeOptions
      .filter(opt => opt.checked)
      .map(opt => opt.value);
    this.page = 0;
    this.types = types;
    this.replaceState({ page: null, types: types.length !=0 ? types.join(',') : null });
    this.search({ types }, undefined);
  }
  unselectAllOptions() {
    this.types = [];
    this.typeOptions.forEach(type => type.checked = false);
    this.replaceState({ types: null, page: null });
    this.search({ types: [] }, undefined);
  }

  addTag(tag: string) {
    if (this.q.trim().toLowerCase().includes(tag.trim().toLowerCase()))
      return;

    if (this.q)
      this.q += ', ';
    this.q += tag.trim();

    this.page = 0;
    this.replaceState({ q: this.q, page: null });
    this.search({ q: this.q }, 0);
  }

  changePage(page: number) {
    if (page === this.page)
      return;

    this.viewportScroller.scrollToAnchor('resource-search');

    this.page = page;
    this.replaceState({ page: page ? page + 1 : null });
    this.search({}, page);
  }
  prevPage() {
    if (this.page <= 0)
      return;

    this.page--;
    let page = this.page;
    this.replaceState({ page: page ? page + 1 : null });
    this.search({}, page);
  }
  nextPage() {
    if (+this.page+1 > this.statistics.total)
      return;

    this.page++;
    let page = this.page;
    this.replaceState({ page: page ? page + 1 : null });
    this.search({}, page);
  }
  createPageRange(): number[] {
    let a: number[] = [];
    let page = this.page;
    let max = this.statistics.pages;

    if (max === 0)
      return a;

    for (let i = 1; i <= 3; i++) {
      if (page - i >= 0)
        a.push(+page - +i)

      if (page + i <= max - 1)
        a.push(+page + +i)
    }

    a.push(page);

    return a.sort((a1, b) => a1 - b);
  }


  private fac = new FastAverageColor();
  getBackgroundColor($event: StateChange, resource: Resource) {
    if ($event.reason !== "loading-succeeded")
      return;

    const element = document.getElementById(resource._id);
    if (!element)
      return;

    this.fac.getColorAsync(resource.files.preview.default[resource.mime], { crossOrigin: 'night.design' }).then((result) => {
      if (result.isLight)
        element.classList.add('card-img-top-inverted');
    });
  }



  q_: string[] = [];
  update() {
    const q = this.q_.map(a=>a.trim()).join(",");
    this.q = q;
    this.page = 0;

    this.replaceState({ q: q ? q : null, page: null });
    this.search({ q }, 0);
  }


  cache: any = {};
  replaceState(key: string | { [key: string]: any }, value?: any) {
    const newQuery: any = {};
    Object.keys(this.cache).map(key => newQuery[key] = this.cache[key]);

    if (typeof key === "object")
      Object.keys(key).map(key_ => {
        let value = key[key_];

        if ([undefined, null].includes(value))
          delete newQuery[key_];
        else
          newQuery[key_] = value;
      });
    else if (value) {
      if ([undefined, null].includes(value))
        delete newQuery[key];
      else
        newQuery[key] = value;
    } else
      return;

    let path = location.pathname.split('/').filter(a => a);
    if (path[0] === this.locale)
      path = path.slice(1);

    this.cache = newQuery;
    this.location.replaceState('/' + path.join('/'), Object.keys(newQuery).map(key => key + '=' + newQuery[key]).join('&'));
  }
}
