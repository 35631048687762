import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';

import {Observable} from "rxjs";
import {retry } from 'rxjs/operators';

import { environment } from "../../environments/environment";
import {Resource} from "../resource/resource.service";

export interface ResourcesRequest {
  data: {
    stats: Statistics, // TODO OldFallback
    statistics: Statistics,
    resources: [Resource]
  }
}
export interface Statistics {
  total: number,
  pages: number
}


export interface SearchData {
  q?: string | string[],
  types?: string[],
  sort?: string | string[],
}
@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  query: SearchData = {
    q: '',
    types: [],
    sort: 'player:asc',
  };
  page = 0;

  constructor(private http: HttpClient) { }

  getResources(data: SearchData, page: number | string | undefined): Observable<ResourcesRequest> {
    this.query = { ...this.query, ...data };
    let { q, types, sort } = this.query;
    if (typeof sort === "string")
      sort = sort.split(':');
    let orderBy = sort![0];
    let order = sort![1];

    switch (typeof page) {
      case "string":
        let action = String(page);
        page = this.page;
        switch (action) {
          case '++':
            page++;
            break;
          case '--':
            page--;
            break;
        }
        break;
      case "number":
        break;
      case "undefined":
        page = 0;
        break;
      default:
        page = 0;
    }
    this.page = ++page;

    let url = new URL(environment.apiServer + 'resources');
    let searchParams = url.searchParams;
    searchParams.set('q', (typeof q === "string" ? q.split(',') : q!).map(a => a.trim()).join(','));
    searchParams.set('types', types!.join(','));
    searchParams.set('orderBy', orderBy);
    searchParams.set('order', order);
    searchParams.set('page', String(page));
    searchParams.set('limit', '40');


    return this.http.get<ResourcesRequest>(url.href)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        // catchError(this.handleError) // then handle the error
      );
  }

  // private handleError(error: HttpErrorResponse) {
  //   if (error.status === 0) {
  //     // A client-side or network error occurred. Handle it accordingly.
  //     console.error('An error occurred:', error.error);
  //   } else {
  //     // The backend returned an unsuccessful response code.
  //     // The response body may contain clues as to what went wrong.
  //     console.error(
  //       `Backend returned code ${error.status}, body was: `, error.error);
  //   }
  //   // Return an observable with a user-facing error message.
  //   return throwError(() => new Error('Something bad happened; please try again later.'));
  // }
}
