import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {SimpleRequest} from "../services/simple-request";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.css','../login/login.component.css'],
})
export class AuthorizeComponent implements OnInit {
  isBrowser = false;

  error?: ErrorResponse;
  client?: OAuth2Client;

  response_type!: 'code' | 'token';
  redirect_uri?: string;
  state?: string;

  scopes: Scope[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (!this.isBrowser)
      return

    this.route.queryParams.subscribe(query => {
      const response_type = query['response_type'];
      this.state = query['state'] ?? undefined;

      const redirect_uri = query['redirect_uri'] ?? undefined;
      const scopes = query['scope'];

      const client_id = query['client_id'];
      if (!client_id) {
        this.error = "invalid_request";
        return;
      }

      this.http.get<SimpleRequest<{ client: OAuth2Client }>>(environment.authServer + "info", { params: { client_id, noDefError: true }}).subscribe(response => {
        this.client = response.data.client;

        if (redirect_uri) {
          if (!this.client.redirect_uri.includes(decodeURIComponent(redirect_uri).toLowerCase())) {
            this.error = "invalid_redirect_uri";
            return;
          }
          this.redirect_uri = redirect_uri;
        } else
          this.redirect_uri = this.client.redirect_uri[0];

        if (!["code","token"].includes(response_type))
          return this.redirectWithError("invalid_request");
        this.response_type = response_type;

        if (!this.scopes)
          return this.redirectWithError("invalid_request")

        this.scopes = scopes.split(/[ ,]+/)
        if (this.scopes.some(value => !this.client!.scopes.includes(value)))
          return this.redirectWithError("invalid_scope");
        this.scopes = scopes.map((scope: Scope) => this.refraktorScopes(scope)) as Scope[];
      }, error => {
        this.error = error.code === 403 ? "access_denied" : "server_error"
      });
    });
  }

  authorize() {
    this.http.post<SimpleRequest<{ authorization: { code?: string; access_token?: string; expires?: number } }>>(environment.authServer + "authorize", { client_id: this.client!._id, scopes: this.scopes }).subscribe(response => {
      switch (this.response_type) {
        case "code":
          return this.redirectWithCode(response.data.authorization.code!);
        case "token":
          return this.redirectWithToken(response.data.authorization.access_token!, response.data.authorization.expires!);

        default:
          this.error = "server_error";
      }
    },error => {
      this.error = error.code === 403 ? "access_denied" : "server_error"
    });
  }

  redirectWithToken(access_token: string, expires: number) {
    const url = new URL(this.redirect_uri!);
    const params = url.searchParams;
    params.set("access_token", access_token);
    params.set("state", this.state!);
    params.set("token_type", "Bearer");
    params.set("expires_in", String(expires));

    window.location.href = url.href;
  }

  redirectWithCode(code: string) {
    const url = new URL(this.redirect_uri!);
    const params = url.searchParams;
    params.set("code", code);
    params.set("state", this.state!);

    window.location.href = url.href;
  }

  redirectWithError(error: ErrorResponse) {
    if (!this.redirect_uri) {
      this.error = error;
      return;
    }

    const url = new URL(this.redirect_uri!);
    const params = url.searchParams;
    params.set("error", error);
    params.set("state", this.state!);

    window.location.href = url.href;
  }

  refraktorScopes(scope: Scope): Scope | undefined {
    switch (scope) {
      case "email":
        return "GET_USER_EMAIL";
      case "profile":
        return undefined;
      default:
        return scope;
    }
  }
}

export type Scope = "openid" | "email" | "profile" | "GET_USER_EMAIL";
export type ErrorResponse = "invalid_request" | "invalid_scope" | "unauthorized_client" | "invalid_redirect_uri" | "access_denied" | "server_error"

export interface OAuth2Client {
  readonly _id: string;
  readonly icon: string;
  readonly name: string;
  readonly summary: string;
  readonly redirect_uri: string[];
  readonly scopes: Scope[];
}
