import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';

import {Observable} from "rxjs";
import {retry } from 'rxjs/operators';

import { environment } from "../../environments/environment";
import {dummy, User, UserService} from "../user/user.service";
import {Cosmetics} from "../interfaces/player-cosmetics";
import {SimpleRequest} from "../services/simple-request";
import {BasicTarget} from "../utils/report/report.component";

export interface ResourceRequest {
  data: {
    resource: Resource
  }
}

export interface ResourceSuggestions {
  readonly colors: string[];
  readonly possible: {
    readonly mode: 'HARD' | 'FLAT' | 'SOFT';
    readonly types: Cosmetics[];
  };
  readonly tags: {
    name: string;
    match: number;
  }[];
}

export interface Resource extends BasicTarget {
  _id: string,
  type: Cosmetics,
  user: User,
  name: string,
  desc?: string | Record<string, any>,
  tags: [string]
  timestamp: string,
  downloads: number,
  mime: 'image/png' | 'image/gif',
  public: boolean,
  rendering?: boolean,
  variables: { [key: string]: any };
  files: {
    preview: {
      default: {
        'image/webp'?: string,
        'image/png': string,
        'image/gif': string
      },
      pre_render?: {
        'image/webp'?: string,
        'image/png'?: string,
        'image/gif'?: string
      },
    },

    download: {
      default: {
        'image/webp'?: string,
        'image/png': string,
        'image/gif': string
      },
      minified?: {
        'image/webp'?: string,
        'image/png'?: string,
        'image/gif'?: string
      },
      mojang?: {
        'image/webp'?: string,
        'image/png'?: string,
        'image/gif'?: string
      },
    }
  }
  _links: {
    share: string,
    preview: string,
    download: string,
  }
}

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  constructor(private http: HttpClient, private userService: UserService) { }

  getResource(id: string): Observable<SimpleRequest<{ resource: Resource, suggestions?: ResourceSuggestions }>> {
    return this.http.get<SimpleRequest<{ resource: Resource, suggestions?: ResourceSuggestions }>>(environment.apiServer + 'resources/' + id, { params: { noDefError: 1 }})
  }

  dummy(): Resource {
    return <Resource>{
      _id: '',
      type: "cloaks",
      user: dummy(),
      name: '',
      tags: [''],
      timestamp: '',
      downloads: 0,
      mime: "image/png",
      files: {
        preview: {
          default: {
            "image/webp": undefined,
            "image/png": '',
            "image/gif": ''
          }
        },
        download: {
          default: {
            "image/webp": undefined,
            "image/png": '',
            "image/gif": ''
          }
        }
      },
      _links: {
        share: '',
        preview: '',
        download: ''
      }
    }
  }
}
