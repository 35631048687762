import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'host'
})
export class UrlPipe implements PipeTransform {

  transform(value: string): unknown {
    const url = new URL(value);
    return url.protocol + '//' + url.host;
  }

}
