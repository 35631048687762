import { Injectable } from '@angular/core';
import {HttpBackend, HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {User} from "../user/user.service";
import {response} from "express";
import {SimpleRequest} from "./simple-request";

export interface CommonRequest {
  status: number,
  error: string,
  message: string,
  debug: string,
  data: {}
}

export interface AuthorisationRequest extends SimpleRequest {
  data: {
    authorization: {
      access_token: string,
      refresh_token: string,
      token_type: "Bearer",
      expires: number
    }
  }
}


export interface UsersRequest extends SimpleRequest {
  readonly data: {
    readonly users: User[];
  };
}

export interface UserRequest extends SimpleRequest {
  readonly data: {
    readonly user: User;
  };
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private httpClient: HttpClient;

  constructor(
    private handler: HttpBackend,
    private http: HttpClient
  ) {
    this.httpClient = new HttpClient(handler);
    this.checkTokens();
  }

  registration(username: string, email: string, password: string, captcha: string, returnTo: string | undefined): string | Observable<CommonRequest> {
    if (AuthService.isAuthorized())
      return 'User is already authorized! Logout first!';

    let url = new URL(environment.apiServer + 'auth/registration');
    return this.http.post<CommonRequest>(url.href, { username, email, password, captcha, returnTo });
  }

  login(email: string, password: string, captcha: string): string | Observable<AuthorisationRequest> {
    if (AuthService.isAuthorized())
      return 'User is already authorized! Logout first!';

    let url = new URL(environment.apiServer + 'auth/local/callback');
    return this.http.post<AuthorisationRequest>(url.href, { email, password, captcha }, { params: { noDefError: 1 }});
  }

  loginActivation(activation: string): string | Observable<AuthorisationRequest> {
    if (AuthService.isAuthorized())
      return 'User is already authorized! Logout first!';

    let url = new URL(environment.apiServer + 'auth/local/callback');
    return this.http.post<AuthorisationRequest>(url.href, { activation });
  }

  loginViaThirdParty(authorisation: string): string | Observable<AuthorisationRequest> {
    if (AuthService.isAuthorized())
      return 'User is already authorized! Logout first!';

    let url = new URL(environment.apiServer + 'auth/local/callback');
    return this.http.post<AuthorisationRequest>(url.href, { authorisation }, { params: { noDefError: 1 }});
  }

  loginViaMinecraft(uuid: string, code: string): string | Observable<AuthorisationRequest> {
    if (AuthService.isAuthorized())
      return 'User is already authorized! Logout first!';
    return this.http.post<AuthorisationRequest>(environment.apiServer + 'auth/minecraft/callback', { uuid, code }, { params: { noDefError: 1 }});
  }

  refreshTokens(token: string = AuthService.getToken()!, refreshToken: string = AuthService.getRefreshToken()!) {
    this.httpClient.post<AuthorisationRequest>(environment.apiServer + 'auth/local/callback', { access_token: token, refresh_token: refreshToken }).subscribe(response => {
      localStorage.setItem('access_token', response.data.authorization.access_token);
      localStorage.setItem('refresh_token', response.data.authorization.refresh_token);
      window.location.reload();
    }, () => AuthService.removeTokens());
  }

  logout() {
    this.http.delete(environment.apiServer + 'users/@me/session/' + AuthService.getTokenData().jti);
    AuthService.removeTokens();
  }

  static removeTokens() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    window.location.reload();
  }

  static isAuthorized(): boolean {
    return localStorage.getItem('access_token') != undefined
  }

  static getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  static getRefreshToken(): string | null {
    return localStorage.getItem('refresh_token');
  }

  static getTokenData(token: string | null = this.getToken()): any | null {
    if (this.isAuthorized() && token)
      return JSON.parse(atob(token.split('.')[1]));

    return null;
  }

  static isTokenExpired(token: string | null = this.getToken(), time: number = Date.now()): boolean {
    time /= 1000;
    const data = this.getTokenData(token);
    return !data || data.exp <= time;
  }

  isTokenExpired(): boolean {
    if (!AuthService.isAuthorized())
      return true;

    return AuthService.isTokenExpired(AuthService.getToken());
  }

  checkTokens() {
    if (!AuthService.isAuthorized())
      return;

    const token = AuthService.getToken();
    if (!AuthService.isTokenExpired(token))
      return;

    const refresh = AuthService.getRefreshToken();
    if (!token || !refresh || AuthService.isTokenExpired(refresh)) {
      AuthService.removeTokens();
      return;
    }

    this.refreshTokens(token, refresh);
  }

  getUserData(): Observable<UserRequest> {
    return this.http.get(environment.apiServer + 'users/@me') as Observable<UserRequest>;
  }
}
