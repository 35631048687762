import {Component, Inject, OnInit} from '@angular/core';
import {Provider, UserConnection} from "../../interfaces/user-connections";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {UserComponent} from "../user.component";
import {User} from "../user.service";
import {SimpleRequest} from "../../services/simple-request";

@Component({
  selector: 'app-connected-accounts',
  templateUrl: './user-connected-accounts.component.html'
})
export class UserConnectedAccountsComponent implements OnInit {
  user!: User;
  connectionsCache: UserConnection[] = [];

  constructor(
    private http: HttpClient,
    @Inject(UserComponent) private parent: UserComponent
  ) { }

  ngOnInit(): void {
    this.user = this.parent.user;

    this.http.get<SimpleRequest<{ readonly connections: UserConnection[]; }>>(environment.apiServer + 'users/' + this.user._id + '/connected-accounts').subscribe(value => {
      this.connectionsCache = value.data.connections;
    });
  }

  getConnections(provider: Provider): UserConnection[] {
    return this.connectionsCache?.filter(a => a.provider === provider) as UserConnection[];
  }

  showProfile(connection: UserConnection) {
    window.open(connection.profile.link , '_blank')!.focus()
  }
}
