import { Component, OnInit } from '@angular/core';
import {Cosmetics, localizeCosmetic} from "../interfaces/player-cosmetics";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

export interface PartiesRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly statistics: {
      readonly total: number;
      readonly pages: number;
    }
    readonly mods: Party[];
  }
}
export interface Party {
  readonly _id: string;
  readonly name: string;
  readonly slug: string;
  readonly metadata: {
    icon: string | undefined;
    banner: string | undefined;
    readonly summary: string;
    readonly description: Record<string, any>;
    readonly cosmetics: Cosmetics[];
    readonly category: string;
    readonly second_categories: string[];
  };
  readonly downloads: number;
  readonly verifiedAt: string | undefined;
  readonly timestamp: string;
}

@Component({
  selector: 'app-modder',
  templateUrl: './modder.component.html',
  styleUrls: ['./modder.component.css']
})
export class ModderComponent implements OnInit {
  parties: Party[] = [];

  constructor(
    private http: HttpClient,
  ) { }

  maxPages: number = 0;
  ngOnInit(): void {
    this.http.get<PartiesRequest>(environment.apiServer + 'mods', { params: { excludes: ['!mine'] }}).subscribe(request => {
      this.maxPages = request.data.statistics.pages;
      this.parties = request.data.mods;
    });
  }

  localizeCosmetic = (cosmetics: Cosmetics) => localizeCosmetic(cosmetics);

  page: number = 1;
  loadMore() {
    if (this.page >= this.maxPages)
      return;

    const page = ++this.page;
    this.http.get<PartiesRequest>(environment.apiServer + 'mods', { params: { excludes: ['!mine'], page }}).subscribe(request => {
      this.parties = this.parties.concat(request.data.mods);
    });
  }
}
