<div class="page-header">
  <div class="page-block">
    <div class="row">
      <div class="col-md-8">
        <div class="page-header-title">
          <h5 class="m-b-10" i18n>Dashboard</h5>
        </div>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-end">
          <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
          <li class="breadcrumb-item"><a routerLink="/dashboard" i18n>Dashboard</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="page-content" *blockUI="'cosmeticsList'">
  <div class="row d-flex justify-content-center" *ngIf="account && accountsCache && accountsCache.length > 1">
    <div class="col-md-8">
      <div class="card">
        <div class="card-block">
          <div class="row">
            <div class="col-md-3 text-center">
              <img class="rounded img-fluid" [src]="account!.profile.avatar" alt="Player Head">
            </div>
            <div class="col-md-9">
              <h3>Spieler: {{account!.profile.name}}</h3>
              <select class="form-select mb-3" (change)="switchAccountInput($event)">
                <option [value]="account_._id" [selected]="account === account_" *ngFor="let account_ of accountsCache">{{account_.profile.name}}</option>
              </select>
              <button class="btn btn-outline-primary" routerLink="/settings/connected-accounts">Account hinzufügen/entfernen</button>
<!--              <button class="btn btn-danger">Account Entfernen</button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row d-flex justify-content-center">
    <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let cosmetic of getCosmetics()">
      <div class="card" *blockUI="cosmetic.type">
        <img [src]="cosmetic.file?.image || cosmeticsTemplates[cosmetic.type]" class="card-img-top" [alt]=cosmetic.file?.name>
        <div class="card-body">
          <h5 class="card-title">{cosmetic.type, select, cloaks {Umhang} round-shields {Wikinger Schild} knight-shields {Ritterschild} other {{{cosmetic.type}}}}</h5>

          <ng-container *ngIf="selectedCosmetic === cosmetic.type && uploadProgress;then a else b"></ng-container>
          <ng-template #a>
            <div class="progress">
              <div [class]="uploadProgress && uploadProgress >= 100 ? 'progress-bar progress-bar-striped progress-bar-animated bg-warning' : 'progress-bar bg-success'" role="progressbar" [style.width]="uploadProgress + '%'" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </ng-template>
          <ng-template #b>
            <button class="btn btn-success btn-small" (click)="upload(cosmetic.type)"><i class="fa-solid fa-upload"></i></button>
            <button class="btn btn-danger btn-small" [class.btn-outline-danger]="!cosmetic.file" (click)="delete(cosmetic.type)" [disabled]="!cosmetic.file"><i class="fa-solid fa-trash"></i></button>
            <button class="btn btn-primary btn-small" routerLink="/resources" [queryParams]="{types: cosmetic.type}"><i class="fa-solid fa-folder"></i></button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>


  <ADPlacement [type]="'side-banner'" *ngIf="isLoggedInViaThirdParty"></ADPlacement>


  <ng-container *ngIf="!isLoggedInViaThirdParty">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header"><h5 i18n>Designer bereich</h5></div>
          <div class="card-block">
            <button class="btn btn-success float-end" routerLink="/creator">Creatorboard</button>
            <span i18n>Bist du ein Designer der gerne Cosmetics für Spieler erstellt und möchtest sie gerne für alle teilen?<br>Dann kannst du ganz einfach deine Cosmetics mit dem <span class="text-success">Creatorboard</span> teilen und verwalten.</span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header"><h5 i18n>Entwicker bereich</h5></div>
          <div class="card-block">
            <button class="btn btn-success float-end" routerLink="/modder">Modderboard</button>
            <span i18n>Bist du ein Developer der eigene Mods / Clients schreibt und unsere Cosmetics integrieren möchte?<br>
        Dann hast du glück, wir besitzen eine offene REST-API und einen Socket damit jedes Entwickler Herz befriedigt werden kann.</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</div>

<input type="file" accept="image/png" (change)="uploadFile($event)" #fileInput>
