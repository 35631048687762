import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {AuthService} from "../services/auth.service";
import {environment} from "../../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = new URL(request.url);

    if (!environment.apiServer.includes(url.host))
      return next.handle(request);

    request = request.clone({
      params: request.params.set('run_scripts', 'false')
    });

    if (!AuthService.isAuthorized())
      return next.handle(request);

    if (this.auth.isTokenExpired()) {
      this.auth.checkTokens();
      return EMPTY;
    }

    request = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${AuthService.getToken()}`)
    });

    return next.handle(request);
  }
}
