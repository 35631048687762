<button class="btn btn-outline-danger" [class.btn-small]="smallBtn" [disabled]="disabled" (click)="isAuthed ? reportPopup.fire() : loginRedirect()" i18n>{{localizedType}} Melden</button>

<swal #reportPopup [showCloseButton]="true" [allowOutsideClick]="false" width="60%" title="{{targetName | titlecase}}, {{localizedType}} Melden" i18n-title>
  <ng-container *swalPortal="swalTargets.content">
    <div class="text-start">
      <div class="divider" i18n>Element</div>
      <div class="mb-1" i18n>
        Was genau in diesem Element stört dich?
      </div>
      <div class="row">
        <div class="col-md-4 mb-2" *ngFor="let field of targetFields">
          <div class="d-grid">
            <button class="btn" [class.btn-outline-primary]="selectedField !== field" [class.btn-primary]="selectedField === field" (click)="selectedField = field; selectedReason = undefined;">{{field.name}}</button>
          </div>
        </div>
      </div>

      <div class="divider" i18n>Grund</div>
      <div class="mb-1" i18n>
        Was ist der grund dazu?
      </div>
      <div class="text-center text-warning" [hidden]="selectedField"><i class="fa-solid fa-info-circle"></i>&nbsp;<span i18n>Das Element muss zuerst ausgewählt werden.</span></div>
      <div class="row">
        <div class="col-md-4 mb-2" *ngFor="let reason of targetReasons">
          <div class="d-grid">
            <button class="btn" [class.btn-outline-primary]="selectedReason !== reason" [class.btn-primary]="selectedReason === reason" (click)="selectedReason = reason;">{{reason.name}}</button>
          </div>
        </div>
      </div>

      <div class="divider" i18n>Beschreibung</div>
      <div i18n>
        Bitte beschreibe den grund für den Report.<br>
        Gebe hierbei so viele informationen wie möglich!
      </div>
      <div class="text-start text-danger"><i class="fa-solid fa-info-circle"></i>&nbsp;<span i18n><b>Achtung!</b> Gebe hier niemals deine Email Adresse, Passwort oder andere Arten von Schlüsseln ein!</span></div>
      <div class="mb-3">
        <label for="message" class="form-label"></label>
        <textarea class="form-control" id="message" rows="3" [(ngModel)]="message"></textarea>
      </div>
    </div>
  </ng-container>
  <ng-container *swalPortal="swalTargets.actions">
    <button class="btn btn-danger me-2" (click)="reportPopup.close(); post()" [disabled]="!selectedReason" i18n>Melden</button>
    <button class="btn btn-outline-success" (click)="reportPopup.close(); reset()" i18n>Abbrechen</button>
  </ng-container>
</swal>
