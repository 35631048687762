import { Injectable } from '@angular/core';

export interface Role {
  readonly _id: string;
  readonly name: string;
  readonly icon?: string;
  readonly sort: number;
  readonly work_description?: string;
}

export interface User {
  _id: string,
  avatar?: string,
  banner?: string,
  username: string,
  readonly display: string,
  readonly role: Role,
  readonly email?: string,
}

export function dummy(): User {
  return {
    _id: '',
    avatar: '',
    username: '',
    display: '',
    role: {
      _id: '',
      icon: '',
      name: '',
      sort: -1
    }
  }
}


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }
}
