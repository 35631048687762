import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {AbstractControl, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from "@angular/forms";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import Swal from "sweetalert2";
import {User} from "../../user/user.service";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styles: ['.text-preview { font-size: 1.5em; } .btn-preview { margin-right: .25em; color: white } .btn-preview:hover { background-color: rgba(42,225,36,0.29)!important; }']
})
export class AccountComponent implements OnInit {
  userData!: User;

  usernameGroup!: UntypedFormGroup;
  usernameSubmitted: boolean = false;
  usernameErrorCooldown: boolean = false;

  constructor(
    private auth: AuthService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.auth.getUserData().subscribe(request => this.userData = request.data.user);
    (this.http.get(environment.apiServer + 'users/@me/username',{ params: { noDefError: 1 }}) as Observable<any>).subscribe({ error: () => {
        this.usernameErrorCooldown = true;
        this.usernameGroup.disable()
      }
    });

    this.usernameGroup = new UntypedFormGroup({
      username: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(16),
        Validators.pattern('^[A-Za-z0-9_]{2,16}$'),
      ], [
        this.validateUsernameCheck(),
      ]),
    });
  }

  patchUsername() {
    this.usernameSubmitted = true;
    if (this.usernameGroup.status !== "VALID")
      return;
    this.usernameGroup.disable({ onlySelf: false });

    (this.http.patch(environment.apiServer + 'users/@me/username', { username: this.username.value }) as Observable<any>).subscribe(() => {
      Swal.fire({
        icon: "success",
        text: $localize`Du hast deinen namen zu ${this.username.value} geändert!`,
      }).then();

      this.usernameGroup.enable({ onlySelf: false });
      this.usernameGroup.reset();
      this.usernameErrorCooldown = true;
      this.auth.getUserData().subscribe(request => this.userData = request.data.user);
    });
  }

  get username() {
    return this.usernameGroup.get('username')!;
  }

  private timeoutUsernameCheck: number = 0;
  private validateUsernameCheck(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      const username = control.value;

      return new Promise<ValidationErrors | null>((resolve, reject) => {
        if (this.timeoutUsernameCheck !== 0)
          clearTimeout(this.timeoutUsernameCheck);

        this.timeoutUsernameCheck = window.setTimeout(() => fetch(environment.apiServer + 'auth/check-username?username=' + encodeURI(username) + '&t=' + Date.now()).then(async response => {
          switch (response.status) {
            case 400:
              switch ((await response.json()).message) {
                case 'username doesn\'t match the pattern':
                  resolve({ regex: true });
                  break;
                default:
                  resolve({ error: true });
              }
              break;
            case 410:
              resolve({ gone: true });
              break;
            default:
              resolve(null);
              break;
          }
        }), 500);
      });
    }
  }


  futur_feature() {
    Swal.fire({
      icon: 'info',
      title: $localize`Zukünftiges Feature`,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    }).then();
  }


  deleteUserConfirm: boolean = false;
  deleteUser() {
    if (!this.deleteUserConfirm)
      return;

    Swal.fire({
      icon: "warning",

      title: $localize`ACHTUNG!`,
      text: $localize`Du bist gerade dabei deinen "${this.userData.username}" Account zu löschen! Bitte bestätige die löschung mit deinem Passwort.`,
      footer: $localize`Du hast nach dem löschen nur 28 Tage zeit deinen Account wiederherstellen zu können.`,

      input: "password",
      inputPlaceholder: "Password",

      confirmButtonText: $localize`Löschen`,
      showDenyButton: true,
      denyButtonText: $localize`Abbrechen`,
    }).then((result) => {
      if (!result.isConfirmed)
        return;

      (this.http.delete(environment.apiServer + 'users/@me', {body: {password: result.value}}) as Observable<any>).subscribe(() => {
        Swal.fire({
          icon: "success",

          text: $localize`Du hast deinen Account ${this.userData.username} gelöscht!`,
          footer: $localize`Ab jetzt hast du 28 Tage zeit deinen Account wieder herstellen zu können`,

          timer: 3000,
          timerProgressBar: true,
        }).then(() => this.auth.logout());
      })
    });
  }



  imageError: { avatar: string, banner: string } = { avatar: '', banner: '' };
  imageType: Image = "avatar";
  uploading = false;
  patchImage($event: Event) {
    const element = $event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (!fileList)
      return;

    this.uploading = true;
    this.imageError[this.imageType] = '';

    const file = fileList[0];
    const data = new FormData();
    data.set('file', file, file.name);
    this.http.patch(environment.apiServer + 'users/@me/' + this.imageType, data, { params: { noDefError: 1 }}).subscribe(() => {
      this.auth.getUserData().subscribe(request => this.userData = request.data.user);
      element.value = '';
      this.uploading = false;
    }, error => {
      console.log(error);
      this.imageError[this.imageType] = error.error.data.check;
      this.uploading = false;
    });
  }
  deleteImage(image: Image) {
    this.http.delete(environment.apiServer + 'users/@me/' + image).subscribe(() => {
      switch (image) {
        case "avatar":
          this.userData.avatar = undefined;
          break;
        case "banner":
          this.userData.banner = undefined;
          break;
      }
    });
  }

  patchUsernameDisplay(type: 'color' | 'font', data: string) {
    this.http.patch(environment.apiServer + 'users/@me/username', { type, data }).subscribe(() => window.location.reload());
  }
}


export type Image = 'avatar' | 'banner';
