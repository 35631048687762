import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {
  Provider,
  UserConnection,
  UserConnectionRequest,
  UserConnectionsRequest
} from "../../interfaces/user-connections";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import Swal from "sweetalert2";
import {HttpClient} from "@angular/common/http";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {ActivatedRoute, Router} from "@angular/router";
import {SimpleRequest} from "../../services/simple-request";
import {isPlatformBrowser} from "@angular/common";
import {response} from "express";

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styles: ['.img-fluid { height: 9em; width: auto; }']
})
export class ConnectionsComponent implements OnInit {
  @BlockUI('connectionsList') blockConnectionsList: NgBlockUI | undefined;
  connectionsCache: UserConnection[] = [];
  private isBrowser: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (!this.isBrowser)
      return;

    this.blockConnectionsList?.start('Loading...');
    this.route.queryParams.subscribe(params => {
      if ('authorisation' in params) {
        this.router.navigate(['/settings/connected-accounts'], { replaceUrl: true }).then();
        (this.http.post<UserConnectionRequest>(environment.apiServer + 'users/@me/connected-accounts', { authorisation: params['authorisation'] })).subscribe(value => {
          this.connectionsCache?.push(value.data.connection);
          this.blockConnectionsList?.stop();

          setTimeout(() => window.location.reload(), 700);
        });

      } else {
        (this.http.get(environment.apiServer + 'users/@me/connected-accounts') as Observable<UserConnectionsRequest>).subscribe(value => {
          this.connectionsCache = value.data.connections;
          this.blockConnectionsList?.stop();
        });
      }
    }).unsubscribe();
  }

  getConnections(provider: Provider): UserConnection[] {
      return this.connectionsCache?.filter(a => a.provider === provider) as UserConnection[];
  }

  toggleVisibility(connection: UserConnection) {
    this.http.patch<SimpleRequest>(environment.apiServer + 'users/@me/connected-accounts/' + connection._id, { public: !connection.public }).subscribe(response => {
      connection.public = !connection.public;
    });
  }

  unlink(connection: UserConnection) {
    Swal.fire({
      title: $localize`Achtung!`,
      text: $localize`Bist du dir sicher das du die verknüpfung mit deinem "${connection.provider} ${connection.profile.name}" Account aufheben möchtest?`,
      icon: "warning",
      confirmButtonColor: '#dc3545',
      confirmButtonText: $localize`Verbindung aufheben`,
      showCancelButton: true,
      cancelButtonText: $localize`Abbrechen`,
    }).then(a => {
      if (!a.isConfirmed)
        return;

      this.blockConnectionsList?.start('Loading...');
      (this.http.delete(environment.apiServer + 'users/@me/connected-accounts/' + connection._id) as Observable<UserConnectionsRequest>).subscribe(value => {
        this.blockConnectionsList?.stop();
        this.connectionsCache = this.connectionsCache?.filter(a => a !== connection);
      });
    });
  }

  refresh(connection: UserConnection) {
    this.blockConnectionsList?.start('Loading...');
    this.http.post<SimpleRequest<{ readonly connection: UserConnection }>>(environment.apiServer + 'users/@me/connected-accounts/' + connection._id + '/refresh', {}).subscribe(response => {
      connection.profile = response.data.connection.profile;
      connection.timestamp = response.data.connection.timestamp;
      this.blockConnectionsList?.stop();
    });
  }

  hasRefreshCooldown(connection: UserConnection): boolean {
    const cooldown = new Date();
    cooldown.setHours(cooldown.getHours() - 6);
    return cooldown.getTime() <= new Date(connection.timestamp).getTime();
  }

  showProfile(connection: UserConnection) {
    window.open(connection.profile.link , '_blank')!.focus()
  }
}
