import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {File} from "../interfaces/file";
import {ActivatedRoute, Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {SimpleRequest} from "../services/simple-request";
import {User} from "../user/user.service";
import Swal from "sweetalert2";
import {BasicTarget} from "../utils/report/report.component";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-server-viewer',
  templateUrl: './server-viewer.component.html',
  styleUrls: ['./server-viewer.component.css']
})
export class ServerViewerComponent implements OnInit {
  server!: Server;

  localUrl!: string;
  serverBanner: ServerBanner[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,

    private titleService: Title,
    private metaTagService: Meta,
    private render: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let id = params['id'];
      this.http.get<SimpleRequest<{ server: Server }>>(environment.apiServer + 'servers/' + id).subscribe(response => {
        this.server = response.data.server;


        this.localUrl = 'https://' + environment.host + '/server/' + this.server._id;

        this.serverBanner = [
          {
            title: $localize`Minecraft Server Banner`,
            url: environment.apiServer + 'servers/' + this.server._id + '/share/banner/default.png'
          },
          {
            title: $localize`Minecraft Server Banner`,
            url: environment.apiServer + 'servers/' + this.server._id + '/share/banner/0.png'
          },
          {
            title: $localize`Minecraft Server Banner`,
            url: environment.apiServer + 'servers/' + this.server._id + '/share/banner/1.png'
          },
          {
            title: $localize`Minecraft Server Banner`,
            url: environment.apiServer + 'servers/' + this.server._id + '/share/banner/2.png'
          },
          {
            title: $localize`Minecraft Server Banner`,
            url: environment.apiServer + 'servers/' + this.server._id + '/share/banner/3.png'
          },
          {
            title: $localize`Minecraft Server Banner`,
            url: environment.apiServer + 'servers/' + this.server._id + '/share/banner/4.png'
          },
          {
            title: $localize`Minecraft Server Banner`,
            url: environment.apiServer + 'servers/' + this.server._id + '/share/banner/5.png'
          },
          {
            title: $localize`Minecraft Server Banner`,
            url: environment.apiServer + 'servers/' + this.server._id + '/share/banner/6.png'
          },
          {
            title: $localize`Minecraft Server Banner`,
            url: environment.apiServer + 'servers/' + this.server._id + '/share/banner/7.png'
          },
          {
            title: $localize`Minecraft Server Banner`,
            url: environment.apiServer + 'servers/' + this.server._id + '/share/banner/8.png'
          },
          {
            title: $localize`Minecraft Server Banner`,
            url: environment.apiServer + 'servers/' + this.server._id + '/share/banner/9.png'
          },
          {
            title: $localize`Minecraft Server Banner`,
            url: environment.apiServer + 'servers/' + this.server._id + '/share/banner/10.png'
          }
        ]


        this.titleService.setTitle($localize`Server: ${this.server.domain} - ${this.pageName} | Night.design`);

        const desc = this.pageDesc;
        if (desc) {
          this.metaTagService.addTag({ property: 'description', content: desc });
          this.metaTagService.addTag({ property: 'og:description', content: desc });
        }

        this.metaTagService.addTag({ property: 'og:image', content: this.serverBanner[0].url });
        this.metaTagService.addTag({ property: 'og:image:type', content: "image/png" });
        this.metaTagService.addTag({ property: 'og:image:alt', content: this.server.domain });
        this.metaTagService.addTag({ name: 'twitter:card', content: 'summary_large_image' });
        if (this.server.statistic?.favicon) {
          this.metaTagService.addTag({ property: 'og:image', content: this.server.statistic?.favicon.image });
          this.metaTagService.addTag({ property: 'og:image:type', content: "image/png" });
          this.metaTagService.addTag({ property: 'og:image:alt', content: this.server.domain });
          this.metaTagService.addTag({ name: 'twitter:card', content: 'summary_large_image' });
        }

        const element = this.render.createElement('script');
        element.type = 'application/ld+json';
        element.text = JSON.stringify(this.server.statistic?.favicon ?
          [
            {
              "@context": "https://schema.org/",
              "@type": "ImageObject",
              "contentUrl": this.server.statistic?.favicon.image,
              "creditText": "Night.design",
              "creator": {
                "@type": "Person",
                "name": this.server.domain
              }
            },
            {
              "@context": "https://schema.org/",
              "@type": "ImageObject",
              "contentUrl": this.serverBanner[0].url,
              "creditText": "Night.design",
              "creator": {
                "@type": "Person",
                "name": this.server.domain
              }
            }
          ]
          : [
            {
              "@context": "https://schema.org/",
              "@type": "ImageObject",
              "contentUrl": this.serverBanner[0].url,
              "creditText": "Night.design",
              "creator": {
                "@type": "Person",
                "name": this.server.domain
              }
            }
          ]);
        this.render.appendChild(this.document.head, element);
      }, error => {
        if (error.error.status === 404)
          this.router.navigate(['/404']).then();
      });
    });
  }


  copied2Clipboard(share = false) {
    Swal.fire({
      icon: 'success',
      title: share ? $localize`Share Code in den Zwischenspeicher kopiert` : $localize`Server adresse in den Zwischenspeicher kopiert`,

      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    }).then();
  }


  getShareCode(banner: ServerBanner): { bbcode: string, html: string } {
    return {
      bbcode: `[url=${this.localUrl}][img]${banner.url}[/img][/url]`,
      html: `<a href="${this.localUrl}"><img src="${banner.url}" alt="${this.server.domain} - Minecraft Server Status"></a>`,
    }
  }

  get pageId(): string {
    return this.route.firstChild!.routeConfig!.path!;
  }
  get pageName(): string {
    const pageId = this.pageId;
    switch (pageId) {
      case 'details':
        return $localize`Informationen`;
      case 'statistics':
        return $localize`Statistiken`;
      case 'vote':
        return $localize`Voten`;

      default:
        return pageId;
    }
  }
  get pageDesc(): string | undefined {
    const pageId = this.pageId;
    switch (pageId) {
      case 'details':
        return $localize`Alle Informationen und Kommentare über ${this.server.domain}.`;
      case 'statistics':
        return $localize`Finde hier interessante Statistiken über ${this.server.domain} heraus.`;
      case 'vote':
        return $localize`Du kannst ${this.server.domain} mit einem Vote im Ranking unterstützen.`;

      default:
        return undefined;
    }
  }
}

export type ServerStatus = 'CHECKING' | 'INVALID' | 'OFFLINE' | 'ONLINE';

export interface Server extends BasicTarget {
  readonly domain: string;
  alternatives: [string];
  description?: string;
  readonly user?: User;
  readonly socials: {
    website?: string;
    store?: string;
    discord?: string;
    teamspeak?: string;
  };
  vote_rewards: boolean;
  readonly status: ServerStatus;
  readonly statistic?: ServerStatistic;
  readonly checkedAt: string;
  readonly timestamp: string;
}

export interface ServerStatistic {
  readonly _id: string;
  readonly ping: number;
  readonly players: {
    readonly online: number;
    readonly max: number;
    readonly sample: [{
      readonly id: string;
      readonly name: string;
    }];
  };
  readonly version: {
    readonly name: string;
    readonly protocol: number;
  };
  readonly motd: {
    readonly raw: string;
    readonly clean: string;
    readonly html: string;
  };
  readonly favicon: File;
  readonly offline: boolean;
  readonly timestamp: string;
}

export interface ServerBanner {
  readonly title: string;
  readonly url: string;
}
