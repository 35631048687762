<ng-container *ngIf="!isAuthorized && managedByGoogle && !developmentMode">
  <div class="card" *ngIf="['top-banner','side-banner'].includes(type)">
    <div class="card-block d-flex justify-content-center">
      <ng-adsense adSlot="3430292161" adFormat="format" [fullWidthResponsive]="true"></ng-adsense>
    </div>
  </div>

  <div class="card" *ngIf="['article-banner','comment-banner'].includes(type)">
    <div class="card-block d-flex justify-content-center">
      <ng-adsense adSlot="8706515364" layout="in-article" adFormat="fluid"></ng-adsense>
    </div>
  </div>

  <div class="card" *ngIf="type === 'bottom-banner'">
    <div class="card-block d-flex justify-content-center">
      <ng-adsense adSlot="3852262289" adFormat="autorelaxed"></ng-adsense>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="developmentMode">
  <div class="card">
    <div class="card-block">
      <img class="img-fluid" src="https://via.placeholder.com/2000x160.png?text=AD+Placement+Banner">
    </div>
  </div>
</ng-container>
