import {Component, OnDestroy, OnInit} from '@angular/core';
import {Server} from "../server-viewer/server-viewer.component";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {SimpleRequest} from "../services/simple-request";
import {environment} from "../../environments/environment";
import {response} from "express";
import {Socket} from "ngx-socket-io";

@Component({
  selector: 'app-gameserver',
  templateUrl: './gameserver.component.html',
  styleUrls: ['./gameserver.component.css']
})
export class GameserverComponent implements OnInit, OnDestroy {
  server!: GameServer;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private socket: Socket,
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let id = params['id'];
      this.http.get<SimpleRequest<{ server: GameServer }>>(environment.apiServer + 'gameserver/' + id).subscribe(response => {
        this.server = response.data.server;
        this.titleService.setTitle($localize`Server: ${this.server.name} - ${this.pageName} | Night.design`);

        this.socket.emit("gameServer.subscribe", this.server._id);
        this.socket.on("reconnect", () => this.socket.emit("gameServer.subscribe", this.server._id));

        this.socket.on("gameServer.status", (serverId: string, state: "INIT" | "INSTALLING" | "STARTING" | "OFFLINE" | "ONLINE" | "CRASHED" | "PAUSED" | "LOCKED" | "DELETED") => {
          if (this.server._id !== serverId)
            return;
          this.server.status = state;
        });
      }, error => {
        if (error.error.status === 404)
          this.router.navigate(['/404']).then();
      });
    });
  }

  ngOnDestroy(): void {
    this.socket.emit("gameServer.cancelSubscription", this.server._id);
  }

  runAction(action: "start" | "stop" | "restart") {
    this.http.post<SimpleRequest>(environment.apiServer + 'gameserver/' + this.server._id + '/' + action, {}).subscribe(response => {

    });
  }
  canRunAction(action: "start" | "stop" | "restart" | "execute"): boolean {
    // "INIT" | "INSTALLING" | "STARTING" | "STOPPING" | "OFFLINE" | "ONLINE" | "CRASHED" | "PAUSED" | "LOCKED" | "DELETED";
    switch (action) {
      case "start":
        return ["OFFLINE","CRASHED"].includes(this.server.status);
      case "stop":
      case "execute":
        return "ONLINE" === this.server.status;
      default:
        return false;
    }
  }

  get pageId(): string {
    return this.route.firstChild!.routeConfig!.path!;
  }
  get pageName(): string {
    const pageId = this.pageId;
    switch (pageId) {
      case 'console':
        return $localize`Server Konsole`;

      default:
        return pageId;
    }
  }
}

export interface GameServer {
  readonly _id: string;
  name: string;
  connection: {
    readonly address: string;
    readonly port: number;
  }
  java: {
    version: number;
    args: string[];
    useAikarFlags: boolean;
    doneRegex: string;
  }
  software: {
    type: string;
    version: string;
  }
  readonly device_limits: {
    readonly memory: number;
    readonly disk: number;
    readonly cpus: number;
  }

  status: "INIT" | "INSTALLING" | "STARTING" | "STOPPING" | "OFFLINE" | "ONLINE" | "CRASHED" | "PAUSED" | "LOCKED" | "DELETED";
  expires: string;
  createdAt: string;
}

export interface ServerFile {
  readonly path: string;
  readonly name: string;
  readonly size: number;
  readonly updatedAt: string;
  readonly createdAt: string;
  readonly isFile: boolean;
  readonly isDirectory: boolean;
  readonly buffer: string;
}
