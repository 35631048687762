import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ModderViewerComponent} from "../../modder-viewer.component";
import {Party} from "../../../modder/modder.component";
import {User} from "../../../user/user.service";
import {Resource} from "../../../resource/resource.service";
import {File} from "../../../interfaces/file";
import {environment} from "../../../../environments/environment";
import {AuthService} from "../../../services/auth.service";
import {Cosmetics, localizeCosmetic} from "../../../interfaces/player-cosmetics";
import {Socket} from "ngx-socket-io";
import {Member} from "../../settings/members/members.component";

@Component({
  selector: 'app-queue',
  templateUrl: './moderation-queue.component.html',
  styles: [
    '.card-img-top { background-image: url("../../../../assets/img/preview-background.webp"); object-fit: scale-down; height: 14em; }',
  ]
})
export class ModerationQueueComponent implements OnInit, OnDestroy {
  party!: Party;
  memberView!: Member | undefined;
  queue: ModerateQueueElement[] = [];

  audio: HTMLAudioElement = new Audio('/assets/sounds/notification_queue.mp3');

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private socket: Socket,
    @Inject(ModderViewerComponent) private parent: ModderViewerComponent
  ) {}

  ngOnInit(): void {
    this.party = this.parent.party;
    this.memberView = this.parent.memberView;
    this.audio.volume = 0.7;

    this.socket.on('party.moderation_queue', this.queueElementListener(this.party));

    this.auth.getUserData().subscribe(user => {
      this.http.get<QueueRequest>(environment.apiServer + 'mods/' + this.party._id + '/moderation/queue').subscribe(response => {
        this.queue = response.data.queue.map(element => {
          return { status: element.user._id === user.data.user._id ? 'SELF' : undefined, ...element };
        }) as ModerateQueueElement[];
      });
    });

    /*this.auth.getUserData().subscribe(user => {
      this.queue.push({
        _id: '',
        file: {
          _id: '',
          player: 'test.png',
          timestamp: new Date().toISOString(),
          mime: 'image/png',
          size: 5468,
          image: 'https://cdn.night.design/files/629b971555cc88384acf2569.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaWxlIjoiNjI5Yjk3MTU1NWNjODgzODRhY2YyNTY5IiwiaWF0IjoxNjY1MTcxMDU5LCJleHAiOjE2NjUxNzQ2NTl9.hlzTMRI6ZObNDNY8Bs2txZdt75W5G6UudoUCx65ZlWo'
        },
        cosmetic: 'cloaks',
        type: 'CUSTOM_UPLOAD',
        user: user.data.user,
        status: undefined
      })
    })*/
  }

  ngOnDestroy() {
    this.socket.removeAllListeners('client_id.moderation_queue');
  }

  queueElementListener(party: Party) {
    return (party_: string, element: QueueElement) => {
      if (party._id !== party_)
        return;

      this.audio.currentTime = 0;
      this.audio.play().then();

      this.queue.push({status: undefined, ...element} as ModerateQueueElement);
    }
  }

  get queueElements() {
    return this.queue.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
  }

  moderateQueueElement(element: ModerateQueueElement, allow: boolean) {
    element.status = 'UPDATING';
    this.http.patch<QueueRequest>(environment.apiServer + 'mods/' + this.party._id + '/moderation/queue/' + element._id, { allow }).subscribe(response => {
      element.status = allow ? 'ACCEPTED' : 'DENIED';
    });
  }

  localizeCosmetic(cosmetic: Cosmetics): string {
    return localizeCosmetic(cosmetic);
  }
}

export interface QueueRequest {
  readonly status: number;
  readonly message: string;
  readonly data: {
    readonly queue: QueueElement[]
  };
}
export type QueueType = 'RESOURCE' | 'CUSTOM_UPLOAD';
export interface QueueElement {
  readonly _id: string;
  readonly type: QueueType;
  readonly cosmetic: Cosmetics;
  readonly user: User;
  readonly resource?: Resource;
  readonly file?: File;
  readonly timestamp: string;
}
export interface ModerateQueueElement extends QueueElement {
  status: 'ACCEPTED' | 'DENIED' | 'UPDATING' | 'SELF' | undefined;
}
