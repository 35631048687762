<div class="page-header">
  <div class="page-block">
    <div class="row">
      <div class="col-md-8">
        <div class="page-header-title">
          <h5 class="m-b-10" i18n>Home</h5>
        </div>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-end">
          <li class="breadcrumb-item"><a routerLink="/"><i class="fa-solid fa-house-blank"></i></a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="page-content">
  <h1 class="text-center mb-3" i18n>Features</h1>
  <div class="row justify-content-center">
    <div class="col-md-4 me-md-5">
      <div class="card">
        <img errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" lazyLoad="/assets/img/examples/search.webp">
        <div class="card-body">
          <h5 class="card-title" i18n>Verbesserte Suche</h5>
          <p class="card-text" i18n>
            Mit wenig aufwand kannst du nun dein lieblings Set an Cosmetics auf einem Blick finden!
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <img errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" lazyLoad="/assets/img/examples/api.webp">
        <div class="card-body">
          <h5 class="card-title" i18n>Offene API</h5>
          <p class="card-text" i18n>
            Wir sind die erste Cosmetics Library mit Offener API für Client/Mod Entwickler! Einfach Client/Mod registrieren und loslegen!
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <img errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" lazyLoad="/assets/img/examples/morecosmetics-mod.webp">
        <div class="card-body">
          <h5 class="card-title" i18n>Neue Cosmetics</h5>
          <p class="card-text" i18n>
            Unser Partner CosmeticsMod bringt mit ihrem Mod und unserer Library neue Cosmetics wie Schilder ins Spiel!<br>
            Hierzu brachten sie <mark>MoreCosmetics Mod</mark> heraus, durch diesen Mod bekommt man ein Cloak das man über unserer Seite mit einer eigenen Textur anpassen kann.
          </p>
        </div>
      </div>
    </div>
  </div>
  
  <h1 class="text-center mb-3" i18n>Partner</h1>
  <div class="row justify-content-center">
    <!--<div class="col-md-2 text-center">
      <h2>NightClient</h2>
      <a href="https://nightclient.net?ref=NightDesign" target="_blank">
        <img class="img-fluid" style="height: 11em" errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" lazyLoad="/assets/img/partner/nightClient.webp">
      </a>
    </div>-->
    <div class="col-md-2 text-center">
      <h2>Halfhosting</h2>
      <a href="https://manager.halfhosting.de?tag=mvuco5kmr1m" target="_blank">
        <img class="img-fluid" style="height: 9em" errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" lazyLoad="/assets/img/partner/halfhosting.webp">
      </a>
    </div>
    <div class="col-md-2 text-center">
      <h2>CosmeticsMod</h2>
      <a href="https://cosmeticsmod.com?ref=NightDesign" target="_blank">
        <img class="img-fluid" style="height: 9em" errorImage="/assets/img/preview-notFound.gif" defaultImage="/assets/img/loading-preview.gif" lazyLoad="/assets/img/partner/cosmetics-mod.webp">
      </a>
    </div>
  </div>
</div>
