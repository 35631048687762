import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'loadingArea',
  templateUrl: './loading.component.html'
})
export class LoadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
