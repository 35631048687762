import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {concat, delay, distinctUntilChanged, map, merge, Observable, of, Subject, switchMap, tap} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {SimpleRequest} from "../../services/simple-request";
import {User} from "../../user/user.service";
import {Player} from "../../server-viewer/vote/vote.component";

@Component({
  selector: 'auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.css']
})
export class AutoCompleteComponent implements OnInit {

  @Input()
  type: "RESOURCES" | "PLAYERS" | "USERS" = "RESOURCES"

  @Input()
  returnId = false;
  @Input()
  addTag = false
  @Input()
  multiple = false;
  @Input()
  placeholder = "";

  @Input()
  selectedInput?: any;
  @Output()
  selectedInputChange = new EventEmitter<any>();


  items!: Observable<EntryResult[]>;
  loading = false;
  input = new Subject<string>();

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  trackByFn(item: any) {
    return item.id;
  }

  update() {
    this.selectedInputChange.emit(this.selectedInput);
  }

  private loadData() {
    this.items = concat(
      of([]), // default items
      this.input.pipe(
        distinctUntilChanged(),
        tap(() => this.loading = true),
        switchMap(term => this.getData(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.loading = false)
        ))
      )
    );
  }

  getData(q: string = ""): Observable<EntryResult[]> {
    const responses: Observable<EntryResult[]>[] = [];

    if (!q)
      q = "";
    else
      q = q.trim();

    switch (this.type) {
      case "PLAYERS":
        responses.push(this.http.get<SimpleRequest<{ players: Player[] }>>(`${environment.apiServer}/players`, { params: { q }}).pipe(map(response =>
          response.data.players.map<EntryResult>(({uuid, name}) => ({ _id: uuid, name, avatar: `https://crafatar.com/avatars/${uuid}.webp` }))
        )));
        break;

      case "USERS":
        responses.push(this.http.get<SimpleRequest<{ users: User[] }>>(`${environment.apiServer}/users`, { params: { q }}).pipe(map(response =>
          response.data.users.map<EntryResult>(({_id, username, avatar}) => ({ _id, name: `@${username}`, avatar }))
        )));
        break;

      case "RESOURCES":
        if (q.startsWith("@"))
          responses.push(this.http.get<SimpleRequest<{ users: User[] }>>(`${environment.apiServer}/users`, { params: { q: q.replace('@','') }}).pipe(map(response =>
            response.data.users.map<EntryResult>(({_id, username, avatar}) => ({ _id, name: `@${username}`, avatar }))
          )));
        else
          responses.push(this.http.get<SimpleRequest<{ autocomplete: EntryResult[] }>>(`${environment.apiServer}/resources/autocomplete`, { params: { q }}).pipe(map(response => response.data.autocomplete)));
    }

    return merge(...responses);
  }
}
export interface EntryResult {
  readonly _id: string;
  readonly name: string;
  readonly resources?: number;
  readonly avatar?: string;
}
